import React, { FC, PropsWithChildren, useEffect } from 'react';
import nextId from 'react-id-generator';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { arrsToSelectableObject, isValidForm } from '@core/utils/methods';
import { uniqueValidator } from '@core/utils/validators';
import { ModalMainTypes } from '@models/enums';
import { EquipmentTabData, SiteAuditRaycapsData } from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { Autocomplete, InputNumber } from '@shared/modules';
import { SiteAuditActions } from '@store/actions';
import { SiteAuditSelectors } from '@store/selectors';
import { SiteAuditModalFormData } from '../../models/interfaces';
import { SiteAuditRaycapModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

export const RaycapModal: FC<SiteAuditRaycapModalProps> = ({
  visible,
  modalType,
  toggleModal,
  itemValue,
}: PropsWithChildren<SiteAuditRaycapModalProps>) => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const { raycapEquipments, raycapsTableData } = useSelector(
    SiteAuditSelectors.getRaycapModalInfo,
  );

  const getFormData = (): SiteAuditModalFormData => {
    const {
      equipment: { modelNumber },
      ...rest
    } = itemValue;

    return {
      ...rest,
      equipment: modelNumber || '',
      ...form.getFieldsValue(),
    };
  };

  const add = async (value: SiteAuditModalFormData): Promise<void> => {
    if (await isValidForm(form)) {
      dispatch(
        SiteAuditActions.addRaycapItemAction.done({
          ...value,
          equipment: { id: value.equipment } as EquipmentTabData,
          generatedId: nextId('Raycap'),
        }),
      );
      toggleModal(modalType);
    }
  };

  const edit = async (value: SiteAuditModalFormData): Promise<void> => {
    if (await isValidForm(form)) {
      dispatch(
        SiteAuditActions.updateRaycapItemAction.done({
          ...value,
          equipment: { id: value.equipment } as EquipmentTabData,
        }),
      );
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    const {
      equipment: { id },
      ...rest
    } = itemValue;

    if (visible) {
      form.setFieldsValue({ ...rest, equipment: id || '' });
    }
  }, [visible, itemValue]);

  const validationValues =
    modalType === ModalMainTypes.Edit
      ? raycapsTableData.filter(
          (raycap: SiteAuditRaycapsData) =>
            raycap.generatedId !== itemValue.generatedId,
        )
      : raycapsTableData || [];

  return (
    <AddOrEditModal
      visible={visible}
      type={modalType}
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getFormData())
          : (): Promise<void> => edit(getFormData())
      }
      onCancel={(): void => toggleModal(modalType)}
      title={
        modalType === ModalMainTypes.Add ? 'Add New Raycap' : 'Edit raycap'
      }
      formProps={{ labelCol: { span: 7 }, form }}
      className="prov-engineering-raycap-modal"
      okText={modalType === ModalMainTypes.Add ? 'Add Raycap' : 'Save'}
    >
      <Autocomplete
        id="equipment"
        label="Raycap"
        options={arrsToSelectableObject(raycapEquipments, 'id', 'modelNumber')}
        formItemProps={{
          rules: [
            { required: true, message: 'Raycap name is required!' },
            uniqueValidator(
              validationValues?.map((item: SiteAuditRaycapsData) => ({
                ...item.equipment,
              })),
              'id',
              'Raycap name',
            ),
          ],
        }}
      />
      <InputNumber
        id="quantity"
        label="Quantity"
        formItemProps={{
          rules: [{ required: true, message: 'Quantity is required!' }],
        }}
      />
    </AddOrEditModal>
  );
};
