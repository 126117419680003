import { DriverWorkflow } from '@symfa-inc/providence-verizon-types';
import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  DriverManagementHttpService,
  ProjectManagementHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';

export class ProjectManagementDetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await HttpService.getHttpRequests(
      ProjectManagementHttpService,
    ).getProjectManagementDetails(projectBundleID);

    await HttpService.getHttpRequests(
      DriverManagementHttpService,
    ).getDriversListByWorkflow(DriverWorkflow.ProjectManagement);
  }
}
