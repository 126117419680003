import { useEffect } from 'react';
import { Undefinable } from '@symfa-inc/providence-verizon-types';

export const useSaveChanged = (
  isEditing: Undefinable<boolean>,
  onSubmit: () => Promise<void>,
  onCancel: () => void,
): void => {
  useEffect(() => {
    if (!isEditing) return;

    window.addEventListener('save-changes', onSubmit);
    window.addEventListener('discard-changes', onCancel);

    return (): void => {
      window.removeEventListener('save-changes', onSubmit);
      window.removeEventListener('discard-changes', onCancel);
    };
  }, [isEditing, onSubmit, onCancel]);
};
