import React, { FC, ReactText, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  IRequiresAttention,
  LINKS,
  UserRole,
} from '@symfa-inc/providence-verizon-types';
import { HttpService } from '@core/services';
import { DashboardHttpService } from '@core/services/http';
import { tableCount } from '@models/constants';
import {
  DashboardItem,
  MultiUpdateAssignees,
  ToDoOrRequiresAttentionsUpdateData,
} from '@models/interfaces';
import { CustomTable, Search, TableContainer } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { DashboardSelectors, UserSelectors } from '@store/selectors';
import { EditDashboardItem, MultiSetAssignees } from '../../modals';
import { requiresAttentionSearcher } from './helpers';
import { REQUIRES_ATTENTION_COLUMNS, RequiresAttentionProps } from './models';

export const RequiresAttentionTable: FC<RequiresAttentionProps> = ({
  type,
  history,
}: RequiresAttentionProps) => {
  const dashboardService = HttpService.getHttpRequests(DashboardHttpService);

  const items = useSelector(DashboardSelectors.getRequiresAttentionItems);
  const isRequiresAttentionItemsFetching = useSelector(
    DashboardSelectors.isRequiresAttentionItemsFetching,
  );
  const adminList = useSelector(DashboardSelectors.getAdminList);
  const currentUser = useSelector(UserSelectors.activeUser);

  const [tableData, setTableData] =
    useState<DashboardItem<IRequiresAttention>[]>(items);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  // edit record
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [editableRecord, setEditableRecord] =
    useState<DashboardItem<IRequiresAttention> | null>(null);
  // multi assign
  const [multiAssignModalVisible, setMultiAssignModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    dashboardService[
      type === 'assigned'
        ? 'getAssignedRequiresAttentionItems'
        : 'getAllRequiresAttentionItems'
    ]();
  }, [type]);

  useEffect(() => {
    setTableData(() =>
      requiresAttentionSearcher(items, searchValue, adminList),
    );
    setSelectedRows([]);
  }, [searchValue, items, adminList]);

  const showEditModalHandler = useCallback(
    (dashboardItem: DashboardItem<IRequiresAttention>): void => {
      setEditableRecord(dashboardItem);
      setEditModalVisible(true);
    },
    [],
  );

  const hideEditModalHandler = useCallback((): void => {
    setEditableRecord(null);
    setEditModalVisible(false);
  }, []);

  const saveRequiresAttentionChanges = useCallback(
    async (
      updatableData: ToDoOrRequiresAttentionsUpdateData,
    ): Promise<void> => {
      await dashboardService.updateRequiresAttentionItem(updatableData);
    },
    [],
  );

  const showMultiAssignModal = useCallback(
    (): void => setMultiAssignModalVisible(true),
    [],
  );

  const hideMultiAssignModal = useCallback(
    (): void => setMultiAssignModalVisible(false),
    [],
  );

  const multiUpdateAssignees = useCallback(
    async (data: Omit<MultiUpdateAssignees, 'recordIds'>): Promise<void> => {
      await dashboardService.multiUpdateRequiresAttentionAssignees({
        recordIds: selectedRows as string[],
        ...data,
      });

      setSelectedRows([]);
    },
    [selectedRows],
  );

  const changeRowSelected = useCallback(
    (rows: ReactText[]): void => setSelectedRows(rows),
    [],
  );

  const onRowClick = useCallback(
    ({
      notificationData: { redirectLinkKey },
      projectData: { projectBundleID },
    }: DashboardItem<IRequiresAttention>): void => {
      if (redirectLinkKey && projectBundleID) {
        history.push(LINKS[redirectLinkKey](projectBundleID));
      }
    },
    [history],
  );

  return (
    <>
      <TableContainer
        header="Requires Attention"
        className="prov-dashboard-panel"
      >
        <div className="prov-dashboard-panel__controls">
          <Search className="search" onChange={setSearchValue} allowClear />
          <PrimaryButton
            title="Set Assignees"
            onClick={showMultiAssignModal}
            disabled={!selectedRows.length}
          />
        </div>
        <CustomTable
          columns={REQUIRES_ATTENTION_COLUMNS(adminList, showEditModalHandler)}
          dataSource={tableData}
          place={tableCount(true)}
          pagination={{
            total: tableData?.length,
          }}
          onRowClick={onRowClick}
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: changeRowSelected,
          }}
          module="home"
          subModule={
            type === 'assigned'
              ? 'assignedRequiresAttention'
              : 'allRequiresAttention'
          }
          loading={isRequiresAttentionItemsFetching}
          legendProps={{
            items: [
              { value: 'Information', color: 'gray' },
              { value: 'Warning', color: 'high' },
            ],
          }}
        />
      </TableContainer>
      <EditDashboardItem
        visible={editModalVisible}
        editableRecord={editableRecord}
        closeHandler={hideEditModalHandler}
        saveHandler={saveRequiresAttentionChanges}
        title="Requires Attention"
        adminList={adminList}
        userId={currentUser.id}
        isAdminUser={currentUser.roles.includes(UserRole.Admin)}
      />
      <MultiSetAssignees
        visible={multiAssignModalVisible}
        closeHandler={hideMultiAssignModal}
        saveHandler={multiUpdateAssignees}
        adminList={adminList}
        userId={currentUser.id}
        isAdminUser={currentUser.roles.includes(UserRole.Admin)}
      />
    </>
  );
};
