import { ScheduleOperator } from '@symfa-inc/providence-verizon-types';

export const getCurrentOperator = (value: ScheduleOperator): string => {
  switch (value) {
    case ScheduleOperator.GreaterThan:
      return 'Greater Than';
    case ScheduleOperator.LessThan:
      return 'Less Than';
    default:
      throw new Error('Incorrect value');
  }
};
