import { FlagOutlined } from '@ant-design/icons/lib';
import { DriverWorkflow, SubPage } from '@symfa-inc/providence-verizon-types';
import { RedirectGuard } from '@core/guards';
import { tabPropsToTabs } from '@core/utils/methods';
import { Route, RouteGroup } from '@models/classes';
import { LEASING_TABS_PROPS } from '@models/constants';
import { MenuPlacement } from '@models/enums';
import { FinancialResolver } from '@shared/components/financial/resolvers';
import { LeasingBrowseResolver } from './components/browse/leasing-browse.resolver';
import { LeasingBrowse, LeasingDetails } from './components';
import { LeasingDetailsResolver } from './resolvers';

export const leasingRoutes = new RouteGroup({
  name: 'Leasing',
  path: '/leasing',
})
  .breadcrumb()
  .addGuard(new RedirectGuard('leasing'), '/')
  .setMenu(MenuPlacement.Main, FlagOutlined)
  .addRoute(
    new Route({
      name: 'Browse',
      path: '/browse',
      component: LeasingBrowse,
    })
      .addResolver(new LeasingBrowseResolver(), false)
      .breadcrumb()
      .addGuard(new RedirectGuard('leasing', SubPage.LeasingBrowse), '/')
      .setMenu(MenuPlacement.Main)
      .addRoute(
        new Route({
          name: ':siteName(:projectBundleID)',
          path: '/:leasingId/details',
          component: LeasingDetails,
          extendPath: false,
        })
          .addResolver(new LeasingDetailsResolver())
          .addResolver(new FinancialResolver('leasing', DriverWorkflow.Leasing))
          .breadcrumb()
          .addTabs(tabPropsToTabs(LEASING_TABS_PROPS)),
      ),
  );
