import React, { FC, PropsWithChildren } from 'react';
import { Form } from 'antd';
import classNames from 'classnames';
import { ShowMoreInput } from '@shared/modules';
import { LeasingEquipmentNotesProps } from './models';

export const LeasingEquipmentNotes: FC<LeasingEquipmentNotesProps> = ({
  form,
  isEditing,
  className,
  onChanges,
  ...props
}: PropsWithChildren<LeasingEquipmentNotesProps>) => (
  <Form
    form={form}
    className={classNames(className, 'prov-leasing-equipment__notes')}
  >
    <ShowMoreInput
      {...props}
      form={form}
      isEditing={isEditing}
      className="prov-leasing-equipment__notes-textarea"
      elementProps={{
        autoSize: { minRows: 3 },
        onChange: () => onChanges?.(),
      }}
    />
  </Form>
);
