import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ISchedule,
  LinkKey,
  NotificationType,
  NotifyType,
  PaceTaskType,
  ScheduleOperator,
  UserRole,
} from '@symfa-inc/providence-verizon-types';
import { Form } from 'antd';
import { SelectValue } from 'antd/es/select';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';
import {
  arraySort,
  getCurrentDateType,
  getCurrentOptions,
  getEnumKeyByEnumValue,
  isValidForm,
  stringEnumToSelectableObject,
} from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import {
  NotifyTypeControls,
  ProjectTypeControls,
} from '@notifications/components';
import { getCurrentOperator } from '@notifications/helpers';
import { NotifyTypeControlsRefType } from '@notifications/models';
import { AddOrEditModal } from '@shared/components';
import {
  Autocomplete,
  Checkbox,
  InputNumber,
  Select,
  TextArea,
} from '@shared/modules';
import { ScheduleModalProps } from '../models';

import './styles.scss';

const { useForm } = Form;

const OperatorSelector: FC = () => (
  <Select
    id="operator"
    label="Operator"
    formItemProps={{
      rules: [{ required: true, message: 'Operator is required!' }],
    }}
    options={arraySort(
      getCurrentOptions(ScheduleOperator, getCurrentOperator),
      'ASC',
      'viewValue',
    )}
  />
);

export const ScheduleModal: FC<ScheduleModalProps> = ({
  itemValue,
  visible,
  modalType,
  toggleModal,
  paceTaskList,
  userList,
  towerOwners,
}: PropsWithChildren<ScheduleModalProps>) => {
  const notificationsService = HttpService.getHttpRequests(
    NotificationsHttpService,
  );

  const [form] = useForm();

  const ref = useRef<NotifyTypeControlsRefType>(null);

  const [autoWarning, setAutoWarning] = useState<boolean>(false);
  const [immediately, setImmediately] = useState<boolean>(false);
  const [notificationType, setNotificationType] =
    useState<NotificationType | null>(null);
  const [hasAdminRole, setAdminRolePresence] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...(itemValue || {}),
      });

      if (itemValue) {
        setAutoWarning(!!itemValue.hasAutoWarningChecking);
        setNotificationType(itemValue.notificationType);
        setImmediately(itemValue.isImmediately);

        if (itemValue.notifyType === NotifyType.Role) {
          setAdminRolePresence(itemValue.roles!.includes(UserRole.Admin));
        }

        ref.current?.changeNotifyType(
          getEnumKeyByEnumValue(NotifyType, itemValue.notifyType),
        );
        ref.current?.changeRoles(itemValue.roles || []);
      }
    }
  }, [visible, itemValue, form]);

  const onClose = (): void => {
    toggleModal(modalType);

    form.resetFields();

    setAutoWarning(false);
    setImmediately(false);
    setNotificationType(null);

    ref.current?.changeNotifyType(null);
    ref.current?.changeRoles(null);
  };

  const add = async (): Promise<void> => {
    if (await isValidForm(form)) {
      const { hasAutoWarningChecking, isImmediately, ...schedule } =
        form.getFieldsValue() as Omit<ISchedule, '_id'>;

      await notificationsService.createSchedule({
        ...schedule,
        hasAutoWarningChecking,
        ...(isImmediately !== undefined
          ? { isImmediately }
          : { isImmediately: false }),
        ...(hasAutoWarningChecking
          ? { needNotifyAdmin: schedule.needNotifyAdmin ?? false }
          : {}),
      });

      onClose();
    }
  };

  const edit = async (): Promise<void> => {
    if (await isValidForm(form)) {
      const { hasAutoWarningChecking, ...schedule } =
        form.getFieldsValue() as Omit<ISchedule, '_id'>;

      await notificationsService.updateSchedule(itemValue!._id, {
        ...schedule,
        hasAutoWarningChecking,
        ...(hasAutoWarningChecking
          ? { needNotifyAdmin: schedule.needNotifyAdmin ?? false }
          : {}),
      });

      onClose();
    }
  };

  const onOk = async (): Promise<void> => {
    await (modalType === ModalMainTypes.Add ? add() : edit());
  };

  const paceTaskTypeOptions = arraySort(
    getCurrentOptions(PaceTaskType, getCurrentDateType),
    'ASC',
    'viewValue',
  );

  const validateField = (): Promise<void> => {
    const { startingField, startingDateType, endingField, endingDateType } =
      form.getFieldsValue();

    return `${startingField}_${startingDateType}` ===
      `${endingField}_${endingDateType}`
      ? Promise.reject(
          new Error(
            'Starting/Ending Field with their Type Field are must be different',
          ),
        )
      : Promise.resolve();
  };

  return (
    <AddOrEditModal
      onOk={onOk}
      onCancel={onClose}
      visible={visible}
      type={modalType}
      title="Schedule"
      okText="Save"
      cancelText="Cancel"
      className="prov-schedule-modal"
      formProps={{
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
        form,
      }}
    >
      <Autocomplete
        id="startingField"
        label="Starting Field"
        formItemProps={{
          dependencies: ['startingDateType', 'endingField', 'endingDateType'],
          rules: [
            { required: true, message: 'Starting Field is required!' },
            { validator: validateField },
          ],
        }}
        options={paceTaskList}
      />
      <Select
        id="startingDateType"
        label="Starting Date Type"
        formItemProps={{
          dependencies: ['startingField', 'endingField', 'endingDateType'],
          rules: [
            { required: true, message: 'Starting Date Type is required!' },
            { validator: validateField },
          ],
        }}
        options={paceTaskTypeOptions}
      />
      <Autocomplete
        id="endingField"
        label="Ending Field"
        formItemProps={{
          dependencies: ['startingField', 'startingDateType', 'endingDateType'],
          rules: [
            { required: true, message: 'Ending Field is required!' },
            { validator: validateField },
          ],
        }}
        options={paceTaskList}
      />
      <Select
        id="endingDateType"
        label="Ending Date Type"
        formItemProps={{
          dependencies: ['startingField', 'startingDateType', 'endingField'],
          rules: [
            { required: true, message: 'Ending Date Type is required!' },
            { validator: validateField },
          ],
        }}
        options={paceTaskTypeOptions}
      />
      <Checkbox
        id="isImmediately"
        label="Immediately"
        elementProps={{
          onChange: (v: CheckboxChangeEvent): void =>
            setImmediately(v.target.checked),
        }}
      />
      {!immediately && (
        <>
          <OperatorSelector />
          <InputNumber
            id="daysCount"
            label="Days Count"
            formItemProps={{
              rules: [{ required: true, message: 'Days Count is required!' }],
            }}
            elementProps={{ min: 0 }}
          />
        </>
      )}
      <Select
        id="notificationType"
        label="Notification Type"
        formItemProps={{
          rules: [
            { required: true, message: 'Notification Type is required!' },
          ],
        }}
        options={getCurrentOptions(
          NotificationType,
          (value: NotificationType) =>
            `${value.charAt(0).toUpperCase()}${value.slice(1)}`,
        )}
        elementProps={{
          onChange: (v: SelectValue): void => {
            setNotificationType(v as NotificationType);
            setAutoWarning(false);

            form.setFieldsValue({
              hasAutoWarningChecking: false,
            });
          },
        }}
      />
      <ProjectTypeControls isEditMode={itemValue != null} />
      <NotifyTypeControls
        ref={ref}
        form={form}
        userList={userList}
        towerOwners={towerOwners}
        setAdminRolePresence={setAdminRolePresence}
      />
      <TextArea
        id="message"
        label="Message"
        formItemProps={{
          rules: [{ required: true, message: 'Message is required!' }],
        }}
      />
      <Checkbox
        id="hasAutoWarningChecking"
        label="Auto Warning"
        tooltipMessage="Only available when Notification Type equals Info."
        elementProps={{
          onChange: (v: CheckboxChangeEvent): void =>
            setAutoWarning(v.target.checked),
          disabled: notificationType !== NotificationType.Info,
        }}
      />
      {autoWarning && (
        <>
          {immediately && <OperatorSelector />}
          <InputNumber
            id="warningDaysCount"
            label="Warning Days Count"
            formItemProps={{
              rules: [
                { required: true, message: 'Warning Days Count is required!' },
              ],
            }}
            elementProps={{ min: 0 }}
          />
          <TextArea
            id="warningMessage"
            label="Warning Message"
            formItemProps={{
              rules: [
                { required: true, message: 'Warning Message is required!' },
              ],
            }}
          />
          {!hasAdminRole && (
            <Checkbox id="needNotifyAdmin" label="Notify Admin" />
          )}
        </>
      )}
      <Autocomplete
        id="redirectLinkKey"
        label="Redirect page"
        formItemProps={{
          rules: [{ required: true, message: 'Redirect page is required!' }],
        }}
        options={arraySort(
          stringEnumToSelectableObject(LinkKey),
          'ASC',
          'viewValue',
        )}
      />
    </AddOrEditModal>
  );
};
