import React, { FC, PropsWithChildren } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons/lib';
import { message } from 'antd';
import { MessageType } from 'antd/lib/message';
import { Modal, PrimaryButton } from '@shared/modules';
import { FinancialExportModalProps } from './models';

import './styles.scss';

export const ExportModal: FC<FinancialExportModalProps> = ({
  data: { siteData, feeData },
  getSiteFeeTypeById,
  ...props
}: PropsWithChildren<FinancialExportModalProps>) => {
  const getFeeTemplate = (): string =>
    `${getSiteFeeTypeById(feeData.siteFeeType || '')} $ ${
      feeData.amount
    } (WHAT) PAID TO ${feeData.payee} (WHO) FOR ${
      feeData.siteFeesNote ?? ''
    } (WHY) ${feeData.paymentDate} (WHEN) FOR SITE ${
      siteData?.siteName
    } (WHERE)`;

  return (
    <Modal
      title="Exports fee"
      okText="OK"
      cancelText="Cancel"
      className="prov-financial-export-modal"
      {...props}
    >
      <div className="border-box">
        <span className="prov-financial-export-modal__text">
          {getFeeTemplate()}
        </span>
        <CopyToClipboard
          text={getFeeTemplate()}
          onCopy={(): MessageType => message.info('Copied to clipboard')}
        >
          <PrimaryButton
            title="Copy to clipboard with button"
            icon={<CopyOutlined className="copy-icon" rotate={180} />}
            className="copy-btn"
          />
        </CopyToClipboard>
      </div>
    </Modal>
  );
};
