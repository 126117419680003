import { RedirectGuard } from '@core/guards';
import { Route, RouteGroup } from '@models/classes';
import { MenuPlacement } from '@models/enums';
import { UserManagementResolver } from './resolvers';
import { UserManagement } from './user-management';

export const userManagementRoutes = new RouteGroup({
  name: 'User Management',
  path: '/user',
}).addRoute(
  new Route({
    name: 'User Management',
    path: '/management',
    component: UserManagement,
  })
    .addResolver(new UserManagementResolver())
    .breadcrumb()
    .addGuard(new RedirectGuard('userManagement'), '/')
    .setMenu(MenuPlacement.Header),
);
