import {
  IReceivedDirectMessage,
  IRequiresAttention,
  ISelectorOption,
  IToDo,
} from '@symfa-inc/providence-verizon-types';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  AccountingNotificationData,
  DashboardItem,
  MultiSetCompletedDirectMessage,
  MultiUpdateAssignees,
  SetCompletedDirectMessage,
  ToDoOrRequiresAttentionsUpdateData,
} from '@models/interfaces';
import { DashboardActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class DashboardHttpService extends CommonHttpService {
  // users
  @StoredRequest(DashboardActions.getAdminList)
  getAdminList(): Promise<ISelectorOption[]> {
    return this.http.callApi.get(`${this.apiUrl}/dashboard/admin-list`);
  }

  @StoredRequest(DashboardActions.getUserList)
  getUserList(): Promise<ISelectorOption[]> {
    return this.http.callApi.get(`${this.apiUrl}/dashboard/user-list`);
  }

  // Accounting
  @StoredRequest(DashboardActions.getAccountingItems)
  getAccountingItems(): Promise<DashboardItem<AccountingNotificationData>[]> {
    return this.http.callApi.get(`${this.apiUrl}/dashboard/accounting`);
  }

  // ToDo
  @StoredRequest(DashboardActions.getAssignedToDoItems)
  getAssignedToDoItems(): Promise<DashboardItem<IToDo>[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/dashboard/to-dos/assigned-to-me`,
    );
  }

  @StoredRequest(DashboardActions.getAllToDoItems)
  getAllToDoItems(): Promise<DashboardItem<IToDo>[]> {
    return this.http.callApi.get(`${this.apiUrl}/dashboard/to-dos`);
  }

  @StoredRequest(DashboardActions.updateToDoItem)
  async updateToDoItem({
    _id,
    reporter,
    ...body
  }: ToDoOrRequiresAttentionsUpdateData): Promise<ToDoOrRequiresAttentionsUpdateData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/dashboard/to-dos/${_id}`,
      body,
    );

    return {
      _id,
      reporter,
      ...body,
    };
  }

  @StoredRequest(DashboardActions.multiUpdateToDoAssignees)
  async multiUpdateToDoAssignees({
    reporter,
    ...body
  }: MultiUpdateAssignees): Promise<MultiUpdateAssignees> {
    await this.http.callApi.patch(
      `${this.apiUrl}/dashboard/to-dos/multi-assign`,
      body,
    );

    return {
      reporter,
      ...body,
    };
  }

  // Requires Attention
  @StoredRequest(DashboardActions.getAssignedRequiresAttentionItems)
  getAssignedRequiresAttentionItems(): Promise<
    DashboardItem<IRequiresAttention>[]
  > {
    return this.http.callApi.get(
      `${this.apiUrl}/dashboard/requires-attentions/assigned-to-me`,
    );
  }

  @StoredRequest(DashboardActions.getAllRequiresAttentionItems)
  getAllRequiresAttentionItems(): Promise<DashboardItem<IRequiresAttention>[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/dashboard/requires-attentions`,
    );
  }

  @StoredRequest(DashboardActions.updateRequiresAttentionItem)
  async updateRequiresAttentionItem({
    _id,
    reporter,
    ...body
  }: ToDoOrRequiresAttentionsUpdateData): Promise<ToDoOrRequiresAttentionsUpdateData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/dashboard/requires-attentions/${_id}`,
      body,
    );

    return { _id, reporter, ...body };
  }

  @StoredRequest(DashboardActions.multiUpdateRequiresAttentionAssignees)
  async multiUpdateRequiresAttentionAssignees({
    reporter,
    ...body
  }: MultiUpdateAssignees): Promise<MultiUpdateAssignees> {
    await this.http.callApi.patch(
      `${this.apiUrl}/dashboard/requires-attentions/multi-assign`,
      body,
    );

    return { reporter, ...body };
  }

  // Direct Messages
  @StoredRequest(DashboardActions.getDirectMessage)
  getDirectMessages(
    isCompleted: boolean,
  ): Promise<DashboardItem<IReceivedDirectMessage>[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/dashboard/direct-messages`,
      null,
      { params: { showCompleted: isCompleted } },
    );
  }

  @StoredRequest(DashboardActions.setCompletedDirectMessage)
  async setCompletedDirectMessage(
    _id: string,
    isCompleted: boolean,
  ): Promise<SetCompletedDirectMessage> {
    await this.http.callApi.patch(
      `${this.apiUrl}/dashboard/direct-messages/${_id}/completed`,
    );

    return { _id, isCompleted };
  }

  @StoredRequest(DashboardActions.multiSetCompletedDirectMessage)
  async multiSetCompletedDirectMessage(
    recordIds: string[],
    isCompleted: boolean,
  ): Promise<MultiSetCompletedDirectMessage> {
    await this.http.callApi.patch(
      `${this.apiUrl}/dashboard/direct-messages/multi-completed`,
      null,
      { params: { recordIds } },
    );

    return { recordIds, isCompleted };
  }
}
