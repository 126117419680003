import { AccountingBrowse, SiteBrowse } from '@models/interfaces';

export const ACCOUNTING_BROWSE_DEFAULT_VALUE: AccountingBrowse = {
  id: '',
  fuzeIDs: [],
  site: {} as SiteBrowse,
  projectType: {
    id: '',
    value: '',
  },
  projectPaceTaskData: [],
};
