import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ISelectorOption,
  Nullable,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';
import { Col, Row } from 'antd';
import Info from 'assets/icons/info.png';
import { HttpService } from '@core/services';
import { DropdownHttpService } from '@core/services/http';
import { ModalMainTypes } from '@models/enums';
import { AdminDropdownValuesData } from '@models/interfaces';
import { DDVSelectorKey, ScrollToInitRef } from '@models/types';
import { DeleteModal, VirtualTable } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { getAdminDropdownValuesColumns } from './models/constants';
import { DropdownValuesModal, SelectWithButton } from './components';

import './styles.scss';

export const Details: FC = () => {
  const virtualTableRef = useRef<ScrollToInitRef>(null);

  const { dropdownValuesOptions, dropdownValues } = useSelector(
    DDVSelectors.getDropdownValuesOptionsAndDropdownValues,
  );

  const [selectorValue, setSelectorValue] =
    useState<Undefinable<string>>(undefined);
  const [tableValues, setTableValues] = useState<AdminDropdownValuesData[]>([]);
  const [selectedItem, setSelectedItem] =
    useState<Nullable<AdminDropdownValuesData>>(null);
  const [activeDDV, setActiveDDV] =
    useState<Undefinable<ISelectorOption<string>>>(undefined);
  const [modalType, setModalType] = useState<Nullable<ModalMainTypes>>(null);
  const [isAddOrEditModalVisible, setAddOrEditEditModalVisible] =
    useState<boolean>(false);
  const [isDeleteModalVisible, setDeleteModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (selectorValue) {
      setTableValues(dropdownValues[selectorValue as DDVSelectorKey]);
    }
  }, [selectorValue, dropdownValues]);

  const changeDDV = (value: string): void => {
    setSelectorValue(value);
    virtualTableRef?.current?.scrollToInit();
  };

  const openAddModal = (data?: ISelectorOption<string>): void => {
    const currentActiveDDV =
      data ??
      dropdownValuesOptions.find(
        ({ value }: ISelectorOption) => value === selectorValue,
      );

    setActiveDDV(currentActiveDDV);
    setModalType(ModalMainTypes.Add);
    setAddOrEditEditModalVisible(true);
  };

  const openEditModal = (data: AdminDropdownValuesData): void => {
    setSelectedItem(data);
    setModalType(ModalMainTypes.Edit);
    setAddOrEditEditModalVisible(true);
  };

  const openRemoveModal = (data: AdminDropdownValuesData): void => {
    setSelectedItem(data);
    setDeleteModalVisible(true);
  };

  const onCancel = (modifyType: 'edit' | 'remove'): void => {
    setSelectedItem(null);

    if (modifyType === 'remove') {
      setDeleteModalVisible(false);
    } else if (modifyType === 'edit') {
      setAddOrEditEditModalVisible(false);
      setActiveDDV(undefined);
    }
  };

  const remove = async (): Promise<void> => {
    await HttpService.getHttpRequests(DropdownHttpService).deleteDropdownValue(
      selectedItem!.id,
      selectorValue!,
    );

    onCancel('remove');
  };

  return (
    <div className="prov-admin-dropdown-values">
      <Row className="prov-admin-dropdown-values__table-handler">
        <Col span="7">
          <div className="label">
            <img className="label__image" src={Info} alt="edit row" />
            <span className="label__text">
              Please select a drop-down value to edit
            </span>
          </div>
          <SelectWithButton
            value={selectorValue}
            onChange={changeDDV}
            onClickButton={openAddModal}
            options={dropdownValuesOptions}
          />
        </Col>
      </Row>
      <VirtualTable
        ref={virtualTableRef}
        dataSource={tableValues}
        columns={getAdminDropdownValuesColumns(
          selectorValue,
          openEditModal,
          openRemoveModal,
        )}
        maxViewRow={10}
      />
      <PrimaryButton
        icon="+"
        title="Add"
        className="prov-admin-dropdown-values__add-button"
        onClick={() => openAddModal()}
        disabled={!selectorValue}
      />
      <DropdownValuesModal
        visible={isAddOrEditModalVisible}
        modalType={modalType}
        closeModal={() => onCancel('edit')}
        selectedItemValue={selectedItem}
        activeDDV={activeDDV}
        selectorValue={selectorValue}
        setSelectorValue={setSelectorValue}
      />
      <DeleteModal
        visible={isDeleteModalVisible}
        onOk={remove}
        onCancel={() => onCancel('remove')}
      />
    </div>
  );
};
