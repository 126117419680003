import { UserRole } from '@symfa-inc/providence-verizon-types';
import store from '@store/index';
import { AppState } from '@store/reducers';

export const canEdit = (roles: UserRole[]): boolean => {
  const state: AppState = store.getState();
  const userRoles: UserRole[] = state.user.activeUser?.roles;

  return roles.some((role: UserRole) => userRoles.includes(role));
};
