import { AnyAction, CombinedState, combineReducers } from 'redux';
import * as accounting from './accounting.reducer';
import * as admin from './admin';
import * as breadcrumbs from './breadcrumbs.reducer';
import * as candidates from './candidates.reducer';
import * as common from './common.reducer';
import * as dashboard from './dashboard.reducer';
import * as directMessage from './direct-message.reducer';
import * as engineering from './engineering';
import * as equipment from './equipment.reducer';
import * as exportsReducer from './exports.reducer';
import * as financial from './financial.reducer';
import * as leasing from './leasing.reducer';
import * as paceTasks from './pace-tasks.reducer';
import * as permitting from './permitting.reducer';
import * as project from './project.reducer';
import * as projectFuzeIDs from './project-fuze-ids.reducer';
import * as projectManagement from './project-management.reducer';
import * as rad from './rad.reducer';
import * as site from './site.reducer';
import * as user from './user.reducer';
import * as userManagement from './user-management.reducer';

export interface AppState {
  accounting: accounting.AccountingState;
  admin: admin.AdminState;
  breadcrumbs: breadcrumbs.BreadcrumbsState;
  candidates: candidates.CandidatesState;
  common: common.CommonState;
  dashboard: dashboard.DashboardState;
  directMessage: directMessage.DirectMessageState;
  engineering: engineering.EngineeringState;
  equipment: equipment.EquipmentState;
  exports: exportsReducer.ExportsState;
  financial: financial.FinancialState;
  leasing: leasing.LeasingState;
  paceTasks: paceTasks.PaceTasksState;
  permitting: permitting.PermittingState;
  project: project.ProjectState;
  projectFuzeIDs: projectFuzeIDs.ProjectFuzeIDsState;
  projectManagement: projectManagement.ProjectManagementState;
  rad: rad.RADState;
  site: site.SiteState;
  user: user.UserState;
  userManagement: userManagement.UserManagementState;
}

const appReducer = combineReducers<AppState>({
  accounting: accounting.reducer,
  admin: admin.reducers,
  breadcrumbs: breadcrumbs.reducer,
  candidates: candidates.reducer,
  common: common.reducer,
  dashboard: dashboard.reducer,
  directMessage: directMessage.reducer,
  engineering: engineering.reducers,
  equipment: equipment.equipmentReducer,
  exports: exportsReducer.reducer,
  financial: financial.reducer,
  leasing: leasing.reducer,
  paceTasks: paceTasks.reducer,
  permitting: permitting.reducer,
  project: project.reducer,
  projectFuzeIDs: projectFuzeIDs.reducer,
  projectManagement: projectManagement.reducer,
  rad: rad.reducer,
  site: site.reducer,
  user: user.reducer,
  userManagement: userManagement.reducer,
});

export const reducers = (
  state: CombinedState<AppState> | undefined,
  action: AnyAction,
) => appReducer(action.type === 'USER_LOGOUT_DONE' ? undefined : state, action);
