import React, { FC, PropsWithChildren, useState } from 'react';
import classNames from 'classnames';
import { PrimaryButton } from '@shared/modules';
import { ViewElementProps } from './models';

import './styles.scss';

export const ViewElement: FC<ViewElementProps> = ({
  label,
  value,
  element,
  additionClassName,
}: PropsWithChildren<ViewElementProps>) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const valueElement = value && (
    <span className="prov-view-element__element">
      {isExpanded || value.length < 100
        ? value
        : `${value.toString().slice(0, 100)}...`}
    </span>
  );

  return (
    <div>
      <div className={classNames('prov-view-element', additionClassName)}>
        <span className="prov-view-element__label">{label}</span>
        <span className="prov-view-element__divider">:</span>
        {element
          ? React.cloneElement(element, {
              className: 'prov-view-element__element',
            })
          : valueElement}
      </div>
      {value?.length > 100 && (
        <div className="prov-view-element__show-more">
          <PrimaryButton
            title={isExpanded ? 'Show less' : 'Show more'}
            type="default"
            onClick={toggleExpanded}
          />
        </div>
      )}
    </div>
  );
};
