import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { LeasingHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  createOnChangeModalVisibility,
  momentizeRangePickerValues,
} from '@core/utils/methods';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
} from '@models/constants';
import { LeasingBrowseFilter } from '@models/interfaces';
import { MainLeasingBrowseFilter, SwitchType } from '@models/types';
import { BrowsePage } from '@shared/modules/browse-page';
import { LeasingSelectors } from '@store/selectors';
import {
  LEASING_BROWSE_COLUMNS,
  LEASING_DEFAULT_BROWSE_CONFIGURE_FILTER,
} from './models/constants';
import { Filters } from './models/filters';

import './styles.scss';

const { useForm } = Form;

export const LeasingBrowse: FC = () => {
  const leasingBrowseData = useSelector(LeasingSelectors.getLeasingBrowseInfo);

  const [form] = useForm();

  const [filterValue, setFilterValue] = useState<MainLeasingBrowseFilter>(
    BROWSE_DEFAULT_FILTER_VALUE,
  );
  const [configureFilter, setConfigureFilter] = useState<LeasingBrowseFilter>(
    LEASING_DEFAULT_BROWSE_CONFIGURE_FILTER,
  );

  useDidUpdateEffect(() => {
    HttpService.getHttpRequests(LeasingHttpService).getLeasingBrowse(
      momentizeRangePickerValues(filterValue, ['assignedDate'], true),
    );
  }, [filterValue]);

  const onSearchChanges = (search: string): void => {
    setFilterValue((prevState: MainLeasingBrowseFilter) => ({
      ...prevState,
      search,
    }));
  };

  const onApplyConfigureFilter = (): void => {
    setFilterValue((prevState: MainLeasingBrowseFilter) => ({
      ...prevState,
      ...form.getFieldsValue(),
    }));
  };

  const onOnlyCompletedSwitch = (value: boolean): void =>
    setFilterValue((prevState: MainLeasingBrowseFilter) => ({
      ...prevState,
      showCompletedProjects: value as SwitchType,
    }));

  const onChangeConfigureFilter = (filter: LeasingBrowseFilter): void =>
    setConfigureFilter((prevState: LeasingBrowseFilter) => ({
      ...prevState,
      ...filter,
    }));

  return (
    <div className="prov-leasing-browse">
      <BrowsePage
        switchInfo="Show Completed Projects"
        onSearchChanges={onSearchChanges}
        onChange={browseFiltersChange(setFilterValue)}
        filters={
          <Filters
            formProps={{ form }}
            onChanges={onChangeConfigureFilter}
            value={configureFilter}
          />
        }
        onChangeFilterModalVisibility={createOnChangeModalVisibility(
          form,
          configureFilter,
          filterValue,
          setConfigureFilter,
        )}
        validation
        columns={LEASING_BROWSE_COLUMNS}
        data={leasingBrowseData.items}
        place={{
          active: true,
          title: '',
        }}
        className="leasing-browse"
        filterFormProps={{ labelCol: { span: 8 }, form }}
        tableProps={{
          pagination: {
            total: leasingBrowseData.total,
          },
          module: 'leasing',
        }}
        cancelButtonProps={{
          onClick: (): void => {
            form.resetFields();
            setConfigureFilter(LEASING_DEFAULT_BROWSE_CONFIGURE_FILTER);
          },
        }}
        onApplyFilter={onApplyConfigureFilter}
        onSwitch={onOnlyCompletedSwitch}
      />
    </div>
  );
};
