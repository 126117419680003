import React, { ReactNode } from 'react';
import {
  ISchedule,
  ScheduleOperator,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';
import { getCurrentDateType } from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { OptionProps } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import {
  getCurrentNotifyType,
  getValueForUsersOrRolesColumn,
} from '@notifications/helpers';
import { VirtualTableColumn } from '@shared/components';

const fieldRender =
  (paceTasksList: OptionProps[], key: 'starting' | 'ending') =>
  (scheduleItem: ISchedule): ReactNode => {
    const currentPaceTask = paceTasksList.find(
      ({ value }: OptionProps) => value === scheduleItem[`${key}Field`],
    );

    return currentPaceTask
      ? `${currentPaceTask.viewValue} - ${getCurrentDateType(
          scheduleItem[`${key}DateType`],
        )}`
      : 'Unknown';
  };

const booleanFieldRender = (value: boolean) => (
  <span>{value ? 'Yes' : 'No'}</span>
);

const daysCountRender = (value?: number) => (
  <span>{value ? `${value} days` : ''}</span>
);

const renderProjectParams = (
  options: OptionProps[],
  id: string,
): Undefinable<OptionProps['viewValue']> =>
  options.find((option: OptionProps) => option.value === id)?.viewValue;

export const getSchedulesTableColumns = (
  paceTasksList: OptionProps[],
  usersList: OptionProps[],
  towerOwners: OptionProps[],
  projectTypeDDV: OptionProps[],
  projectTypes: OptionProps[],
  projectSubTypes: OptionProps[],
  editFunc: ColumnsFunc<ISchedule>,
  deleteFunc: ColumnsFunc<ISchedule>,
): Array<VirtualTableColumn<ISchedule>> => [
  {
    title: 'Starting Field',
    key: 'startingField',
    width: 150,
    render: fieldRender(paceTasksList, 'starting'),
  },
  {
    title: 'EndingField',
    key: 'endingField',
    width: 150,
    render: fieldRender(paceTasksList, 'ending'),
  },
  {
    title: 'Immediately',
    key: 'isImmediately',
    width: 150,
    dataIndex: 'isImmediately',
    render: booleanFieldRender,
  },
  {
    title: 'Operator',
    key: 'operator',
    width: 150,
    dataIndex: 'operator',
    render: (operator: ScheduleOperator): ReactNode => (
      <span className="cell cell--capitalize">{operator}</span>
    ),
  },
  {
    title: 'Day Count',
    key: 'dayCount',
    width: 150,
    dataIndex: 'daysCount',
    render: daysCountRender,
  },
  {
    title: 'Notification Type',
    key: 'notificationType',
    width: 170,
    render: ({ notificationType }: ISchedule): ReactNode => (
      <span className="cell cell--capitalize">{notificationType}</span>
    ),
  },
  {
    title: 'Project Type',
    key: 'projectType',
    dataIndex: 'projectTypeId',
    width: 200,
    render: (value: string) => renderProjectParams(projectTypeDDV, value),
  },
  {
    title: 'Project Subtype',
    key: 'projectSubType',
    dataIndex: 'projectSubTypeId',
    width: 200,
    render: (value: string) => renderProjectParams(projectSubTypes, value),
  },
  {
    title: 'Notify Type',
    key: 'notifyType',
    width: 150,
    dataIndex: 'notifyType',
    render: getCurrentNotifyType,
  },
  {
    title: 'Users/Roles',
    key: 'usersOrRoles',
    width: 150,
    render: (scheduleItem: ISchedule): ReactNode => (
      <span className="cell cell--pre-wrap">
        {getValueForUsersOrRolesColumn(
          {
            ...scheduleItem,
            responsibleForProjectType: renderProjectParams(
              projectTypes,
              scheduleItem.responsibleForProjectType ?? '',
            ) as string,
          },
          usersList,
          towerOwners,
        )}
      </span>
    ),
  },
  {
    title: 'Message',
    key: 'message',
    dataIndex: 'message',
    width: 150,
  },
  {
    title: 'Auto Warning',
    key: 'hasAutoWarningChecking',
    dataIndex: 'hasAutoWarningChecking',
    width: 150,
    render: booleanFieldRender,
  },
  {
    title: 'Warning Days Count',
    key: 'warningDaysCount',
    dataIndex: 'warningDaysCount',
    width: 200,
    render: daysCountRender,
  },
  {
    title: 'Notify Admin',
    key: 'needNotifyAdmin',
    dataIndex: 'needNotifyAdmin',
    width: 150,
    render: (needNotifyAdmin?: boolean): ReactNode => {
      let strValue = '';

      if (needNotifyAdmin !== undefined) {
        strValue = needNotifyAdmin ? 'Yes' : 'No';
      }

      return <span>{strValue}</span>;
    },
  },
  {
    title: 'Warning Message',
    key: 'warningMessage',
    dataIndex: 'warningMessage',
    width: 170,
  },
  {
    title: 'Redirect link',
    key: 'redirectLink',
    dataIndex: 'redirectLinkKey',
    width: 150,
  },
  actionButtons({ editFunc, deleteFunc }) as VirtualTableColumn,
];
