import { UserOutlined } from '@ant-design/icons';
import { SubPage } from '@symfa-inc/providence-verizon-types';
import { RedirectGuard } from '@core/guards';
import { tabPropsToTabs } from '@core/utils/methods';
import { Route, RouteGroup, Tab } from '@models/classes';
import {
  ENGINEERING_TABS_PROPS,
  LEASING_TABS_PROPS,
  SITE_TABS_PROPS,
} from '@models/constants';
import { MenuPlacement } from '@models/enums';
import { Financial } from '@shared/components/financial';
import { ProjectManagementSelectors } from '@store/selectors/project-management.selectors';
import { EngineeringDetails } from '../engineering/components';
import { EngineeringDetailsResolver } from '../engineering/resolvers';
import { LeasingDetails } from '../leasing/components';
import { LeasingDetailsResolver } from '../leasing/resolvers';
import {
  PERMITTING_TABS_PROPS,
  PermittingDetails,
  PermittingDetailsResolver,
} from '../permitting/browse';
import { ProvidenceAgentsResolver } from '../project/resolvers';
import { SiteDetails } from '../site/components';
import { ProjectManagementDetails } from './components/details';
import {
  ProjectManagementBrowse,
  ProjectManagementSummaryTab,
} from './components';
import {
  ProjectManagementBrowseResolver,
  ProjectManagementDetailsResolver,
} from './resolvers';

export const projectManagementRoutes = new RouteGroup({
  name: 'PM',
  path: '/project-management',
})
  .breadcrumb()
  .addGuard(new RedirectGuard('projectManagement'), '/')
  .setMenu(MenuPlacement.Main, UserOutlined)
  .addRoute(
    new Route({
      name: 'Browse',
      path: '/browse',
      component: ProjectManagementBrowse,
    })
      .breadcrumb()
      .addGuard(
        new RedirectGuard('projectManagement', SubPage.ProjectManagementBrowse),
        '/',
      )
      .addResolver(new ProjectManagementBrowseResolver(), false)
      .addResolver(new ProvidenceAgentsResolver(), false)
      .setMenu(MenuPlacement.Main)
      .addRoute(
        new Route({
          name: 'Details',
          breadcrumbName: ':siteName(:projectBundleID)',
          path: '/:pmProjectBundleID/details',
          component: ProjectManagementDetails,
          extendPath: false,
        })
          .breadcrumb()
          .addTabs([
            new Tab({
              name: 'Summary',
              path: '/summary',
              component: ProjectManagementSummaryTab,
              isLink: false,
            }),
            new Tab({
              path: '/site',
              name: 'Site',
              breadcrumbName: 'Site-:siteName',
              component: SiteDetails,
              isLink: true,
            })
              .breadcrumb()
              .addGuard(new RedirectGuard('site', SubPage.SiteBrowse), '/')
              .addTabs(tabPropsToTabs(SITE_TABS_PROPS)),
            new Tab({
              path: '/engineering',
              name: 'Engineering',
              breadcrumbName: 'Engineering-:siteName(:projectBundleID)',
              component: EngineeringDetails,
              isLink: true,
            })
              .addGuard(
                new RedirectGuard('engineering', SubPage.EngineeringBrowse),
                '/',
              )
              .addResolver(new EngineeringDetailsResolver())
              .breadcrumb()
              .addTabs(tabPropsToTabs(ENGINEERING_TABS_PROPS)),
            new Tab({
              path: '/leasing',
              name: 'Leasing',
              breadcrumbName: 'Leasing-:siteName(:projectBundleID)',
              component: LeasingDetails,
              isLink: true,
            })
              .addGuard(
                new RedirectGuard('leasing', SubPage.LeasingBrowse),
                '/',
              )
              .addResolver(new LeasingDetailsResolver())
              .breadcrumb()
              .addTabs(tabPropsToTabs(LEASING_TABS_PROPS)),
            new Tab({
              path: '/permitting',
              name: 'Permitting',
              breadcrumbName: 'Permitting-:siteName(:projectBundleID)',
              component: PermittingDetails,
              isLink: true,
            })
              .addTabs(tabPropsToTabs(PERMITTING_TABS_PROPS))
              .addResolver(new PermittingDetailsResolver())
              .breadcrumb()
              .addGuard(new RedirectGuard('permitting'), '/'),
            new Tab({
              path: '/financial',
              name: 'Financial',
              component: Financial,
              componentProps: {
                feeAccessPath: 'projectManagement',
                siteDataRootPath: ['site'],
                projectTypeSelector: ProjectManagementSelectors.getProjectType,
                projectIdSelector: ProjectManagementSelectors.getProjectId,
              },
              isLink: false,
            }),
          ])
          .addResolver(new ProjectManagementDetailsResolver(), false),
      ),
  );
