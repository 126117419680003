import {
  IReceivedDirectMessage,
  ISelectorOption,
} from '@symfa-inc/providence-verizon-types';
import { findFromSelectorOptions, mainDateFormat } from '@core/utils/methods';
import { DashboardItem, DashboardProjectData } from '@models/interfaces';

export const directMessagesSearcher = (
  data: DashboardItem<IReceivedDirectMessage>[],
  usersList: ISelectorOption[],
  searchValue: string,
): DashboardItem<IReceivedDirectMessage>[] => {
  const regExp = new RegExp(searchValue, 'gi');

  return !searchValue
    ? data
    : data.filter(
        ({
          notificationData: { senderId, message, createdAt, areaKey },
          projectData: {
            projectBundleID,
            projectType,
            siteName,
            fuzeIDs,
            PSProjectNumbers,
          },
        }: DashboardItem<IReceivedDirectMessage>) =>
          [
            projectBundleID.toString(),
            siteName,
            message,
            areaKey,
            projectType,
            ...fuzeIDs.map(
              ({ value }: DashboardProjectData['fuzeIDs'][number]) => value,
            ),
            ...PSProjectNumbers.map(
              ({ value }: DashboardProjectData['PSProjectNumbers'][number]) =>
                value,
            ),
            mainDateFormat(createdAt),
            findFromSelectorOptions(senderId, usersList),
          ].some(
            (item: string | null | undefined) => item && regExp.test(item),
          ),
      );
};
