import {
  ISelectorOption,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';

export const findFromSelectorOptions = <
  TValue extends string | number = string,
  TViewValue extends string | number = string,
>(
  findValue: TValue,
  selectorOptions: ISelectorOption<TValue, TViewValue>[],
): Undefinable<TViewValue> =>
  selectorOptions.find(
    ({ value }: ISelectorOption<TValue, TViewValue>) => value === findValue,
  )?.viewValue;
