import React, { Dispatch, FC, PropsWithChildren, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '@core/utils/methods';
import { getProjectFuzeIDColumns } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { ProjectFuzeID as ProjectFuzeIDInterface } from '@models/interfaces';
import { ProjectFuzeIDsActions } from '@store/actions';
import { ProjectFuzeIDsSelectors } from '@store/selectors';
import { CustomTable } from '../../components/custom-table';
import { DeleteModal } from '../../components/delete-modal';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';
import { ProjectFuzeIDModal } from './components';
import {
  PROJECT_FUZE_ID_SUM_DEFAULT_VALUE,
  ProjectFuzeIDProps,
} from './models';

import './styles.scss';

export const ProjectFuzeID: FC<ProjectFuzeIDProps> = ({
  projectCreate = false,
  editable = false,
}: PropsWithChildren<ProjectFuzeIDProps>) => {
  const dispatch = useDispatch();

  const {
    projectFuzeIDsTableVisible,
    currentProjectFuzeIDs,
    isCategoriesTableEditing,
  } = useSelector(ProjectFuzeIDsSelectors.getProjectFuzeIDInfo);

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [selectedItem, setSelectedItem] = useState<ProjectFuzeIDInterface>(
    PROJECT_FUZE_ID_SUM_DEFAULT_VALUE,
  );

  const toggleAddAndEditModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);

    if (isAddEditModalVisible) {
      setSelectedItem(PROJECT_FUZE_ID_SUM_DEFAULT_VALUE);
    }
  };

  const selectCurrentProjectFuzeID = (
    currentProjectFuzeID: ProjectFuzeIDInterface,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(currentProjectFuzeID);
    setter(!prevState);
  };

  const onDelete = (): void => {
    dispatch(
      ProjectFuzeIDsActions.deleteProjectFuzeIDsItemAction.done({
        generatedId: selectedItem.generatedId || '',
      }),
    );

    setDeleteModalVisible(!deleteModalVisible);
    setSelectedItem(PROJECT_FUZE_ID_SUM_DEFAULT_VALUE);
  };

  const isEditing = (editable && isCategoriesTableEditing) || projectCreate;

  return projectFuzeIDsTableVisible || projectCreate ? (
    <div className="prov-project-fuze-id">
      <CustomTable
        columns={getProjectFuzeIDColumns(
          isEditing,
          (projectFuzeID: ProjectFuzeIDInterface): void =>
            selectCurrentProjectFuzeID(
              projectFuzeID,
              () => toggleAddAndEditModal(ModalMainTypes.Edit),
              isAddEditModalVisible,
            ),
          (projectFuzeID: ProjectFuzeIDInterface): void =>
            selectCurrentProjectFuzeID(
              projectFuzeID,
              setDeleteModalVisible,
              deleteModalVisible,
            ),
        )}
        dataSource={currentProjectFuzeIDs}
        pagination={false}
        hoverable={false}
      />
      {isEditing ? (
        <div className="prov-project-fuze-id__add-button">
          <PrimaryButton
            icon="+"
            title="Add"
            onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
          />
        </div>
      ) : null}
      <ProjectFuzeIDModal
        visible={isAddEditModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditModal}
        itemValue={selectedItem}
        projectCreate={projectCreate}
      />
      <DeleteModal
        onOk={onDelete}
        onCancel={toggleModal(setDeleteModalVisible)}
        visible={deleteModalVisible}
      />
    </div>
  ) : null;
};
