import {
  ISelectorOption,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';
import { StoredRequest } from '@core/utils/store';
import { IProjectPACETask, IUpdateProjectPACETask } from '@models/interfaces';
import { PACETasksBrowse } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class PaceTasksBrowseHttpService extends CommonHttpService {
  @StoredRequest(PACETasksBrowse.getProjectBundleIDsOptions)
  getPaceTasksBrowseFilterOptions({
    projectId,
  }: {
    projectId?: Undefinable<string>;
  }): Promise<ISelectorOption<string, number>[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/pace-tasks-browse/filter-options`,
      null,
      {
        params: { projectId },
      },
    );
  }

  @StoredRequest(PACETasksBrowse.getProjectPACETasks)
  getProjectPACETasks({
    projectId,
  }: {
    projectId: Undefinable<string>;
  }): Promise<IProjectPACETask[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/pace-tasks-browse/project-pace-tasks`,
      null,
      {
        params: { projectId },
      },
    );
  }

  @StoredRequest(PACETasksBrowse.updateProjectPACETasks)
  async updateProjectPACETasks(
    projectId: string,
    projectPACETasks: IUpdateProjectPACETask[],
  ): Promise<IUpdateProjectPACETask[]> {
    await this.http.callApi.patch(
      `${this.apiUrl}/admin/pace-tasks-browse/project-pace-tasks/${projectId}`,
      projectPACETasks,
    );

    return projectPACETasks;
  }
}
