import React, { ReactNode } from 'react';
import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { SelectValue } from 'antd/lib/select';
import { actionButtons } from '@models/constants';
import { AdminDropdownValuesData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { VirtualTableColumn } from '@shared/components';

export const getAdminDropdownValuesColumns = (
  selectorValue: Undefinable<SelectValue>,
  editFunc: ColumnsFunc<AdminDropdownValuesData>,
  deleteFunc: ColumnsFunc<AdminDropdownValuesData>,
): Array<VirtualTableColumn> => [
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render: (value: string): ReactNode => (
      <span className="column-text">{value}</span>
    ),
  },
  actionButtons({ editFunc, deleteFunc }) as VirtualTableColumn,
];
