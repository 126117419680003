import { Store } from 'antd/lib/form/interface';
import { formDiffs, momentizeObjectDates } from '@core/utils/methods';
import {
  PermittingDetailsJurisdictionInfo,
  PermittingDetailsJurisdictionPermitting,
  PermittingDetailsJurisdictionZoning,
  PermittingJurisdictionAuditBody,
} from '@models/interfaces';

export const dataToBodyMapper = (
  [initialSummary, initialZoningFormData, initialPermitting]: Store[],
  [summary, zoningFormData, permitting]: Store[],
  jurisdictionId: string,
  zoningId?: string,
  permittingId?: string,
): PermittingJurisdictionAuditBody => ({
  summarySection: {
    ...formDiffs(
      initialSummary,
      momentizeObjectDates(summary, ['jurisdictionAuditComplete'], true),
    ),
    id: jurisdictionId,
  } as PermittingDetailsJurisdictionInfo,
  ...(zoningId
    ? {
        zoningSection: {
          ...formDiffs(initialZoningFormData, zoningFormData),
          id: zoningId,
        } as PermittingDetailsJurisdictionZoning,
      }
    : {}),
  ...(permittingId
    ? {
        permittingSection: {
          ...formDiffs(initialPermitting, permitting),
          id: permittingId,
        } as PermittingDetailsJurisdictionPermitting,
      }
    : {}),
});
