import { ProjectType } from '@symfa-inc/providence-verizon-types';
import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { AccountingHttpService } from '@core/services/http';
import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';

export class AccountingBrowseResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(
      AccountingHttpService,
    ).getAccountingBrowse({
      ...BROWSE_DEFAULT_FILTER_VALUE,
      showCompletedProjects: false,
      projectTypeMode: ProjectType.Mod,
    });

    await HttpService.getHttpRequests(
      AccountingHttpService,
    ).getAccountingBrowseFiltersOptions();
  }
}
