import { ISelectorOption, IToDo } from '@symfa-inc/providence-verizon-types';
import { mainDateFormat } from '@core/utils/methods';
import { DashboardItem, DashboardProjectData } from '@models/interfaces';
import { assigneesIdsToNamedAssignees } from '../../../helpers';

export const toDoSearcher = (
  data: DashboardItem<IToDo>[],
  searchValue: string,
  adminsList: ISelectorOption[],
): DashboardItem<IToDo>[] => {
  const regExp = new RegExp(searchValue, 'gi');

  return !searchValue
    ? data
    : data.filter(
        ({
          notificationData: { date, taskNote, actionField, message, assignees },
          projectData: {
            projectBundleID,
            projectType,
            siteName,
            fuzeIDs,
            PSProjectNumbers,
          },
        }: DashboardItem<IToDo>) =>
          [
            mainDateFormat(date),
            taskNote,
            actionField,
            message,
            ...assigneesIdsToNamedAssignees(assignees, adminsList),
            projectBundleID.toString(),
            siteName,
            projectType,
            ...fuzeIDs.map(
              ({ value }: DashboardProjectData['fuzeIDs'][number]) => value,
            ),
            ...PSProjectNumbers.map(
              ({ value }: DashboardProjectData['PSProjectNumbers'][number]) =>
                value,
            ),
          ].some(
            (item: string | null | undefined) => item && regExp.test(item),
          ),
      );
};
