import { actionCreatorFactory } from '@core/utils/factories';
import { ModalMainTypes } from '@models/enums';
import {
  PaginatedResponse,
  ScopingBrowseResponse,
  ScopingDetailsResponse,
  ScopingSummaryData,
  UpdateScopingEquipmentData,
} from '@models/interfaces';

const actionCreator = actionCreatorFactory('SCOPING');

export namespace ScopingActions {
  export const getScopingBrowseDataAction =
    actionCreator.createAsyncAction<PaginatedResponse<ScopingBrowseResponse>>(
      'GET_SCOPING_BROWSE',
    );
  export const updateScopingBrowseDataAction = actionCreator.createAsyncAction<
    PaginatedResponse<ScopingBrowseResponse>
  >('UPDATE_SCOPING_BROWSE');
  export const updateCurrentModalTypeAction =
    actionCreator.createAsyncAction<ModalMainTypes>(
      'UPDATE_CURRENT_MODAL_TYPE',
    );
  export const getScopingDetailsAction = actionCreator.createAsyncAction<
    Omit<ScopingDetailsResponse, 'categories' | 'RAD'>
  >('GET_SCOPING_DETAILS');
  export const updateScopingSummaryAction =
    actionCreator.createAsyncAction<ScopingSummaryData>(
      'UPDATE_SCOPING_SUMMARY',
    );
  export const updateScopingEquipmentAction =
    actionCreator.createAsyncAction<UpdateScopingEquipmentData>(
      'UPDATE_SCOPING_EQUIPMENT',
    );
}
