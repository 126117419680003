import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { ProjectHttpService } from '@core/services/http';
import { dateWorker } from '@core/utils/date-worker';
import { getFormData, isValidForm } from '@core/utils/methods';
import { serverValidator, uniqueValidator } from '@core/utils/validators';
import { ModalMainTypes } from '@models/enums';
import { ProjectFuzeID } from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { ProjectFuzeIDSumModalProps } from '@shared/components/project-category/models';
import { DatePicker, Input } from '@shared/modules';
import { ProjectFuzeIDsActions } from '@store/actions';
import { ProjectFuzeIDsSelectors } from '@store/selectors';

import './styles.scss';

const { useForm } = Form;

export const ProjectFuzeIDModal: FC<ProjectFuzeIDSumModalProps> = ({
  itemValue,
  visible,
  modalType,
  toggleModal,
  projectCreate,
}: PropsWithChildren<ProjectFuzeIDSumModalProps>) => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const currentProjectFuzeIDs = useSelector(
    ProjectFuzeIDsSelectors.getProjectFuzeIDs,
  );
  const { currentProject } = useSelector(
    ProjectFuzeIDsSelectors.getProjectFuzeIDModalInfo,
  );

  const add = async (value: ProjectFuzeID): Promise<void> => {
    if (await isValidForm(form)) {
      dispatch(ProjectFuzeIDsActions.addProjectFuzeIDsItemAction.done(value));
      toggleModal(modalType);
    }
  };

  const edit = async (value: ProjectFuzeID): Promise<void> => {
    if (await isValidForm(form)) {
      dispatch(
        ProjectFuzeIDsActions.updateProjectFuzeIDsItemAction.done(value),
      );
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...itemValue,
        projectAssignedDate: itemValue?.projectAssignedDate
          ? dateWorker(itemValue?.projectAssignedDate)
          : null,
      });
    }
  }, [visible, itemValue]);

  const validationValues =
    modalType === ModalMainTypes.Edit
      ? currentProjectFuzeIDs.filter(
          (record: ProjectFuzeID) =>
            record.generatedId !== itemValue.generatedId,
        )
      : currentProjectFuzeIDs;

  return (
    <AddOrEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getFormData(itemValue, form))
          : (): Promise<void> => edit(getFormData(itemValue, form))
      }
      onCancel={(): void => toggleModal(modalType)}
      visible={visible}
      type={modalType}
      formProps={{ labelCol: { span: 8 }, wrapperCol: { span: 15 }, form }}
      className="prov-project-fuze-id-modal"
      title="Fuze ID"
      cancelButtonProps={{ onClick: (): void => form.resetFields() }}
    >
      <Input
        id="fuzeID"
        label="Fuze ID"
        formItemProps={{
          validateFirst: true,
          rules: [
            { required: true, message: 'Fuze ID is required!' },
            uniqueValidator(validationValues, 'fuzeID', 'Fuze ID'),
            serverValidator(
              {
                method: 'getProjectProjectFuzeIDValidation',
                errorMessage: 'Fuze ID must be unique',
              },
              ProjectHttpService,
              !projectCreate && modalType === ModalMainTypes.Edit
                ? currentProject.id
                : undefined,
              'fuzeID',
            ),
          ],
        }}
      />
      <Input
        id="PSProjectNumber"
        label="PS Project Number"
        formItemProps={{
          validateFirst: true,
          rules: [
            { required: true, message: 'PS Project Number is required!' },
            uniqueValidator(
              validationValues,
              'PSProjectNumber',
              'PS Project Number',
            ),
            serverValidator(
              {
                method: 'getProjectProjectFuzeIDValidation',
                errorMessage: 'PS Project Number must be unique',
              },
              ProjectHttpService,
              !projectCreate && modalType === ModalMainTypes.Edit
                ? currentProject.id
                : undefined,
              'PSProjectNumber',
            ),
          ],
        }}
      />
      <DatePicker id="projectAssignedDate" label="Project Assigned Date" />
      <Input id="RFPriority" label="RF Priority" />
    </AddOrEditModal>
  );
};
