import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs as AntTabs } from 'antd';
import { Tab } from '@models/classes';
import { CommonSelectors } from '@store/selectors';
import { ProjectFuzeID } from '../project-category';
import { getProjectFuzeIDsVisibility } from './models/constants';
import { TabsProps } from './models/interfaces';

const { TabPane } = AntTabs;

export const Tabs: FC<TabsProps> = ({
  tabs,
  params,
  destroyInactiveTabPane = false,
  animated = true,
}: PropsWithChildren<TabsProps>) => {
  const location = useLocation();
  const history = useHistory();

  const currentTab = new URLSearchParams(location.search).get('tabName')!;

  const hasUnsubmittedData = useSelector(CommonSelectors.getHasUnsubmittedData);

  const locationNameWithTab = currentTab
    ? `${location.pathname}/${currentTab.toLowerCase()}`
    : location.pathname;

  const changeTab = (tabName: string): void => {
    const selectedTab = tabs.find((tab: Tab) => tab.name === tabName);

    if (selectedTab?.isLink) {
      history.replace(selectedTab.replacePath(params));
    } else {
      const searchParams = new URLSearchParams();

      searchParams.set('tabName', tabName);

      history.replace(`${location.pathname}?${searchParams}`);
    }
  };

  useEffect(() => {
    if (!tabs.find((tab: Tab) => tab.name === currentTab)) {
      const [firstTab] = tabs;

      changeTab(firstTab.name);
    }
  }, [currentTab, tabs]);

  return (
    <AntTabs
      animated={animated}
      activeKey={currentTab}
      onChange={changeTab}
      destroyInactiveTabPane={destroyInactiveTabPane}
      tabBarStyle={{ display: 'block' }}
    >
      {tabs.map((tab: Tab) => {
        return (
          <TabPane key={tab.name} tab={tab.name}>
            {getProjectFuzeIDsVisibility(locationNameWithTab) ? (
              <ProjectFuzeID />
            ) : null}
            <Prompt when={hasUnsubmittedData} message="" />
            {React.createElement(tab.component, {
              isActive: currentTab === tab.name,
              history,
              params,
              ...tab.componentProps,
            })}
          </TabPane>
        );
      })}
    </AntTabs>
  );
};
