import { FRONTEND_DATE_FORMAT } from '@symfa-inc/providence-verizon-types';
import isEqual from 'lodash.isequal';
import { dateWorker } from '@core/utils/date-worker';

export const formDiffs = <I extends Record<string, any>>(
  initial: I,
  final: I,
): I => {
  const keys = Object.keys(initial) as Array<keyof I>;

  return initial && keys.length
    ? keys.reduce((acc: I, key: keyof I) => {
        if (
          dateWorker(initial[key], FRONTEND_DATE_FORMAT, true).isValid() &&
          dateWorker(final[key], FRONTEND_DATE_FORMAT, true).isValid()
            ? !!dateWorker(initial[key]).diff(final[key])
            : !isEqual(initial[key], final[key])
        ) {
          acc[key] = final[key];
        }

        return initial && Object.keys(initial).length ? acc : final;
      }, {} as I)
    : final;
};
