import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  DropdownHttpService,
  NotificationsHttpService,
} from '@core/services/http';

export class NotificationsResolver implements Resolver {
  async resolve(): Promise<void> {
    const httpService = HttpService.getHttpRequests(NotificationsHttpService);

    await httpService.getPaceTaskList();
    await httpService.getUsersList();
    await httpService.getRules();
    await httpService.getSchedules();
    await httpService.getEmailCertificationDate();
    await httpService.getDirectMessages();

    await HttpService.getHttpRequests(DropdownHttpService).getProjectTypes();
  }
}
