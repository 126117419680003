import { ReactNode } from 'react';
import { ColumnsType } from 'antd/lib/table';
import {
  projectFuzeIDRender,
  providenceAgentRender,
  readOnlyDatePickerRenderer,
  readOnlyInputNumberRender,
} from '@core/utils/methods';
import {
  AdditionalFilter,
  SiteAuditBrowseFilter,
  SiteAuditInformation,
  SiteBrowse,
} from '@models/interfaces';
import { Browse } from '@models/types';

export const SITE_AUDIT_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'Alternate Site Info',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.alternateSiteInfo,
  },
  {
    title: 'City',
    key: 'city',
    dataIndex: 'site',
    width: 160,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.city,
  },
  {
    title: 'Contract Number',
    width: 150,
    key: 'contractNumber',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.contractNumber,
  },
  {
    title: 'PS Project Number',
    key: 'PSProjectNumber',
    dataIndex: 'fuzeIDs',
    render: projectFuzeIDRender({ field: 'PSProjectNumber' }),
    width: 150,
    sorter: true,
  },
  {
    title: 'Fuze ID',
    key: 'fuzeId',
    dataIndex: 'fuzeIDs',
    render: projectFuzeIDRender({ field: 'fuzeID' }),
    width: 150,
    sorter: true,
  },
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    width: 180,
    sorter: true,
    dataIndex: 'providenceAgent',
    render: providenceAgentRender,
  },
  {
    title: 'Scheduled Site Walk',
    key: 'scheduledSiteWalkDate',
    dataIndex: 'siteAudit',
    width: 190,
    sorter: true,
    render: (siteAudit: SiteAuditInformation): ReactNode =>
      readOnlyDatePickerRenderer(siteAudit?.scheduledSiteWalkDate || undefined),
  },
  {
    title: 'Site Name',
    dataIndex: 'site',
    key: 'siteName',
    width: 135,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.siteName,
  },
  {
    title: 'State',
    dataIndex: 'site',
    width: 110,
    key: 'state',
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.state?.name,
  },
  {
    title: 'Street Address',
    key: 'streetAddress',
    dataIndex: 'site',
    width: 170,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.streetAddress,
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.submarket?.value,
  },
  {
    title: 'Tower Owner',
    dataIndex: 'site',
    width: 140,
    key: 'towerOwner',
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.towerOwner?.value,
  },
  {
    title: 'Zip',
    key: 'zip',
    dataIndex: 'site',
    width: 100,
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.zip),
  },
  {
    title: 'MDG Location Code',
    key: 'MDGLocationCode',
    dataIndex: 'site',
    width: 180,
    render: (site: SiteBrowse): ReactNode => site?.MDGLocationCode,
  },
];

export const ADDITIONAL_FILTERS: AdditionalFilter[] = [
  {
    key: 'siteWalkNotScheduled',
    name: 'Site Walk Not Scheduled',
  },
  {
    key: 'siteWalkScheduledToday',
    name: 'Site Walk Scheduled Today',
  },
  {
    key: 'completedAuditNotSavedToFile',
    name: 'Completed Audit Not Saved to File',
  },
];

export const SITE_AUDIT_RANGE_PICKERS: Array<keyof SiteAuditBrowseFilter> = [
  'scopingCompleteForecasted',
  'scheduledSiteWalkDate',
  'siteAuditComplete',
];

export const SITE_AUDIT_DEFAULT_BROWSE_CONFIGURE_FILTER: SiteAuditBrowseFilter =
  {
    jurisdictionId: undefined,
    scheduledSiteWalkDate: undefined,
    isFollowUpRequired: undefined,
    siteAuditComplete: undefined,
    projectTypeId: undefined,
    scopingCompleteForecasted: undefined,
    stateId: undefined,
    towerOwnerId: undefined,
  };
