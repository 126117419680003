import { distructAndDispatch } from '../../core/utils/methods/destruct-and-dispatch';
import { FinancialData, ProjectDetailsResponse } from '../../models/interfaces';
import { FinancialActions } from '../actions/financial.actions';

export namespace PermittingEffects {
  export const setFinancial = distructAndDispatch<
    ProjectDetailsResponse & { financial?: FinancialData | null },
    FinancialData
  >('financial', FinancialActions.getFinancialDataAction);
}
