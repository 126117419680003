import {
  DropDownSelectorValues,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';
import { AdminDropdownValuesData } from '@models/interfaces';
import { DDVSelectorKey } from '@models/types';
import store from '@store/index';
import { AppState } from '@store/reducers';
import { getEnumKeyByEnumValue } from './get-enum-key-by-enum-value';

export const getDDVById = (
  valueKey: DropDownSelectorValues,
  id: string,
): AdminDropdownValuesData => {
  const state: AppState = store.getState();
  const DDV = state.admin.dropdownValues.dropdownValues;

  const result = DDV[
    getEnumKeyByEnumValue(DropDownSelectorValues, valueKey) as DDVSelectorKey
  ].find((value: AdminDropdownValuesData) => value.id === id);

  if (!result) {
    throw new Error(
      `DropDown state does not have value ${valueKey} with id ${id}`,
    );
  }

  return result;
};

export const getDDVByIdOrUndefined = (
  valueKey: DropDownSelectorValues,
  id?: string | null,
): Undefinable<AdminDropdownValuesData> =>
  id ? getDDVById(valueKey, id) : undefined;
