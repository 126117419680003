import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { Form } from 'antd';
import { HttpService, ObjectComparatorService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import { isValidForm, removeEmptyFields } from '@core/utils/methods';
import {
  ActionRes,
  ProjectCreateRequestData,
  ProjectFuzeID as ProjectFuzeIDInterface,
  ProjectInformationData,
} from '@models/interfaces';
import { IdResponse } from '@models/types';
import { NotificationsLoader, ProjectFuzeID } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import {
  CommonActions,
  ProjectActions,
  ProjectFuzeIDsActions,
  SiteActions,
} from '@store/actions';
import {
  ProjectFuzeIDsSelectors,
  ProjectSelectors,
  SiteSelectors,
  UserSelectors,
} from '@store/selectors';
import { ProjectInformation } from '../information';

import './styles.scss';

const { useForm } = Form;

export const ProjectCreate: FC<RouterProps> = ({
  history,
}: PropsWithChildren<RouterProps>) => {
  const [form] = useForm();
  const { params } = useRouteMatch<IdResponse>();

  const currentProjectFuzeIDs = useSelector(
    ProjectFuzeIDsSelectors.getProjectFuzeIDs,
  );
  const providenceAgent = useSelector(
    ProjectSelectors.getProjectCreateProvidenceAgent,
  );
  const projectInformationFields = useSelector(
    UserSelectors.getProjectInformationPermissions,
  );
  const PSLocationCodeOptions = useSelector(
    SiteSelectors.getPSLocationCodeOptions,
  );
  const MDGLocationCodeOptions = useSelector(
    SiteSelectors.getMDGLocationCodeOptions,
  );

  const dispatch = useDispatch();

  const clearProjectFuzeIDs = (): ActionRes<unknown> =>
    dispatch(ProjectFuzeIDsActions.setProjectFuzeIDsAction.done([]));

  useEffect(() => {
    clearProjectFuzeIDs();
    dispatch(SiteActions.clearCreatedSite.done());

    if (!(params as IdResponse)?.id) {
      dispatch(ProjectActions.getProvidenceAgent.done(undefined));
      form.resetFields();
    }
  }, []);

  const [fuzeIDsError, setFuzeIDsError] = useState<boolean>();
  const [objectsCompare, setObjectsCompare] = useState<boolean>(true);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const getPageData = (): ProjectCreateRequestData => {
    const { PSLocationCode, MDGLocationCode, ...values } =
      form.getFieldsValue();

    return {
      ...(values as ProjectInformationData),
      site: PSLocationCode || MDGLocationCode,
      fuzeIDs: currentProjectFuzeIDs.map(
        (projectFuzeID: ProjectFuzeIDInterface) => {
          const clonedItem = projectFuzeID;

          delete clonedItem.generatedId;

          return clonedItem;
        },
      ),
    };
  };

  const onSubmit = async (): Promise<void> => {
    if (currentProjectFuzeIDs?.length === 0) {
      setFuzeIDsError(true);

      return;
    }

    if (await isValidForm(form))
      try {
        setSubmitDisabled(true);

        dispatch(CommonActions.setHasUnsubmittedData.done(false));
        await HttpService.getHttpRequests(ProjectHttpService).createProject(
          removeEmptyFields(getPageData()),
        );

        NotificationsLoader.notificationSuccess('Project has been created!');

        dispatch(ProjectActions.getProvidenceAgent.done(undefined));

        history?.push('/project/browse');
      } catch (e) {
        console.error(e);
      }

    setSubmitDisabled(false);
  };

  const onCancel = (): void => {
    form.resetFields();
    clearProjectFuzeIDs();
    setFuzeIDsError(false);
    setObjectsCompare(true);
  };

  useDidUpdateEffect(
    () => setFuzeIDsError(fuzeIDsError && currentProjectFuzeIDs?.length === 0),
    [fuzeIDsError, currentProjectFuzeIDs?.length],
  );

  const options =
    params.id?.length === 6 ? PSLocationCodeOptions : MDGLocationCodeOptions;

  const data = {
    providenceAgent,
    ...(params.id
      ? {
          siteId: options.find(
            ({ viewValue }) => viewValue?.toString() === params.id,
          )?.value,
        }
      : {}),
  };

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(
      CommonActions.setHasUnsubmittedData.done(
        !(
          objectsCompare &&
          ObjectComparatorService.arraysCompare(currentProjectFuzeIDs, [])
        ),
      ),
    );
  }, [currentProjectFuzeIDs, objectsCompare]);

  const onProjectInfoChange = (): void =>
    setObjectsCompare(
      ObjectComparatorService.objectsCompare(form.getFieldsValue(), data),
    );

  useSaveChanged(true, onSubmit, onCancel);

  return (
    <div className="prov-project-create">
      <div className="tabs-wrap tabs-wrap_with-actions">
        <ProjectInformation
          isEditing
          isNew
          permissions={projectInformationFields}
          className="prov-project-create__form"
          form={form}
          data={data}
          onChange={onProjectInfoChange}
        />
        <ProjectFuzeID projectCreate />
        {fuzeIDsError && ( // TODO: rename Project Category(new Fuze ID)
          <span className="fuze-ids-error">Fuze ID is required!</span>
        )}
      </div>
      <div className="prov-project-create__action-buttons">
        <PrimaryButton
          htmlType="submit"
          title="Submit"
          disabled={submitDisabled}
          onClick={onSubmit}
        />
        <PrimaryButton title="Clear" type="default" onClick={onCancel} />
      </div>
    </div>
  );
};
