import React, {
  FC,
  FormEvent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { FRONTEND_DATE_FORMAT } from '@symfa-inc/providence-verizon-types';
import { Input } from 'antd';
import { dateWorker } from '@core/utils/date-worker';
import { readOnlyDatePickerRenderer } from '@core/utils/methods';
import { FormItem } from '@shared/modules';
import { getReadonlyElementFunction } from '../../models/constants';
import { DATE_INPUT_FORMAT_VALIDATOR } from './models/constants';
import { DatePickerProps, IDateInput } from './models/interfaces';

export const DateInput: FC<IDateInput> = ({
  format,
  value,
  onChange,
  placeholder = 'Enter date',
  ...props
}: PropsWithChildren<IDateInput>) => {
  const [inputValue, setInputValue] = useState<string>(
    value?.format(format) ?? '',
  );

  useEffect(() => {
    setInputValue((prevValue: string) =>
      value ? (value?.isValid() && value?.format(format)) || prevValue : '',
    );
  }, [value]);

  return (
    <Input
      suffix={<CalendarOutlined />}
      allowClear
      value={inputValue}
      className="ant-input"
      placeholder={placeholder}
      onChange={(e: FormEvent<HTMLInputElement>): void => {
        if (!e.currentTarget.value) {
          setInputValue('');
          onChange?.(null);

          return;
        }

        const isMainFormat = /^[0-1]\d\/[0-3]\d\/\d{4}$/.test(
          e.currentTarget.value,
        );
        const isSecondaryFormat = /^[0-1]\d[0-3]\d\d{4}$/.test(
          e.currentTarget.value,
        );

        if (isMainFormat || isSecondaryFormat) {
          const dateData = dateWorker(
            e.currentTarget.value,
            isMainFormat ? format : 'MMDDYYYY',
          );

          onChange?.(dateData);
          setInputValue(dateData.format(format));

          return;
        }

        onChange?.(dateWorker(e.currentTarget.value, format, true));
        setInputValue(e.currentTarget.value);
      }}
      {...props}
    />
  );
};

export const DatePicker: FC<DatePickerProps> = ({
  id,
  label,
  elementProps,
  format = FRONTEND_DATE_FORMAT,
  formItemProps,
  ...props
}: PropsWithChildren<DatePickerProps>) => (
  <FormItem
    id={id}
    label={label}
    editingElement={<DateInput id={id} format={format} {...elementProps} />}
    readOnlyElement={getReadonlyElementFunction(
      id,
      { formItemProps },
      readOnlyDatePickerRenderer,
    )}
    formItemProps={{
      ...formItemProps,
      rules: [...DATE_INPUT_FORMAT_VALIDATOR, ...(formItemProps?.rules || [])],
    }}
    {...props}
  />
);
