import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MAIN_PACE_TASK_IDs,
  PaceTaskType,
} from '@symfa-inc/providence-verizon-types';
import { Col, Form, Row } from 'antd';
import classNames from 'classnames';
import { Store } from 'rc-field-form/es/interface';
import { HttpService, ObjectComparatorService } from '@core/services';
import { EngineeringHttpService } from '@core/services/http';
import {
  useCountingTotalEquipment,
  useDidUpdateEffect,
  useSaveChanged,
} from '@core/utils/hooks';
import { formDiffs, isValidForm } from '@core/utils/methods';
import { EQUIPMENT_DEFAULT_VALUE, SCOPING_SECTORS } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  EquipmentModal,
  FormProps,
  OtherEquipmentData,
  ScopingNotes as IScopingNotes,
} from '@models/interfaces';
import { SectorDataState } from '@models/types';
import {
  EquipmentTotals,
  NotificationsLoader,
  OtherEquipment,
  ScopingNotes,
  SectorsOfEquipment,
} from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions } from '@store/actions';
import { ProjectsEffects } from '@store/effects';
import {
  EngineeringSelectors,
  EquipmentSelectors,
  ProjectFuzeIDsSelectors,
  ScopingSelectors,
  UserSelectors,
} from '@store/selectors';
import { ProjectInformation, SiteInformation } from './components';
import { processUpdateSOFBody } from './helpers';

import './styles.scss';

const { useForm } = Form;

export const EngineeringSOF: FC<FormProps> = ({
  isEditing = false,
  toggleEditing,
}: PropsWithChildren<FormProps>) => {
  const [siteForm] = useForm();
  const [projectForm] = useForm();
  const [scopingNotesForm] = useForm();

  const [SOFForm, setSOFForm] = useState<Array<Store>>([]);
  const [initialForm, setInitialForm] = useState<Array<Store>>([]);

  const {
    currentProjectId,
    projectInfo,
    siteInfo,
    scopingInfo,
    scopingSectors = SCOPING_SECTORS,
    otherEquipments: scopingOtherEquipments,
  } = useSelector(EngineeringSelectors.getSOFData);

  const dispatch = useDispatch();

  const arraysCompare = ObjectComparatorService.arraysCompare(
    initialForm,
    SOFForm,
    true,
  );

  const { scopingNotesFields, projectInfoFields } = useSelector(
    UserSelectors.getEngineeringBrowsePermissions,
  );
  const equipmentTypes = useSelector(EquipmentSelectors.getEquipmentTypes);
  const projectFuzeIDsTableVisible = useSelector(
    ProjectFuzeIDsSelectors.getProjectFuzeIDsTableVisible,
  );
  const currentModalType = useSelector(ScopingSelectors.getCurrentModalType);
  const scopingFetching = useSelector(ScopingSelectors.isFetching);

  const [otherEquipments, setOtherEquipments] = useState<OtherEquipmentData[]>(
    scopingOtherEquipments || [],
  );
  const [equipmentItem, setEquipmentItem] = useState<EquipmentModal>(
    EQUIPMENT_DEFAULT_VALUE,
  );
  const [sectorsData, setSectorsData] =
    useState<SectorDataState>(SCOPING_SECTORS);

  const totalEquipments = useCountingTotalEquipment(
    'scoping',
    otherEquipments,
    sectorsData,
    equipmentTypes,
  );

  useEffect(() => {
    setSectorsData(scopingSectors ?? SCOPING_SECTORS);
  }, [scopingSectors]);

  const onSubmit = async (): Promise<void> => {
    if (
      (await isValidForm(projectForm)) &&
      (await isValidForm(scopingNotesForm))
    ) {
      try {
        const [projectInfoFormInitial, scopingNotesFormInitial] = initialForm;
        const [projectInfoForm, notesForm] = SOFForm;

        await HttpService.getHttpRequests(EngineeringHttpService).updateSOF(
          currentProjectId,
          processUpdateSOFBody({
            projectInfo: formDiffs(projectInfoFormInitial, projectInfoForm),
            scopingNotes: formDiffs(
              scopingNotesFormInitial,
              notesForm,
            ) as IScopingNotes,
            otherEquipments,
            sectors: sectorsData,
          }),
        );

        setInitialForm(SOFForm);

        toggleEditing?.();

        dispatch(CommonActions.setHasUnsubmittedData.done(false));

        ProjectsEffects.updatePaceTaskFieldsByConfig(projectInfoForm, [
          {
            id: MAIN_PACE_TASK_IDs.scopingComplete,
            dateType: PaceTaskType.ActualDate,
            field: 'scopingComplete',
          },
          {
            id: MAIN_PACE_TASK_IDs.finalRFDSReceived,
            dateType: PaceTaskType.ActualDate,
            field: 'finalRFDSReceived',
          },
        ]);

        NotificationsLoader.notificationSuccess(
          `Information has been updated!`,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onCancel = (): void => {
    const [projectInfoForm, notesForm] = initialForm;

    scopingNotesForm.setFieldsValue(notesForm);
    projectForm.setFieldsValue(projectInfoForm);

    setOtherEquipments(scopingOtherEquipments);
    setSectorsData(scopingSectors);

    setSOFForm(initialForm);

    dispatch(CommonActions.setHasUnsubmittedData.done(false));

    toggleEditing?.();
  };

  const onValuesChange = (): void => {
    if (Object.keys(projectForm.getFieldsValue()).length) {
      const data = [
        projectForm.getFieldsValue(),
        scopingNotesForm.getFieldsValue(),
      ];

      setSOFForm(data);

      if (!Object.keys(initialForm).length) {
        setInitialForm(data);
      }
    }
  };

  const isDataNotUpdated =
    ObjectComparatorService.objectsCompare(sectorsData, scopingSectors) &&
    ObjectComparatorService.arraysCompare(
      otherEquipments,
      scopingOtherEquipments,
    ) &&
    arraysCompare;

  const buttonsDisabled = scopingFetching || isDataNotUpdated;

  useDidUpdateEffect(() => {
    onValuesChange();
  }, [isEditing]);

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!isDataNotUpdated));
  }, [
    sectorsData,
    scopingSectors,
    otherEquipments,
    scopingOtherEquipments,
    arraysCompare,
  ]);

  useDidUpdateEffect(() => {
    const newDataState =
      currentModalType === ModalMainTypes.Add
        ? [...otherEquipments, equipmentItem]
        : otherEquipments.map((other: OtherEquipmentData) =>
            other.generatedId === equipmentItem.generatedId
              ? { ...other, ...equipmentItem }
              : other,
          );

    setOtherEquipments(newDataState);
  }, [equipmentItem]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <div className="prov-scoping-equipment">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'fuze-ids-table-open': projectFuzeIDsTableVisible,
        })}
      >
        <Row justify="space-between" className="prov-engineering-sof-tab__row">
          <Col span="12">
            <SiteInformation
              form={siteForm}
              isEditing={false}
              data={siteInfo}
            />
          </Col>
          <Col span="12">
            <ProjectInformation
              form={projectForm}
              isEditing={isEditing}
              data={projectInfo}
              onValuesChange={onValuesChange}
              permissions={projectInfoFields}
            />
          </Col>
        </Row>
        <ScopingNotes
          className="prov-scoping-equipment__information"
          isEditing={isEditing}
          form={scopingNotesForm}
          data={scopingInfo}
          onValuesChange={onValuesChange}
          permissions={scopingNotesFields}
        />
        <SectorsOfEquipment
          className="prov-scoping-equipment__information"
          isEditing={isEditing}
          sectorsData={sectorsData}
          setSectorsData={setSectorsData}
        />
        <OtherEquipment
          className="prov-scoping-equipment__information"
          isEditing={isEditing}
          setOtherEquipments={setOtherEquipments}
          setEquipmentItem={setEquipmentItem}
          data={otherEquipments}
        />
        <EquipmentTotals
          className="prov-scoping-equipment__information"
          data={totalEquipments}
        />
      </div>
      {isEditing && (
        <div className="prov-site-information__btn-wrap">
          <div>
            <PrimaryButton
              htmlType="submit"
              title="Submit"
              disabled={buttonsDisabled}
              onClick={onSubmit}
            />
            <PrimaryButton
              title="Cancel"
              disabled={buttonsDisabled}
              type="default"
              onClick={onCancel}
            />
          </div>
        </div>
      )}
    </div>
  );
};
