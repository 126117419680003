import { Nullable, Undefinable } from '@symfa-inc/providence-verizon-types';
import { createReducer, on } from '../../core/utils/store';
import { PayloadAndState, ProcessEnvMap } from '../../models/interfaces';
import { CommonActions } from '../actions/common.actions';

export interface CommonState {
  hasUnsubmittedData: boolean;
  params?: ProcessEnvMap;
  routePath: string;
  currentProjectId: Nullable<string>;
}

const initialState: CommonState = {
  hasUnsubmittedData: false,
  routePath: '',
  currentProjectId: null,
};

export const reducer = createReducer(
  initialState,
  on(
    CommonActions.setHasUnsubmittedData,
    ({
      payload: hasUnsubmittedData,
    }: PayloadAndState<boolean, CommonState>) => ({ hasUnsubmittedData }),
  ),
  on(
    CommonActions.setRouteParams,
    ({
      payload: params,
    }: PayloadAndState<Undefinable<ProcessEnvMap>, CommonState>) => ({
      params,
    }),
  ),
  on(
    CommonActions.setRoutePath,
    ({ payload }: PayloadAndState<string, CommonState>) => ({
      routePath: payload.replace(/\/(.*)\/\d+\/(.*)/, '$1/:projectBundleID/$2'),
    }),
  ),
);
