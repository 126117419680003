import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Details } from '@shared/modules';
import { AccountingSelectors } from '@store/selectors';

export const AccountingDetails: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const site = useSelector(AccountingSelectors.getSiteData);

  return <Details {...props} fuzeID site={site} />;
};
