import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import {
  DropDownSelectorValues,
  ProjectStatus as ProjectStatusEnum,
  SubPage,
  Tab,
} from '@symfa-inc/providence-verizon-types';
import { canUserEditByProjectStatusAndRoles } from '@core/utils/methods';
import { EditablePage } from '@shared/components';
import {
  DDVSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';
import { ProjectSummary } from './components';

export const ProjectSummaryTab: FC<RouterProps> = ({
  history,
}: PropsWithChildren<RouterProps>) => {
  const browse = useSelector(UserSelectors.getProjectBrowsePermissions);
  const projectType = useSelector(ProjectSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit('projects', SubPage.ProjectsBrowse, Tab.Summary),
  );

  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);
  const projectStatus = useSelector(
    DDVSelectors.getCurrentDDVValueById<ProjectStatusEnum>(
      DropDownSelectorValues.ProjectStatus,
      projectStatusId,
    ),
  );

  return (
    <EditablePage
      editableComponent={ProjectSummary}
      history={history}
      permissions={browse}
      canUserEdit={
        projectTypePermission &&
        canEdit &&
        canUserEditByProjectStatusAndRoles(projectStatus, userRoles)
      }
    />
  );
};
