import { Candidate } from '@models/interfaces';

const SITE_NAME_TEMPLATE = `
        <div class="item">
            <span class="label">Site Name:</span>
            <span class="value">{siteName}</span>
        </div>`;

export const getDescriptionContent = (
  candidate: Candidate,
  needTitle?: boolean,
) =>
  `
    <div>
    ${needTitle ? `<div'>${candidate?.PSLocationCode}</div>` : ''}
        ${SITE_NAME_TEMPLATE}
    </div>
    `.replace(
    candidate?.siteName ? '{siteName}' : SITE_NAME_TEMPLATE,
    candidate?.siteName ?? '',
  );
