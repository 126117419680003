import {
  ResponsibleForProjectType,
  SubPage,
  UserRole,
} from '@symfa-inc/providence-verizon-types';
import { Guard } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { UserPermissionKeys } from '../../models/types';
import store from '../../store';
import { UserState } from '../../store/reducers/user.reducer';
import { UserHttpService } from '../services/http';
import { requestWithStoreWaiting } from '../utils/methods';

type NotAllowedUser = {
  role: UserRole;
  responsibleForProjectType?: ResponsibleForProjectType;
};
export class RedirectGuard implements Guard {
  private readonly _page: UserPermissionKeys;
  private readonly _subpage: SubPage;
  private readonly _notAllowedUsers: NotAllowedUser[];

  constructor(
    page: UserPermissionKeys,
    subpage: SubPage = '' as SubPage,
    notAllowedUsers: NotAllowedUser[] = [],
  ) {
    this._page = page;
    this._subpage = subpage;
    this._notAllowedUsers = notAllowedUsers;
  }

  private static get _state(): UserState {
    return store.getState().user;
  }

  private _accessBasedOnRoleAndProjectType(): boolean {
    if (!this._notAllowedUsers.length) {
      return true;
    }

    const { activeUser } = RedirectGuard._state;

    return this._notAllowedUsers.every(
      ({ role, responsibleForProjectType }) => {
        if (
          responsibleForProjectType === ResponsibleForProjectType.All &&
          activeUser.responsibleForProjectType?.value
        ) {
          return false;
        }

        return !(
          activeUser.roles.includes(role) &&
          responsibleForProjectType ===
            activeUser.responsibleForProjectType?.value
        );
      },
    );
  }

  async canActivate(): Promise<boolean> {
    if (!Object.keys(RedirectGuard._state.activeUser).length) {
      await requestWithStoreWaiting(
        () => HttpService.getHttpRequests(UserHttpService).getSelf(),
        () => RedirectGuard._state,
      );
    }

    return (
      (this._subpage
        ? RedirectGuard._state.activeUser.permissions[
            this._page
          ].subPageAccess.includes(this._subpage)
        : RedirectGuard._state.activeUser.permissions[this._page].haveAccess) &&
      this._accessBasedOnRoleAndProjectType()
    );
  }
}
