import {
  MAIN_PACE_TASK_IDs,
  PaceTaskType,
  UserRole,
} from '@symfa-inc/providence-verizon-types';
import { createSelector, OutputSelector } from 'reselect';
import {
  PaceTaskPermissions,
  ProjectPaceTask,
  ProjectPaceTaskData,
} from '../../models/interfaces';
import { PaceTaskDatesNarrowed } from '../../models/types';
import { AppState } from '../reducers';
import { PaceTasksState } from '../reducers/pace-tasks.reducer';
import { UserState } from '../reducers/user.reducer';

interface PaceTasksPermissionConfig {
  PACETaskId: string;
  dateType: keyof Omit<ProjectPaceTaskData, 'id'>;
}

type MainPaceTasks = PaceTaskDatesNarrowed<
  Array<keyof typeof MAIN_PACE_TASK_IDs>[number]
>;

const paceTasksState = (state: AppState): PaceTasksState => state.paceTasks;
const usersState = (state: AppState): UserState => state.user;

export namespace PaceTasksSelectors {
  export const getPaceTasks = createSelector(
    [paceTasksState],
    (state: PaceTasksState) => state.currentPaceTasks,
  );

  export const getPaceTaskFields = createSelector(
    [paceTasksState],
    (state: PaceTasksState) => state.paceTaskFields,
  );

  export const getMainPaceTaskDates = (
    dateType: PaceTaskType,
    ...mainPaceTasks: Array<keyof typeof MAIN_PACE_TASK_IDs>
  ): OutputSelector<
    [typeof paceTasksState],
    MainPaceTasks,
    (state: PaceTasksState) => MainPaceTasks
  > =>
    createSelector(
      [paceTasksState],
      (state: PaceTasksState): MainPaceTasks =>
        mainPaceTasks.reduce(
          (acc: MainPaceTasks, taskKey: keyof typeof MAIN_PACE_TASK_IDs) => ({
            ...acc,
            [taskKey]: state.paceTaskFields.find(
              (paceTask: ProjectPaceTask) =>
                paceTask.id === MAIN_PACE_TASK_IDs[taskKey],
            )?.projectPaceTaskData?.[dateType],
          }),
          {} as MainPaceTasks,
        ),
    );

  export const arePaceTasksChanged = createSelector(
    [paceTasksState],
    (state: PaceTasksState) => state.arePaceTasksChanged,
  );

  export const getPaceTasksPermissions = (
    config: Array<PaceTasksPermissionConfig>,
  ): OutputSelector<
    [typeof paceTasksState, typeof usersState],
    boolean[],
    (paceTaskState: PaceTasksState, userState: UserState) => boolean[]
  > =>
    createSelector(
      [paceTasksState, usersState],
      (paceTaskState: PaceTasksState, userState: UserState) => {
        const result: boolean[] = [];
        const userRoles: UserRole[] = userState.activeUser.roles;

        config.forEach(
          ({ PACETaskId, dateType }: PaceTasksPermissionConfig) => {
            const currentPaceTasks = paceTaskState.currentPaceTasks.find(
              (paceTasks: ProjectPaceTask) => paceTasks.id === PACETaskId,
            );

            if (currentPaceTasks) {
              const currentType =
                `${dateType}Editors` as keyof PaceTaskPermissions;
              const permissions = currentPaceTasks.permissions[
                currentType
              ] as UserRole[];

              result.push(
                permissions.some((userRole: UserRole) =>
                  userRoles.includes(userRole),
                ),
              );
            } else {
              result.push(false);
            }
          },
        );

        return result;
      },
    );

  export const isFetching = createSelector(
    [paceTasksState],
    (state: PaceTasksState) => state.isFetching,
  );
}
