import React, { FC } from 'react';
import { ISelectorOption } from '@symfa-inc/providence-verizon-types';
import { Form } from 'antd';
import { isValidForm } from '@core/utils/methods';
import { MultiUpdateAssignees } from '@models/interfaces';
import { Autocomplete, Modal } from '@shared/modules';
import { MultiSetAssigneesProps } from '../models';

export const MultiSetAssignees: FC<MultiSetAssigneesProps> = ({
  visible,
  closeHandler,
  saveHandler,
  adminList,
  userId,
  isAdminUser,
}: MultiSetAssigneesProps) => {
  const [form] = Form.useForm();

  const onCancel = (): void => {
    form.resetFields();
    closeHandler();
  };

  const onOk = async (): Promise<void> => {
    if (await isValidForm(form)) {
      saveHandler({ reporter: userId, ...form.getFieldsValue() } as Omit<
        MultiUpdateAssignees,
        'recordIds'
      >);
      onCancel();
    }
  };

  return (
    <Modal
      title="Multi Set Assignees"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      cancelText="Cancel"
      cancelButtonProps={{
        onClick: onCancel,
      }}
    >
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 15 }}>
        <Autocomplete
          label="Assigners"
          id="assignees"
          options={adminList.map(({ value, viewValue }: ISelectorOption) => ({
            value,
            viewValue,
            disabled: isAdminUser && userId === value,
          }))}
          elementProps={{
            mode: 'multiple',
          }}
          formItemProps={{
            rules: [
              {
                required: true,
                message: 'Please select one or some assignees!',
              },
            ],
          }}
        />
      </Form>
    </Modal>
  );
};
