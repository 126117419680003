import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import {
  DropDownSelectorValues,
  ProjectStatus as ProjectStatusEnum,
  SubPage,
  Tab,
} from '@symfa-inc/providence-verizon-types';
import { canUserEditByProjectStatusAndRoles } from '@core/utils/methods';
import { EditablePage } from '@shared/components';
import {
  DDVSelectors,
  EngineeringSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';
import { EngineeringSummary } from './components';

export const EngineeringSummaryTab: FC<RouterProps> = () => {
  const projectType = useSelector(EngineeringSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit(
      'engineering',
      SubPage.EngineeringBrowse,
      Tab.Summary,
    ),
  );

  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);
  const projectStatus = useSelector(
    DDVSelectors.getCurrentDDVValueById<ProjectStatusEnum>(
      DropDownSelectorValues.ProjectStatus,
      projectStatusId,
    ),
  );

  return (
    <EditablePage
      editableComponent={EngineeringSummary}
      canUserEdit={
        canEdit &&
        projectTypePermission &&
        canUserEditByProjectStatusAndRoles(projectStatus, userRoles)
      }
    />
  );
};
