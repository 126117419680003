import { useEffect, useState } from 'react';
import {
  ScopingEquipmentTotal,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';
import { SectorEquipmentType } from '@models/enums';
import {
  EquipmentTypeData,
  OptionProps,
  OtherEquipmentData,
  SectorEquipment,
  SectorEquipmentType as SectorEquipmentTypeInterface,
  TotalsEquipmentType,
} from '@models/interfaces';
import { SectorDataState } from '@models/types';
import { arrsToSelectableObject } from '../methods';

const getViewValue = (
  equipmentType: string,
  equipmentTypes: EquipmentTypeData[],
): Undefinable<OptionProps> =>
  arrsToSelectableObject<EquipmentTypeData>(equipmentTypes, 'id', 'name').find(
    (type: OptionProps) => type.value === equipmentType,
  );

const scopingStrategy = (
  totalEquipments: TotalsEquipmentType[],
  equipmentTypes: EquipmentTypeData[],
): TotalsEquipmentType[] =>
  totalEquipments.sort((a: TotalsEquipmentType, b: TotalsEquipmentType) => {
    const firstValue =
      getViewValue(a.equipmentType, equipmentTypes)?.viewValue || 0;
    const secondValue =
      getViewValue(b.equipmentType, equipmentTypes)?.viewValue || 0;

    if (firstValue > secondValue) {
      return 1;
    }

    if (firstValue < secondValue) {
      return -1;
    }

    return 0;
  });

const updateGeneralMap = (
  mapper: ScopingEquipmentTotal.GeneralMap,
  equipmentType: string,
  manufacturer: string,
  quantity: number,
): void => {
  const currentManufacturer = mapper.get(equipmentType) ?? new Map();
  const currentQuantity = currentManufacturer?.get(manufacturer) || 0;

  currentManufacturer.set(manufacturer, currentQuantity + quantity);

  mapper.set(equipmentType, currentManufacturer);
};

export const useCountingTotalEquipment = (
  strategy: 'sof' | 'scoping',
  otherEquipments: OtherEquipmentData[],
  sectorsData: SectorDataState,
  equipmentTypes?: EquipmentTypeData[],
): TotalsEquipmentType[] => {
  const [totalEquipments, setTotalEquipments] = useState<TotalsEquipmentType[]>(
    [],
  );

  useEffect((): void => {
    const mapper: ScopingEquipmentTotal.GeneralMap = new Map<
      string,
      ScopingEquipmentTotal.InternalMap
    >(); // equipmentTypeId -> manufacturerId -> quantity

    (Object.keys(sectorsData) as SectorEquipmentType[]).forEach(
      (sectorName: SectorEquipmentType) =>
        sectorsData[sectorName]?.forEach(
          ({ equipmentType, equipments }: SectorEquipmentTypeInterface) => {
            equipments
              .filter(
                ({ manufacturer, equipment }: SectorEquipment) =>
                  !!manufacturer && !!equipment,
              )
              .forEach(({ manufacturer, quantity }: SectorEquipment) =>
                updateGeneralMap(mapper, equipmentType, manufacturer, quantity),
              );
          },
        ),
    );

    otherEquipments.forEach(
      ({ quantity, manufacturer, equipmentType }: OtherEquipmentData) =>
        updateGeneralMap(mapper, equipmentType!, manufacturer!, quantity),
    );

    const result: TotalsEquipmentType[] = [];

    mapper.forEach(
      (
        internalMap: ScopingEquipmentTotal.InternalMap,
        equipmentType: string,
      ) => {
        internalMap.forEach((quantity: number, manufacturer: string) => {
          result.push({
            equipmentType,
            manufacturer,
            quantity,
          });
        });
      },
    );

    setTotalEquipments(
      strategy === 'scoping'
        ? scopingStrategy(result, equipmentTypes!)
        : result,
    );
  }, [otherEquipments, sectorsData]);

  return totalEquipments;
};
