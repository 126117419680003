import { EngineeringAdditionFilterType } from '@symfa-inc/providence-verizon-types';
import { AdditionalFilter } from '@models/interfaces';

export const ADDITIONAL_FILTERS: AdditionalFilter[] = [
  {
    key: EngineeringAdditionFilterType.DemotedRFDSRequired,
    name: 'Demoted RFDS Required',
  },
  {
    key: EngineeringAdditionFilterType.CraneFileDateRequired,
    name: 'Crane File Date Required',
  },
  {
    key: EngineeringAdditionFilterType.SOWSubmitRequired,
    name: 'SOW Submit Required',
  },
];
