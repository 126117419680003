import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { SelectValue } from 'antd/lib/select';
import { useFindCountyAndJurisdictions } from '@core/utils/hooks';
import { arrsToSelectableObject } from '@core/utils/methods';
import {
  AdminDropdownValuesData,
  ConfigureFilterProps,
  OptionProps,
  ScopingBrowseFilter,
  StateWithCounties,
} from '@models/interfaces';
import { Autocomplete } from '@shared/modules';
import { SharedSelectors } from '@store/selectors';

export const Filters: FC<ConfigureFilterProps<ScopingBrowseFilter>> = ({
  value,
  onChanges,
  formProps,
}: PropsWithChildren<ConfigureFilterProps<ScopingBrowseFilter>>) => {
  const { dropdownValues, states } = useSelector(
    SharedSelectors.getDropdownValuesAndStates,
  );

  const [stateId, setStateId] = useState<Undefinable<string>>();
  const [jurisdictionOptions, setJurisdictionOptions] = useState<OptionProps[]>(
    [],
  );

  useFindCountyAndJurisdictions({
    stateId,
    setJurisdictionOptions,
    effectDepArray: [stateId],
  });

  useEffect(() => {
    if (value.stateId !== stateId) {
      setStateId(value.stateId);
    }
  }, [value]);

  return (
    <div className="prov-project-browse-filters">
      <Autocomplete
        id="projectTypeId"
        key="projectTypeId"
        label="Project Type"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProjectType,
          'id',
          'value',
        )}
        elementProps={{
          value: value.projectTypeId,
          onChange: (projectTypeId: SelectValue): void =>
            onChanges({ projectTypeId: projectTypeId as Undefinable<string> }),
          allowClear: true,
        }}
      />
      <Autocomplete
        id="projectStatusId"
        key="projectStatusId"
        label="Project Status"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProjectStatus,
          'id',
          'value',
        )}
        elementProps={{
          value: value.projectStatusId,
          onChange: (projectStatusId: SelectValue): void =>
            onChanges({
              projectStatusId: projectStatusId as Undefinable<string>,
            }),
          allowClear: true,
        }}
      />
      <Autocomplete
        id="towerOwnerId"
        key="towerOwnerId"
        label="Tower Owner"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.TowerOwner,
          'id',
          'value',
        )}
        elementProps={{
          value: value.towerOwnerId,
          onChange: (towerOwnerId: SelectValue): void =>
            onChanges({
              towerOwnerId: towerOwnerId as Undefinable<string>,
            }),
          allowClear: true,
        }}
      />
      <Autocomplete
        id="stateId"
        key="stateId"
        label="State"
        options={arrsToSelectableObject<StateWithCounties>(
          states,
          'id',
          'name',
        )}
        elementProps={{
          onChange: (e: SelectValue): void => {
            formProps?.form?.resetFields(['jurisdictionId']);
            onChanges({
              jurisdictionId: undefined,
              stateId: e as string,
            });
            setStateId(e as string);
          },
        }}
      />
      <Autocomplete
        id="jurisdictionId"
        key="jurisdictionId"
        label="Jurisdiction"
        title="You should first select a state"
        tooltip={!stateId}
        options={jurisdictionOptions}
        elementProps={{
          disabled: !stateId,
          value: value.jurisdictionId,
          onChange: (e: SelectValue): void => {
            onChanges({ jurisdictionId: e as string });
          },
          allowClear: true,
        }}
      />
    </div>
  );
};
