import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { AccountingState } from '../reducers/accounting.reducer';

const accountingState = (state: AppState): AccountingState => state.accounting;

export namespace AccountingSelectors {
  export const getProjectType = createSelector(
    [accountingState],
    (state: AccountingState) => state.projectData.projectType?.value,
  );

  export const getSiteData = createSelector(
    [accountingState],
    (state: AccountingState) => state.accountingSite,
  );

  export const getProjectId = createSelector(
    [accountingState],
    (state: AccountingState) => state.projectData.id,
  );

  export const getProjectData = createSelector(
    [accountingState],
    (state: AccountingState) => {
      const {
        MDGLocationCode,
        PSLocationCode,
        projectBundleID,
        projectType,
        projectSubtype,
        projectStatus,
        progressStatus,
        RESpecialist,
        providenceAgent,
        civilVendor,
        projectNote,
        id,
      } = state.projectData;

      return {
        MDGLocationCode,
        PSLocationCode,
        projectBundleID,
        projectType: projectType?.value,
        projectSubtype: projectSubtype?.value,
        projectStatus: projectStatus?.value,
        progressStatus: progressStatus?.value,
        RESpecialist: RESpecialist?.value,
        providenceAgent:
          providenceAgent?.firstName && providenceAgent?.lastName
            ? `${providenceAgent.firstName} ${providenceAgent.lastName}`
            : undefined,
        civilVendor: civilVendor?.value,
        projectNote,
        projectId: id,
      };
    },
  );

  export const getNotesData = createSelector(
    [accountingState],
    (state: AccountingState) => state.notes,
  );

  export const getAccountingBrowseData = createSelector(
    [accountingState],
    (state: AccountingState) => state.accountingBrowseData,
  );
}
