import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { SelectValue } from 'antd/lib/select';
import { useFindCountyAndJurisdictions } from '@core/utils/hooks';
import { arrsToSelectableObject } from '@core/utils/methods';
import {
  AdminDropdownValuesData,
  ConfigureFilterProps,
  LeasingBrowseFilter,
  OptionProps,
  RangePickerValues,
  StateWithCounties,
} from '@models/interfaces';
import { Autocomplete, RangePicker } from '@shared/modules';
import {
  CountySelectors,
  DDVSelectors,
  ProjectSelectors,
} from '@store/selectors';

export const Filters: FC<ConfigureFilterProps<LeasingBrowseFilter>> = ({
  formProps,
  value,
  onChanges,
}: PropsWithChildren<ConfigureFilterProps<LeasingBrowseFilter>>) => {
  const agentsOptions = useSelector(ProjectSelectors.getProvidenceAgents);
  const dropdownValues = useSelector(DDVSelectors.getDDValues);
  const states = useSelector(CountySelectors.getStatesWithCounties);

  const [stateId, setStateId] = useState<Undefinable<string>>();
  const [countyOptions, setCountyOptions] = useState<OptionProps[]>([]);
  const [jurisdictionOptions, setJurisdictionOptions] = useState<OptionProps[]>(
    [],
  );

  useFindCountyAndJurisdictions({
    stateId,
    setCountyOptions,
    setJurisdictionOptions,
    effectDepArray: [stateId, states],
  });

  useEffect(() => {
    if (stateId !== value.stateId) {
      setStateId(value.stateId);
    }
  }, [value]);

  return (
    <div className="prov-project-browse-filters">
      <Autocomplete
        id="projectTypeId"
        key="projectTypeId"
        label="Project Type"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProjectType,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.projectTypeId,
          onChange: (e: SelectValue): void =>
            onChanges({ projectTypeId: e as string }),
        }}
      />
      <Autocomplete
        id="projectStatusId"
        key="projectStatusId"
        label="Project Status"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProjectStatus,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.projectStatusId,
          onChange: (e: SelectValue): void =>
            onChanges({ projectStatusId: e as string }),
        }}
      />
      <Autocomplete
        id="providenceAgentId"
        key="providenceAgentId"
        label="Providence Agent"
        options={agentsOptions}
        elementProps={{
          allowClear: true,
          value: value.providenceAgentId,
          onChange: (e: SelectValue): void =>
            onChanges({ providenceAgentId: e as string }),
        }}
      />
      <Autocomplete
        id="progressStatusId"
        key="progressStatusId"
        label="Progress Status"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProgressStatus,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.progressStatusId,
          onChange: (e: SelectValue): void =>
            onChanges({ progressStatusId: e as string }),
        }}
      />
      <Autocomplete
        id="towerOwnerId"
        key="towerOwnerId"
        label="Tower Owner"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.TowerOwner,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.towerOwnerId,
          onChange: (e: SelectValue): void =>
            onChanges({ towerOwnerId: e as string }),
        }}
      />
      <RangePicker
        id="assignedDate"
        key="assignedDate"
        label="Assigned Date"
        onChange={(date?: RangePickerValues): void =>
          onChanges({ assignedDate: date })
        }
        value={value.assignedDate}
      />
      <Autocomplete
        id="stateId"
        key="stateId"
        label="State"
        options={arrsToSelectableObject<StateWithCounties>(
          states,
          'id',
          'name',
        )}
        elementProps={{
          allowClear: true,
          onChange: (e: SelectValue): void => {
            formProps?.form?.resetFields(['countyId', 'jurisdictionId']);
            setStateId(e as string);
            onChanges({
              stateId: e as string,
              jurisdictionId: undefined,
              countyId: undefined,
            });
          },
        }}
      />
      <Autocomplete
        id="countyId"
        key="countyId"
        label="County"
        title="You should first select a state"
        tooltip={!stateId}
        options={countyOptions}
        elementProps={{
          allowClear: true,
          disabled: !stateId,
          value: value.countyId,
          onChange: (e: SelectValue): void =>
            onChanges({ countyId: e as string }),
        }}
      />
      <Autocomplete
        id="jurisdictionId"
        key="jurisdictionId"
        label="Jurisdiction"
        title="You should first select a state"
        tooltip={!stateId}
        options={jurisdictionOptions}
        elementProps={{
          allowClear: true,
          disabled: !stateId,
          value: value.jurisdictionId,
          onChange: (e: SelectValue): void =>
            onChanges({ jurisdictionId: e as string }),
        }}
      />
    </div>
  );
};
