import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { ApiMethods } from '@models/enums';
import {
  AccountingBrowseResponse,
  AccountingBrowseUpdateData,
  AccountingDetails,
  AccountingExportParams,
  PaginatedResponse,
} from '@models/interfaces';
import {
  AccountingFilterOptionsResponse,
  MainAccountingBrowseFilter,
} from '@models/types';
import {
  AccountingActions,
  BreadcrumbsActions,
  SOFToolActions,
} from '@store/actions';
import { ProjectFuzeIDsEffects, ProjectsEffects } from '@store/effects';
import { CommonHttpService } from './common-http.service';

export class AccountingHttpService extends CommonHttpService {
  @StoredRequest(AccountingActions.getAccountingBrowseDataAction)
  getAccountingBrowse(
    params: MainAccountingBrowseFilter,
  ): Promise<PaginatedResponse<AccountingBrowseResponse>> {
    return this.http.callApi.get(`${this.apiUrl}/accounting`, null, {
      params,
    });
  }

  @StoredRequest(SOFToolActions.getSOFToolBrowseFilters)
  getAccountingBrowseFiltersOptions(): Promise<AccountingFilterOptionsResponse> {
    return this.http.callApi.get(`${this.apiUrl}/accounting/filter-options`);
  }

  @StoredRequest(
    AccountingActions.getAccountingDetailsAction,
    BreadcrumbsActions.accounting,
  )
  async getAccounting(projectBundleID: string): Promise<AccountingDetails> {
    const resp = await this.http.callApi.get(
      `${this.apiUrl}/accounting/${projectBundleID}`,
    );

    ProjectsEffects.setPaceTasks(resp);

    ProjectsEffects.setHoldOptions(
      ProjectsEffects.setCurrentProvidenceAgent(
        ProjectFuzeIDsEffects.setProjectFuzeIDs(resp.projectData),
      ),
    );

    return resp;
  }

  @StoredRequest(AccountingActions.updateAccountingBrowseAction)
  async updateAccountingBrowse(
    id: string,
    body: AccountingBrowseUpdateData,
  ): Promise<AccountingBrowseUpdateData> {
    await this.http.callApi.patch(`${this.apiUrl}/accounting`, body, {
      params: { projectId: id },
    });

    return { ...body, id };
  }

  exportAccountingProjects({
    exportType,
    ...params
  }: AccountingExportParams): Promise<void> {
    return this.downloadFile(
      ApiMethods.Get,
      `${this.apiUrl}/accounting/report/${exportType.toLowerCase()}`,
      { params },
    );
  }
}
