import { SectorEquipmentType as SectorEquipmentTypeEnum } from '@models/enums';
import {
  BaseUpdateEquipmentData,
  OtherEquipmentData,
  SectorEquipment,
  SectorEquipmentData,
  SectorEquipmentType,
} from '@models/interfaces';

export const processScopingEquipmentInfo = <T extends BaseUpdateEquipmentData>({
  otherEquipments,
  sectors,
  ...restData
}: T) => {
  const processedOtherEquipments = otherEquipments.map(
    (equipment: OtherEquipmentData) => {
      const { equipmentType, generatedId, ...itemToReq } = equipment;

      return itemToReq;
    },
  );

  const processedSectors = Object.keys(sectors)
    .filter(
      (sectorName: string) =>
        sectors[sectorName as SectorEquipmentTypeEnum].length,
    )
    .reduce<SectorEquipmentData[]>(
      (
        acc: SectorEquipmentData[],
        sectorName: string,
      ): SectorEquipmentData[] => [
        ...acc,
        {
          type: sectorName,
          sectorEquipmentTypes: sectors[
            sectorName as SectorEquipmentTypeEnum
          ].map((sector: SectorEquipmentType) => ({
            ...sector,
            equipments: sector.equipments.filter(
              (equipmentItem: SectorEquipment) => !!equipmentItem.equipment,
            ),
          })),
        } as SectorEquipmentData,
      ],
      [],
    );

  return {
    ...restData,
    sectors: processedSectors,
    otherEquipments: processedOtherEquipments,
  };
};
