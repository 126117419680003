import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';
import { ExportsBrowseFilter } from '@models/interfaces';

export const EXPORTS_BROWSE_DEF_FILTER_VALUE = {
  ...BROWSE_DEFAULT_FILTER_VALUE,
  showCompletedProjects: false,
  showExportedRecords: false,
  todaysDate: false,
  reportDateType: 'Both',
  reportDate: undefined,
};

export const EXPORT_BROWSE_DEFAULT_CONFIGURE_FILTER: ExportsBrowseFilter = {
  actualDateIsNA: undefined,
  PSLocationCode: undefined,
  PACEForecastedDateIsNA: undefined,
  fuzeId: undefined,
  paceTaskID: undefined,
  projectBundleID: undefined,
};
