import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CloseOutlined, ToTopOutlined } from '@ant-design/icons/lib';
import { Drawer, message } from 'antd';
import { MessageType } from 'antd/lib/message';
import { FinancialSiteFeesData } from '@models/interfaces';
import { PrimaryButton } from '@shared/modules';
import { FinancialDrawerProps } from './models';

import './styles.scss';

export const FinancialDrawer: FC<FinancialDrawerProps> = ({
  onClose,
  visible,
  data,
  getSiteFeeTypeById,
}: PropsWithChildren<FinancialDrawerProps>) => {
  const [feesArray, setFeesArray] = useState<FinancialSiteFeesData[]>(
    data.financialData,
  );

  useEffect(() => {
    setFeesArray(data.financialData);
  }, [data]);

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      className="prov-financial-drower"
      width={445}
    >
      <ul className="fee-list">
        <div className="closing-cross">
          <CloseOutlined
            onClick={(
              e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>,
            ): void => onClose?.(e)}
            className="close-icon"
          />
        </div>
        {feesArray.length
          ? feesArray.map(
              (fee: FinancialSiteFeesData): ReactNode => (
                <li className="fee-item" key={fee.id}>
                  <span className="fee-item__text">
                    {getSiteFeeTypeById(fee.siteFeeType || '')}
                    {` $ ${fee.amount}`} (WHAT) PAID TO
                    {` ${fee.payee} `}
                    (WHO) FOR
                    {` ${fee.siteFeesNote ?? ''}`} (WHY)
                    {` ${fee.paymentDate}`} (WHEN) FOR SITE
                    {` ${data.siteData.siteName}`} (WHERE)
                  </span>
                </li>
              ),
            )
          : null}
      </ul>
      <div className="btn-wrapper">
        <CopyToClipboard
          text={feesArray
            .map(
              (fee: FinancialSiteFeesData) =>
                `${getSiteFeeTypeById(fee.siteFeeType || '')} $ ${
                  fee.amount
                } (WHAT) PAID TO ${fee.payee} (WHO) FOR ${
                  fee.siteFeesNote ?? ''
                } (WHY) ${fee.paymentDate} (WHEN) FOR SITE ${
                  data.siteData.siteName
                } (WHERE)`,
            )
            .join('\n')}
          onCopy={(): MessageType => message.info('Copied to clipboard')}
        >
          <PrimaryButton
            title="Export"
            icon={<ToTopOutlined />}
            className="export-btn"
            disabled={!feesArray.length}
          />
        </CopyToClipboard>
      </div>
    </Drawer>
  );
};
