import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { SiteState } from '../reducers/site.reducer';

const siteState = (state: AppState): SiteState => state.site;

export namespace SiteSelectors {
  export const isFetching = createSelector(
    [siteState],
    (state: SiteState) => state.isFetching,
  );

  export const getSiteBrowseInfo = createSelector(
    [siteState],
    (state: SiteState) => state.siteBrowse,
  );

  export const getCurrentSite = createSelector(
    [siteState],
    (state: SiteState) => state.currentSite,
  );

  export const getSiteInformationTabInfo = createSelector(
    [siteState],
    ({ currentSite, associatedProjects }: SiteState) => ({
      currentSite,
      associatedProjects,
    }),
  );

  export const getSiteContactsInfo = createSelector(
    [siteState],
    (state: SiteState) => state.siteContacts,
  );

  export const getCreatedSiteUniqueFields = createSelector(
    [siteState],
    (state: SiteState) => state.createdSiteUniqueFields,
  );

  export const getPSLocationCodeOptions = createSelector(
    [siteState],
    (state: SiteState) => state.PSLocationCodeOptions,
  );

  export const getMDGLocationCodeOptions = createSelector(
    [siteState],
    (state: SiteState) => state.MDGLocationCodeOptions,
  );
}
