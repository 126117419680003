import React, { ReactElement, ReactNode, ReactText } from 'react';
import {
  DateStringOrNull,
  Nullable,
} from '@symfa-inc/providence-verizon-types';
import classNames from 'classnames';
import {
  AdminDefaultTowerAgentAgent,
  AdminName,
  DashboardProjectData,
  LeasingBrowseResponseItem,
  OptionProps,
  ProjectFuzeID,
  RenderProps,
} from '@models/interfaces';
import {
  ProjectPaceTaskDataType,
  RenderProjectPaceTaskDataPropsType,
} from '@models/types';
import {
  ReadOnlyBoolean,
  ReadOnlyDate,
  ReadOnlyInputNumber,
  ReadOnlySelect,
} from '@shared/modules/ui-kit/read-only-elements';
import { mainDateFormat } from '../methods/main-date-format';

export const projectFuzeIDRender =
  ({
    field,
    className = '',
    renderElement = (element: any): ReactNode => element,
    dateField = false,
  }: RenderProps<ProjectFuzeID>) =>
  (fuzeIDs: ProjectFuzeID[]): ReactElement =>
    (
      <div className={classNames(className, 'project-fuze-id-cell')}>
        {fuzeIDs?.map((fuzeID: ProjectFuzeID) => {
          const value = dateField
            ? mainDateFormat(fuzeID[field] as string)
            : fuzeID[field];

          return (
            <span
              key={
                fuzeID?.id ||
                fuzeID?.generatedId ||
                `${fuzeID.fuzeID}_${fuzeID.PSProjectNumber}`
              }
            >
              {renderElement(value)}
            </span>
          );
        })}
      </div>
    );

export const leasingProjectFuzeIDRender =
  ({ field }: RenderProps<LeasingBrowseResponseItem['categories'][number]>) =>
  (fuzeIDs: LeasingBrowseResponseItem['categories']): ReactElement =>
    (
      <div className="project-fuze-id-cell">
        {fuzeIDs?.map(
          (fuzeID: LeasingBrowseResponseItem['categories'][number]) => {
            const value = fuzeID[field];

            return <span key={value}>{value}</span>;
          },
        )}
      </div>
    );

export const homeProjectFuzeIDRender = <
  T extends
    | DashboardProjectData['PSProjectNumbers']
    | DashboardProjectData['fuzeIDs'],
>(
  projectNumberData: T,
): ReactElement => (
  <div className="project-fuze-id-cell">
    {projectNumberData?.map((item: T[number]) => (
      <span key={item.value}>{item.value}</span>
    ))}
  </div>
);

export const readOnlyDatePickerRenderer = (
  date?: DateStringOrNull,
): ReactNode => <ReadOnlyDate date={date} />;

export const readOnlyInputNumberRender = (
  numberValue?: ReactText,
): ReactNode => <ReadOnlyInputNumber value={numberValue} />;

export const readOnlyPriceRender = (priceValue?: ReactText): ReactNode => (
  <span>
    <ReadOnlyInputNumber value={priceValue} suffix="$" />
  </span>
);

export const readOnlyPercentRender = (percentValue?: ReactText): ReactNode => (
  <span>
    <ReadOnlyInputNumber value={percentValue} prefix="%" />
  </span>
);

export const readOnlySelectRender = (
  options: OptionProps[],
  value?: Nullable<string>,
): ReactNode => <ReadOnlySelect value={value} options={options} />;

export const readonlyBooleanRender = (booleanValue?: boolean): ReactNode => (
  <ReadOnlyBoolean value={booleanValue} />
);

export const projectPaceTaskDataRender =
  ({ field, id: PACETaskId }: RenderProjectPaceTaskDataPropsType) =>
  (projectPaceTaskData: Array<ProjectPaceTaskDataType>): ReactElement => {
    const currentPaceTask = [...projectPaceTaskData]?.find(
      ({ paceTask: { id } }: ProjectPaceTaskDataType) => id === PACETaskId,
    );

    return field === 'invoiceNumber' ? (
      <>{currentPaceTask?.[field]}</>
    ) : (
      <ReadOnlyDate date={currentPaceTask?.[field]} />
    );
  };

export const phoneElementRender = (phone?: string): string =>
  phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/gm, '+$1 ($2) $3-$4') ?? '';

export const providenceAgentRender = (
  providenceAgent?: AdminDefaultTowerAgentAgent,
): ReactNode =>
  providenceAgent && `${providenceAgent.firstName} ${providenceAgent.lastName}`;

export const adminNameRender = (
  adminsList: AdminName[],
  adminId: string,
): string => {
  const foundAdmin = adminsList.find(
    (admin: AdminName) => admin.id === adminId,
  );

  return foundAdmin ? `${foundAdmin.firstName} ${foundAdmin.lastName}` : '';
};

export const wrappedTextRender = (text: string): ReactNode => (
  <div className={classNames({ 'text-wrapper': text })}>{text}</div>
);
