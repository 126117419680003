import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FRONTEND_DATE_FORMAT } from '@symfa-inc/providence-verizon-types';
import { dateWorker } from '@core/utils/date-worker';
import { NotificationSelectors } from '@store/selectors';

export const Mailer: FC = () => {
  const emailCertificationDate = useSelector(
    NotificationSelectors.getEmailCertificationDate,
  );

  const [currentTime, setCurrentTime] = useState(dateWorker());

  const timeBetween = dateWorker.duration(
    dateWorker(emailCertificationDate).diff(currentTime),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dateWorker());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="prov-notification">
      <div className="prov-notification_wrap">
        <div className="prov-notification_wrap__labels">
          <span className="first-element">
            Email certificate expiration date:
          </span>{' '}
          <span>Remaining time:</span>{' '}
        </div>
        <div className="prov-notification_wrap__dates">
          <span className="first-element">
            {dateWorker(emailCertificationDate).format(FRONTEND_DATE_FORMAT)}
          </span>
          <span>
            <span>{timeBetween.years()}yr </span>
            <span>{timeBetween.months()}m </span>
            <span>{timeBetween.days()}d </span>
            <span>{timeBetween.hours()}h </span>
            <span>{timeBetween.minutes()}min </span>
            <span>{timeBetween.seconds()}s </span>
          </span>
        </div>
      </div>
    </div>
  );
};
