import React, { PropsWithChildren, ReactNode, ReactText } from 'react';
import { ISelectorOption } from '@symfa-inc/providence-verizon-types';
import { findFromSelectorOptions } from '@core/utils/methods';
import { ROSelect } from '../models';

import './styles.scss';

export const ReadOnlySelect = <T extends ReactText | ReactNode = ReactText>({
  options,
  value,
  render,
}: PropsWithChildren<ROSelect<T>>) => {
  const viewValue = Array.isArray(value) ? value : [value];

  const result = render
    ? render(
        findFromSelectorOptions(
          value as string,
          options as ISelectorOption[],
        ) as T,
      )
    : viewValue
        ?.map(v =>
          findFromSelectorOptions(v as string, options as ISelectorOption[]),
        )
        .join(', ');

  return <div className="prov-read-only-select">{result}</div>;
};
