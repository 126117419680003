import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Details } from '@shared/modules/details';
import { ProjectSelectors } from '@store/selectors';

export const ProjectDetails: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const site = useSelector(ProjectSelectors.getProjectDetailsInfo);

  return <Details {...props} fuzeID site={site} />;
};
