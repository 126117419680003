import { KeyOutlined } from '@ant-design/icons/lib';
import { SubPage } from '@symfa-inc/providence-verizon-types';
import { RedirectGuard } from '@core/guards/redirect.guard';
import { tabPropsToTabs } from '@core/utils/methods';
import { Route, RouteGroup } from '@models/classes';
import {
  DROPDOWN_VALUES_TABS,
  NOTIFICATIONS_TABS,
  PACE_TASKS_TABS,
} from '@models/constants';
import { MenuPlacement } from '@models/enums';
import { PaceTasksBrowse } from './components/pace-tasks-browse';
import {
  County,
  DefaultTowerAgent,
  DriverManagement,
  DropdownValues,
  NotificationRoot,
  PaceTasksDetails,
  Vendors,
} from './components';
import {
  DefaultTowerAgentResolver,
  DriverManagementResolver,
  DropdownValuesResolver,
  NotificationsResolver,
  ProjectPaceTasksBrowseResolver,
  VendorsResolver,
} from './resolvers';

export const adminPaceTaskTabs = tabPropsToTabs(PACE_TASKS_TABS);

export const adminNotificationTabs = tabPropsToTabs(NOTIFICATIONS_TABS);

export const adminDropdownValuesTabs = tabPropsToTabs(DROPDOWN_VALUES_TABS);

export const adminRoutes = new RouteGroup({
  name: 'Admin',
  path: '/admin',
})
  .setMenu(MenuPlacement.Main, KeyOutlined)
  .breadcrumb()
  .addGuard(new RedirectGuard('admin'), '/')
  .addRoutes([
    new Route({
      path: '/dropdown-values',
      name: 'Dropdown values',
      component: DropdownValues,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('admin', SubPage.AdminDropdownValues), '/')
      .addResolver(new DropdownValuesResolver())
      .breadcrumb()
      .addTabs(adminDropdownValuesTabs),
    new Route({
      path: '/default-tower-agent',
      name: 'Default Tower Agent',
      component: DefaultTowerAgent,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('admin', SubPage.AdminDefaultTowerAgent), '/')
      .addResolver(new DefaultTowerAgentResolver())
      .breadcrumb(),
    new Route({
      path: '/county',
      name: 'County',
      component: County,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('admin', SubPage.AdminCountry), '/')
      .breadcrumb(),
    new Route({
      path: '/driver-management',
      name: 'Driver Management',
      component: DriverManagement,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('admin', SubPage.AdminDriverManagement), '/')
      .addResolver(new DriverManagementResolver())
      .breadcrumb(),
    new Route({
      path: '/vendors',
      name: 'Vendors',
      component: Vendors,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('admin', SubPage.AdminVendors), '/')
      .addResolver(new VendorsResolver())
      .breadcrumb(),
    new Route({
      name: 'PACE Tasks Browse',
      path: '/pace-tasks-browse',
      component: PaceTasksBrowse,
    })
      .addResolver(new ProjectPaceTasksBrowseResolver())
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('admin', SubPage.AdminPaceTasksBrowse), '/')
      .breadcrumb(),
    new Route({
      name: 'PACE Task',
      path: '/pace-tasks',
      component: PaceTasksDetails,
    })
      .breadcrumb()
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('admin', SubPage.AdminPaceTasks), '/')
      .addTabs(adminPaceTaskTabs),
    new Route({
      name: 'Notifications',
      path: '/notifications',
      component: NotificationRoot,
    })
      .addResolver(new NotificationsResolver())
      .breadcrumb()
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('admin', SubPage.AdminNotifications), '/')
      .addTabs(adminNotificationTabs),
  ]);
