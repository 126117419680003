import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { ISchedule } from '@symfa-inc/providence-verizon-types';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';
import { ModalMainTypes } from '@models/enums';
import { DeleteModal, VirtualTable } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { DDVSelectors, NotificationSelectors } from '@store/selectors';
import { TriggerFilter } from '../../components';
import { ScheduleModal } from './modal';
import { getSchedulesTableColumns } from './models';

export const Schedules: FC = () => {
  const schedules = useSelector(NotificationSelectors.getSchedules);
  const paceTasksList = useSelector(NotificationSelectors.getPaceTasksList);
  const userList = useSelector(NotificationSelectors.getUserList);
  const { TowerOwnerDDV, ProjectTypeDDV, ProjectSubtypeDDV } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes([
      'TowerOwner',
      'ProjectType',
      'ProjectSubtype',
    ]),
  );
  const projectTypes = useSelector(DDVSelectors.getProjectTypes);

  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [modalValue, setModalValues] = useState<ISchedule | null>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const toggleAddAndEditModal = (
    type: ModalMainTypes,
    formValue?: ISchedule,
  ): void => {
    setCurrentModalType(type);
    setModalValues(formValue || null);
    setAddEditModalVisible(!isAddEditModalVisible);
  };

  const toggleDeleteModal = (formValue?: ISchedule): void => {
    setDeleteModalVisible(!deleteModalVisible);
    setModalValues(formValue || null);
  };

  const onDelete = async (): Promise<void> => {
    await HttpService.getHttpRequests(NotificationsHttpService).deleteSchedule(
      modalValue!._id,
    );

    toggleDeleteModal();
  };

  return (
    <div className="prov-notification">
      <div className="prov-notification__header-controls">
        <TriggerFilter towerOwners={TowerOwnerDDV} place="schedules" />
        <PrimaryButton
          title="Add Schedule"
          icon="+"
          onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
        />
      </div>
      <VirtualTable
        dataSource={schedules}
        columns={getSchedulesTableColumns(
          paceTasksList,
          userList,
          TowerOwnerDDV,
          ProjectTypeDDV,
          projectTypes,
          ProjectSubtypeDDV,
          (triggerRecord: ISchedule): void =>
            toggleAddAndEditModal(ModalMainTypes.Edit, triggerRecord),
          (triggerRecord: ISchedule): void => toggleDeleteModal(triggerRecord),
        )}
        maxViewRow={5}
        rowHeight={72}
        hasLongCellData
        verticalScrollCorrection={30}
      />
      <ScheduleModal
        visible={isAddEditModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditModal}
        itemValue={modalValue}
        paceTaskList={paceTasksList}
        userList={userList}
        towerOwners={TowerOwnerDDV}
      />
      <DeleteModal
        visible={deleteModalVisible}
        onOk={onDelete}
        onCancel={(): void => toggleDeleteModal()}
      />
    </div>
  );
};
