import {
  PaceTaskScreen as PaceTaskScreenEnum,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';
import { OptionProps } from 'antd/es/mentions';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { ApiMethods } from '@models/enums';
import {
  MainBrowseFilter,
  PaginatedResponse,
  ProjectBrowseResponse,
  ProjectCreateRequestData,
  ProjectDetailsResponse,
  ProjectPaceTask,
  ProjectPaceTaskData,
  ProjectUpdateRequestData,
  SiteBrowse,
} from '@models/interfaces';
import { FileType, ProjectDetailsWithoutFuzeIDs } from '@models/types';
import {
  BreadcrumbsActions,
  ProjectActions,
  SiteActions,
} from '@store/actions';
import { ProjectFuzeIDsEffects } from '@store/effects';
import { CommonHttpService } from './common-http.service';

export class ProjectHttpService extends CommonHttpService {
  @StoredRequest(ProjectActions.getProjectBrowseDataAction)
  getProjectBrowse(
    params: MainBrowseFilter,
  ): Promise<PaginatedResponse<ProjectBrowseResponse>> {
    return this.http.callApi.get(`${this.apiUrl}/projects`, null, {
      params,
    });
  }

  @StoredRequest(ProjectActions.getProjectAction, BreadcrumbsActions.project)
  async getProject(
    projectBundleID: string,
  ): Promise<ProjectDetailsWithoutFuzeIDs> {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/projects/${projectBundleID}`,
    );

    return ProjectFuzeIDsEffects.setProjectFuzeIDs(response);
  }

  @StoredRequest(ProjectActions.getProvidenceAgent)
  getProvidenceAgent(siteId: string): Promise<Undefinable<string>> {
    return this.http.callApi.get(
      `${this.apiUrl}/projects/${siteId}/providence-agent`,
    );
  }

  @StoredRequest(ProjectActions.getProvidenceAgents)
  getProvidenceAgents(): Promise<OptionProps[]> {
    return this.http.callApi.get(`${this.apiUrl}/projects/providence-agents`);
  }

  @StoredRequest(SiteActions.getSiteAction)
  getSiteByProjectBundleID(projectBundleID: string): Promise<SiteBrowse> {
    return this.http.callApi.get(
      `${this.apiUrl}/projects/${projectBundleID}/site`,
      null,
    );
  }

  importProjects(file: FileType): Promise<void> {
    return this.uploadFile(
      ApiMethods.Post,
      `${this.apiUrl}/projects/import`,
      file,
    ) as Promise<void>;
  }

  importUpdateProjects(file: FileType): Promise<void> {
    return this.uploadFile(
      ApiMethods.Patch,
      `${this.apiUrl}/projects/import`,
      file,
    ) as Promise<void>;
  }

  importProjectPACEDates(file: File): Promise<void> {
    return this.uploadFile(
      ApiMethods.Patch,
      `${this.apiUrl}/projects/import/pace-dates`,
      file,
    ) as Promise<void>;
  }

  siteOnValidation(siteId: string): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/projects/site-on-validation`,
      null,
      {
        params: { siteId },
      },
    );
  }

  createProject({ fuzeIDs, ...body }: ProjectCreateRequestData): Promise<void> {
    return this.http.callApi.post(`${this.apiUrl}/projects`, {
      ...body,
      categories: fuzeIDs,
    });
  }

  @StoredRequest(ProjectActions.updateProjectAction)
  async updateProject(
    projectId: string,
    { fuzeIDs, ...body }: ProjectUpdateRequestData,
  ): Promise<ProjectDetailsResponse> {
    const project = await this.http.callApi.patch(
      `${this.apiUrl}/projects`,
      { ...body, categories: fuzeIDs },
      {
        params: { projectId },
      },
    );

    return ProjectFuzeIDsEffects.setProjectFuzeIDs(project);
  }

  @StoredRequest(ProjectActions.updateProjectPaceTasksAction)
  updateProjectPaceTasks(
    id: string,
    screen: PaceTaskScreenEnum,
    body: ProjectPaceTaskData[],
  ): Promise<ProjectPaceTask[]> {
    return this.http.callApi.patch(
      `${this.apiUrl}/projects/pace-task-data`,
      body,
      {
        params: { id, screen },
      },
    );
  }

  async deleteProject(projectId: string): Promise<void> {
    await this.http.callApi.delete(`${this.apiUrl}/projects`, null, {
      params: { projectId },
    });
  }

  getProjectProjectFuzeIDValidation(
    id: string,
    field: 'PSProjectNumber' | 'fuzeId',
    value: string,
  ): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/projects/categories/validation`,
      null,
      {
        params: {
          id,
          field,
          value,
        },
      },
    );
  }
}
