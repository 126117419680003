import { ProjectBrowseFilter } from '@models/interfaces';

export const PROJECT_BROWSE_DEFAULT_CONFIGURE_FILTER: Partial<ProjectBrowseFilter> =
  {
    projectBundleID: undefined,
    projectTypeId: undefined,
    projectStatusId: undefined,
    projectSubtypeId: undefined,
    progressStatusId: undefined,
    civilVendorId: undefined,
    RE020ForecastedDate: undefined,
    RE020ActualDate: undefined,
    CI032ForecastedDate: undefined,
    CI032ActualDate: undefined,
    assignedDate: undefined,
    towerOwnerId: undefined,
    stateId: undefined,
    countyId: undefined,
    jurisdictionId: undefined,
  };
