import { DriverWorkflow } from '@symfa-inc/providence-verizon-types';
import { DetailsResolver } from '@core/resolvers';
import { HttpService } from '@core/services';
import {
  DriverManagementHttpService,
  PermittingHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';

export class PermittingDetailsResolver extends DetailsResolver {
  async resolve(): Promise<void> {
    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await HttpService.getHttpRequests(
      PermittingHttpService,
    ).getPermittingDetailsData(projectBundleID);

    await HttpService.getHttpRequests(
      DriverManagementHttpService,
    ).getDriversListByWorkflow(DriverWorkflow.Permitting);
  }
}
