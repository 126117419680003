import {
  MAIN_PACE_TASK_IDs,
  PaceTaskType,
} from '@symfa-inc/providence-verizon-types';
import {
  EngineeringRegulatoryCompliance,
  EngineeringRFDSPhase1,
} from '@models/interfaces';
import { ProjectsEffects } from '@store/effects';

export const updateEngineeringPACETaskFields = (
  regulatoryComplianceSection: EngineeringRegulatoryCompliance,
  RFDSPhase1Section: EngineeringRFDSPhase1,
): void => {
  ProjectsEffects.updatePaceTaskFieldsByConfig(regulatoryComplianceSection, [
    {
      id: MAIN_PACE_TASK_IDs.RFSSROrdered,
      dateType: PaceTaskType.ActualDate,
      field: 'RFSSROrdered',
    },
    {
      id: MAIN_PACE_TASK_IDs.RFSSRReceived,
      dateType: PaceTaskType.ActualDate,
      field: 'RFSSRReceived',
    },
  ]);
  ProjectsEffects.updatePaceTaskFieldsByConfig(RFDSPhase1Section, [
    {
      id: MAIN_PACE_TASK_IDs.finalRFDSReceived,
      dateType: PaceTaskType.ActualDate,
      field: 'finalRFDSReceived',
    },
    {
      id: MAIN_PACE_TASK_IDs.RBSDataEntered,
      dateType: PaceTaskType.PACEActualDate,
      field: 'CI004',
    },
  ]);
};
