import {
  DateStringOrNull,
  FRONTEND_DATE_FORMAT,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';
import { dateWorker } from '@core/utils/date-worker';
import { DateWorkerString } from '@models/types';

export const getDateStringOrEmptyString = (
  value: Undefinable<DateWorkerString | DateStringOrNull> = '',
): string =>
  value != null ? dateWorker(value).format(FRONTEND_DATE_FORMAT) : '';
