import { ProjectManagementFilterField } from '@symfa-inc/providence-verizon-types';
import { arrsToSelectableObject } from '@core/utils/methods';
import { OptionProps, StateWithCounties } from '@models/interfaces';
import {
  AutocompleteWithOperator,
  InputNumberWithOperator,
  RangePicker,
} from '@shared/modules';
import store from '@store/index';
import { FieldsListsOptions } from './fields-lists-options.type';
import { ItemToRender } from './item-to-render.type';

const getOptionsWithNullValue = (options?: OptionProps[]): OptionProps[] => [
  { value: 'null', viewValue: 'Null' },
  ...(options || []),
];

export const getSiteFieldsList = (
  props?: FieldsListsOptions,
): ItemToRender[] => {
  const {
    admin: {
      dropdownValues: { dropdownsOptions },
      countyValues: { states },
    },
  } = store.getState();

  const {
    countyOptions,
    jurisdictionOptions,
    PSLocationCodes,
    siteNames,
    ZIPs,
  } = props ?? {};

  return [
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.PSLocationCode,
      label: 'PS Location Code',
      componentProps: {
        mainItemProps: { options: PSLocationCodes },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.SiteName,
      label: 'Site Name',
      componentProps: {
        mainItemProps: { options: getOptionsWithNullValue(siteNames) },
      },
    },
    {
      component: InputNumberWithOperator,
      id: ProjectManagementFilterField.Longitude,
      label: 'Longitude',
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.TowerType,
      label: 'Tower Type',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.TowerType),
        },
      },
    },
    {
      component: InputNumberWithOperator,
      id: ProjectManagementFilterField.TowerHeight,
      label: 'Tower Height',
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.State,
      label: 'State',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(
            arrsToSelectableObject<StateWithCounties>(states, 'id', 'name'),
          ),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.Zip,
      label: 'Zip',
      componentProps: {
        mainItemProps: { options: getOptionsWithNullValue(ZIPs) },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.County,
      label: 'County',
      componentProps: {
        mainItemProps: { options: getOptionsWithNullValue(countyOptions) },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.Jurisdiction,
      label: 'Jurisdiction',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(jurisdictionOptions),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.TowerOwner,
      label: 'Tower Owner',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.TowerOwner),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.Submarket,
      label: 'Submarket',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.Submarket),
        },
      },
    },
  ];
};

export const getProjectFieldsList = (
  props?: FieldsListsOptions,
): ItemToRender[] => {
  const {
    admin: {
      dropdownValues: { dropdownsOptions },
    },
    project: { providenceAgents },
  } = store.getState();

  const { fuzeIDs, PSProjectNumbers, projectBundleIDs } = props ?? {};

  return [
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.ProjectBundleID,
      label: 'Project Bundle ID',
      componentProps: {
        mainItemProps: { options: projectBundleIDs },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.ProjectStatus,
      label: 'Project Status',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.ProjectStatus),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.ProgressStatus,
      label: 'Progress Status',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.ProgressStatus),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.RESpecialist,
      label: 'RE Specialist',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.RESpecialist),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.ProvidenceAgent,
      label: 'Providence Agent',
      componentProps: {
        mainItemProps: { options: getOptionsWithNullValue(providenceAgents) },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.CivilVendor,
      label: 'Civil Vendor',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.CivilVendor),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      label: 'Fuze ID',
      id: ProjectManagementFilterField.FuzeID,
      componentProps: {
        mainItemProps: {
          options: fuzeIDs,
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      label: 'PS Project Number',
      id: ProjectManagementFilterField.PSProjectNumber,
      componentProps: {
        mainItemProps: {
          options: PSProjectNumbers,
        },
      },
    },
    {
      component: RangePicker,
      label: 'Project Assigned Date',
      id: ProjectManagementFilterField.ProjectAssignedDate,
    },
    // not project fields
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.AEVendor,
      label: 'A/E CD Vendor',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.AEVendor),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.StructuralResult,
      label: 'Structural Result',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.StructuralResult),
        },
      },
    },
  ];
};
