import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import classNames from 'classnames';
import { YES_NO_GROUP } from '@models/constants';
import { ModalProps } from '@models/interfaces';
import {
  Input,
  Modal,
  PhoneNumber,
  PrimaryButton,
  RadioGroup,
  TextArea,
} from '@shared/modules';
import { SiteAuditSelectors } from '@store/selectors';

import './styles.scss';

const { useForm } = Form;

export const SiteAccessNotesModal: FC<ModalProps> = ({
  className,
  ...props
}: PropsWithChildren<ModalProps>) => {
  const [form] = useForm();
  const siteAuditSiteInfo = useSelector(
    SiteAuditSelectors.getSiteAuditSiteInfo,
  );

  useEffect(() => form.setFieldsValue(siteAuditSiteInfo), [siteAuditSiteInfo]);

  return (
    <Modal
      title="Site Access Notes"
      okText="Edit"
      cancelText="Close"
      className={classNames(className, 'site-access-notes-modal')}
      {...props}
    >
      <Form labelCol={{ span: 9 }} form={form}>
        <div className="edit-btn-wrapper">
          <PrimaryButton
            title="Edit"
            href={`/site/${
              siteAuditSiteInfo?.PSLocationCode ||
              siteAuditSiteInfo?.MDGLocationCode
            }/details`}
            target="_blank"
          />
        </div>
        <TextArea
          id="providenceAccessNote"
          label="Providence Access Notes"
          isEditing={false}
        />
        <TextArea id="accessDetails" label="Access Details" isEditing={false} />
        <TextArea
          id="gateOrDoorCode"
          label="Gate/Door Codes"
          isEditing={false}
        />
        <RadioGroup
          id="isNoticeNeeded"
          label="Notice Needed"
          buttons={YES_NO_GROUP}
          isEditing={false}
        />
        <TextArea
          id="noticeComment"
          label="Notice Comments"
          isEditing={false}
        />
        <TextArea
          id="leaseAccessRestriction"
          label="Lease Access Restrictions"
          isEditing={false}
        />
        <Input id="primaryTech" label="Primary Tech" isEditing={false} />
        <PhoneNumber
          id="primaryTechMobile"
          label="Primary Tech Mobile"
          isEditing={false}
        />
      </Form>
    </Modal>
  );
};
