import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from '@symfa-inc/providence-verizon-types';
import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { HttpService } from '@core/services';
import { UserManagementHttpService } from '@core/services/http';
import { getFormData, isValidForm } from '@core/utils/methods';
import { emailValidator, serverValidator } from '@core/utils/validators';
import {
  USER_ROLE_OPTIONS,
  WHITESPACE_INPUT_LIMIT_RULES,
} from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { UserManagementDataRequest } from '@models/interfaces';
import { NotificationsLoader } from '@shared/components';
import { Autocomplete, Button, Input, Modal, Switch } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { UserManagementModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

export const UserModal: FC<UserManagementModalProps> = ({
  formValues,
  visible,
  modalType,
  toggleModal,
  currentOptions,
  ...props
}: PropsWithChildren<UserManagementModalProps>) => {
  const userManagementService = HttpService.getHttpRequests(
    UserManagementHttpService,
  );

  const [form] = useForm();

  const projectTypes = useSelector(DDVSelectors.getProjectTypes);

  const [switchState, setSwitchState] = useState<boolean>(false);
  const [selectValueArray, setSelectValueArray] = useState<string[]>([]);

  const hasAdminOrEMRole = [UserRole.Admin, UserRole.EngineeringManager].some(
    (role: UserRole) => selectValueArray.includes(role),
  );

  const getUserData = (): UserManagementDataRequest => ({
    ...getFormData(formValues, form, {
      isActive: switchState,
    }),
    responsibleForProjectType: !hasAdminOrEMRole
      ? undefined
      : form.getFieldsValue(['responsibleForProjectType'])
          .responsibleForProjectType,
  });

  const add = async (reqValue: UserManagementDataRequest): Promise<void> => {
    if (await isValidForm(form)) {
      await userManagementService.createUsers(reqValue, currentOptions);
      toggleModal(modalType);
    }
  };

  const edit = async (reqValue: UserManagementDataRequest): Promise<void> => {
    if (await isValidForm(form)) {
      await userManagementService.editUser(formValues.id, reqValue);
      toggleModal(modalType);
    }
  };

  const onResetButton = async (): Promise<void> => {
    try {
      await userManagementService.resetUserPassword(formValues.id);

      NotificationsLoader.notificationSuccess('Password has been changed!');
    } catch (e) {
      console.error(e);
    }
  };

  const resetForm = (): void => {
    form.setFieldsValue({
      ...formValues,
      responsibleForProjectType: formValues?.responsibleForProjectType?.id,
    });
    setSwitchState(formValues.isActive);
    setSelectValueArray(formValues.roles);
  };

  useEffect(() => {
    if (visible) {
      resetForm();
    }
  }, [visible, formValues, form]);

  return (
    <Modal
      visible={visible}
      type={modalType}
      title={`${modalType === ModalMainTypes.Add ? 'Create' : 'Edit'} User`}
      okText="Save"
      cancelText="Clear"
      className="prov-create-user-modal"
      onCancel={(): void => {
        form.resetFields();
        toggleModal(modalType);
        setSelectValueArray([]);
      }}
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getUserData())
          : (): Promise<void> => edit(getUserData())
      }
      cancelButtonProps={{
        onClick: resetForm,
      }}
      {...props}
    >
      <Form
        wrapperCol={{ span: 14 }}
        labelCol={{ span: 7 }}
        form={form}
        initialValues={formValues}
      >
        <Row>
          <Col span="24">
            <Input
              id="firstName"
              label="First Name"
              formItemProps={{
                rules: [
                  { required: true, message: 'First Name is required!' },
                  ...WHITESPACE_INPUT_LIMIT_RULES,
                ],
              }}
            />
            <Input
              id="lastName"
              label="Last Name"
              formItemProps={{
                rules: [
                  { required: true, message: 'Last Name is required!' },
                  ...WHITESPACE_INPUT_LIMIT_RULES,
                ],
              }}
            />
            <Input
              id="email"
              label="Email"
              formItemProps={{
                rules: [
                  ...emailValidator(true),
                  serverValidator(
                    {
                      method: 'getUserValidation',
                      errorMessage: 'Email address should be Unique!',
                    },
                    UserManagementHttpService,
                    modalType === ModalMainTypes.Edit
                      ? formValues.id
                      : undefined,
                  ),
                  ...WHITESPACE_INPUT_LIMIT_RULES,
                ],
              }}
            />
            {modalType === ModalMainTypes.Edit && (
              <Button
                id="password"
                label="Password"
                elementProps={{
                  title: 'Send Temp Password',
                  onClick: onResetButton,
                }}
              />
            )}
            <Autocomplete
              id="roles"
              label="Roles"
              options={USER_ROLE_OPTIONS}
              elementProps={{
                mode: 'multiple',
                onChange: (value: SelectValue): void => {
                  setSelectValueArray(value as typeof selectValueArray);
                },
              }}
              formItemProps={{
                rules: [{ required: true, message: 'Roles is required!' }],
              }}
            />

            {hasAdminOrEMRole && (
              <Autocomplete
                id="responsibleForProjectType"
                label="Responsible For Project Type"
                options={projectTypes}
                formItemProps={{
                  rules: [
                    {
                      required: true,
                      message: 'Responsible For Project Type is required!',
                    },
                  ],
                }}
              />
            )}
            <Switch
              id="isActive"
              label="Status"
              switchState={switchState}
              elementProps={{
                title: switchState ? 'Active' : 'Inactive',
                checked: switchState,
                onChange: (value: boolean): void => {
                  setSwitchState(value);
                },
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
