import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { ApiMethods } from '@models/enums';
import {
  PaginatedResponse,
  RangePickerValues,
  ScopingBrowseResponse,
  ScopingSummaryData,
  UpdateScopingEquipmentData,
} from '@models/interfaces';
import {
  MainScopingBrowseFilter,
  ProjectDetailsWithoutFuzeIDs,
} from '@models/types';
import {
  BreadcrumbsActions,
  ProjectActions,
  ScopingActions,
} from '@store/actions';
import { ProjectFuzeIDsEffects, ProjectsEffects } from '@store/effects';
import store from '@store/index';
import { CommonHttpService } from './common-http.service';

export class ScopingHttpService extends CommonHttpService {
  @StoredRequest(ScopingActions.getScopingBrowseDataAction)
  getScopingBrowse(
    params: MainScopingBrowseFilter,
  ): Promise<PaginatedResponse<ScopingBrowseResponse[]>> {
    return this.http.callApi.get(`${this.apiUrl}/engineering/scoping`, null, {
      params,
    });
  }

  exportScheduledScoping(date: RangePickerValues): Promise<void> {
    return this.downloadFile(
      ApiMethods.Get,
      `${this.apiUrl}/engineering/scoping/report`,
      { params: { date } },
    );
  }

  @StoredRequest(ScopingActions.updateScopingBrowseDataAction)
  updateScopingBrowse(
    params: MainScopingBrowseFilter,
    body: { date: string },
  ): Promise<PaginatedResponse<ScopingBrowseResponse[]>> {
    return this.http.callApi.patch(`${this.apiUrl}/engineering/scoping`, body, {
      params,
    });
  }

  @StoredRequest(
    ScopingActions.getScopingDetailsAction,
    BreadcrumbsActions.Engineering.scoping,
  )
  async getScoping(
    projectBundleID: string,
  ): Promise<Omit<ProjectDetailsWithoutFuzeIDs, 'RAD'>> {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/engineering/scoping/${projectBundleID}`,
    );

    store.dispatch(
      ProjectActions.updateProjectPaceTaskFields.done(response.paceTasks),
    );

    return ProjectsEffects.setHoldOptions(
      ProjectsEffects.setCurrentProvidenceAgent(
        ProjectsEffects.setRAD(
          ProjectFuzeIDsEffects.setProjectFuzeIDs(response),
        ),
      ),
    );
  }

  @StoredRequest(ScopingActions.updateScopingSummaryAction)
  async updateScopingSummary(
    id: string,
    body: ScopingSummaryData,
  ): Promise<ScopingSummaryData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/engineering/scoping/summary/${id}`,
      body,
    );

    return body;
  }

  @StoredRequest(ScopingActions.updateScopingEquipmentAction)
  async updateScopingEquipment(
    id: string,
    body: UpdateScopingEquipmentData,
  ): Promise<UpdateScopingEquipmentData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/engineering/scoping/equipment/${id}`,
      body,
    );

    return body;
  }
}
