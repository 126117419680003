import React, { ReactNode } from 'react';
import { MAIN_PACE_TASK_IDs } from '@symfa-inc/providence-verizon-types';
import { Popover } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import {
  getJurisdictionByIdOrUndefined,
  projectFuzeIDRender,
  projectPaceTaskDataRender,
  providenceAgentRender,
  readOnlyInputNumberRender,
} from '@core/utils/methods';
import { IdAndValue, ProjectBrowse, SiteBrowse } from '@models/interfaces';
import { Browse } from '@models/types';
import { RiskPopup } from '@shared/components';
import { ProjectStatus } from '@shared/modules';
import { SiteRisk } from '@shared/modules/browse-page/models/enums';

export const PROJECT_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    dataIndex: 'site',
    key: 'risk',
    width: 45,
    render: (site: ProjectBrowse): ReactNode =>
      site?.isHighRisk && (
        <Popover
          content={
            <RiskPopup
              risk={site?.isHighRisk && SiteRisk.High}
              riskCategory={site?.riskCategory?.value}
            />
          }
          placement="bottomLeft"
        >
          <i className="circle circle_high" />
        </Popover>
      ),
  },
  {
    title: 'RE Specialist',
    key: 'RESpecialist',
    width: 130,
    dataIndex: 'RESpecialist',
    sorter: true,
    render: (RESpecialist: IdAndValue): ReactNode => RESpecialist?.value,
  },
  {
    title: 'Alternate Site Info',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.alternateSiteInfo,
  },
  {
    title: 'CI032 OnAir Actual',
    key: 'CI032OnAirActual',
    dataIndex: 'projectPaceTaskData',
    width: 210,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.onAir,
      field: 'actualDate',
    }),
  },
  {
    title: 'CI032 OnAir Forecast',
    key: 'CI032OnAirForecast',
    dataIndex: 'projectPaceTaskData',
    width: 210,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.onAir,
      field: 'forecastedDate',
    }),
  },
  {
    title: 'Civil Vendor',
    key: 'civilVendor',
    width: 165,
    dataIndex: 'civilVendor',
    sorter: true,
    render: (civilVendor: IdAndValue): ReactNode => civilVendor?.value,
  },
  {
    title: 'PS Location Code',
    dataIndex: 'site',
    width: 195,
    key: 'PSLocationCode',
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.PSLocationCode),
  },
  {
    title: 'Jurisdiction',
    key: 'jurisdiction',
    width: 215,
    dataIndex: 'site',
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      getJurisdictionByIdOrUndefined(site?.jurisdiction?.id)?.jurisdictionName,
  },
  {
    title: 'Contract Number',
    width: 150,
    key: 'contractNumber',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.contractNumber,
  },
  {
    title: 'PS Project Number',
    dataIndex: 'fuzeIDs',
    key: 'PSProjectNumber',
    width: 215,
    render: projectFuzeIDRender({
      field: 'PSProjectNumber',
      className: 'prov-project-browse__items prov-project-browse__items_col',
    }),
  },
  {
    title: 'Fuze ID',
    dataIndex: 'fuzeIDs',
    key: 'fuzeId',
    width: 215,
    render: projectFuzeIDRender({
      field: 'fuzeID',
      className: 'prov-project-browse__items prov-project-browse__items_col',
    }),
  },
  {
    title: 'Project Status',
    dataIndex: 'projectStatus',
    key: 'projectStatus',
    width: 150,
    sorter: true,
    render: (status: IdAndValue): ReactNode => (
      <ProjectStatus status={status?.value} />
    ),
  },
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    width: 180,
    sorter: true,
    dataIndex: 'providenceAgent',
    render: providenceAgentRender,
  },
  {
    title: 'RE020 Site Acq Complete Actual',
    key: 'RE020SiteAcqCompleteActual',
    dataIndex: 'projectPaceTaskData',
    width: 280,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.siteAcqComplete,
      field: 'actualDate',
    }),
  },
  {
    title: 'Site Name',
    dataIndex: 'site',
    width: 140,
    key: 'siteName',
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.siteName),
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.submarket?.value,
  },
  {
    title: 'Tower Owner',
    key: 'towerOwner',
    width: 145,
    dataIndex: 'site',
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.towerOwner?.value,
  },
  {
    title: 'Tower Owner Site ID',
    key: 'towerOwnerSiteID',
    width: 195,
    dataIndex: 'site',
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.towerOwnerSiteID,
  },
  {
    title: 'MDG Location Code',
    key: 'MDGLocationCode',
    dataIndex: 'site',
    width: 180,
    render: (site: SiteBrowse): ReactNode => site?.MDGLocationCode,
  },
];
