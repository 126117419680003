import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { PasswordValidationField } from '@symfa-inc/providence-verizon-types';
import { Form } from 'antd';
import { RuleObject } from 'rc-field-form/es/interface';
import { HttpService } from '@core/services';
import { UserHttpService } from '@core/services/http';
import { isValidForm } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { WHITESPACE_INPUT_LIMIT_RULES } from '@models/constants';
import { ModalProps } from '@models/interfaces';
import { ChangeUserPasswordData } from '@models/types';
import { NotificationsLoader } from '@shared/components';
import { Input, Modal } from '@shared/modules';
import { UserSelectors } from '@store/selectors';
import { PASSWORD_PATTERN } from './models/validators';

import './styles.scss';

const { useForm } = Form;

export const ChangePassword: FC<ModalProps> = ({
  onCancel,
  ...props
}: PropsWithChildren<ModalProps>) => {
  const [form] = useForm();

  const user = useSelector(UserSelectors.activeUser);

  const onCancelButton = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    if (onCancel) {
      onCancel(e);
    }

    form.resetFields();
  };

  const onSave = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): Promise<void> => {
    if (await isValidForm(form)) {
      try {
        await HttpService.getHttpRequests(UserHttpService).changeUserPassword(
          form.getFieldsValue() as ChangeUserPasswordData,
        );

        NotificationsLoader.notificationSuccess('Password has been changed!');

        onCancelButton(e);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Modal
      title="Change password"
      okText="Save"
      onOk={onSave}
      onCancel={onCancelButton}
      {...props}
    >
      <Form form={form} wrapperCol={{ span: 10 }} labelCol={{ span: 10 }}>
        {user?.isTempPassword && (
          <div className="prov-change-password-modal-notification">
            <p>You have temp password, please change it.</p>
          </div>
        )}
        <Input
          id="oldPassword"
          key="oldPassword"
          label="Old password"
          formItemProps={{
            rules: [
              {
                required: true,
                message: 'Please input your old password!',
              },
              serverValidator(
                {
                  method: 'getUserPasswordValidation',
                  errorMessage: 'This value does not match the old password!',
                },
                UserHttpService,
                PasswordValidationField.OldPassword,
              ),
              ...WHITESPACE_INPUT_LIMIT_RULES,
            ],
          }}
        />
        <Input
          id="newPassword"
          key="newPassword"
          label="New password"
          formItemProps={{
            rules: [
              {
                required: true,
                message: 'Please input your password!',
              },
              serverValidator(
                {
                  method: 'getUserPasswordValidation',
                  errorMessage:
                    'The new password should not match the old one!',
                },
                UserHttpService,
                PasswordValidationField.NewPassword,
              ),
              PASSWORD_PATTERN,
              ...WHITESPACE_INPUT_LIMIT_RULES,
            ],
          }}
        />
        <Input
          id="confirmNewPassword"
          key="confirmNewPassword"
          label="Confirm new password"
          formItemProps={{
            dependencies: ['newPassword'],
            rules: [
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {
                validator(rule: RuleObject, value: string): Promise<void> {
                  if (!value || form.getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                },
              },
            ],
          }}
        />
      </Form>
    </Modal>
  );
};
