import { MAIN_PACE_TASK_IDs } from '@symfa-inc/providence-verizon-types';
import { OptionProps } from '../interfaces/option-props';

export const ALLOW_OOM_INFORMATION_PACE_TASK_OPTIONS: OptionProps[] = [
  {
    value: MAIN_PACE_TASK_IDs.leasingComplete,
    viewValue: 'RE015 - Leasing Complete',
  },
  {
    value: MAIN_PACE_TASK_IDs.siteAcqComplete,
    viewValue: 'RE020 - Site Acq Complete',
  },
];
