import {
  PasswordValidationField,
  ProjectType,
} from '@symfa-inc/providence-verizon-types';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  ColumnSettings,
  ColumnSettingsModules,
  ColumnSettingsResponse,
  User,
} from '@models/interfaces';
import { ChangeUserPasswordData } from '@models/types';
import { UserActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class UserHttpService extends CommonHttpService {
  @StoredRequest(UserActions.getActiveUserAction)
  getSelf(): Promise<User> {
    return this.http.callApi.get(`${this.apiUrl}/users/self`);
  }

  @StoredRequest(UserActions.changeColumnsSettings)
  async changeColumnSettings(
    modules: ColumnSettingsModules,
    columns: ColumnSettings[],
  ): Promise<ColumnSettingsResponse> {
    await this.http.callApi.patch(
      `${this.apiUrl}/users/change-columns-settings`,
      {
        columns: columns.filter((column: ColumnSettings) => !column.configured),
      },
      {
        params: modules,
      },
    );

    return {
      ...modules,
      columns,
    };
  }

  @StoredRequest(UserActions.changePMProjectType)
  async changeDefaultPMProjectType(
    projectType: ProjectType,
  ): Promise<ProjectType> {
    await this.http.callApi.patch(
      `${this.apiUrl}/users/change-default-pm-project-type`,
      { projectType },
    );

    return projectType;
  }

  //  USER PASSWORD
  getUserPasswordValidation(
    field: PasswordValidationField,
    password: string,
  ): Promise<boolean> {
    return this.http.callApi.post(
      `${this.apiUrl}/users/validation`,
      { password },
      {
        params: { field },
      },
    );
  }

  @StoredRequest(UserActions.changeUserPasswordAction)
  changeUserPassword({
    oldPassword,
    newPassword,
  }: ChangeUserPasswordData): Promise<boolean> {
    return this.http.callApi.patch(`${this.apiUrl}/users/change-password`, {
      oldPassword,
      newPassword,
    });
  }
}
