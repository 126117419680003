import React, { ReactNode } from 'react';
import { actionButtons } from '@models/constants';
import { EquipmentManufacturerData, EquipmentType } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { VirtualTableColumn } from '@shared/components';

export const equipmentManufacturerColumns = (
  editFunc: ColumnsFunc<EquipmentManufacturerData>,
  deleteFunc: ColumnsFunc<EquipmentManufacturerData>,
  selectorNotEmpty: boolean,
): Array<VirtualTableColumn> => [
  {
    title: 'Manufacturer',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Equipment Types',
    dataIndex: 'equipmentTypes',
    key: 'equipmentTypes',
    render: (
      equipmentTypes: EquipmentType[],
      manufacturerData: EquipmentManufacturerData,
    ): ReactNode => (
      <div className="project-fuze-id-cell">
        <span key={manufacturerData.id}>
          {[...equipmentTypes]
            .map(({ name }: EquipmentType) => name)
            .join(', ')}
        </span>
      </div>
    ),
  },
  actionButtons<EquipmentManufacturerData>({
    editFunc,
    deleteFunc,
    isEditing: selectorNotEmpty,
  }) as VirtualTableColumn,
];
