import { DetailsResolver } from '@core/resolvers';
import { HttpService } from '@core/services';
import {
  EquipmentHttpService,
  LeasingHttpService,
  PermittingHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';
import store from '@store/index';
import { LeasingState } from '@store/reducers/leasing.reducer';

export class LeasingDetailsResolver extends DetailsResolver {
  private static get _state(): LeasingState {
    return store.getState()?.leasing;
  }

  async resolve(): Promise<void> {
    await super.resolve();

    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await HttpService.getHttpRequests(LeasingHttpService).getLeasing(
      projectBundleID,
    );
    await HttpService.getHttpRequests(EquipmentHttpService).getEquipmentTypes();

    await HttpService.getHttpRequests(
      PermittingHttpService,
    ).getPermittingForLeasisingJurisdictionAuditTab(projectBundleID);

    if (LeasingDetailsResolver._state?.equipmentTypeNames?.length) {
      await HttpService.getHttpRequests(
        EquipmentHttpService,
      ).getEquipmentsByTypes(LeasingDetailsResolver._state?.equipmentTypeNames);
    }
  }
}
