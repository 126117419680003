import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DropDownSelectorValues } from '@symfa-inc/providence-verizon-types';
import { useForm } from 'antd/lib/form/Form';
import { HttpService } from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { getFormData, isValidForm } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { JurisdictionDetailsPermittingType } from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { Autocomplete, Input } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { PermitTypeTableModalProps } from '../models';

export const PermitTypeTableModal: FC<PermitTypeTableModalProps> = ({
  visibleModal,
  toggleModal,
  formValue,
  permittingId,
  reqType,
}: PropsWithChildren<PermitTypeTableModalProps>) => {
  const [form] = useForm();

  const permittingService = useMemo(
    () => HttpService.getHttpRequests(PermittingHttpService),
    [],
  );

  const permitTypeDDV = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByType(DropDownSelectorValues.PermitType),
  );

  useEffect(() => {
    if (visibleModal) {
      form.setFieldsValue(formValue);
    }
  }, [visibleModal, formValue, form]);

  const addPermittingType = async (
    data: JurisdictionDetailsPermittingType,
  ): Promise<void> => {
    if (await isValidForm(form)) {
      await permittingService[
        reqType === 'permitting'
          ? 'permittingAddPermittingType'
          : 'jurisdictionAddPermittingType'
      ]({
        permittingId,
        data,
      });
      toggleModal();
    }
  };

  const editPermitType = async (
    data: JurisdictionDetailsPermittingType,
  ): Promise<void> => {
    if (await isValidForm(form)) {
      await permittingService[
        reqType === 'permitting'
          ? 'permittingUpdatePermittingType'
          : 'jurisdictionUpdatePermittingType'
      ]({ permittingId, data });
      toggleModal();
    }
  };

  return (
    <AddOrEditModal
      className="permit-type-table__modal"
      visible={visibleModal}
      type={formValue.id ? ModalMainTypes.Edit : ModalMainTypes.Add}
      cancelText="Cancel"
      onOk={
        formValue.id
          ? (): Promise<void> => editPermitType(getFormData(formValue, form))
          : (): Promise<void> => addPermittingType(getFormData(formValue, form))
      }
      onCancel={toggleModal}
      title={formValue.id ? 'Permit Type' : 'New Permit Type'}
      formProps={{
        labelCol: { span: 12 },
        wrapperCol: { span: 14 },
        form,
      }}
    >
      <Autocomplete
        id="permitType"
        label="Permit Type"
        options={permitTypeDDV}
        formItemProps={{
          rules: [
            {
              required: true,
              message: 'Permit Type is required!',
            },
          ],
        }}
      />
      <Input
        id="expectedPermitLeadTime"
        label="Expected Permit Lead Time"
        formItemProps={{
          rules: [
            {
              required: true,
              message: 'Expected Permit Lead Time is required!',
            },
          ],
        }}
      />
      <Input
        id="permitAndApplicationFee"
        label="Permit and Application Fees"
        formItemProps={{
          rules: [
            {
              required: true,
              message: 'Permit and Application Fees is required!',
            },
          ],
        }}
      />
      <Input
        id="requiredInfoForObtainingPermit"
        label="Required Info for Obtaining Permit"
        formItemProps={{
          rules: [
            {
              required: true,
              message: 'Required Info for Obtaining Permit is required!',
            },
          ],
        }}
      />
    </AddOrEditModal>
  );
};
