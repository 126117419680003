import {
  MAIN_PACE_TASK_IDs,
  PaceTaskType,
} from '@symfa-inc/providence-verizon-types';
import { ProjectsEffects } from '@store/effects';
import { PermittingSection, ZoningSection } from './../models';

export const updatePermittingPaceTaskFields = (
  permittingSection: PermittingSection,
  zoningSection: ZoningSection,
): void => {
  ProjectsEffects.updatePaceTaskFieldsByConfig(permittingSection, [
    {
      id: MAIN_PACE_TASK_IDs.jurisdictionAuditComplete,
      dateType: PaceTaskType.ActualDate,
      field: 'jurisdictionAuditComplete',
    },
    {
      id: MAIN_PACE_TASK_IDs.E911AddressValidation,
      dateType: PaceTaskType.ActualDate,
      field: 'e911AddressValidation',
    },
    {
      id: MAIN_PACE_TASK_IDs.prelimCDsReviewedPermitting,
      dateType: PaceTaskType.ActualDate,
      field: 'prelimCDsReviewedPermitting',
    },
  ]);

  ProjectsEffects.updatePaceTaskFieldsByConfig(zoningSection, [
    {
      id: MAIN_PACE_TASK_IDs.zoningSubmitted,
      dateType: PaceTaskType.ActualDate,
      field: 'zoningSubmitted',
    },
    {
      id: MAIN_PACE_TASK_IDs.zoningAppDeemedComplete,
      dateType: PaceTaskType.ActualDate,
      field: 'zoningAppDeemedComplete',
    },
    {
      id: MAIN_PACE_TASK_IDs.zoningApproved,
      dateType: PaceTaskType.ActualDate,
      field: 'zoningApproved',
    },
    {
      id: MAIN_PACE_TASK_IDs.BPSubmitted,
      dateType: PaceTaskType.ActualDate,
      field: 'BPSubmitted',
    },
    {
      id: MAIN_PACE_TASK_IDs.BPReceived,
      dateType: PaceTaskType.ActualDate,
      field: 'BPReceived',
    },
  ]);
};
