import React, { FC, PropsWithChildren } from 'react';
import { FilterDropdownOperator } from '@symfa-inc/providence-verizon-types';
import { arraySort, getCurrentOptions } from '@core/utils/methods';
import { Autocomplete } from '@shared/modules';
import { FormElementSelect } from '@shared/modules/ui-kit/models/interfaces';
import { ItemWithOperator } from '../item-with-operator';
import { ComponentWithOperatorProps, getFilterOperator } from '../models';

type AutocompleteWithOperatorProps =
  ComponentWithOperatorProps<FormElementSelect>;

export const AutocompleteWithOperator: FC<AutocompleteWithOperatorProps> = (
  props: PropsWithChildren<AutocompleteWithOperatorProps>,
) => (
  <ItemWithOperator
    component={Autocomplete}
    className="prov-autocomplete-with-operator"
    selectProps={{
      options: arraySort(
        getCurrentOptions(
          FilterDropdownOperator,
          getFilterOperator,
          false,
          true,
        ),
        'ASC',
        'viewValue',
      ),
      label: '',
    }}
    {...props}
  />
);
