import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { ProcessEnvMap } from '@models/interfaces';

export const replacePath = (
  path: string,
  replace: Record<string, Undefinable<string | number>> = {},
): string =>
  Object.keys(replace).reduce(
    (replacement: string, key: string): string =>
      replacement.replace(
        `:${key}`,
        replace[key] !== undefined ? `${replace[key]}` : `:${key}`,
      ),
    path,
  );

export const replaceLocationPathName = (
  path: string,
  replace: ProcessEnvMap = {},
): string =>
  Object.keys(replace).reduce(
    (replacement: string, key: string): string =>
      replacement.replace(replace[key], `:${key}`),
    path,
  );
