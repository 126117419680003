import { ReactNode } from 'react';
import { Nullable } from '@symfa-inc/providence-verizon-types';
import { ColumnsType } from 'antd/lib/table/interface';
import { leasingProjectFuzeIDRender } from '@core/utils/methods';
import { LeasingBrowseResponseItem, SiteBrowse } from '@models/interfaces';
import { Browse } from '@models/types';

export const LEASING_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'Alternate Site Info',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
    dataIndex: 'site',
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.alternateSiteInfo,
  },
  {
    title: 'City',
    key: 'city',
    dataIndex: 'site',
    width: 160,
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode => site?.city,
  },
  {
    title: 'County',
    key: 'county',
    dataIndex: 'site',
    width: 140,
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.countyName,
  },
  {
    title: 'PS Location Code',
    dataIndex: 'site',
    key: 'PSLocationCode',
    width: 140,
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.PSLocationCode,
  },
  {
    title: 'Jurisdiction',
    key: 'jurisdiction',
    width: 180,
    dataIndex: 'site',
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.jurisdictionName,
  },
  {
    title: 'Contract Number',
    width: 150,
    key: 'contractNumber',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.contractNumber,
  },
  {
    title: 'PS Project Number',
    dataIndex: 'fuzeIDs',
    key: 'PSProjectNumber',
    width: 150,
    sorter: true,
    render: leasingProjectFuzeIDRender({ field: 'PSProjectNumber' }),
  },
  {
    title: 'Fuze ID',
    dataIndex: 'fuzeIDs',
    key: 'fuzeId',
    width: 150,
    sorter: true,
    render: leasingProjectFuzeIDRender({ field: 'fuzeID' }),
  },
  {
    title: 'Project Type',
    key: 'projectType',
    width: 140,
    dataIndex: 'projectTypeValue',
    sorter: true,
    render: (projectTypeValue: string): ReactNode => projectTypeValue,
  },
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    width: 180,
    sorter: true,
    dataIndex: 'providenceAgentName',
    render: (providenceAgentName: Nullable<string>): ReactNode =>
      providenceAgentName,
  },
  {
    title: 'Site Name',
    dataIndex: 'siteName',
    key: 'siteName',
    width: 100,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.siteName,
  },
  {
    title: 'State',
    dataIndex: 'site',
    width: 100,
    key: 'state',
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.stateName,
  },
  {
    title: 'Street Address',
    key: 'streetAddress',
    dataIndex: 'site',
    width: 170,
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.streetAddress,
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    sorter: true,
    dataIndex: 'site',
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.submarketValue,
  },

  {
    title: 'Tower Owner',
    dataIndex: 'site',
    width: 155,
    key: 'towerOwner',
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.towerOwnerValue,
  },
  {
    title: 'Zip',
    key: 'zip',
    dataIndex: 'site',
    width: 100,
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode => site?.zip,
  },
  {
    title: 'MDG Location Code',
    key: 'MDGLocationCode',
    dataIndex: 'site',
    width: 180,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.MDGLocationCode,
  },
];
