import { arraySort, transformFields } from '../../core/utils/methods';
import { createReducer, on } from '../../core/utils/store';
import { SITE_INFO_TRANSFORM_FIELDS } from '../../models/constants/transform-fields';
import {
  AssociatedProject,
  ContactsData,
  CreateSiteResp,
  OptionProps,
  PaginatedResponse,
  PayloadAndState,
  SiteBrowse,
  SiteResponse,
} from '../../models/interfaces';
import { SiteActions } from '../actions/site.actions';

export interface SiteState {
  associatedProjects: AssociatedProject[];
  siteContacts: ContactsData[];
  siteBrowse: PaginatedResponse<SiteBrowse>;
  PSLocationCodeOptions: OptionProps[];
  MDGLocationCodeOptions: OptionProps[];
  currentSite: SiteBrowse;
  createdSiteUniqueFields: CreateSiteResp | null;
  isFetching: boolean;
  errors: boolean;
}

const initialState: SiteState = {
  associatedProjects: [],
  siteContacts: [],
  siteBrowse: {
    items: [],
    total: 0,
  },
  currentSite: {} as SiteBrowse,
  createdSiteUniqueFields: null,
  PSLocationCodeOptions: [],
  MDGLocationCodeOptions: [],
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  on(
    SiteActions.getSiteBrowseDataAction,
    ({
      payload: siteBrowse,
    }: PayloadAndState<PaginatedResponse<SiteBrowse>, SiteState>) => ({
      siteBrowse,
    }),
  ),
  on(
    SiteActions.getAssociatedProjectsDataAction,
    ({
      payload: associatedProjects,
    }: PayloadAndState<AssociatedProject[], SiteState>) => ({
      associatedProjects,
    }),
  ),
  on(
    SiteActions.getSiteAction,
    ({
      payload: { projects, contacts, ...site },
    }: PayloadAndState<SiteResponse, SiteState>) => ({
      currentSite: transformFields<SiteBrowse>(
        site,
        SITE_INFO_TRANSFORM_FIELDS,
      ),
      siteContacts: contacts,
      associatedProjects: projects,
      createdSite: {} as SiteBrowse,
    }),
  ),
  on(
    SiteActions.createSiteContactAction,
    ({
      payload,
      state: { siteContacts },
    }: PayloadAndState<ContactsData, SiteState>) => ({
      siteContacts: [...siteContacts, payload],
    }),
  ),
  on(
    SiteActions.createSiteAction,
    ({
      payload: createdSiteUniqueFields,
    }: PayloadAndState<CreateSiteResp, SiteState>) => ({
      createdSiteUniqueFields,
    }),
  ),
  on(
    SiteActions.updateSiteAction,
    ({
      payload,
      state: { currentSite },
    }: PayloadAndState<SiteBrowse, SiteState>) => ({
      currentSite: {
        ...currentSite,
        ...payload,
      },
    }),
  ),
  on(SiteActions.clearCreatedSite, (_: PayloadAndState<void, SiteState>) => ({
    createdSiteUniqueFields: null,
  })),
  on(
    SiteActions.updateSiteContactAction,
    ({
      payload,
      state: { siteContacts },
    }: PayloadAndState<ContactsData, SiteState>) => ({
      siteContacts: arraySort(
        [
          ...siteContacts.filter(
            (contact: ContactsData) => contact.id !== payload.id,
          ),
          payload,
        ],
        'ASC',
        'contactName',
      ),
    }),
  ),
  on(
    SiteActions.deleteSiteContactAction,
    ({
      payload: id,
      state: { siteContacts },
    }: PayloadAndState<string, SiteState>) => ({
      siteContacts: siteContacts.filter(
        (contact: ContactsData) => contact.id !== id,
      ),
    }),
  ),
  on(
    SiteActions.getPSLocationCodeOptionsList,
    ({ payload }: PayloadAndState<OptionProps[], SiteState>) => ({
      PSLocationCodeOptions: payload ?? [],
    }),
  ),
  on(
    SiteActions.getMDGLocationCodeOptionsList,
    ({ payload }: PayloadAndState<OptionProps[], SiteState>) => ({
      MDGLocationCodeOptions: payload ?? [],
    }),
  ),
);
