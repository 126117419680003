import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { SiteHttpService } from '../services/http';

export class DetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    const siteHttpService = HttpService.getHttpRequests(SiteHttpService);

    await Promise.all([
      siteHttpService.getPSLocationCodeOptions(),
      siteHttpService.getMDGLocationCodeOptions(),
    ]);
  }
}
