import { actionCreatorFactory } from '../../core/utils/factories';
import {
  AssociatedProject,
  ContactsData,
  CreateSiteResp,
  OptionProps,
  PaginatedResponse,
  SiteBrowse,
  SiteResponse,
} from '../../models/interfaces';

const actionCreator = actionCreatorFactory('SITE');

export namespace SiteActions {
  export const getSiteBrowseDataAction =
    actionCreator.createAsyncAction<PaginatedResponse<SiteBrowse>>(
      'GET_BROWSE_DATA',
    );
  export const getAssociatedProjectsDataAction =
    actionCreator.createAsyncAction<AssociatedProject[]>(
      'GET_ASSOCIATED_PROJECTS_DATA',
    );
  export const createSiteAction =
    actionCreator.createAsyncAction<CreateSiteResp>('CREATE_SITE');
  export const updateSiteAction =
    actionCreator.createAsyncAction<SiteBrowse>('UPDATE_SITE');
  export const getSiteAction =
    actionCreator.createAsyncAction<SiteResponse>('GET_SITE');
  export const createSiteContactAction =
    actionCreator.createAsyncAction<ContactsData>('CREATE_SITE_CONTACT');
  export const updateSiteContactAction =
    actionCreator.createAsyncAction<ContactsData>('UPDATE_SITE_CONTACT');
  export const deleteSiteContactAction =
    actionCreator.createAsyncAction<string>('DELETE_SITE_CONTACT');
  export const clearCreatedSite =
    actionCreator.createAsyncAction<void>('CLEAR_CURRENT_SITE');
  export const getPSLocationCodeOptionsList = actionCreator.createAsyncAction<
    OptionProps[]
  >('GET_PS_LOCATION_NUMBER_OPTIONS');
  export const getMDGLocationCodeOptionsList = actionCreator.createAsyncAction<
    OptionProps[]
  >('GET_MDG_LOCATION_NUMBER_OPTIONS');
}
