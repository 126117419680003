import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { ERROR_MASK } from '@models/constants/error-mask';
import { ActionRes } from '@models/interfaces';
import {
  ActionCreator,
  Done,
  ReducerConfig,
  ReducerMasks,
} from './models/interfaces';
import { DefaultReducer, DefaultReducerValue } from './models/types';

export const defaultReducer = <S, P>(
  { type }: ActionCreator<P>,
  state: S,
  masks: Partial<ReducerMasks<S>>,
  actionResult: any,
): DefaultReducer<S, P> => {
  const { fetchingMask = 'isFetching', errorMask = '' } = masks || {};

  return {
    [`${type}_START`]: (): DefaultReducerValue<S, P> => ({
      ...state,
      [fetchingMask]: true,
      [errorMask]: false,
    }),
    [`${type}_DONE`]: (): DefaultReducerValue<S, P> => ({
      ...state,
      ...actionResult(),
      [fetchingMask]: false,
      [errorMask]: false,
    }),
    [`${type}_FAIL`]: (): DefaultReducerValue<S, P> => ({
      ...state,
      [errorMask]: true,
      [fetchingMask]: false,
    }),
  };
};

export const createReducer =
  <S>(initialState: S, ...configs: ReducerConfig<any, S>[]) =>
  (incomingState: Undefinable<S>, { payload, type }: ActionRes): S => {
    const state = incomingState ?? initialState;
    const states = configs.reduce(
      (
        acc: DefaultReducer<S, any>,
        { action: actionCreator, masks = ERROR_MASK, done }: ReducerConfig,
      ) => ({
        ...acc,
        ...defaultReducer(actionCreator, state, masks, () =>
          done({ payload, state }),
        ),
      }),
      {},
    );

    return states[type] ? states[type]() : state;
  };

export const on = <P = any, S = any>(
  action: ActionCreator<P>,
  done: Done<P, S>,
  masks: Partial<ReducerMasks<S>> = ERROR_MASK,
): ReducerConfig<P, S> => ({
  action,
  done,
  masks,
});
