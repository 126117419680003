import { ExportReportDateType } from '@symfa-inc/providence-verizon-types';
import { OptionProps } from '@models/interfaces';

export const REPORT_DATE_TYPE_OPTIONS: OptionProps[] = [
  {
    value: ExportReportDateType.ActualDate,
    viewValue: 'Actual Date',
  },
  {
    value: ExportReportDateType.PACEForecastedDate,
    viewValue: 'PACE Forecasted Date',
  },
  { value: ExportReportDateType.Both, viewValue: 'Both Dates' },
];
