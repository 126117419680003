import React, {
  FC,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  ProjectStatus,
  Undefinable,
  UserRole,
} from '@symfa-inc/providence-verizon-types';
import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/es/select';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { normalize } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import {
  INPUT_POSITIVE_NUMBER_VALIDATOR,
  YES_NO_GROUP,
} from '@models/constants';
import { OptionProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  Input,
  InputNumber,
  ProjectStatus as ProjectStatusComponent,
  RadioGroup,
  ShowMoreInput,
  TextArea,
} from '@shared/modules';
import {
  DDVSelectors,
  ProjectSelectors,
  SiteSelectors,
  UserSelectors,
} from '@store/selectors';
import { ProjectInformationProps } from './models';

export const ProjectInformation: FC<ProjectInformationProps> = ({
  form,
  isEditing,
  isNew = false,
  colSpan = 12,
  className = '',
  collapsible = false,
  permissions: {
    buildYearPlan,
    PSLocationCode,
    MDGLocationCode,
    projectBundleID,
    projectType,
    projectStatus,
    progressStatus,
    RESpecialist,
    civilVendor,
    environmentalVendor,
    projectSubtype,
    projectNote: projectNotePermission,
    providenceAgent,
    RFEngineer,
    projectName,
    POR,
    gatingItems,
    holdReason: holdReasonPermission,
    constructionEngineer,
  },
  data: { projectNote, ...data },
  onChange,
}: PropsWithChildren<ProjectInformationProps>) => {
  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);
  const PSLocationCodeOptions = useSelector(
    SiteSelectors.getPSLocationCodeOptions,
  );
  const MDGLocationCodeOptions = useSelector(
    SiteSelectors.getMDGLocationCodeOptions,
  );
  const providenceAgents = useSelector(ProjectSelectors.getProvidenceAgents);
  const currentProjectType = useSelector(ProjectSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(currentProjectType),
  );
  const isAdmin = useSelector(UserSelectors.getUserRoles)?.includes(
    UserRole.Admin,
  );

  const [currentSite, setCurrentSite] = useState<Undefinable<string>>(
    data.siteId,
  );
  const getCodeValuesFromOptions = () => {
    const PSLocationCodeValue =
      PSLocationCodeOptions.find(
        (option: OptionProps) => option.value === currentSite,
      )?.value ?? null;
    const MDGLocationCodeValue =
      MDGLocationCodeOptions.find(
        (option: OptionProps) => option.value === currentSite,
      )?.value ?? null;

    return [PSLocationCodeValue, MDGLocationCodeValue];
  };

  useEffect(() => {
    const [PSLocationCodeValue, MDGLocationCodeValue] =
      getCodeValuesFromOptions();

    form?.setFieldsValue({
      ...data,
      PSLocationCode: PSLocationCodeValue,
      MDGLocationCode: MDGLocationCodeValue,
    });
  }, [isEditing]);

  useDidUpdateEffect(() => {
    if (currentSite && isNew) {
      const [PSLocationCodeValue, MDGLocationCodeValue] =
        getCodeValuesFromOptions();

      form?.setFieldsValue({
        PSLocationCode: PSLocationCodeValue,
        MDGLocationCode: MDGLocationCodeValue,
      });
      HttpService.getHttpRequests(ProjectHttpService).getProvidenceAgent(
        currentSite,
      );
    }
  }, [currentSite]);

  return (
    <Form
      labelCol={{ span: colSpan === 12 ? 7 : 10 }}
      wrapperCol={{ span: 14 }}
      className={className}
      form={form}
      onValuesChange={onChange}
    >
      <Panel header="Project Information" collapsible={collapsible}>
        <Row>
          <Col span={colSpan}>
            <Autocomplete
              id="MDGLocationCode"
              label="MDG Location Code"
              isEditing={
                isEditing && projectTypePermission && !!MDGLocationCode
              }
              options={MDGLocationCodeOptions}
              formItemProps={{
                rules: [
                  serverValidator(
                    {
                      method: 'siteOnValidation',
                      errorMessage:
                        'Project cannot be created for Inactive Site',
                    },
                    ProjectHttpService,
                  ),
                  {
                    required:
                      !currentSite || !form?.getFieldValue('PSLocationCode'),
                    message: 'MDG Location Code is required!',
                  },
                ],
              }}
              elementProps={{
                onChange: (value: SelectValue): void => {
                  setCurrentSite(value as string);
                },
              }}
            />
            <Autocomplete
              id="PSLocationCode"
              label="PS Location Code"
              isEditing={isEditing && projectTypePermission && !!PSLocationCode}
              options={PSLocationCodeOptions}
              formItemProps={{
                rules: [
                  serverValidator(
                    {
                      method: 'siteOnValidation',
                      errorMessage:
                        'Project cannot be created for Inactive Site',
                    },
                    ProjectHttpService,
                  ),
                  {
                    required:
                      !currentSite || !form?.getFieldValue('MDGLocationCode'),
                    message: 'PS Location Code is required!',
                  },
                ],
              }}
              elementProps={{
                onChange: (value: SelectValue): void => {
                  setCurrentSite(value as string);
                },
              }}
            />
            <Input
              id="projectName"
              label="Project Name"
              isEditing={isEditing && projectTypePermission && !!projectName}
            />
            <Input
              id="buildYearPlan"
              label="Build Year Plan"
              isEditing={isEditing && projectTypePermission && !!buildYearPlan}
            />
            {!isNew && (
              <InputNumber
                id="projectBundleID"
                label="Project Bundle ID"
                isEditing={
                  isEditing && projectTypePermission && !!projectBundleID
                }
                formItemProps={{
                  normalize,
                  rules: [INPUT_POSITIVE_NUMBER_VALIDATOR],
                }}
              />
            )}
            <Autocomplete
              id="projectType"
              label="Project Type"
              isEditing={isEditing && projectTypePermission && !!projectType}
              options={dropdownsOptions?.ProjectType}
              formItemProps={{
                rules: [
                  {
                    required: true,
                    message: 'Project Type is required!',
                  },
                ],
              }}
            />
            <Autocomplete
              id="projectSubtype"
              label="Project Subtype"
              isEditing={isEditing && projectTypePermission && !!projectSubtype}
              options={dropdownsOptions?.ProjectSubtype}
              formItemProps={{
                rules: [
                  {
                    required: true,
                    message: 'Project Subtype is required!',
                  },
                ],
              }}
            />
            <Autocomplete
              id="projectStatus"
              label="Project Status"
              isEditing={isEditing && projectTypePermission && !!projectStatus}
              options={dropdownsOptions?.ProjectStatus?.map(
                (option: OptionProps) =>
                  option.value === ProjectStatus.Hold
                    ? {
                        ...option,
                        disabled: !holdReasonPermission,
                        ...(!isAdmin
                          ? { title: 'Only Admin could set Hold status.' }
                          : {}),
                      }
                    : option,
              )}
              readOnlyElementRender={(value: string): ReactElement => (
                <ProjectStatusComponent status={value} />
              )}
            />
          </Col>
          <Col span={colSpan}>
            <Autocomplete
              id="progressStatus"
              label="Progress Status"
              isEditing={isNew && projectTypePermission && !!progressStatus}
              options={dropdownsOptions?.ProgressStatus}
            />
            <Autocomplete
              id="RESpecialist"
              label="RE Specialist"
              isEditing={isEditing && projectTypePermission && !!RESpecialist}
              options={dropdownsOptions?.RESpecialist}
            />
            <Autocomplete
              id="providenceAgent"
              label="Providence Agent"
              isEditing={
                isEditing && projectTypePermission && !!providenceAgent
              }
              options={providenceAgents}
            />
            <Input
              id="RFEngineer"
              label="RF Engineer"
              isEditing={isEditing && projectTypePermission && !!RFEngineer}
            />
            <Input
              id="constructionEngineer"
              label="Construction Engineer"
              isEditing={
                isEditing && projectTypePermission && !!constructionEngineer
              }
            />
            <RadioGroup
              id="POR"
              label="POR"
              isEditing={isEditing && projectTypePermission && !!POR}
              buttons={YES_NO_GROUP}
            />
            <Autocomplete
              id="civilVendor"
              label="Civil Vendor"
              isEditing={isEditing && projectTypePermission && !!civilVendor}
              options={dropdownsOptions?.CivilVendor}
            />
            <Autocomplete
              id="environmentalVendor"
              label="Environmental Vendor"
              isEditing={
                isEditing && projectTypePermission && !!environmentalVendor
              }
              options={dropdownsOptions?.EnvironmentalVendor}
            />
            <ShowMoreInput
              id="projectNote"
              label="Project Notes"
              isEditing={
                isEditing && projectTypePermission && !!projectNotePermission
              }
              data={projectNote}
              form={form}
            />
            <TextArea
              id="gatingItems"
              label="Gating Items"
              isEditing={isEditing && projectTypePermission && !!gatingItems}
            />
          </Col>
        </Row>
      </Panel>
    </Form>
  );
};
