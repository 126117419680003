import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';
import { PermittingBrowseFilter } from '@models/interfaces';

export class PermittingBrowseResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(
      PermittingHttpService,
    ).getPermittingBrowseData(
      BROWSE_DEFAULT_FILTER_VALUE as PermittingBrowseFilter,
    );
  }
}
