import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MAIN_PACE_TASK_IDs } from '@symfa-inc/providence-verizon-types';
import { Col, Form, Row } from 'antd';
import { momentizeObjectDates } from '@core/utils/methods';
import { ACTUAL_DATE, PACE_ACTUAL_DATE } from '@core/utils/validators';
import { YES_NO_GROUP } from '@models/constants';
import { EngineeringRFDSPhase1, FormProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  DatePicker,
  RadioGroup,
  ShowMoreInput,
} from '@shared/modules';
import { DDVSelectors, PaceTasksSelectors } from '@store/selectors';
import { RFDS_INFO_DATES } from '../../models/constants';

export const RFDSPhase1Panel: FC<FormProps> = ({
  form,
  isEditing,
  data: { phase1Note, engineeringNote, RFDSPromotionFailureNote, ...data },
  permissions: {
    prelimRFDSReceivedDate,
    RFDSStatus,
    prelimRFDSDemotedTo,
    revisedRFDSReceivedDate,
    prelimRFDSPromotedDate,
    revisedRFDSDemotedTo,
    finalRFDSReceived,
    finalRFDSPromotedToCXDate,
    isFinalRFDSPromotionSuccessful,
    RFDSPromotionFailureNote: RFDSPromotionFailureNotePermissions,
    successfulOfPromotionDate,
    CI004,
    CSSDataCompleteDate,
    isPhase1Required,
    phase1OrderedDate,
    phase1CompleteDate,
    phase1Vendor,
    phase1Note: phase1NotePermissions,
    phase1UploadedDate,
    engineeringNote: engineeringNotePermissions,
  },
  onValuesChange,
}: PropsWithChildren<FormProps>) => {
  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  const [CI004PTP, finalRFDSReceivedPTP] = useSelector(
    PaceTasksSelectors.getPaceTasksPermissions([
      {
        PACETaskId: MAIN_PACE_TASK_IDs.RBSDataEntered,
        dateType: 'PACEActualDate',
      },
      {
        dateType: 'actualDate',
        PACETaskId: MAIN_PACE_TASK_IDs.finalRFDSReceived,
      },
    ]),
  );

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(
        momentizeObjectDates<EngineeringRFDSPhase1>(
          data as EngineeringRFDSPhase1,
          RFDS_INFO_DATES,
        ),
      );
    }
  }, [data, isEditing]);

  return (
    <Panel
      header="RFDS/Phase 1"
      collapsible
      className="prov-engineering-workflow__panel rfds-panel"
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span="10">
            <DatePicker
              id="prelimRFDSReceivedDate"
              label="Prelim RFDS Received"
              isEditing={isEditing && !!prelimRFDSReceivedDate}
            />
            <Autocomplete
              id="RFDSStatus"
              label="RFDS Status"
              isEditing={isEditing && !!RFDSStatus}
              options={dropdownsOptions?.RFDSStatus}
            />
            <Autocomplete
              id="prelimRFDSDemotedTo"
              label="Prelim RFDS Demoted To"
              isEditing={isEditing && !!prelimRFDSDemotedTo}
              options={dropdownsOptions?.PrelimRFDSDemotedTo}
            />
            <DatePicker
              id="revisedRFDSReceivedDate"
              label="Revised RFDS Received"
              isEditing={isEditing && !!revisedRFDSReceivedDate}
            />
            <DatePicker
              id="prelimRFDSPromotedDate"
              label="Prelim RFDS Promoted"
              isEditing={isEditing && !!prelimRFDSPromotedDate}
            />
            <Autocomplete
              id="revisedRFDSDemotedTo"
              label="Revised RFDS Demoted To"
              options={dropdownsOptions?.RevisedRFDSDemotedTo}
              isEditing={isEditing && !!revisedRFDSDemotedTo}
            />
            <DatePicker
              id="finalRFDSReceived"
              label="Final RFDS Received"
              isEditing={
                isEditing && !!finalRFDSReceived && finalRFDSReceivedPTP
              }
              formItemProps={{
                rules: ACTUAL_DATE(data?.finalRFDSReceived),
              }}
            />
            <DatePicker
              id="finalRFDSPromotedToCXDate"
              label="Final RFDS Promoted to CX"
              isEditing={isEditing && !!finalRFDSPromotedToCXDate}
            />
            <RadioGroup
              id="isFinalRFDSPromotionSuccessful"
              label="Final RFDS Promotion Successful"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!isFinalRFDSPromotionSuccessful}
            />
            <ShowMoreInput
              id="RFDSPromotionFailureNote"
              label="RFDS Promotion Failure Note"
              data={RFDSPromotionFailureNote}
              form={form}
              isEditing={isEditing && !!RFDSPromotionFailureNotePermissions}
            />
          </Col>
          <Col span="12">
            <DatePicker
              id="successfulOfPromotionDate"
              label="Date of Successful Promotion"
              isEditing={isEditing && !!successfulOfPromotionDate}
            />
            <DatePicker
              id="CI004"
              label="CI004 PACE Actual Date"
              isEditing={isEditing && !!CI004 && CI004PTP}
              formItemProps={{
                rules: PACE_ACTUAL_DATE(data?.CI004),
              }}
            />
            <DatePicker
              id="CSSDataCompleteDate"
              label="CSS Data Complete"
              isEditing={isEditing && !!CSSDataCompleteDate}
            />
            <RadioGroup
              id="isPhase1Required"
              label="Phase 1 Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!isPhase1Required}
            />
            <DatePicker
              id="phase1OrderedDate"
              label="Phase 1 Ordered"
              isEditing={isEditing && !!phase1OrderedDate}
            />
            <DatePicker
              id="phase1CompleteDate"
              label="Phase 1 Complete"
              isEditing={isEditing && !!phase1CompleteDate}
            />
            <Autocomplete
              id="phase1Vendor"
              label="Phase 1 Vendor"
              isEditing={isEditing && !!phase1Vendor}
              options={dropdownsOptions?.Phase1Vendor}
            />
            <ShowMoreInput
              id="phase1Note"
              label="Phase 1 Notes"
              data={phase1Note}
              form={form}
              isEditing={isEditing && !!phase1NotePermissions}
            />
            <DatePicker
              id="phase1UploadedDate"
              label="Phase 1 Uploaded"
              isEditing={isEditing && !!phase1UploadedDate}
            />
            <ShowMoreInput
              id="engineeringNote"
              label="Engineering Notes"
              data={engineeringNote}
              form={form}
              isEditing={isEditing && !!engineeringNotePermissions}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
