import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DropDownSelectorValues } from '@symfa-inc/providence-verizon-types';
import { Form } from 'antd';
import { EditableInformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Autocomplete, Coordinate, Input } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';

export const SiteInformation: FC<EditableInformationProps> = ({
  isEditing,
  data,
  form,
}: EditableInformationProps) => {
  const towerOwnerOptions = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByType(DropDownSelectorValues.TowerOwner),
  );

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(data);
    }
  }, []);

  return (
    <Panel header="Site Information">
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} form={form}>
        <Input
          id="MDGLocationCode"
          label="MDG Location Code"
          isEditing={isEditing}
        />
        <Input
          id="PSLocationCode"
          label="PS Location Code"
          isEditing={isEditing}
        />
        <Input id="siteName" label="Site Name" isEditing={isEditing} />
        <Input
          id="streetAddress"
          label="Street Address"
          isEditing={isEditing}
        />
        <Input id="city" label="City" isEditing={isEditing} />
        <Input id="state" label="State" isEditing={isEditing} />
        <Input id="zip" label="Zip" isEditing={isEditing} />
        <Coordinate id="latitude" label="Latitude" isEditing={isEditing} />
        <Coordinate id="longitude" label="Longitude" isEditing={isEditing} />
        <Input id="jurisdiction" label="Jurisdiction" isEditing={isEditing} />
        <Autocomplete
          id="towerOwner"
          label="Tower Owner"
          isEditing={isEditing}
          options={towerOwnerOptions}
        />
        <Input id="towerType" label="Tower Type" isEditing={isEditing} />
        <Input id="towerHeight" label="Tower Height" isEditing={isEditing} />
        <Input id="equipmentType" label="EquipmentType" isEditing={isEditing} />
      </Form>
    </Panel>
  );
};
