import { ProjectType, Undefinable } from '@symfa-inc/providence-verizon-types';
import { SiteInformationType } from '@models/types';

export const getModAdditionalInfo = (
  formValues: Omit<SiteInformationType, 'site'>,
  projectType: ProjectType,
  unknownSwitchState: Undefinable<boolean>,
) =>
  projectType === ProjectType.Mod
    ? {
        powerPlantType: !unknownSwitchState ? formValues?.powerPlantType : null,
        unknownPowerPlantType: !unknownSwitchState
          ? null
          : formValues?.unknownPowerPlantType,
        isUnknownPowerPlantType: !!unknownSwitchState,
      }
    : {};
