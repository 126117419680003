import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { SiteHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { createOnChangeModalVisibility } from '@core/utils/methods';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
  tableCount,
} from '@models/constants';
import { MainBrowseFilter, SiteBrowseFilter } from '@models/interfaces';
import { MainSiteBrowseFilter, SwitchType } from '@models/types';
import { BrowsePage } from '@shared/modules/browse-page';
import { SiteSelectors } from '@store/selectors';
import {
  SITE_BROWSE_COLUMNS,
  SITE_BROWSE_DEFAULT_CONFIGURE_FILTER,
} from './models/constants';
import { Filters } from './filters';

import './styles.scss';

const { useForm } = Form;

export const SiteBrowse: FC = () => {
  const [form] = useForm();

  const siteBrowse = useSelector(SiteSelectors.getSiteBrowseInfo);

  const [filterValue, setFilterValue] = useState<MainBrowseFilter>(
    BROWSE_DEFAULT_FILTER_VALUE,
  );
  const [configureFilter, setConfigureFilter] = useState(
    SITE_BROWSE_DEFAULT_CONFIGURE_FILTER,
  );

  useDidUpdateEffect(() => {
    HttpService.getHttpRequests(SiteHttpService).getSiteBrowse(filterValue);
  }, [filterValue]);

  const onSearchChanges = (search: string): void =>
    setFilterValue((prevState: MainSiteBrowseFilter) => ({
      ...prevState,
      page: 1,
      search,
    }));

  const onConfigureFilterChanges = (value: SiteBrowseFilter): void => {
    setConfigureFilter((prevState: SiteBrowseFilter) => ({
      ...prevState,
      ...value,
    }));
  };

  const onOnlyActiveSwitch = (value: boolean): void =>
    setFilterValue((prevState: MainSiteBrowseFilter) => ({
      ...prevState,
      onlyActive: value as SwitchType,
    }));

  const onClear = (): void => {
    form.resetFields();
    setConfigureFilter(SITE_BROWSE_DEFAULT_CONFIGURE_FILTER);
  };

  const onApplyConfigureFilter = (): void =>
    setFilterValue((prevState: MainSiteBrowseFilter) => ({
      ...prevState,
      ...configureFilter,
    }));

  return (
    <BrowsePage
      legend={[
        {
          title: 'High Risk Site',
          status: 'circle_high',
        },
        {
          title: 'Inactive/Off Site',
          status: 'circle_inactive',
        },
      ]}
      tableProps={{
        pagination: {
          current: filterValue.page,
          total: siteBrowse.total,
        },
        module: 'site',
      }}
      switchInfo="Show Only Sites with Active Projects"
      onSwitch={onOnlyActiveSwitch}
      columns={SITE_BROWSE_COLUMNS}
      data={siteBrowse.items}
      onSearchChanges={onSearchChanges}
      onChangeFilterModalVisibility={createOnChangeModalVisibility(
        form,
        configureFilter,
        filterValue,
        setConfigureFilter,
      )}
      onChange={browseFiltersChange(setFilterValue)}
      onApplyFilter={onApplyConfigureFilter}
      className="site-browse"
      filters={
        <Filters
          value={configureFilter}
          formProps={{ form }}
          onChanges={onConfigureFilterChanges}
        />
      }
      cancelButtonProps={{
        onClick: onClear,
      }}
      filterFormProps={{ form }}
      place={tableCount(true, 'Site')}
      redirectToDetailsRowKeys={['PSLocationCode', 'MDGLocationCode']}
    />
  );
};
