import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { Rule } from 'antd/lib/form';
import { HttpService } from '@core/services';
import { SiteHttpService } from '@core/services/http';

export const SITE_UNIQUE_FIELD = (
  required: boolean,
  fieldName: string,
  length: number,
  id?: string,
): Rule[] => [
  {
    required,
    message: `${fieldName} is required!`,
  },
  {
    validator: async (
      { field }: any,
      value: Undefinable<string>,
    ): Promise<void> => {
      if (!value) {
        return;
      }

      if (value.length < length) {
        throw new Error(`${fieldName} must be ${length} characters!`);
      }

      if (!/^\d+$/.test(value)) {
        throw new Error(`${fieldName} must consist only of digits!`);
      }

      if (
        value.length === length &&
        !(await HttpService.getHttpRequests(
          SiteHttpService,
        ).validateSiteUniqueField({
          [field]: value,
          id,
        }))
      ) {
        throw new Error(`${fieldName} must be unique!`);
      }
    },
  },
];
