import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { canBeEditable, momentizeObjectDates } from '@core/utils/methods';
import { ACTUAL_DATE } from '@core/utils/validators';
import {
  FormProps,
  LeasingPermission,
  LeasingWorkflowUpdateData,
} from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  DatePicker,
  Input,
  ShowMoreInput,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { LEASING_INFO_DATES } from '../../models/constants';

interface Props extends FormProps {
  data: LeasingWorkflowUpdateData['leasing'];
  permissions: LeasingPermission.LeasingFields;
}

export const LeasingPanel: FC<Props> = ({
  form,
  data: { leasingNote, ...data },
  isEditing,
  onValuesChange,
  permissions: {
    leaseAuditComplete,
    SOFIssued,
    landlordRequiresMAId,
    coloAppDrafted,
    coloAppSubmitted,
    coloAppApproved,
    PALOrNPARequiredId,
    PALOrNPAReceived,
    leaseDraftReceived,
    leaseDocumentName,
    leaseTypeId,
    leaseRevisionRequestedDate,
    LMPSSubmitted,
    LMPSApproved,
    legalAssignedDate,
    legalSubmittedDate,
    legalCompanyId,
    legalApprovedDate,
    outForSignatureDate,
    eSignatureId,
    leaseOrAmendmentFullyExecuted,
    NTPRequirement,
    NTPRequested,
    NTPApproved,
    leasingNote: leasingNotePermission,
  },
}: PropsWithChildren<Props>) => {
  const {
    LandlordRequiresMADDV,
    PALNPARequiredDDV,
    LeaseTypeDDV,
    LegalCompanyDDV,
    ESignatureDDV,
  } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes([
      'LandlordRequiresMA',
      'PALNPARequired',
      'LeaseType',
      'LegalCompany',
      'ESignature',
    ]),
  );

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(
        momentizeObjectDates<LeasingWorkflowUpdateData['leasing']>(
          data,
          LEASING_INFO_DATES,
        ),
      );
    }
  }, [isEditing]);

  return (
    <Panel header="Leasing" className="leasing-panel" collapsible>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        onValuesChange={onValuesChange}
        form={form}
      >
        <Row>
          <Col span="11">
            <DatePicker
              id="leaseAuditComplete"
              label="Lease Audit Complete"
              isEditing={canBeEditable(leaseAuditComplete, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.leaseAuditComplete),
              }}
            />
            <DatePicker
              id="SOFIssued"
              label="SOF Issued Date"
              isEditing={canBeEditable(SOFIssued, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.SOFIssued),
              }}
            />
            <Autocomplete
              id="landlordRequiresMAId"
              label="Landlord Requires MA"
              options={LandlordRequiresMADDV}
              isEditing={canBeEditable(landlordRequiresMAId, isEditing)}
            />
            <DatePicker
              id="coloAppDrafted"
              label="Colo App Drafted"
              isEditing={canBeEditable(coloAppDrafted, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.coloAppDrafted),
              }}
            />
            <DatePicker
              id="coloAppSubmitted"
              label="Colo App Submitted"
              isEditing={canBeEditable(coloAppSubmitted, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.coloAppSubmitted),
              }}
            />
            <DatePicker
              id="coloAppApproved"
              label="Colo App Approved"
              isEditing={canBeEditable(coloAppApproved, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.coloAppApproved),
              }}
            />
            <Autocomplete
              id="PALOrNPARequiredId"
              label="PAL/NPA Required"
              isEditing={canBeEditable(PALOrNPARequiredId, isEditing)}
              options={PALNPARequiredDDV}
            />
            <DatePicker
              id="PALOrNPAReceived"
              label="PAL/NPA Received"
              isEditing={canBeEditable(PALOrNPAReceived, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.PALOrNPAReceived),
              }}
            />
            <DatePicker
              id="leaseDraftReceived"
              label="Lease Draft Received"
              isEditing={canBeEditable(leaseDraftReceived, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.leaseDraftReceived),
              }}
            />
            <Input
              id="leaseDocumentName"
              label="Lease Document Name"
              isEditing={canBeEditable(leaseDocumentName, isEditing)}
            />
            <Autocomplete
              id="leaseTypeId"
              label="Lease Type"
              options={LeaseTypeDDV}
              isEditing={canBeEditable(leaseTypeId, isEditing)}
            />
            <DatePicker
              id="leaseRevisionRequestedDate"
              label="Lease Revision Requested"
              isEditing={canBeEditable(leaseRevisionRequestedDate, isEditing)}
            />
            <DatePicker
              id="LMPSSubmitted"
              label="LMPS Submitted"
              isEditing={canBeEditable(LMPSSubmitted, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.LMPSSubmitted),
              }}
            />
          </Col>
          <Col span="11">
            <DatePicker
              id="LMPSApproved"
              label="LMPS Approved"
              isEditing={canBeEditable(LMPSApproved, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.LMPSApproved),
              }}
            />
            <DatePicker
              id="legalSubmittedDate"
              label="Legal Submitted"
              isEditing={canBeEditable(legalSubmittedDate, isEditing)}
            />
            <DatePicker
              id="legalAssignedDate"
              label="Legal Assigned Date"
              isEditing={canBeEditable(legalAssignedDate, isEditing)}
            />
            <Autocomplete
              id="legalCompanyId"
              label="Legal Company"
              options={LegalCompanyDDV}
              isEditing={canBeEditable(legalCompanyId, isEditing)}
            />
            <DatePicker
              id="legalApprovedDate"
              label="Legal Approved"
              isEditing={canBeEditable(legalApprovedDate, isEditing)}
            />
            <DatePicker
              id="outForSignatureDate"
              label="Out for Signature"
              isEditing={canBeEditable(outForSignatureDate, isEditing)}
            />
            <Autocomplete
              id="eSignatureId"
              label="eSignatureId"
              options={ESignatureDDV}
              isEditing={canBeEditable(eSignatureId, isEditing)}
            />
            <DatePicker
              id="leaseOrAmendmentFullyExecuted"
              label="Lease/Amendment Fully Executed"
              isEditing={canBeEditable(
                leaseOrAmendmentFullyExecuted,
                isEditing,
              )}
              formItemProps={{
                rules: ACTUAL_DATE(data?.leaseOrAmendmentFullyExecuted),
              }}
            />
            <Input
              id="NTPRequirement"
              label="NTP Requirements"
              isEditing={canBeEditable(NTPRequirement, isEditing)}
            />
            <DatePicker
              id="NTPRequested"
              label="NTP Requested"
              isEditing={canBeEditable(NTPRequested, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.NTPRequested),
              }}
            />
            <DatePicker
              id="NTPApproved"
              label="NTP Approved"
              isEditing={canBeEditable(NTPApproved, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.NTPApproved),
              }}
            />
            <ShowMoreInput
              id="leasingNote"
              label="Leasing Notes"
              data={leasingNote}
              form={form}
              isEditing={canBeEditable(leasingNotePermission, isEditing)}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
