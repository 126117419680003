import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { EngineeringAdditionFilterType } from '@symfa-inc/providence-verizon-types';
import { Form, Row } from 'antd';
import { HttpService } from '@core/services';
import { EngineeringHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  createOnChangeModalVisibility,
  removeEmptyFields,
} from '@core/utils/methods';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
  tableCount,
} from '@models/constants';
import { EngineeringBrowseFilter } from '@models/interfaces';
import { MainEngineeringBrowseFilter, SwitchType } from '@models/types';
import { AdditionalFilters } from '@shared/components';
import { BrowsePage } from '@shared/modules/browse-page';
import { EngineeringSelectors } from '@store/selectors';
import {
  ADDITIONAL_FILTERS,
  ENGINEERING_BROWSE_COLUMNS,
  ENGINEERING_BROWSE_CONFIGURE_DEFAULT_FILTER,
} from './models/constants';
import { Filters } from './filters';

import './styles.scss';

const { useForm } = Form;

export const EngineeringBrowse: FC = () => {
  const [form] = useForm();

  const engineeringBrowseData = useSelector(EngineeringSelectors.getBrowseData);

  const [filterValue, setFilterValue] = useState<MainEngineeringBrowseFilter>({
    ...BROWSE_DEFAULT_FILTER_VALUE,
    showCompleted: false,
  });

  const [configureFilter, setConfigureFilter] =
    useState<EngineeringBrowseFilter>(
      ENGINEERING_BROWSE_CONFIGURE_DEFAULT_FILTER,
    );

  useDidUpdateEffect(() => {
    HttpService.getHttpRequests(EngineeringHttpService).getEngineeringBrowse(
      removeEmptyFields({ ...filterValue }),
    );
  }, [filterValue]);

  const onSearchChanges = (search: string): void => {
    setFilterValue((prevState: MainEngineeringBrowseFilter) => ({
      ...prevState,
      search,
    }));
  };

  const onApplyConfigureFilter = (): void => {
    setFilterValue((prevState: MainEngineeringBrowseFilter) => ({
      ...prevState,
      ...configureFilter,
    }));
  };

  const onOnlyCompletedSwitch = (value: boolean): void =>
    setFilterValue((prevState: MainEngineeringBrowseFilter) => ({
      ...prevState,
      showCompleted: value as SwitchType,
    }));

  const onConfigureFilterChanges = (value: EngineeringBrowseFilter): void => {
    setConfigureFilter((prevState: EngineeringBrowseFilter) => ({
      ...prevState,
      ...value,
    }));
  };

  const onChangeAdditionFilter = (filter: string | string[]): void => {
    setFilterValue((prevState: MainEngineeringBrowseFilter) => ({
      ...prevState,
      additionFilter: filter as EngineeringAdditionFilterType,
    }));
  };

  return (
    <div className="prov-engineering-browse">
      <BrowsePage
        switchInfo="Show Completed Projects"
        filters={
          <Filters
            formProps={{ form }}
            value={configureFilter}
            onChanges={onConfigureFilterChanges}
          />
        }
        onChangeFilterModalVisibility={createOnChangeModalVisibility(
          form,
          configureFilter,
          filterValue,
          setConfigureFilter,
        )}
        columns={ENGINEERING_BROWSE_COLUMNS}
        data={engineeringBrowseData?.items}
        place={tableCount(true)}
        className="engineering-browse"
        filterFormProps={{ labelCol: { span: 8 }, form }}
        cancelButtonProps={{
          onClick: (): void => {
            form.resetFields();
            setConfigureFilter(ENGINEERING_BROWSE_CONFIGURE_DEFAULT_FILTER);
          },
        }}
        additional={
          <Row>
            <AdditionalFilters
              filters={ADDITIONAL_FILTERS}
              onChange={onChangeAdditionFilter}
              activeFilters={(filterValue.additionFilter as string) || ''}
              mode="switch"
            />
          </Row>
        }
        onChange={browseFiltersChange(setFilterValue)}
        onSearchChanges={onSearchChanges}
        onApplyFilter={onApplyConfigureFilter}
        onSwitch={onOnlyCompletedSwitch}
        tableProps={{
          pagination: {
            total: engineeringBrowseData?.total,
          },
          module: 'engineering',
        }}
      />
    </div>
  );
};
