import { ProjectType, Undefinable } from '@symfa-inc/providence-verizon-types';
import { FormInstance } from 'antd/lib/form';
import { ObjectComparatorService } from '@core/services';
import { momentizeObjectDates } from '@core/utils/methods';
import { SITE_AUDIT_MOMENTIZE_FIELDS } from '@models/constants';
import { SiteAuditInformation, SiteBrowse } from '@models/interfaces';
import { SiteInformationType } from '@models/types';
import { getModAdditionalInfo } from './get-mod-additional-info.constant';

export const onSiteInfoFormChange = (
  form: FormInstance,
  data: SiteInformationType,
  setIsSiteInfoCompare: (value: boolean) => void,
  projectType: ProjectType,
  unknownSwitchState: Undefinable<boolean>,
) => {
  const formValues = form.getFieldsValue() as Omit<SiteInformationType, 'site'>;

  const {
    site: { equipmentType, towerType },
    id,
    fileName,
    ...prevData
  } = data;

  setIsSiteInfoCompare(
    ObjectComparatorService.objectsCompare(
      {
        ...prevData,
        equipmentType,
        towerType,
        isUnknownPowerPlantType: prevData?.isUnknownPowerPlantType ?? false,
      } as Omit<SiteAuditInformation, 'raycaps' | 'cables'> &
        Pick<SiteBrowse, 'equipmentType' | 'towerType'>,
      {
        ...momentizeObjectDates<Omit<SiteInformationType, 'site'>>(
          formValues,
          SITE_AUDIT_MOMENTIZE_FIELDS,
          true,
        ),
        ...getModAdditionalInfo(formValues, projectType, unknownSwitchState),
      },
      true,
    ),
  );
};
