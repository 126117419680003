import { ReactNode } from 'react';
import { MAIN_PACE_TASK_IDs } from '@symfa-inc/providence-verizon-types';
import { ColumnsType } from 'antd/lib/table/interface';
import {
  projectFuzeIDRender,
  projectPaceTaskDataRender,
  readOnlyInputNumberRender,
} from '@core/utils/methods';
import { SiteBrowse } from '@models/interfaces';
import { Browse } from '@models/types';

export const SOF_TOOL_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'PS Location Code',
    dataIndex: 'site',
    key: 'PSLocationCode',
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.PSLocationCode),
  },
  {
    title: 'Contract Number',
    dataIndex: 'site',
    key: 'contractNumber',
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.contractNumber,
  },
  {
    title: 'PS Project Number',
    dataIndex: 'fuzeIDs',
    key: 'PSProjectNumber',
    sorter: true,
    render: projectFuzeIDRender({
      field: 'PSProjectNumber',
    }),
  },
  {
    title: 'Fuze ID',
    dataIndex: 'fuzeIDs',
    key: 'fuzeId',
    sorter: true,
    render: projectFuzeIDRender({
      field: 'fuzeID',
    }),
  },
  {
    title: 'SS014 Actual Date',
    key: 'SS014ActualDate',
    dataIndex: 'projectPaceTaskData',
    sorter: true,
    width: 200,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.scopingComplete,
      field: 'actualDate',
    }),
  },
  {
    title: 'Site Name',
    dataIndex: 'site',
    key: 'siteName',
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.siteName,
  },
];
