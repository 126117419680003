import { ReactNode } from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { ColumnsFunc } from '@models/types';
import {
  phoneElementRender,
  readOnlyDatePickerRenderer,
} from '../../core/utils/methods';
import {
  AdminCountyData,
  AdminDropdownValuesData,
  ContactsData,
  ProjectFuzeID,
} from '../interfaces';
import { actionButtons } from './action-buttons';

export const getProjectFuzeIDColumns = (
  isEditing: boolean,
  editFunc?: ColumnsFunc<ProjectFuzeID>,
  deleteFunc?: ColumnsFunc<ProjectFuzeID>,
): ColumnsType => [
  {
    title: 'Fuze ID',
    key: 'fuzeID',
    dataIndex: 'fuzeID',
  },
  {
    title: 'PS Project Number',
    key: 'PSProjectNumber',
    dataIndex: 'PSProjectNumber',
  },
  {
    title: 'Project Assigned Date',
    key: 'projectAssignedDate',
    dataIndex: 'projectAssignedDate',
    render: readOnlyDatePickerRenderer,
  },
  {
    title: 'RF Priority',
    key: 'RFPriority',
    dataIndex: 'RFPriority',
  },
  ...(isEditing
    ? [actionButtons<ProjectFuzeID>({ editFunc, deleteFunc })]
    : []),
];

export const getContactsColumns = (
  editFunc: ColumnsFunc<ContactsData>,
  deleteFunc: ColumnsFunc<ContactsData>,
  isEditing?: boolean,
): ColumnsType => [
  {
    title: 'Contact Type',
    dataIndex: 'contactType',
    key: 'contactType',
    width: 150,
    render: (adminDropdownValuesData: AdminDropdownValuesData): ReactNode =>
      adminDropdownValuesData?.value,
  },
  {
    title: 'Contact Name',
    dataIndex: 'contactName',
    key: 'lastValidatedDate',
    width: 150,
  },
  {
    title: 'Contact Address',
    dataIndex: 'contactAddress',
    key: 'address',
    width: 200,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    width: 165,
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    width: 120,
    render: (state: AdminCountyData): ReactNode => state?.name,
  },
  {
    title: 'Zip',
    dataIndex: 'zip',
    key: 'zip',
    width: 120,
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    width: 165,
    render: (phone: string): ReactNode => phoneElementRender(phone),
  },
  {
    title: 'Fax',
    dataIndex: 'fax',
    key: 'fax',
    width: 165,
    render: (phone: string): ReactNode => phoneElementRender(phone),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 190,
  },
  {
    title: 'Contact Notes',
    dataIndex: 'contactNote',
    key: 'contactNote',
    width: 180,
  },
  {
    ...actionButtons({ editFunc, deleteFunc, isEditing }),
    fixed: 'right',
  } as ColumnType<unknown>,
];
