import {
  ExportType,
  MAIN_PACE_TASK_IDs,
  ProjectType,
} from '@symfa-inc/providence-verizon-types';
import { Store } from 'antd/es/form/interface';

export const getAccountingBrowseFormDefValues = (
  projectTypeMode: ProjectType,
): Store => ({
  invoiceDatePaceTaskId:
    projectTypeMode === ProjectType.Mod
      ? MAIN_PACE_TASK_IDs.leasingComplete
      : MAIN_PACE_TASK_IDs.candidateApproved,
  exportType: ExportType.CSV,
});
