import { UsergroupAddOutlined } from '@ant-design/icons';
import {
  ResponsibleForProjectType,
  SubPage,
  UserRole,
} from '@symfa-inc/providence-verizon-types';
import { RedirectGuard } from '@core/guards';
import { Route, RouteGroup, Tab } from '@models/classes';
import { MenuPlacement } from '@models/enums';
import { LeafletMap, UsualMap } from './components/mapping/components';
import { MappingArea } from './components';
import { CandidatesMappingResolver } from './resolvers';

export const usualMapTab = new Tab({
  component: UsualMap,
  name: 'Usual Map',
  path: 'usual-map',
});

export const topographicMapTab = new Tab({
  component: LeafletMap,
  name: 'Topographic Map',
  path: 'topographic-map',
});

export const candidatesRoutes = new RouteGroup({
  name: 'Candidates',
  path: '/candidates',
})
  .breadcrumb()
  .setMenu(MenuPlacement.Main, UsergroupAddOutlined)
  .addGuard(new RedirectGuard('candidates'), '/')
  .addRoutes([
    new Route({
      path: '/mapping',
      name: 'Mapping',
      component: MappingArea,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(
        new RedirectGuard('candidates', SubPage.CandidatesMapping, [
          {
            role: UserRole.EngineeringManager,
            responsibleForProjectType: ResponsibleForProjectType.Mod,
          },
        ]),
        '/',
      )
      .addResolver(new CandidatesMappingResolver())
      .breadcrumb()
      .addTabs([usualMapTab, topographicMapTab]),
  ]);
