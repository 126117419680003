import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { actionCreatorFactory } from '../../core/utils/factories';
import { ProcessEnvMap } from '../../models/interfaces';

const actionCreator = actionCreatorFactory('COMMON');

export namespace CommonActions {
  export const setHasUnsubmittedData = actionCreator.createAsyncAction<boolean>(
    'SET_HAS_UNSUBMITTED_DATA',
  );

  export const setRouteParams =
    actionCreator.createAsyncAction<Undefinable<ProcessEnvMap>>(
      'SET_ROUTE_PARAMS',
    );

  export const setRoutePath =
    actionCreator.createAsyncAction<string>('SET_ROUTE_PATH');
}
