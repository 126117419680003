import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { EngineeringSOFToolState } from '../../reducers/engineering/sof-tool.reducer';

const SOFToolState = (state: AppState): EngineeringSOFToolState =>
  state.engineering.SOFTool;

export namespace SOFToolSelectors {
  export const browseData = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => state.SOFToolBrowse,
  );

  export const browseFilters = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => state.filtersOptions,
  );

  export const detailsHeaderData = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => {
      const {
        details: {
          generalInfo: { site, fuzeIDs },
        },
      } = state;

      return {
        site,
        fuzeIDs,
      };
    },
  );

  export const detailsData = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => state.details,
  );

  export const previewSrc = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => state.previewSrc,
  );

  export const isFetching = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => state.isFetching,
  );

  export const getProjectType = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) =>
      state.details.generalInfo.projectType.value,
  );

  export const getProjectId = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => state.details.generalInfo.id,
  );
}
