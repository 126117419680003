import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { SubPage, Tab } from '@symfa-inc/providence-verizon-types';
import { SiteInformation } from '@client/pages/site/components';
import { EditablePage } from '@shared/components';
import { SiteSelectors, UserSelectors } from '@store/selectors';

export const SiteInformationTab: FC<RouterProps> = ({
  history,
}: PropsWithChildren<RouterProps>) => {
  const permissions = useSelector(UserSelectors.getSitePermissions('browse'));
  const { currentSite, associatedProjects } = useSelector(
    SiteSelectors.getSiteInformationTabInfo,
  );
  const canEdit = useSelector(
    UserSelectors.canEdit('site', SubPage.SiteBrowse, Tab.SiteInformation),
  );

  return (
    <EditablePage
      permissions={permissions}
      editableComponent={SiteInformation}
      data={{ ...currentSite, associatedProjects }}
      history={history}
      canUserEdit={canEdit && currentSite.isSiteOn}
    />
  );
};
