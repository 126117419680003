import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  LeasingBrowseResponseItem,
  LeasingDetails,
  LeasingEquipmentResp,
  LeasingEquipmentUpdateData,
  LeasingWorkflowUpdateData,
  MainBrowseFilter,
  PaginatedResponse,
  RadData,
} from '@models/interfaces';
import { LeasingLeaseAuditUpdateData } from '@models/types';
import {
  BreadcrumbsActions,
  LeasingActions,
  ProjectActions,
} from '@store/actions';
import { LeasingEffects } from '@store/effects';
import store from '@store/index';
import { CommonHttpService } from './common-http.service';

export class LeasingHttpService extends CommonHttpService {
  @StoredRequest(LeasingActions.getLeasingBrowseDataAction)
  getLeasingBrowse(
    params: MainBrowseFilter,
  ): Promise<PaginatedResponse<LeasingBrowseResponseItem>> {
    return this.http.callApi.get(`${this.apiUrl}/leasing`, null, {
      params,
    });
  }

  @StoredRequest(
    LeasingActions.getLeasingDetailsAction,
    BreadcrumbsActions.leasing,
  )
  async getLeasing(
    projectBundleID: string,
  ): Promise<
    Omit<
      LeasingDetails,
      | 'categories'
      | 'PACETasks'
      | 'PACETasksTableData'
      | 'RAD'
      | 'providenceAgentName'
    >
  > {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/leasing/${projectBundleID}`,
    );

    return LeasingEffects.setHoldOptions(
      LeasingEffects.setCurrentProvidenceAgent(
        LeasingEffects.setRAD(
          LeasingEffects.setPACETasks(
            LeasingEffects.setPACETasksTableData(
              LeasingEffects.setProjectFuzeIDs(response),
            ),
          ),
        ),
      ),
    );
  }

  @StoredRequest(LeasingActions.getLeasingEquipmentPriorProjectAction)
  getLeasingEquipmentPriorProject(id: string): Promise<LeasingEquipmentResp> {
    return this.http.callApi.get(`${this.apiUrl}/leasing/${id}/equipment`);
  }

  @StoredRequest(LeasingActions.updateLeasingWorkflowAction)
  async updateLeasingWorkflow(
    projectId: string,
    body: LeasingWorkflowUpdateData,
  ): Promise<LeasingWorkflowUpdateData> {
    await this.http.callApi.patch(`${this.apiUrl}/leasing/${projectId}`, body);

    return body;
  }

  @StoredRequest(LeasingActions.updateLeaseAuditAction)
  async updateLeaseAudit(
    projectId: string,
    area: 'engineering' | 'leasing',
    body: Partial<LeasingLeaseAuditUpdateData> & { RAD: RadData[] },
  ): Promise<
    Partial<LeasingLeaseAuditUpdateData> & { area: 'engineering' | 'leasing' }
  > {
    await this.http.callApi.patch(
      `${this.apiUrl}/leasing/lease-audit/${projectId}`,
      body,
    );

    const { RAD, ...rest } = body;

    store.dispatch(ProjectActions.setRADAction.done(RAD));

    return { ...rest, area };
  }

  @StoredRequest(LeasingActions.updateLeasingEquipmentAction)
  async updateLeasingEquipment(
    id: string,
    { priorTableData, finalTableData, ...notes }: LeasingEquipmentUpdateData,
  ): Promise<LeasingEquipmentUpdateData> {
    await this.http.callApi.patch(`${this.apiUrl}/leasing/${id}/equipment`, {
      /**
       * INFO: because backend does not need to equipment Type, but leasing-reducer does need
       * and wait equipmentId.
       */
      priorTableData: priorTableData.map(
        ({ equipmentType, equipment, ...data }) => ({
          ...data,
          equipmentId: equipment,
        }),
      ),
      finalTableData: finalTableData.map(
        ({ equipmentType, equipment, ...data }) => ({
          ...data,
          equipmentId: equipment,
        }),
      ),
      ...notes,
    });

    return { priorTableData, finalTableData, ...notes };
  }
}
