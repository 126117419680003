import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';
import { Form } from 'antd';
import classNames from 'classnames';
import { Store } from 'rc-field-form/lib/interface';
import {
  HttpService,
  ObjectComparatorService,
  ObjectDifferencesService,
} from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import { arraySort, momentizeObjectDates } from '@core/utils/methods';
import { JURISDICTION_DATES_FOR_MOMENTIZE } from '@models/constants';
import {
  JurisdictionDetailsPermitting,
  JurisdictionDetailsSummary,
  JurisdictionPermittingData,
} from '@models/interfaces';
import { JurisdictionPermittingSectionType } from '@models/types';
import { EditablePage, NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions } from '@store/actions';
import {
  CountySelectors,
  PermittingSelectors,
  UserSelectors,
} from '@store/selectors';
import { JurisdictionSummarySection } from '../../components';
import { JURISDICTION_CAN_USER_EDIT } from '../../models';
import { JurisdictionPermittingSection } from './components';
import { getDefaultPermittingData } from './helpers';
import { PermittingProps } from './models';

import './styles.scss';

const { useForm } = Form;

const Permitting: FC<PermittingProps> = ({
  isEditing,
  permissions,
  toggleEditing,
  history,
}: PropsWithChildren<PermittingProps>) => {
  const dispatch = useDispatch();

  const [summaryForm] = useForm();

  const { pathname } = useLocation();
  const searchParams = new URLSearchParams();

  const states = useSelector(CountySelectors.getStatesWithCounties);

  const [resetPermitting, setResetPermitting] = useState<boolean>(false);

  const {
    permitting,
    summary,
    id: jurisdictionId,
  } = useSelector(
    PermittingSelectors.getJurisdictionDetailsData([
      'summary',
      'permitting',
      'id',
    ]),
  );

  const [data, setData] = useState<JurisdictionPermittingData>(() =>
    getDefaultPermittingData(summary, permitting),
  );
  const [permittingChanges, setPermittingChanges] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const isDisabled = ObjectComparatorService.objectsCompare(
    getDefaultPermittingData(summary, permitting),
    data,
  );

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!isDisabled));
  }, [isDisabled]);

  useDidUpdateEffect(() => {
    if (permittingChanges) {
      setData(getDefaultPermittingData(summary, permitting));
      setPermittingChanges(!permittingChanges);
    }
  }, [permittingChanges, permitting]);

  const updateSummarySection = (summaryData: Store): void => {
    setData((prevState: JurisdictionPermittingData) => ({
      ...prevState,
      summarySection: {
        ...prevState.summarySection,
        ...summaryData,
      },
    }));
  };

  const updatePermittingSection = (
    permittingId: string,
    formValue: Store,
  ): void => {
    setData((prevState: JurisdictionPermittingData) => ({
      ...prevState,
      permittingSection: prevState.permittingSection.map(
        (permittingSection: JurisdictionPermittingSectionType) => {
          const condition = permittingSection.id !== permittingId;

          return condition
            ? permittingSection
            : {
                ...permittingSection,
                ...formValue,
              };
        },
      ),
    }));
  };

  const onSubmit = async (): Promise<void> => {
    try {
      setSubmitDisabled(true);

      const permittingSorted = arraySort([...permitting!], 'ASC', 'id');
      const permittingDataSorted = arraySort(
        data.permittingSection.map(
          (section: Omit<JurisdictionDetailsPermitting, 'permittingTypes'>) =>
            momentizeObjectDates(
              section,
              JURISDICTION_DATES_FOR_MOMENTIZE,
              true,
            ),
        ),
        'ASC',
        'id',
      );

      const needUpdatedPermitting = permittingDataSorted.filter(
        (p: JurisdictionPermittingSectionType, index: number) =>
          !ObjectComparatorService.objectsCompare(p, permittingSorted[index]),
      );

      const summaryFormValues = summaryForm.getFieldsValue();

      const stateName = states.find(item => {
        return item.id === summaryFormValues.state;
      })?.name;

      await HttpService.getHttpRequests(
        PermittingHttpService,
      ).updateJurisdictionPermitting(jurisdictionId!, {
        summarySection: ObjectDifferencesService.getObjectsDiff(
          summary || {},
          summaryFormValues,
          ['jurisdictionName'],
        ) as Omit<JurisdictionDetailsSummary, 'id'>,
        permittingSection: needUpdatedPermitting,
      });

      setPermittingChanges(!permittingChanges);

      toggleEditing?.();

      searchParams.set('tabName', 'Permitting');

      history!.replace(
        pathname.replace(
          /(\/jurisdiction\/)([^/]+)\/([^/]+)(.*)/,
          `$1${encodeURIComponent(
            summaryFormValues.jurisdictionName,
          )}/${stateName}$4?${searchParams}`,
        ),
      );

      NotificationsLoader.notificationSuccess('Information has been updated!');
    } catch (e) {
      console.error(e);
    }

    setSubmitDisabled(false);
  };

  const onCancel = (): void => {
    summaryForm.resetFields();

    setResetPermitting(!resetPermitting);
    setData(getDefaultPermittingData(summary, permitting));

    toggleEditing?.();
  };

  const buttonsDisabled = submitDisabled || isDisabled;

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <main className="prov-jurisdiction-permitting">
      <div
        className={classNames('page-wrap', {
          'page-wrap_with-actions': isEditing,
        })}
      >
        <JurisdictionSummarySection
          permissions={permissions!.zoningSummaryFields}
          isEditing={isEditing}
          updateSummarySection={updateSummarySection}
          jurisdictionId={jurisdictionId!}
          data={summary!}
          form={summaryForm}
        />
        <JurisdictionPermittingSection
          isEditing={isEditing}
          permittingModFields={permissions!.permittingModFields}
          permittingNSBFields={permissions!.permittingNSBFields}
          updatePermittingSection={updatePermittingSection}
          data={permitting!}
          setPermittingChanges={setPermittingChanges}
          reset={resetPermitting}
          jurisdictionId={jurisdictionId!}
        />
      </div>
      {isEditing && (
        <section className="actions">
          {JURISDICTION_CAN_USER_EDIT() && (
            <PrimaryButton
              title="Submit"
              disabled={buttonsDisabled}
              onClick={onSubmit}
            />
          )}
          <PrimaryButton
            title="Cancel"
            disabled={buttonsDisabled}
            type="default"
            onClick={onCancel}
          />
        </section>
      )}
    </main>
  );
};

export const JurisdictionPermitting: FC<RouteComponentProps> = ({
  history,
}) => {
  const permissions = useSelector(
    UserSelectors.getPermittingJurisdictionPermissions,
  );

  return (
    <EditablePage
      editableComponent={Permitting}
      permissions={permissions}
      canUserEdit={JURISDICTION_CAN_USER_EDIT()}
      history={history}
    />
  );
};
