import React, { FC, PropsWithChildren } from 'react';
import { ItemProps } from '../models/interfaces';

export const Item: FC<ItemProps> = ({
  label,
  value,
  element,
}: PropsWithChildren<ItemProps>) => (
  <div className="item">
    <span className="item__label">{label} :</span>
    {element || <span className="item__value">{value}</span>}
  </div>
);
