import { IRule, ISchedule } from '@symfa-inc/providence-verizon-types';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  DirectMessageData,
  OptionProps,
  TriggerFilter,
} from '@models/interfaces';
import { NotificationActions, UserActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class NotificationsHttpService extends CommonHttpService {
  // USER REQUESTS
  @StoredRequest(UserActions.clearAll)
  removeAllNotifications(): Promise<void> {
    return this.http.callApi.patch(
      `${this.apiUrl}/users/remove-all-notifications`,
    );
  }

  @StoredRequest(UserActions.clearCurrent)
  async removeCurrentNotification(notificationId: string): Promise<string> {
    await this.http.callApi.patch(
      `${this.apiUrl}/users/remove-current-notification`,
      null,
      { params: { notificationId } },
    );

    return notificationId;
  }

  // ADMIN REQUESTS
  @StoredRequest(NotificationActions.getPaceTaskList)
  getPaceTaskList(): Promise<OptionProps[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/notifications/pace-tasks`,
    );
  }

  @StoredRequest(NotificationActions.getUserList)
  getUsersList(): Promise<OptionProps[]> {
    return this.http.callApi.get(`${this.apiUrl}/admin/notifications/users`);
  }

  @StoredRequest(NotificationActions.getSchedules)
  getSchedules(filter?: TriggerFilter): Promise<ISchedule[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/notifications/schedules`,
      null,
      {
        params: filter,
      },
    );
  }

  @StoredRequest(NotificationActions.createSchedule)
  async createSchedule(schedule: Omit<ISchedule, '_id'>): Promise<ISchedule> {
    const response = await this.http.callApi.post(
      `${this.apiUrl}/admin/notifications/schedules`,
      schedule,
    );

    return {
      _id: response,
      ...schedule,
    };
  }

  @StoredRequest(NotificationActions.updateSchedule)
  async updateSchedule(
    scheduleId: string,
    schedule: Omit<ISchedule, '_id'>,
  ): Promise<ISchedule> {
    await this.http.callApi.patch(
      `${this.apiUrl}/admin/notifications/schedules/${scheduleId}`,
      schedule,
    );

    return { _id: scheduleId, ...schedule };
  }

  @StoredRequest(NotificationActions.deleteSchedule)
  async deleteSchedule(scheduleId: string): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/notifications/schedules/${scheduleId}`,
      null,
    );

    return scheduleId;
  }

  @StoredRequest(NotificationActions.getRules)
  getRules(filter?: TriggerFilter): Promise<IRule[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/notifications/rules`,
      null,
      {
        params: filter,
      },
    );
  }

  @StoredRequest(NotificationActions.createRule)
  async createRule(rule: Omit<IRule, '_id'>): Promise<IRule> {
    const response = await this.http.callApi.post(
      `${this.apiUrl}/admin/notifications/rules`,
      rule,
    );

    return {
      _id: response,
      ...rule,
    };
  }

  @StoredRequest(NotificationActions.updateRule)
  async updateRule(ruleId: string, rule: Omit<IRule, '_id'>): Promise<IRule> {
    await this.http.callApi.patch(
      `${this.apiUrl}/admin/notifications/rules/${ruleId}`,
      rule,
    );

    return { _id: ruleId, ...rule };
  }

  @StoredRequest(NotificationActions.deleteRule)
  async deleteRule(ruleId: string): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/notifications/rules/${ruleId}`,
      null,
    );

    return ruleId;
  }

  // Email Certification Date
  @StoredRequest(NotificationActions.getEmailCertificationDate)
  getEmailCertificationDate(): Promise<string> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/notifications/email-certification-date`,
    );
  }

  // Direct Messages
  @StoredRequest(NotificationActions.getDirectMessages)
  getDirectMessages(): Promise<DirectMessageData[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/notifications/direct-message`,
    );
  }

  @StoredRequest(NotificationActions.removeDirectMessage)
  async removeDirectMessages(_id: string): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/notifications/direct-message`,
      null,
      { params: { _id } },
    );

    return _id;
  }

  @StoredRequest(NotificationActions.multiRemoveDirectMessages)
  async multiRemoveDirectMessages(recordIds: string[]): Promise<string[]> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/notifications/direct-message/multi`,
      null,
      { params: { recordIds } },
    );

    return recordIds;
  }
}
