import React, { ReactNode } from 'react';
import { DeleteOutlined } from '@ant-design/icons/lib';
import { UserRole } from '@symfa-inc/providence-verizon-types';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import editIcon from 'assets/icons/edit-icon.svg';
import { mapFromBackendToUserRole } from '@core/utils/methods';
import { PaceTask, PaceTaskPermissions } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';

export const rolesToString = (roles: UserRole[]): string =>
  roles.map(mapFromBackendToUserRole).sort().join(', ');

export const stringSorter =
  (field: keyof PaceTask) =>
  (a: PaceTask, b: PaceTask): number => {
    const aField: string = (a[field] as string) ?? '';
    const bField: string = (b[field] as string) ?? '';

    return aField.localeCompare(bField);
  };

export const permissionSorter =
  (field: keyof Omit<PaceTaskPermissions, 'id' | 'isCanBeDeleted'>) =>
  (a: PaceTask, b: PaceTask): number =>
    rolesToString(a.permissions[field]).localeCompare(
      rolesToString(b.permissions[field]),
    );

export const getAllPaceTaskColumns = (
  editFunc: ColumnsFunc<PaceTask>,
  deletePaceTask: ColumnsFunc<PaceTask>,
): ColumnsType<PaceTask> => [
  {
    title: 'PACE Task Id',
    key: 'PACETaskID',
    dataIndex: 'paceTaskID',
    width: 200,
    sorter: stringSorter('paceTaskID'),
  },
  {
    title: 'Task Name',
    key: 'taskName',
    dataIndex: 'taskName',
    width: 200,
    sorter: stringSorter('taskName'),
  },
  {
    title: 'Screens',
    key: 'screens',
    dataIndex: 'screens',
    width: 250,
    sorter: stringSorter('screens'),
  },
  {
    title: 'Can Be Deleted',
    key: 'isCanBeDeleted',
    dataIndex: 'permissions',
    width: 85,
    sorter: (a: PaceTask, b: PaceTask): number =>
      Number(a.permissions.isCanBeDeleted) -
      Number(b.permissions.isCanBeDeleted),
    render: (permissions: PaceTaskPermissions): ReactNode =>
      permissions.isCanBeDeleted ? 'Yes' : 'No',
  },
  {
    title: 'Edit Forecasted Date',
    key: 'forecastedDateEditors',
    dataIndex: 'permissions',
    width: 250,
    sorter: permissionSorter('forecastedDateEditors'),
    render: (permissions: PaceTaskPermissions): ReactNode =>
      rolesToString(permissions.forecastedDateEditors),
  },
  {
    title: 'Edit Actual Date',
    key: 'actualDateEditors',
    dataIndex: 'permissions',
    width: 250,
    sorter: permissionSorter('actualDateEditors'),
    render: (permissions: PaceTaskPermissions): ReactNode =>
      rolesToString(permissions.actualDateEditors),
  },
  {
    title: 'Edit PACE Forecasted Date',
    key: 'editPaceForecastedDate',
    dataIndex: 'permissions',
    width: 250,
    sorter: permissionSorter('PACEForecastedDateEditors'),
    render: (permissions: PaceTaskPermissions): ReactNode =>
      rolesToString(permissions.PACEForecastedDateEditors),
  },
  {
    title: 'Edit PACE Actual Date',
    key: 'PACEActualDateEditors',
    dataIndex: 'permissions',
    width: 250,
    sorter: permissionSorter('PACEActualDateEditors'),
    render: (permissions: PaceTaskPermissions): ReactNode =>
      rolesToString(permissions.PACEActualDateEditors),
  },
  {
    key: 'action_buttons',
    fixed: 'right',
    width: 150,
    render: (paceTask: PaceTask): ReactNode => (
      <div className="controls">
        <Button
          className="controls__btn"
          onClick={(): void => editFunc(paceTask)}
        >
          <img src={editIcon} alt="Edit row" />
        </Button>
        {paceTask?.permissions.isCanBeDeleted && (
          <Button
            className="controls__btn"
            onClick={(): void => deletePaceTask(paceTask)}
          >
            <DeleteOutlined />
          </Button>
        )}
      </div>
    ),
  },
];
