import {
  IAssignee,
  ISelectorOption,
} from '@symfa-inc/providence-verizon-types';
import { findFromSelectorOptions } from '@core/utils/methods';

export const assigneesIdsToNamedAssignees = (
  assignees: IAssignee[],
  adminsList: ISelectorOption[],
): string[] =>
  assignees
    .map(
      ({ assignee }: IAssignee) =>
        findFromSelectorOptions(assignee, adminsList) || '',
    )
    .filter((v: string) => !!v);
