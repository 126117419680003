import {
  PaginatedResponse,
  PayloadAndState,
  ProjectFuzeID,
} from '@models/interfaces';

type IncomingType = { categories: ProjectFuzeID[] };
type ResultType<T extends IncomingType> = Record<
  string,
  PaginatedResponse<ReplaceType<T>>
>;

type ReplaceType<T extends IncomingType> = Omit<T, 'categories'> & {
  fuzeIDs: ProjectFuzeID[];
};

export const browseReducer =
  <P extends IncomingType, S>(key: string) =>
  ({ payload }: PayloadAndState<PaginatedResponse<P>, S>): ResultType<P> => ({
    [key]: {
      ...payload,
      items: payload.items.map(({ categories, ...item }) => ({
        ...item,
        fuzeIDs: categories,
      })),
    },
  });
