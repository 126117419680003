import React, { ReactNode } from 'react';
import {
  DateStringOrNull,
  MAIN_PACE_TASK_IDs,
} from '@symfa-inc/providence-verizon-types';
import { ColumnsType } from 'antd/lib/table';
import {
  getJurisdictionByIdOrUndefined,
  projectFuzeIDRender,
  projectPaceTaskDataRender,
  providenceAgentRender,
  readOnlyInputNumberRender,
} from '@core/utils/methods';
import { AdminDropdownValuesData, SiteBrowse } from '@models/interfaces';
import { Browse } from '@models/types';
import { ReadOnlyDate } from '@shared/modules';

export const PERMITTING_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'Alternate Site Info',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.alternateSiteInfo,
  },
  {
    title: 'City',
    dataIndex: 'site',
    key: 'city',
    sorter: true,
    width: 100,
    render: (site: SiteBrowse): ReactNode => site?.city,
  },
  {
    title: 'County',
    dataIndex: 'site',
    key: 'county',
    sorter: true,
    width: 90,
    render: (site: SiteBrowse): ReactNode => site?.county?.name,
  },
  {
    title: 'PS Location Code',
    dataIndex: 'site',
    key: 'PSLocationCode',
    width: 190,
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.PSLocationCode),
  },
  {
    title: 'Jurisdiction',
    dataIndex: 'site',
    key: 'jurisdiction',
    sorter: true,
    width: 165,
    render: (site: SiteBrowse): ReactNode =>
      getJurisdictionByIdOrUndefined(site?.jurisdiction?.id)?.jurisdictionName,
  },
  {
    title: 'Contract Number',
    width: 150,
    key: 'contractNumber',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.contractNumber,
  },
  {
    title: 'PS Project Number',
    dataIndex: 'fuzeIDs',
    key: 'PSProjectNumber',
    width: 150,
    sorter: true,
    render: projectFuzeIDRender({ field: 'PSProjectNumber' }),
  },
  {
    title: 'Fuze ID',
    dataIndex: 'fuzeIDs',
    key: 'fuzeId',
    width: 150,
    sorter: true,
    render: projectFuzeIDRender({ field: 'fuzeID' }),
  },
  {
    title: 'Project Type',
    key: 'projectType',
    dataIndex: 'projectType',
    sorter: true,
    width: 165,
    render: (projectType?: AdminDropdownValuesData): ReactNode =>
      projectType?.value,
  },
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    width: 180,
    sorter: true,
    dataIndex: 'providenceAgent',
    render: providenceAgentRender,
  },
  {
    title: 'BP Expiration Date',
    dataIndex: 'BPExpiration',
    key: 'BPExpiration',
    width: 140,
    sorter: true,
    render: (BPExpiration: DateStringOrNull): ReactNode => (
      <ReadOnlyDate date={BPExpiration} />
    ),
  },
  {
    title: 'RE005 Zoning Submitted - Actual',
    key: 'RE005ZoningSubmittedActual',
    dataIndex: 'projectPaceTaskData',
    sorter: true,
    width: 260,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.zoningSubmitted,
      field: 'actualDate',
    }),
  },
  {
    title: 'RE005 Zoning Submitted - Forecasted',
    key: 'RE005ZoningSubmittedForecasted',
    dataIndex: 'projectPaceTaskData',
    sorter: true,
    width: 290,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.zoningSubmitted,
      field: 'forecastedDate',
    }),
  },
  {
    title: 'RE020 Site Acq Complete - Forecasted',
    key: 'RE020SiteAcqCompleteForecasted',
    dataIndex: 'projectPaceTaskData',
    sorter: true,
    width: 290,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.siteAcqComplete,
      field: 'forecastedDate',
    }),
  },
  {
    title: 'Site Name',
    dataIndex: 'siteName',
    key: 'siteName',
    sorter: true,
    width: 100,
    render: (site: SiteBrowse): ReactNode => site?.siteName,
  },
  {
    title: 'State',
    dataIndex: 'site',
    key: 'state',
    sorter: true,
    width: 90,
    render: (site: SiteBrowse): ReactNode => site?.state?.name,
  },
  {
    title: 'Street Address',
    dataIndex: 'site',
    key: 'streetAddress',
    width: 170,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.streetAddress,
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.submarket?.value,
  },
  {
    title: 'Tower Owner',
    dataIndex: 'site',
    key: 'towerOwner',
    width: 140,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.towerOwner?.value,
  },
  {
    title: 'Zip',
    dataIndex: 'site',
    key: 'zip',
    sorter: true,
    width: 90,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.zip),
  },
  {
    title: 'MDG Location Code',
    key: 'MDGLocationCode',
    dataIndex: 'site',
    width: 180,
    render: (site: SiteBrowse): ReactNode => site?.MDGLocationCode,
  },
];
