import { DropDownSelectorValues } from '@symfa-inc/providence-verizon-types';
import { ColumnsType } from 'antd/lib/table';
import { getDDVById } from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { JurisdictionDetailsPermittingType } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';

export const getPermitTypeTableColumns = (
  editFunc: ColumnsFunc<JurisdictionDetailsPermittingType>,
  deleteFunc: ColumnsFunc<JurisdictionDetailsPermittingType>,
  isEditing: boolean = false,
): ColumnsType => [
  {
    title: 'Permit Type',
    dataIndex: 'permitType',
    key: 'permitType',
    render: (value: string): string =>
      getDDVById(DropDownSelectorValues.PermitType, value).value,
  },
  {
    title: 'Expected Permit Lead Time',
    dataIndex: 'expectedPermitLeadTime',
    key: 'expectedPermitLeadTime',
  },
  {
    title: 'Permit and Application Fees',
    dataIndex: 'permitAndApplicationFee',
    key: 'permitAndApplicationFee',
  },
  {
    title: 'Required Info for Obtaining Permit',
    dataIndex: 'requiredInfoForObtainingPermit',
    key: 'requiredInfoForObtainingPermit',
  },
  actionButtons({ editFunc, deleteFunc, width: 130, isEditing }),
];
