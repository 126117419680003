import {
  IRequiresAttention,
  ISelectorOption,
} from '@symfa-inc/providence-verizon-types';
import { mainDateFormat } from '@core/utils/methods';
import { DashboardItem, DashboardProjectData } from '@models/interfaces';
import { assigneesIdsToNamedAssignees } from '../../../helpers';

export const requiresAttentionSearcher = (
  data: DashboardItem<IRequiresAttention>[],
  searchValue: string,
  adminsList: ISelectorOption[],
): DashboardItem<IRequiresAttention>[] => {
  const regExp = new RegExp(searchValue, 'gi');

  return !searchValue
    ? data
    : data.filter(
        ({
          notificationData: {
            notificationType,
            pastDueDate,
            date,
            dueDate,
            taskNote,
            actionField,
            message,
            assignees,
          },
          projectData: {
            projectBundleID,
            projectType,
            siteName,
            PSProjectNumbers,
            fuzeIDs,
          },
        }: DashboardItem<IRequiresAttention>) =>
          [
            notificationType,
            pastDueDate.toString(),
            mainDateFormat(date),
            mainDateFormat(dueDate),
            taskNote,
            actionField,
            message,
            ...assigneesIdsToNamedAssignees(assignees, adminsList),
            projectBundleID.toString(),
            siteName,
            projectType,
            ...fuzeIDs.map(
              ({ value }: DashboardProjectData['fuzeIDs'][number]) => value,
            ),
            ...PSProjectNumbers.map(
              ({ value }: DashboardProjectData['PSProjectNumbers'][number]) =>
                value,
            ),
          ].some(
            (item: string | null | undefined) => item && regExp.test(item),
          ),
      );
};
