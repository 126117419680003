import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { ProjectHttpService, SiteHttpService } from '@core/services/http';
import { getPSLocationCodeParamFromUrl } from '@core/utils/methods';
import { OptionProps } from '@models/interfaces';
import store from '@store/index';

export class ProjectCreateResolver implements Resolver {
  private static get _PSLocationCodeOptions(): OptionProps[] {
    return store.getState().site.PSLocationCodeOptions;
  }

  private static get _MDGLocationCodeOptions(): OptionProps[] {
    return store.getState().site.MDGLocationCodeOptions;
  }

  private static get _siteId(): Undefinable<string> {
    let siteId;

    try {
      const [locationCodeFromUrl] = getPSLocationCodeParamFromUrl();
      const options =
        locationCodeFromUrl.length === 6
          ? ProjectCreateResolver._PSLocationCodeOptions
          : ProjectCreateResolver._MDGLocationCodeOptions;

      siteId = options.find(
        ({ viewValue }) => viewValue.toString() === locationCodeFromUrl,
      )?.value;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return siteId as string;
  }

  async resolve(): Promise<void> {
    const siteHttpService = HttpService.getHttpRequests(SiteHttpService);

    await Promise.all([
      siteHttpService.getPSLocationCodeOptions(),
      siteHttpService.getMDGLocationCodeOptions(),
    ]);

    const siteId = ProjectCreateResolver._siteId;

    if (siteId) {
      await HttpService.getHttpRequests(ProjectHttpService).getProvidenceAgent(
        siteId,
      );
    }
  }
}
