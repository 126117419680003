import { ReactNode } from 'react';
import { MAIN_PACE_TASK_IDs } from '@symfa-inc/providence-verizon-types';
import { ColumnsType } from 'antd/lib/table/interface';
import {
  getJurisdictionByIdOrUndefined,
  projectFuzeIDRender,
  projectPaceTaskDataRender,
  providenceAgentRender,
  readOnlyInputNumberRender,
} from '@core/utils/methods';
import { IdAndValue, SiteBrowse } from '@models/interfaces';
import { Browse } from '@models/types';

export const SCOPING_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'Alternate Site Info',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.alternateSiteInfo,
  },
  {
    title: 'Civil Vendor',
    key: 'civilVendor',
    dataIndex: 'civilVendor',
    sorter: true,
    width: 130,
    render: (civilVendor: IdAndValue): ReactNode => civilVendor?.value,
  },
  {
    title: 'PS Location Code',
    dataIndex: 'site',
    key: 'PSLocationCode',
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.PSLocationCode),
    sorter: true,
    width: 190,
  },
  {
    title: 'Jurisdiction',
    key: 'jurisdiction',
    dataIndex: 'site',
    sorter: true,
    width: 180,
    render: (site: SiteBrowse): ReactNode =>
      getJurisdictionByIdOrUndefined(site?.jurisdiction?.id)?.jurisdictionName,
  },
  {
    title: 'Contract Number',
    width: 150,
    key: 'contractNumber',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.contractNumber,
  },
  {
    title: 'PS Project Number',
    dataIndex: 'fuzeIDs',
    key: 'PSProjectNumber',
    render: projectFuzeIDRender({
      field: 'PSProjectNumber',
    }),
    sorter: true,
    width: 210,
  },
  {
    title: 'Fuze ID',
    dataIndex: 'fuzeIDs',
    key: 'fuzeId',
    render: projectFuzeIDRender({
      field: 'fuzeID',
    }),
    sorter: true,
    width: 200,
  },
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    width: 180,
    sorter: true,
    dataIndex: 'providenceAgent',
    render: providenceAgentRender,
  },
  {
    title: 'Scheduled Scoping',
    key: 'scheduledScoping',
    dataIndex: 'projectPaceTaskData',
    width: 180,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.scopingComplete,
      field: 'forecastedDate',
    }),
  },
  {
    title: 'Site Name',
    dataIndex: 'site Name',
    key: 'siteName',
    defaultSortOrder: 'ascend',
    sorter: true,
    width: 120,
    render: (site: SiteBrowse): ReactNode => site?.siteName,
  },
  {
    title: 'State',
    key: 'state',
    dataIndex: 'site',
    sorter: true,
    width: 85,
    render: (site: SiteBrowse): ReactNode => site?.state?.name,
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.submarket?.value,
  },
  {
    title: 'Tower Owner',
    key: 'towerOwner',
    dataIndex: 'site',
    sorter: true,
    width: 140,
    render: (site: SiteBrowse): ReactNode => site?.towerOwner?.value,
  },
  {
    title: 'MDG Location Code',
    key: 'MDGLocationCode',
    dataIndex: 'site',
    width: 180,
    render: (site: SiteBrowse): ReactNode => site?.MDGLocationCode,
  },
];
