import {
  ISelectorOption,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';
import { findFromSelectorOptions, mainDateFormat } from '@core/utils/methods';
import { DirectMessageData } from '@models/interfaces';

export const directMessagesSearcher = (
  data: DirectMessageData[],
  usersList: ISelectorOption<string>[],
  searchValue: string,
): DirectMessageData[] => {
  const regExp = new RegExp(searchValue, 'gi');

  return !searchValue
    ? data
    : data.filter(
        ({
          directMessageData: { senderId, message, createdAt, areaKey },
          projectData: { projectBundleID, siteName },
        }: DirectMessageData) =>
          [
            siteName,
            projectBundleID.toString(),
            message,
            mainDateFormat(createdAt),
            findFromSelectorOptions(senderId, usersList),
            areaKey,
          ].some((item: Undefinable<string>) => item && regExp.test(item)),
      );
};
