import nextId from 'react-id-generator';
import { arraySort } from '../../core/utils/methods';
import { createReducer, on } from '../../core/utils/store';
import {
  PayloadAndState,
  ProcessEnvMap as KeyString,
  ProjectFuzeID,
} from '../../models/interfaces';
import { ProjectFuzeIDsActions } from '../actions/project-fuze-ids.actions';

export interface ProjectFuzeIDsState {
  projectFuzeIDsTableVisible: boolean;
  isCategoriesTableEditing: boolean;
  originalProjectFuzeIDs: ProjectFuzeID[];
  currentProjectFuzeIDs: ProjectFuzeID[];
  total?: number;
  isFetching: boolean;
  errors: boolean;
}

const initialState: ProjectFuzeIDsState = {
  projectFuzeIDsTableVisible: false,
  isCategoriesTableEditing: false,
  originalProjectFuzeIDs: [],
  currentProjectFuzeIDs: [],
  total: undefined,
  isFetching: false,
  errors: false,
};
const generateId = () => nextId('PCCategory');

export const reducer = createReducer(
  initialState,
  // set/clear
  on(
    ProjectFuzeIDsActions.setProjectFuzeIDsAction,
    ({ payload }: PayloadAndState<ProjectFuzeID[], ProjectFuzeIDsState>) => {
      const fuzeIDs = payload.map((fuzeID: ProjectFuzeID) => ({
        ...fuzeID,
        generatedId: generateId(),
      }));

      return {
        currentProjectFuzeIDs: fuzeIDs,
        originalProjectFuzeIDs: fuzeIDs,
      };
    },
  ),
  // add
  on(
    ProjectFuzeIDsActions.addProjectFuzeIDsItemAction,
    ({
      payload,
      state: { currentProjectFuzeIDs },
    }: PayloadAndState<ProjectFuzeID, ProjectFuzeIDsState>) => ({
      currentProjectFuzeIDs: arraySort<ProjectFuzeID>(
        [...currentProjectFuzeIDs, { ...payload, generatedId: generateId() }],
        'ASC',
      ),
    }),
  ),
  // update
  on(
    ProjectFuzeIDsActions.updateProjectFuzeIDsItemAction,
    ({
      payload,
      state: { currentProjectFuzeIDs },
    }: PayloadAndState<ProjectFuzeID, ProjectFuzeIDsState>) => ({
      currentProjectFuzeIDs: arraySort<ProjectFuzeID>(
        [
          ...currentProjectFuzeIDs.filter(
            (projectFuzeID: ProjectFuzeID) =>
              projectFuzeID.generatedId !== payload.generatedId,
          ),
          payload,
        ],
        'ASC',
      ),
    }),
  ),
  // remove
  on(
    ProjectFuzeIDsActions.deleteProjectFuzeIDsItemAction,
    ({
      payload,
      state: { currentProjectFuzeIDs },
    }: PayloadAndState<KeyString, ProjectFuzeIDsState>) => ({
      currentProjectFuzeIDs: currentProjectFuzeIDs.filter(
        (projectFuzeID: ProjectFuzeID) =>
          projectFuzeID.generatedId !== payload.generatedId,
      ),
    }),
  ),
  // toggle table visible
  on(
    ProjectFuzeIDsActions.toggleProjectFuzeIDsTableAction,
    ({
      state: { projectFuzeIDsTableVisible },
    }: PayloadAndState<void, ProjectFuzeIDsState>) => ({
      projectFuzeIDsTableVisible: !projectFuzeIDsTableVisible,
    }),
  ),
  // set table visible
  on(
    ProjectFuzeIDsActions.setProjectFuzeIDsTableAction,
    ({
      payload: projectFuzeIDsTableVisible,
    }: PayloadAndState<boolean, ProjectFuzeIDsState>) => ({
      projectFuzeIDsTableVisible,
    }),
  ),
  // set table visible
  on(
    ProjectFuzeIDsActions.setCategoriesTableEditing,
    ({
      payload: isCategoriesTableEditing,
    }: PayloadAndState<boolean, ProjectFuzeIDsState>) => ({
      isCategoriesTableEditing,
    }),
  ),
);
