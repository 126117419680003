import { ProjectType, Undefinable } from '@symfa-inc/providence-verizon-types';
import { FormInstance } from 'antd/es/form';
import { ObjectDifferencesService } from '@core/services';
import { momentizeObjectDates } from '@core/utils/methods';
import { SITE_AUDIT_MOMENTIZE_FIELDS } from '@models/constants';
import { SiteBrowse } from '@models/interfaces';
import { SiteInformationType } from '@models/types';
import { getModAdditionalInfo } from './get-mod-additional-info.constant';

export const onSiteInfoFormSubmit = (
  form: FormInstance,
  data: SiteInformationType,
  projectType: ProjectType,
  unknownSwitchState: Undefinable<boolean>,
) => {
  const { equipmentType, towerType, ...siteAuditFields } =
    form.getFieldsValue() as Omit<SiteInformationType, 'site'> &
      Pick<SiteBrowse, 'equipmentType' | 'towerType'>;

  const {
    site: { equipmentType: prevEquipmentType, towerType: prevTowerType },
    ...prevData
  } = data;

  return {
    ...(ObjectDifferencesService.getObjectsDiff(prevData, {
      ...momentizeObjectDates(
        siteAuditFields,
        SITE_AUDIT_MOMENTIZE_FIELDS,
        true,
      ),
      ...getModAdditionalInfo(siteAuditFields, projectType, unknownSwitchState),
    }) as Omit<SiteInformationType, 'site'>),
    site: {
      id: data.site.id,
      ...ObjectDifferencesService.getObjectsDiff(
        { equipmentType: prevEquipmentType, towerType: prevTowerType },
        {
          equipmentType,
          towerType,
        },
      ),
    },
  };
};
