import React, { Dispatch, FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from '@symfa-inc/providence-verizon-types';
import { SelectValue } from 'antd/lib/select';
import { USER_ROLE_OPTIONS } from '@models/constants';
import { Autocomplete } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { USER_STATUS_SELECT_OPTIONS } from './models/constants';

import './styles.scss';

type UserManagementFiltersProps = {
  selectValueArray: string[];
  setSelectValueArray: Dispatch<UserManagementFiltersProps['selectValueArray']>;
};

export const UserManagementFilters: FC<UserManagementFiltersProps> = ({
  selectValueArray,
  setSelectValueArray,
}: PropsWithChildren<UserManagementFiltersProps>) => {
  const projectTypes = useSelector(DDVSelectors.getProjectTypes);

  const hasAdminOrEMRole = [UserRole.Admin, UserRole.EngineeringManager].some(
    (role: UserRole) => selectValueArray.includes(role),
  );

  return (
    <div className="user-management-filters">
      <Autocomplete
        label="Roles"
        id="roles"
        options={USER_ROLE_OPTIONS}
        elementProps={{
          mode: 'multiple',
          onChange: (value: SelectValue): void =>
            setSelectValueArray(value as typeof selectValueArray),
        }}
      />
      {hasAdminOrEMRole && (
        <Autocomplete
          id="responsibleForProjectType"
          label="Responsible For Project Type"
          options={projectTypes}
          elementProps={{
            allowClear: true,
          }}
        />
      )}
      <Autocomplete
        id="isActive"
        label="Status"
        options={USER_STATUS_SELECT_OPTIONS}
        elementProps={{
          allowClear: true,
        }}
      />
    </div>
  );
};
