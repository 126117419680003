import { LeasingBrowseFilter } from '@models/interfaces';

export const LEASING_DEFAULT_BROWSE_CONFIGURE_FILTER: LeasingBrowseFilter = {
  projectTypeId: undefined,
  assignedDate: undefined,
  countyId: undefined,
  jurisdictionId: undefined,
  progressStatusId: undefined,
  projectStatusId: undefined,
  providenceAgentId: undefined,
  stateId: undefined,
  towerOwnerId: undefined,
};
