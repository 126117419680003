import {
  ALL_RULE_FIELDS_OPTIONS,
  ISelectorOption,
  RULE_FIELDS_AS_MAP,
  RuleArea,
  RuleFieldValueType,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';

interface RuleModalState {
  triggerFieldArea: Undefinable<RuleArea>;
  triggerFieldType: Undefinable<RuleFieldValueType>;
  triggerFieldOptions: ISelectorOption[];
  completingFieldArea: Undefinable<RuleArea>;
  completingFieldType: Undefinable<RuleFieldValueType>;
  completingFieldOptions: ISelectorOption[];
}

export enum RuleModalActions {
  ChangeTriggerFieldArea = 'CHANGE_TRIGGER_FIELD_AREA',
  ChangeTriggerFieldType = 'CHANGE_TRIGGER_FIELD_TYPE',
  ChangeCompletingFieldArea = 'CHANGE_COMPLETING_FIELD_AREA',
  ChangeCompletingFieldType = 'CHANGE_COMPLETING_FIELD_TYPE',
  Reset = 'RESET',
}

export type ChaneAreaActionPayload =
  | RuleModalState['triggerFieldArea']
  | RuleModalState['completingFieldArea'];

interface ChangeAreaAction {
  type:
    | RuleModalActions.ChangeTriggerFieldArea
    | RuleModalActions.ChangeCompletingFieldArea;
  payload: ChaneAreaActionPayload;
}

export type ChaneTypeActionPayload =
  | RuleModalState['triggerFieldType']
  | RuleModalState['completingFieldType'];

interface ChangeTypeAction {
  type:
    | RuleModalActions.ChangeTriggerFieldType
    | RuleModalActions.ChangeCompletingFieldType;
  payload: ChaneTypeActionPayload;
}

interface ResetAction {
  type: RuleModalActions.Reset;
  payload?: never;
}

type FieldAction = ResetAction | ChangeAreaAction | ChangeTypeAction;

export const INITIAL_RULE_MODAL_STATE: RuleModalState = {
  triggerFieldArea: undefined,
  triggerFieldType: undefined,
  triggerFieldOptions: ALL_RULE_FIELDS_OPTIONS,
  completingFieldArea: undefined,
  completingFieldType: undefined,
  completingFieldOptions: ALL_RULE_FIELDS_OPTIONS,
};

export const ruleModalReducer = (
  state: RuleModalState,
  action: FieldAction,
): RuleModalState => {
  const { type, payload } = action;

  switch (type) {
    case RuleModalActions.ChangeTriggerFieldArea:
      return {
        ...state,
        triggerFieldArea: payload,
        triggerFieldOptions:
          RULE_FIELDS_AS_MAP.get(`${payload}_${state.triggerFieldType}`) ?? [],
      };
    case RuleModalActions.ChangeTriggerFieldType:
      return {
        ...state,
        triggerFieldType: payload,
        triggerFieldOptions:
          RULE_FIELDS_AS_MAP.get(`${state.triggerFieldArea}_${payload}`) ?? [],
      };
    case RuleModalActions.ChangeCompletingFieldArea:
      return {
        ...state,
        completingFieldArea: payload,
        completingFieldOptions:
          RULE_FIELDS_AS_MAP.get(`${payload}_${state.completingFieldType}`) ??
          [],
      };
    case RuleModalActions.ChangeCompletingFieldType:
      return {
        ...state,
        completingFieldType: payload,
        completingFieldOptions:
          RULE_FIELDS_AS_MAP.get(`${state.completingFieldArea}_${payload}`) ??
          [],
      };
    case RuleModalActions.Reset:
      return INITIAL_RULE_MODAL_STATE;
    default:
      return state;
  }
};
