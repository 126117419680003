import { CustomRow } from '../enums';
import { ProcessEnvMap } from '../interfaces';

export const projectFuzeIDModifier = {
  Primary: CustomRow.Primary,
  Secondary: CustomRow.Secondary,
  Other: CustomRow.White,
};

export const leasingEquipmentModifier: ProcessEnvMap = {
  Red: CustomRow.Red,
  Orange: CustomRow.Orange,
  Secondary: CustomRow.Secondary,
};
