import React, { FC, PropsWithChildren } from 'react';
import nextId from 'react-id-generator';
import { CloseOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, Form } from 'antd';
import classNames from 'classnames';
import { isValidForm } from '@core/utils/methods';
import { Modal } from '@shared/modules/ui-kit/modal';
import { IModalTitle, IMultipleAddModal } from './models/interfaces';

import './styles.scss';

const ModalTitle = ({ title, subTitle }: PropsWithChildren<IModalTitle>) => (
  <div className="prov-multiple-add-modal__modal-title">
    <span className="title">{title}</span>
    {subTitle ? <span className="subtitle">({subTitle})</span> : ''}
  </div>
);

export const MultipleAddModal: FC<IMultipleAddModal> = ({
  values,
  setValues,
  okText = 'Save',
  cancelText = 'Cancel',
  children,
  className,
  inputRender,
  title,
  subTitle,
  visible,
  formProps: { labelCol = { span: 6 }, ...formProps } = {},
  ...props
}: PropsWithChildren<IMultipleAddModal>) => {
  const addFields = async () => {
    if (formProps?.form && (await isValidForm(formProps.form))) {
      setValues([...values, nextId('multiple')]);
    }
  };

  const removeFields = (fieldId: string) => {
    setValues(values.filter((id: string) => id !== fieldId));
  };

  return (
    <Modal
      title={<ModalTitle title={title} subTitle={subTitle} />}
      width={500}
      forceRender
      okText={okText}
      visible={visible}
      cancelText={cancelText}
      className={classNames(className, 'prov-multiple-add-modal')}
      {...props}
    >
      <Form labelCol={labelCol} {...formProps}>
        {values.map((id: string, index: number) => (
          <div className="dropdown-value" key={id}>
            {inputRender(id)}
            <div className="controls">
              {index === values.length - 1 ? (
                <Button className="controls__btn" onClick={addFields}>
                  <PlusCircleFilled className="icon" />
                </Button>
              ) : (
                ''
              )}
              {values.length > 1 ? (
                <Button
                  className="controls__btn remove-field"
                  onClick={() => removeFields(id)}
                >
                  <CloseOutlined className="icon" />
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        ))}
      </Form>
    </Modal>
  );
};
