import { actionCreatorFactory } from '@core/utils/factories';
import {
  AccountingDetails,
  JurisdictionDetails,
  ProjectDetailsResponse,
  ProjectManagementDetails,
  SiteBrowse,
  SOFToolDetails,
} from '../../models/interfaces';
import { ProjectDetailsWithoutFuzeIDs } from '../../models/types';

const action = actionCreatorFactory('BREADCRUMBS');

export namespace BreadcrumbsActions {
  export const site = action.createAsyncAction<SiteBrowse>('SITE');
  export const project =
    action.createAsyncAction<ProjectDetailsWithoutFuzeIDs>('PROJECT');

  export namespace Engineering {
    export const engineering =
      action.createAsyncAction<Omit<ProjectDetailsWithoutFuzeIDs, 'paceTasks'>>(
        'ENGINEERING',
      );
    export const SOFTool = action.createAsyncAction<SOFToolDetails>('SOF_TOOL');
    export const scoping =
      action.createAsyncAction<Omit<ProjectDetailsWithoutFuzeIDs, 'RAD'>>(
        'SCOPING',
      );
    export const siteAudit =
      action.createAsyncAction<ProjectDetailsWithoutFuzeIDs>('SITE_AUDIT');
  }

  export const leasing =
    action.createAsyncAction<Omit<ProjectDetailsWithoutFuzeIDs, 'paceTasks'>>(
      'LEASING',
    );

  export namespace Permitting {
    export const permitting =
      action.createAsyncAction<ProjectDetailsResponse>('PERMITTING');
    export const jurisdiction =
      action.createAsyncAction<JurisdictionDetails>('JURISDICTION');
  }

  export const projectManagement =
    action.createAsyncAction<
      Omit<ProjectManagementDetails, 'fuzeIDs' | 'paceTasks'>
    >('PROJECT_MANAGEMENT');
  export const accounting =
    action.createAsyncAction<AccountingDetails>('ACCOUNTING');
}
