import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { ProjectState } from '../reducers/project.reducer';
import { ProjectFuzeIDsState } from '../reducers/project-fuze-ids.reducer';

const projectState = (state: AppState): ProjectState => state.project;
const projectFuzeIDsState = (state: AppState): ProjectFuzeIDsState =>
  state.projectFuzeIDs;

export namespace ProjectSelectors {
  export const getProjectBrowseInfo = createSelector(
    [projectState],
    (state: ProjectState) => state.projectBrowse,
  );

  export const getProjectDetailsInfo = createSelector(
    [projectState],
    (state: ProjectState) => state.projectSite,
  );

  export const getProjectCreateProvidenceAgent = createSelector(
    [projectState],
    (state: ProjectState) => state?.providenceAgentIdForCreateProject,
  );

  export const getProjectSummaryData = createSelector(
    [projectState, projectFuzeIDsState],
    (
      { currentProject, projectPaceTasks, projectSite, notes }: ProjectState,
      {
        currentProjectFuzeIDs,
        originalProjectFuzeIDs,
        projectFuzeIDsTableVisible,
      }: ProjectFuzeIDsState,
    ) => ({
      currentProject,
      projectPaceTasks,
      projectSite,
      notes,
      currentProjectFuzeIDs,
      originalProjectFuzeIDs,
      projectFuzeIDsTableVisible,
    }),
  );

  export const getProvidenceAgents = createSelector(
    [projectState],
    (state: ProjectState) => state.providenceAgents,
  );

  export const getProjectType = createSelector(
    [projectState],
    (state: ProjectState) => state.currentProjectType.value,
  );

  export const getProjectId = createSelector(
    [projectState],
    (state: ProjectState) => state.currentProject.id,
  );

  export const getProvidenceAgent = createSelector(
    [projectState],
    (state: ProjectState) => state.currentProvidenceAgent,
  );

  export const getProjectStatus = createSelector(
    [projectState],
    (state: ProjectState) => state.hold.projectStatus,
  );

  export const getHoldOptions = createSelector(
    [projectState],
    (state: ProjectState) => state.hold,
  );

  export const getProjectFuzeIDs = createSelector(
    [projectFuzeIDsState],
    ({
      currentProjectFuzeIDs,
      projectFuzeIDsTableVisible,
    }: ProjectFuzeIDsState) => ({
      currentProjectFuzeIDs,
      projectFuzeIDsTableVisible,
    }),
  );
}
