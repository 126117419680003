import React, { ReactNode } from 'react';
import { MAIN_PACE_TASK_IDs } from '@symfa-inc/providence-verizon-types';
import {
  projectFuzeIDRender,
  projectPaceTaskDataRender,
} from '@core/utils/methods';
import { AdminDropdownValuesData } from '@models/interfaces';
import { ProjectStatus } from '@shared/modules';

export const ASSOCIATED_PROJECTS_COLUMNS = [
  {
    title: 'Fuze ID',
    key: 'fuzeId',
    dataIndex: 'fuzeIDs',
    sorter: true,
    width: 195,
    render: projectFuzeIDRender({
      field: 'fuzeID',
    }),
  },
  {
    title: 'PS Project Number',
    key: 'PSProjectNumber',
    dataIndex: 'fuzeIDs',
    sorter: true,
    width: 210,
    render: projectFuzeIDRender({
      field: 'PSProjectNumber',
    }),
  },
  {
    title: 'Project Type',
    key: 'projectType',
    dataIndex: 'projectType',
    sorter: true,
    render: (projectType: AdminDropdownValuesData): ReactNode =>
      projectType?.value,
  },
  {
    title: 'Date Assigned',
    key: 'projectAssignedDate',
    dataIndex: 'fuzeIDs',
    sorter: true,
    render: projectFuzeIDRender({
      field: 'projectAssignedDate',
      dateField: true,
    }),
  },
  {
    title: 'Project Status',
    key: 'projectStatus',
    dataIndex: 'projectStatus',
    sorter: true,
    render: (status: { value: string }): ReactNode => (
      <ProjectStatus status={status?.value} />
    ),
  },
  {
    title: 'Site Acq Comp RE020',
    key: 'siteAcqCompleteRE020',
    dataIndex: 'projectPaceTaskData',
    sorter: true,
    width: 200,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.siteAcqComplete,
      field: 'actualDate',
    }),
  },
];
