import { ReactNode } from 'react';
import { ColumnsType } from 'antd/es/table';
import { homeProjectFuzeIDRender } from '@core/utils/methods/renders';
import { tableSorter } from '@core/utils/methods/table-sorter';
import { DashboardProjectData } from '@models/interfaces';

export const PROJECT_DATA_COLUMNS: ColumnsType = [
  {
    title: 'Site Name',
    dataIndex: 'projectData',
    key: 'siteName',
    sorter: tableSorter(['projectData', 'siteName']),
    render: ({ siteName }: DashboardProjectData): ReactNode => siteName,
  },
  {
    title: 'Project Bundle ID',
    dataIndex: 'projectData',
    key: 'projectBundleID',
    sorter: tableSorter(['projectData', 'projectBundleID']),
    render: ({ projectBundleID }: DashboardProjectData): ReactNode =>
      projectBundleID,
  },
  {
    title: 'Project Type',
    dataIndex: 'projectData',
    key: 'projectType',
    sorter: tableSorter(['projectData', 'projectType']),
    render: ({ projectType }: DashboardProjectData): ReactNode => projectType,
  },
  {
    title: 'Fuze ID',
    dataIndex: 'projectData',
    key: 'fuzeID',
    render: ({ fuzeIDs }: DashboardProjectData): ReactNode =>
      homeProjectFuzeIDRender(fuzeIDs),
  },
  {
    title: 'PS Project Numbers',
    dataIndex: 'projectData',
    key: 'PSProjectNumber',
    render: ({ PSProjectNumbers }: DashboardProjectData): ReactNode =>
      homeProjectFuzeIDRender(PSProjectNumbers),
  },
];
