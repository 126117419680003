import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { SiteBrowse } from '@models/interfaces';
import { Details } from '@shared/modules/details';
import { PermittingSelectors } from '@store/selectors';
import { DETAILS_HEADER_OPTIONS } from '../models';

export const PermittingDetails: FC<RouteComponentProps> = (
  props: PropsWithChildren<RouteComponentProps>,
) => {
  const siteData = useSelector(
    PermittingSelectors.getPermittingDetailsHeaderData,
  );

  return (
    <Details
      {...props}
      site={siteData as unknown as SiteBrowse}
      siteOptions={DETAILS_HEADER_OPTIONS}
      showTowerOwner
      destroyInactiveTabPane
      fuzeID
    />
  );
};
