import { ReactText } from 'react';
import nextId from 'react-id-generator';
import { IMap } from '@symfa-inc/providence-verizon-types';
import { generatedIdMapper, transformFields } from '../../core/utils/methods';
import { createReducer, on } from '../../core/utils/store';
import {
  LEASING_INFO_TRANSFORM_FIELDS,
  STRUCTURAL_INFO_TRANSFORM_FIELDS,
} from '../../models/constants/transform-fields';
import {
  AssociatedProject,
  EquipmentModal,
  IdAndValue,
  LeasingBrowseItem,
  LeasingBrowseResponseItem,
  LeasingDetails,
  LeasingEquipmentResp,
  LeasingEquipmentUpdateData,
  LeasingInformation,
  LeasingWorkflowUpdateData,
  OptionProps,
  PaginatedResponse,
  PayloadAndState,
  ProjectDetails,
  ScopingDetailsResponse,
  SiteResponse,
  StructuralInformation,
} from '../../models/interfaces';
import {
  CuttedProjectDetails,
  LeasingEquipmentData,
  LeasingLeaseAuditUpdateData,
} from '../../models/types';
import { ScopingActions } from '../actions/engineering/scoping.actions';
import { LeasingActions, LeasingActionType } from '../actions/leasing.actions';
import {
  getEquipmentTotalsFromScoping,
  getProcessedEquipments,
  sortEquipments,
} from './helpers';

type StoreLeasingInformation = Partial<LeasingInformation> & {
  leasingNote: string;
};

export interface LeasingState {
  leasingBrowseData: PaginatedResponse<LeasingBrowseItem>;
  leasingDetailsData: LeasingDetails;
  //
  associatedProjects: AssociatedProject[];
  currentProject: CuttedProjectDetails;
  leasingSite: SiteResponse;
  leasingInformation: StoreLeasingInformation;
  leasingAgents: OptionProps[];
  leasingEquipmentData: LeasingEquipmentData;
  equipmentTypeNames: string[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: LeasingState = {
  leasingBrowseData: {
    items: [],
    total: 0,
  },
  leasingDetailsData: {} as LeasingDetails,
  //
  associatedProjects: [],
  currentProject: {} as Omit<ProjectDetails, 'fuzeIDs' | 'paceTasks' | 'site'>,
  leasingSite: {} as SiteResponse,
  leasingInformation: {} as StoreLeasingInformation,
  leasingAgents: [],
  leasingEquipmentData: {
    priorTableData: [],
    curPriorTableData: [],
    scopingTableData: [],
    finalTableData: [],
    curFinalTableData: [],
    scopingNotes: null,
    priorLeasingNote: null,
    finalLeasingNote: null,
  },
  equipmentTypeNames: [],
  isFetching: false,
  errors: false,
};

const equipmentTypeNames = new Set<string>();

const removeNullableValues = <T>(obj: T, keys: Array<keyof T>) =>
  keys.reduce(
    (acc: IMap<T[keyof T]>, key: keyof T) => ({
      ...acc,
      ...(obj[key] != null ? { [key]: obj[key] } : {}),
    }),
    {},
  );

export const reducer = createReducer(
  initialState,
  // GET LEASING BROWSE
  on(
    LeasingActions.getLeasingBrowseDataAction,
    ({
      payload,
    }: PayloadAndState<
      PaginatedResponse<LeasingBrowseResponseItem>,
      LeasingState
    >) => ({
      leasingBrowseData: {
        ...payload,

        items: payload.items.map(({ categories, ...item }) => ({
          ...item,
          fuzeIDs: categories,
        })),
      },
    }),
  ),
  // GET LEASING DETAILS
  on(
    LeasingActions.getLeasingDetailsAction,
    ({
      payload: leasingDetailsData,
    }: PayloadAndState<LeasingDetails, LeasingState>) => ({
      leasingDetailsData,
      leasingEquipmentData: {
        priorTableData: getProcessedEquipments(
          equipmentTypeNames,
          leasingDetailsData.leasing.leasingEquipments,
          'PriorEquipment',
        ),
        curPriorTableData: getProcessedEquipments(
          equipmentTypeNames,
          leasingDetailsData.leasing.leasingEquipments,
          'PriorEquipment',
        ),
        finalTableData: getProcessedEquipments(
          equipmentTypeNames,
          leasingDetailsData.leasing.leasingFinalEquipments,
          'FinalEquipment',
        ),
        curFinalTableData: getProcessedEquipments(
          equipmentTypeNames,
          leasingDetailsData.leasing.leasingFinalEquipments,
          'FinalEquipment',
        ),
        scopingTableData: sortEquipments(
          getEquipmentTotalsFromScoping(
            equipmentTypeNames,
            leasingDetailsData.scoping,
            'ScopingEquipment',
          ),
        ),
        scopingNotes: leasingDetailsData.scoping.scopingTowerNote,
        priorLeasingNote: leasingDetailsData.leasing.priorLeasingNote,
        finalLeasingNote: leasingDetailsData.leasing.finalLeasingNote,
      },
      equipmentTypeNames: [...equipmentTypeNames],
    }),
  ),
  // GET SCOPING DETAILS
  on(
    ScopingActions.getScopingDetailsAction,
    ({
      payload: {
        site,
        leasing: leasingPayload,
        leasingAgents,
        ...currentProject
      },
      state: { leasingInformation },
    }: PayloadAndState<
      Omit<ScopingDetailsResponse, 'categories' | 'RAD'>,
      LeasingState
    >) => {
      const leasing = leasingPayload ?? {};
      const { structural, ...restLeasingData } = leasing;

      return {
        currentProject,
        leasingAgents,
        leasingSite: transformFields<SiteResponse>(site, [
          'towerOwner',
          'riskCategory',
        ]),
        leasingInformation: {
          ...leasingInformation,
          ...transformFields<LeasingInformation>(
            restLeasingData,
            LEASING_INFO_TRANSFORM_FIELDS,
          ),
          structural: structural
            ? transformFields<StructuralInformation>(
                structural,
                STRUCTURAL_INFO_TRANSFORM_FIELDS,
              )
            : undefined,
        },
      };
    },
  ),
  // GET LEASING EQUIPMENT PRIOR PROJECT
  on(
    LeasingActions.getLeasingEquipmentPriorProjectAction,
    ({
      payload: { scoping: priorScopingData, leasing },
      state: { leasingEquipmentData },
    }: PayloadAndState<LeasingEquipmentResp, LeasingState>) => {
      const scopingData = priorScopingData ?? {};

      const curPriorTableData = getEquipmentTotalsFromScoping(
        equipmentTypeNames,
        scopingData,
        'PriorEquipment',
      );

      return {
        leasingEquipmentData: {
          ...leasingEquipmentData,
          curPriorTableData,
          curFinalTableData: sortEquipments(
            leasingEquipmentData.scopingTableData.map(
              (equipment: EquipmentModal) => ({
                ...equipment,
                generatedId: nextId(),
              }),
            ),
          ),
          priorLeasingNote: leasing?.priorLeasingNote,
          finalLeasingNote: null,
        },
        equipmentTypeNames: [...equipmentTypeNames],
      };
    },
  ),
  // UPDATE LEASING WORKFLOW
  on(
    LeasingActions.updateLeasingWorkflowAction,
    ({
      payload: {
        engineering: scoping,
        leasing: { NTPRequirement, ...leasing },
        structural,
      },
      state: { leasingDetailsData },
    }: PayloadAndState<LeasingWorkflowUpdateData, LeasingState>) => ({
      leasingDetailsData: {
        ...leasingDetailsData,
        scoping: { ...leasingDetailsData.scoping, ...scoping },
        site: {
          ...leasingDetailsData.site,
          ...(NTPRequirement !== undefined ? { NTPRequirement } : {}),
        },
        leasing: {
          ...leasingDetailsData.leasing,
          ...leasing,
          structural: leasingDetailsData.leasing.structural
            ? {
                ...leasingDetailsData.leasing.structural,
                ...structural,
              }
            : structural,
        },
      },
    }),
  ),
  // UPDATE LEASE AUDIT
  on(
    LeasingActions.updateLeaseAuditAction,
    ({
      payload: {
        area,
        leasingNote,
        mostRecentSATIARevCodeId,
        agreementTypeId,
        ...payloadData
      },
      state: { leasingInformation, leasingDetailsData },
    }: PayloadAndState<
      Partial<LeasingLeaseAuditUpdateData> & {
        area: 'engineering' | 'leasing';
      },
      LeasingState
    >) =>
      area === 'engineering'
        ? {
            leasingInformation: {
              ...leasingInformation,
              ...(payloadData.leaseAuditComplete
                ? { leaseAuditComplete: payloadData.leaseAuditComplete }
                : {}),
              ...(leasingNote ? { leasingNote } : {}),
              leaseAudit: {
                ...leasingInformation.leaseAudit,
                ...(agreementTypeId
                  ? { agreementType: { id: agreementTypeId } as IdAndValue }
                  : {}),
                ...(mostRecentSATIARevCodeId
                  ? {
                      mostRecentSATIARevCode: {
                        id: mostRecentSATIARevCodeId,
                      } as IdAndValue,
                    }
                  : {}),
                ...removeNullableValues(payloadData, [
                  'leaseAuditComplete',
                  'leaseAuditBy',
                  'documentAudited',
                  'documentDate',
                  'currentRentAmount',
                  'escalator',
                  'termLengthOrExtension',
                  'currentTermStartDate',
                  'currentTermEndDate',
                  'finalTermEndDate',
                  'mostRecentSADate',
                  'mostRecentTowerSAPercentage',
                  'isMostRecentAdequate',
                  'mostRecentFoundationSAPercentage',
                  'separateGeneratorSpace',
                  'generatorDetails',
                  'azimuthA',
                  'azimuthB',
                  'azimuthD',
                  'azimuthG',
                ]),
              },
            },
          }
        : {
            leasingDetailsData: {
              ...leasingDetailsData,
              leasing: {
                ...leasingDetailsData.leasing,
                ...(leasingNote ? { leasingNote } : {}),
                leaseAudit: leasingDetailsData.leasing.leaseAudit
                  ? {
                      ...leasingDetailsData.leasing.leaseAudit,
                      ...payloadData,
                    }
                  : {
                      mostRecentSATIARevCodeId,
                      agreementTypeId,
                      ...payloadData,
                    },
              },
            },
          },
  ),
  // ADD LEASING EQUIPMENT TABLE ITEM
  on(
    LeasingActions.addTableItemAction,
    ({
      payload: { data, key },
      state: { leasingEquipmentData },
    }: PayloadAndState<LeasingActionType, LeasingState>) => ({
      leasingEquipmentData: {
        ...leasingEquipmentData,
        [key]: sortEquipments([...leasingEquipmentData[key], data]),
      },
    }),
  ),
  // UPDATE LEASING EQUIPMENT TABLE ITEM
  on(
    LeasingActions.editTableItemAction,
    ({
      payload: { data, key },
      state: { leasingEquipmentData },
    }: PayloadAndState<LeasingActionType, LeasingState>) => {
      const dataToEdit = leasingEquipmentData[key];

      const index = dataToEdit.findIndex(
        (equipment: EquipmentModal) =>
          equipment.generatedId === data.generatedId,
      );

      const editedData = [...dataToEdit];

      editedData.splice(index, 1, data);

      return {
        leasingEquipmentData: {
          ...leasingEquipmentData,
          [key]: sortEquipments(editedData),
        },
      };
    },
  ),
  // REMOVE LEASING EQUIPMENT TABLE ITEM
  on(
    LeasingActions.deleteTableItemAction,
    ({
      payload: { data, key },
      state: { leasingEquipmentData },
    }: PayloadAndState<LeasingActionType, LeasingState>) => {
      const revertKey =
        key === 'curPriorTableData' ? 'curFinalTableData' : 'curPriorTableData';
      const dataToEdit = leasingEquipmentData[revertKey];

      const index = dataToEdit.findIndex(
        (equipmentModalItem: EquipmentModal) =>
          equipmentModalItem.generatedId === data.generatedId ||
          equipmentModalItem.equipment === data.equipment,
      );

      const editedData = [...dataToEdit];

      if (index >= 0) {
        editedData.splice(index, 1, {
          ...editedData[index],
          isLeaseRight: false,
        });
      }

      return {
        leasingEquipmentData: {
          ...leasingEquipmentData,
          [key]: [
            ...leasingEquipmentData[key].filter(
              (equipment: EquipmentModal) =>
                equipment.generatedId !== data.generatedId,
            ),
          ],
          [revertKey]: index < 0 ? leasingEquipmentData[revertKey] : editedData,
        },
      };
    },
  ),
  // UPDATE LEASING EQUIPMENT
  on(
    LeasingActions.updateLeasingEquipmentAction,
    ({
      payload,
      state: { leasingEquipmentData },
    }: PayloadAndState<LeasingEquipmentUpdateData, LeasingState>) => ({
      leasingEquipmentData: {
        ...leasingEquipmentData,
        ...payload,
        curPriorTableData: generatedIdMapper(
          payload.priorTableData,
          'PriorEquipment',
        ),
        curFinalTableData: generatedIdMapper(
          payload.finalTableData,
          'FinalEquipment',
        ),
      },
    }),
  ),
  // EMPTY A PRIOR TABLE
  on(
    LeasingActions.emptyPriorTableAction,
    ({
      state: { leasingEquipmentData },
    }: PayloadAndState<null, LeasingState>) => ({
      leasingEquipmentData: {
        ...leasingEquipmentData,
        curPriorTableData: [],
      },
    }),
  ),
  // GENERATE LEASING EQUIPMENT FINAL TABLE DATA
  on(
    LeasingActions.generateFinalLoadingAction,
    ({
      payload,
      state: { leasingEquipmentData },
    }: PayloadAndState<ReactText[], LeasingState>) => ({
      leasingEquipmentData: {
        ...leasingEquipmentData,
        curPriorTableData: sortEquipments(
          leasingEquipmentData.curPriorTableData.map(
            (priorEquipment: EquipmentModal) => ({
              ...priorEquipment,
              ...{
                isLeaseRight: payload.includes(
                  priorEquipment?.generatedId || '',
                ),
              },
            }),
          ),
        ),
        curFinalTableData: sortEquipments([
          ...leasingEquipmentData.scopingTableData,
          ...leasingEquipmentData.curPriorTableData
            .filter((priorEquipment: EquipmentModal) =>
              payload.includes(priorEquipment?.generatedId || ''),
            )
            .map((priorEquipment: EquipmentModal) => ({
              ...priorEquipment,
              isLeaseRight: true,
            })),
        ]),
      },
    }),
  ),
  // RESET LEASING EQUIPMENT TABLES DATA
  on(
    LeasingActions.resetTablesDataAction,
    ({
      state: { leasingEquipmentData },
    }: PayloadAndState<null, LeasingState>) => ({
      leasingEquipmentData: {
        ...leasingEquipmentData,
        curPriorTableData: [...leasingEquipmentData.priorTableData],
        curFinalTableData: [...leasingEquipmentData.finalTableData],
      },
    }),
  ),
);
