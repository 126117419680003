import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Tab } from '@models/classes';
import { CommonActions } from '@store/actions';
import { HeaderBar, Tabs } from '../../components';
import { DetailsProps } from './models/interfaces';
import { DetailsTabsProps } from './models/types';
import { setCurrentTabs } from './helpers';

import './styles.scss';

const DetailsTabs: FC<DetailsTabsProps> = ({
  match,
  destroyInactiveTabPane,
  animated,
}: PropsWithChildren<DetailsTabsProps>) => {
  const [tabs, setTabs] = useState<Tab[]>([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useMemo(() => match.params || {}, [match]);

  useEffect(() => {
    setCurrentTabs(location, tabs, setTabs);
  }, []);

  useEffect(() => {
    dispatch(CommonActions.setRouteParams.done(params));
  }, [params]);

  return tabs.length ? (
    <Tabs
      tabs={tabs}
      params={params}
      destroyInactiveTabPane={destroyInactiveTabPane}
      animated={animated}
    />
  ) : null;
};

export const Details: FC<DetailsProps> = ({
  match,
  className = '',
  headerBar = true,
  destroyInactiveTabPane,
  animated,
  ...headerProps
}: PropsWithChildren<DetailsProps>) => (
  <div className={classNames('prov-details', className)}>
    {headerBar && (
      <HeaderBar className="no-border-radius__bottom" {...headerProps} />
    )}
    <DetailsTabs
      match={match}
      destroyInactiveTabPane={destroyInactiveTabPane}
      animated={animated}
    />
  </div>
);
