import { Dispatch, SetStateAction } from 'react';
import { NotifyType, Nullable } from '@symfa-inc/providence-verizon-types';
import { FormInstance } from 'antd/lib/form';
import { getEnumKeyByEnumValue } from '@core/utils/methods';

export const actualizeControl = (
  setter: Dispatch<SetStateAction<Nullable<keyof typeof NotifyType>>>,
  form: FormInstance,
  currentEnum: typeof NotifyType,
  value: string,
  resetFields: string[],
): void => {
  setter(getEnumKeyByEnumValue(currentEnum, value));
  form.resetFields([...resetFields]);
};
