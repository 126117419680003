import { Undefinable } from '@symfa-inc/providence-verizon-types';

export const normalize = (value: string): Undefinable<number> => {
  const newValue = parseInt(value, 10);

  return Number.isNaN(newValue) ? undefined : newValue;
};

const countDecimalDigits = (value: string) => {
  const decimalPart = value.match(/\.\d*/);

  return decimalPart ? decimalPart[0].length - 1 : 0;
};

export const normalizeDecimal = (value: string): Undefinable<string> =>
  countDecimalDigits(value) > 2 ? (+value).toFixed(2) : value;
