import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { SelectValue } from 'antd/lib/select';
import {
  EquipmentManufacturerData,
  EquipmentTypeData,
} from '../../models/interfaces';
import { arraySort } from '../utils/methods/array-sort';

export class EquipmentAvailableSelectorValueService {
  #availableEquipmentTypeValue: EquipmentTypeData[] = [];
  #availableEquipmentManufacturerValue: EquipmentManufacturerData[] = [];
  #equipmentTypes: EquipmentTypeData[] = [];

  set equipmentTypes(value: EquipmentTypeData[]) {
    this.#equipmentTypes = value;
  }

  get availableEquipmentTypeValue(): EquipmentTypeData[] {
    return this.#availableEquipmentTypeValue;
  }

  get availableEquipmentManufacturerValue(): EquipmentManufacturerData[] {
    return this.#availableEquipmentManufacturerValue;
  }

  init(): void {
    this.setAvailableEquipmentManufacturerValue(this.#equipmentTypes);

    this.#availableEquipmentTypeValue = arraySort(
      this.#equipmentTypes,
      'ASC',
      'name',
    );
  }

  selectEquipmentType(selectedValue: Undefinable<SelectValue>): void {
    const equipmentTypeLikeArray = selectedValue
      ? this.#equipmentTypes.filter(
          (equipmentType: EquipmentTypeData) =>
            equipmentType.id === selectedValue,
        )
      : this.#equipmentTypes;

    this.setAvailableEquipmentManufacturerValue(equipmentTypeLikeArray);
  }

  selectEquipmentManufacturer(selectedValue: Undefinable<SelectValue>): void {
    this.#availableEquipmentTypeValue = selectedValue
      ? this.#equipmentTypes.filter((equipmentType: EquipmentTypeData) => {
          if (
            equipmentType.manufacturers &&
            equipmentType.manufacturers.length
          ) {
            return equipmentType.manufacturers
              .map(
                (equipmentManufacturer: EquipmentManufacturerData) =>
                  equipmentManufacturer.id,
              )
              .includes(selectedValue as string);
          }

          return false;
        })
      : this.#equipmentTypes;
  }

  private setAvailableEquipmentManufacturerValue(
    equipmentTypes: EquipmentTypeData[],
  ): void {
    const manufacturers = equipmentTypes
      .map((equipmentType: EquipmentTypeData) => equipmentType.manufacturers)
      .flat() as EquipmentManufacturerData[];

    this.#availableEquipmentManufacturerValue = [];

    if (manufacturers && manufacturers.length) {
      const uniqueManufacturers =
        this.removeDuplication<EquipmentManufacturerData>(manufacturers, 'id');

      this.#availableEquipmentManufacturerValue = arraySort(
        uniqueManufacturers,
        'ASC',
        'name',
      );
    }
  }

  private removeDuplication<T>(arr: T[], compareKey: keyof T): T[] {
    return arr.filter(
      (filterItem: T, index: number, array: T[]) =>
        array.findIndex(
          (findIndexItem: T) =>
            findIndexItem[compareKey] === filterItem[compareKey],
        ) === index,
    );
  }
}
