import { SiteOptions } from '@models/interfaces';

export const SOF_TOOL_HEADER_SITE_OPTIONS: SiteOptions[] = [
  {
    key: 'PSLocationCode',
    label: 'PS Location Code',
  },
  {
    key: 'siteName',
    label: 'Site Name',
  },
  {
    key: 'contractNumber',
    label: 'Contract Number',
  },
];
