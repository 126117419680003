import React, { ReactNode } from 'react';
import { ISelectorOption, IToDo } from '@symfa-inc/providence-verizon-types';
import { ColumnsType } from 'antd/es/table';
import { tableSorter } from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { DashboardItem } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { ReadOnlyDate } from '@shared/modules';
import { assigneesRenderer } from '../../../helpers';
import { PROJECT_DATA_COLUMNS } from '../../../models';

export const TO_DO_COLUMNS = (
  adminsList: ISelectorOption[],
  editFunc: ColumnsFunc<DashboardItem<IToDo>>,
): ColumnsType => [
  {
    title: 'Date',
    dataIndex: 'notificationData',
    key: 'date',
    sorter: tableSorter(['notificationData', 'date'], true),
    render: ({ date }: IToDo): ReactNode => <ReadOnlyDate date={date} utc />,
  },
  ...PROJECT_DATA_COLUMNS,
  {
    title: 'Action Required',
    dataIndex: 'notificationData',
    key: 'message',
    sorter: tableSorter(['notificationData', 'message']),
    render: ({ message }: IToDo): ReactNode => message,
  },
  {
    title: 'Action Field',
    dataIndex: 'notificationData',
    key: 'actionField',
    sorter: tableSorter(['notificationData', 'actionField']),
    render: ({ actionField }: IToDo): ReactNode => actionField,
  },
  {
    title: 'Past Due Date',
    dataIndex: 'notificationData',
    sorter: tableSorter(['notificationData', 'pastDueDate']),
    render: ({ pastDueDate }: IToDo): ReactNode => `${pastDueDate} day(s)`,
  },
  {
    title: 'Task Note',
    dataIndex: 'notificationData',
    key: 'taskNote',
    sorter: tableSorter(['notificationData', 'taskNote']),
    render: ({ taskNote }: IToDo): ReactNode => taskNote,
  },
  {
    title: 'Assignee',
    dataIndex: 'notificationData',
    key: 'assignee',
    render: assigneesRenderer(adminsList),
  },
  actionButtons({ editFunc, width: 86 }),
];
