import React, { FC, PropsWithChildren, useEffect } from 'react';
import { UserRole } from '@symfa-inc/providence-verizon-types';
import { Form } from 'antd';
import { canEdit } from '@core/utils/methods';
import { Autocomplete, DatePicker } from '@shared/modules';
import { SOFUploadButton } from '../upload-button';
import { SOFConfigurationPanelProps } from './models';

import './styles.scss';

export const SOFConfigurationPanel: FC<SOFConfigurationPanelProps> = ({
  values,
  configurationPanelChanges,
  engineeringManagers,
  projectTypePermission,
  isFetching = false,
}: PropsWithChildren<SOFConfigurationPanelProps>) => {
  const [form] = Form.useForm();

  const isDisabled =
    isFetching ||
    !canEdit([
      UserRole.Admin,
      UserRole.EngineeringManager,
      UserRole.ProjectDataSpecialist,
    ]);

  const updateAttachment = (attachment: File | null): void =>
    configurationPanelChanges({ attachment });

  useEffect(() => {
    form.setFieldsValue(values);
  }, [values]);

  return (
    <div className="prov-sof-tool-configuration-panel">
      <Form
        form={form}
        className="prov-sof-tool-configuration-panel__form"
        onValuesChange={configurationPanelChanges}
      >
        <DatePicker
          id="orderDate"
          label="Order Date"
          elementProps={{
            disabled: isDisabled || !projectTypePermission,
          }}
        />
        <Autocomplete
          id="orderedBy"
          label="Ordered By"
          options={engineeringManagers}
          elementProps={{
            disabled: isDisabled || !projectTypePermission,
          }}
        />
      </Form>
      <SOFUploadButton
        updateAttachment={updateAttachment}
        isDisabled={isDisabled || !projectTypePermission}
      />
    </div>
  );
};
