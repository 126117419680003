import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { AdminDefaultTowerAgentData } from '@models/interfaces';
import { DefaultTowerAgentActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class DefaultTowerAgentHttpService extends CommonHttpService {
  @StoredRequest(DefaultTowerAgentActions.getDefaultTowerAgentDataAction)
  getDefaultTowerAgentData({
    providenceAgentId,
    towerOwnerId,
  }: {
    providenceAgentId: Undefinable<string>;
    towerOwnerId: Undefinable<string>;
  }): Promise<AdminDefaultTowerAgentData[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/default-tower-agents`,
      null,
      {
        params: { providenceAgentId, towerOwnerId },
      },
    );
  }

  @StoredRequest(DefaultTowerAgentActions.getDefaultTowerAgentOptionsAction)
  getDefaultTowerAgentOptions(
    id: string | null,
  ): Promise<AdminDefaultTowerAgentData[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/default-tower-agents/filter-options`,
      null,
      {
        params: {
          defaultTowerAgentId: id,
        },
      },
    );
  }

  @StoredRequest(DefaultTowerAgentActions.addDefaultTowerAgentDataAction)
  addDefaultTowerAgentData({
    providenceAgent,
    towerOwners,
  }: Omit<
    AdminDefaultTowerAgentData,
    'id'
  >): Promise<AdminDefaultTowerAgentData> {
    return this.http.callApi.post(
      `${this.apiUrl}/admin/default-tower-agents`,
      null,
      {
        params: {
          providenceAgentId: providenceAgent,
          towerOwnerIds: towerOwners,
        },
      },
    );
  }

  @StoredRequest(DefaultTowerAgentActions.updateDefaultTowerAgentDataAction)
  updateDefaultTowerAgentData({
    defaultTowerAgentId,
    towerOwners,
  }: {
    defaultTowerAgentId: string;
    towerOwners: string[];
  }): Promise<AdminDefaultTowerAgentData> {
    return this.http.callApi.patch(
      `${this.apiUrl}/admin/default-tower-agents`,
      null,
      {
        params: {
          defaultTowerAgentId,
          towerOwnerIds: towerOwners,
        },
      },
    );
  }

  @StoredRequest(DefaultTowerAgentActions.deleteDefaultTowerAgentAction)
  async deleteDefaultTowerAgent(id: string): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/default-tower-agents`,
      null,
      {
        params: {
          defaultTowerAgentId: id,
        },
      },
    );

    return id;
  }
}
