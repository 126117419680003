import { actionCreatorFactory } from '@core/utils/factories';
import {
  PaginatedResponse,
  SofToolBrowseFilterOptionsResponse,
  SOFToolBrowseResponse,
  SOFToolDetails,
} from '@models/interfaces';

const actionCreator = actionCreatorFactory('ENGINEERING');

export namespace SOFToolActions {
  export const getSOFToolDataAction =
    actionCreator.createAsyncAction<PaginatedResponse<SOFToolBrowseResponse>>(
      'GET_SOF_TOOL_DATA',
    );
  export const getSOFToolBrowseFilters =
    actionCreator.createAsyncAction<SofToolBrowseFilterOptionsResponse>(
      'GET_SOF_TOOL_BROWSE_FILTERS',
    );
  export const getSOFToolDetails =
    actionCreator.createAsyncAction<SOFToolDetails>('GET_SOF_TOOL_DETAILS');
  export const setSOFToolPreviewSrcPDF =
    actionCreator.createAsyncAction<string>('SET_SOF_TOOL_PREVIEW_SRC_PDF');
  export const sendSOFToolPDF =
    actionCreator.createAsyncAction<void>('SEND_SOF_TOOL_PDF');
}
