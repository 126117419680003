import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import nextId from 'react-id-generator';
import { Form } from 'antd';
import SuccessIcon from 'assets/icons/success-icon.svg';
import { RuleObject } from 'rc-field-form/es/interface';
import { HttpService } from '@core/services';
import { DropdownHttpService } from '@core/services/http';
import { isValidForm } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { WHITESPACE_INPUT_LIMIT_RULES } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { AdminDropdownValuesData } from '@models/interfaces';
import { AddOrEditModal, NotificationsLoader } from '@shared/components';
import { Input } from '@shared/modules';
import { MultipleAddModal } from '../multiple-add-modal';
import { DropdownValuesModalProps } from './models';

import './styles.scss';

export const DropdownValuesModal: FC<DropdownValuesModalProps> = ({
  selectedItemValue,
  visible,
  modalType,
  closeModal,
  activeDDV,
  selectorValue,
  setSelectorValue,
}: PropsWithChildren<DropdownValuesModalProps>) => {
  const dropdownService = HttpService.getHttpRequests(DropdownHttpService);
  const isAddModalVisible = modalType === ModalMainTypes.Add && visible;
  const isEditModalVisible = modalType === ModalMainTypes.Edit && visible;

  const [multipleAddForm] = Form.useForm();
  const [editForm] = Form.useForm();

  const [dropdownValues, setDropdownValues] = useState<string[]>([
    nextId('multiple'),
  ]);

  const inputRender = useCallback(
    (id: string) => (
      <Input
        id={id}
        label=""
        formItemProps={{
          rules: [
            {
              max: 255,
              message: 'Dropdown Value should be 255 characters or less.',
            },
            {
              required: true,
              message: 'Dropdown value is required!',
            },
            {
              validator: (_: RuleObject): Promise<void> => {
                const { [id]: inputValue, ...otherFormFields } =
                  multipleAddForm.getFieldsValue();

                return Object.values(otherFormFields).find(
                  (v: string) => v === inputValue,
                )
                  ? Promise.reject(new Error('Value should be unique.'))
                  : Promise.resolve();
              },
            },
            serverValidator(
              {
                method: 'getDropdownValueValidation',
                errorMessage: 'Dropdown value must be unique',
              },
              DropdownHttpService,
              modalType === ModalMainTypes.Add
                ? undefined
                : selectedItemValue?.id,
              activeDDV?.value ?? selectorValue,
            ),
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
    ),
    [modalType, activeDDV, selectedItemValue],
  );

  useEffect(() => {
    if (isEditModalVisible) {
      editForm.setFieldsValue(selectedItemValue!);
    }
  }, [visible, modalType, selectedItemValue, editForm]);

  const onCancel = (): void => {
    if (modalType === ModalMainTypes.Add) {
      multipleAddForm.resetFields();
      setDropdownValues([nextId('multiple')]);
    } else if (modalType === ModalMainTypes.Edit) {
      editForm.resetFields();
    }

    closeModal();
  };

  const onSave = async (): Promise<void> => {
    if (await isValidForm(multipleAddForm)) {
      const values = Object.values(multipleAddForm.getFieldsValue());

      await dropdownService.addDropdownValue(activeDDV!.value, values);

      onCancel();

      if (activeDDV!.value !== selectorValue) {
        NotificationsLoader.openNotificationWithArgs('success', {
          icon: <img src={SuccessIcon} alt="Success Icon" />,
          className: 'notification-success',
          message: 'Success',
          description: `Dropdown values have been added to ${
            activeDDV!.viewValue
          }. Click to go to the new changes.`,
          onClick: () => setSelectorValue(activeDDV!.value),
        });
      }
    }
  };

  const onEdit = async (): Promise<void> => {
    if (await isValidForm(editForm)) {
      await dropdownService.updateDropdownValue(
        selectedItemValue!.id,
        editForm.getFieldsValue() as Omit<AdminDropdownValuesData, 'id'>,
      );

      onCancel();
    }
  };

  return (
    <>
      <MultipleAddModal
        onOk={onSave}
        onCancel={onCancel}
        visible={isAddModalVisible}
        title="Add Dropdown Values"
        subTitle={activeDDV?.viewValue}
        values={dropdownValues}
        setValues={setDropdownValues}
        inputRender={inputRender}
        formProps={{ form: multipleAddForm }}
      />
      <AddOrEditModal
        onOk={onEdit}
        onCancel={onCancel}
        visible={isEditModalVisible}
        type={ModalMainTypes.Edit}
        title="Dropdown Value"
        okText="Save"
        cancelText="Cancel"
        className="prov-admin-dropdown-values-modal"
        formProps={{
          wrapperCol: { span: 15 },
          form: editForm,
          initialValues: selectedItemValue!,
        }}
      >
        {inputRender('value')}
      </AddOrEditModal>
    </>
  );
};
