import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { HttpService } from '@core/services';
import { ProjectHttpService, SiteHttpService } from '@core/services/http';
import { getParamFromURL } from '@core/utils/methods';
import { Details } from '@shared/modules/details';
import { SiteSelectors } from '@store/selectors';

export const SiteDetails: FC<RouteComponentProps> = (
  props: PropsWithChildren<RouteComponentProps>,
) => {
  const {
    match: { params },
  } = props;

  const site = useSelector(SiteSelectors.getCurrentSite);

  const projectService = useMemo(
    () => HttpService.getHttpRequests(ProjectHttpService),
    [],
  );

  const [URLParam] = getParamFromURL(
    'Project Bundle ID, PS Location Code or MDG Location Code',
    /\d+/,
  );

  const isParamMatchLocation = useCallback(
    (locationIdParam: string): boolean =>
      Object.keys(params).includes(locationIdParam),
    [params],
  );

  const isParamMatchProjectOrPM =
    isParamMatchLocation('projectBundleID') ||
    isParamMatchLocation('pmProjectBundleID');

  useEffect(() => {
    if (isParamMatchLocation('PSLocationCode')) {
      HttpService.getHttpRequests(SiteHttpService).getSite(URLParam);
    } else if (isParamMatchProjectOrPM) {
      projectService.getProject(URLParam);
      projectService.getSiteByProjectBundleID(URLParam);
    }
  }, [URLParam, isParamMatchLocation, isParamMatchProjectOrPM]);

  return <Details fuzeID={isParamMatchProjectOrPM} {...props} site={site} />;
};
