import { actionCreatorFactory } from '../../core/utils/factories';
import {
  ProcessEnvMap as KeyString,
  ProjectFuzeID,
} from '../../models/interfaces';

const actionCreator = actionCreatorFactory('PROJECT_FUZE_IDS');

export namespace ProjectFuzeIDsActions {
  export const setProjectFuzeIDsAction = actionCreator.createAsyncAction<
    ProjectFuzeID[]
  >('CLEAR_PROJECT_FUZE_IDS');
  export const addProjectFuzeIDsItemAction =
    actionCreator.createAsyncAction<ProjectFuzeID>('ADD_PROJECT_FUZE_IDS_ITEM');
  export const updateProjectFuzeIDsItemAction =
    actionCreator.createAsyncAction<ProjectFuzeID>(
      'UPDATE_PROJECT_FUZE_IDS_ITEM',
    );
  export const deleteProjectFuzeIDsItemAction =
    actionCreator.createAsyncAction<KeyString>('DELETE_PROJECT_FUZE_IDS_ITEM');
  export const toggleProjectFuzeIDsTableAction =
    actionCreator.createAsyncAction<void>('TOGGLE_PROJECT_FUZE_IDS_TABLE');
  export const setProjectFuzeIDsTableAction =
    actionCreator.createAsyncAction<boolean>('SET_PROJECT_FUZE_IDS_TABLE');
  export const setCategoriesTableEditing =
    actionCreator.createAsyncAction<boolean>(
      'SET_PROJECT_FUZE_IDS_TABLE_EDITING',
    );
}
