import {
  CreateNotificationData,
  ProjectType,
} from '@symfa-inc/providence-verizon-types';
import { actionCreatorFactory } from '../../core/utils/factories';
import { ColumnSettingsResponse, User } from '../../models/interfaces';

const actionCreator = actionCreatorFactory('USER');

export namespace UserActions {
  export const getActiveUserAction =
    actionCreator.createAsyncAction<User>('GET_ACTIVE_USER');
  export const changeUserPasswordAction = actionCreator.createAsyncAction<null>(
    'CHANGE_USER_PASSWORD',
  );
  export const changeColumnsSettings =
    actionCreator.createAsyncAction<ColumnSettingsResponse>(
      'CHANGE_COLUMNS_SETTINGS',
    );
  export const changePMProjectType =
    actionCreator.createAsyncAction<ProjectType>('CHANGE_PM_PROJECT_TYPE');
  export const logout = actionCreator.createAsyncAction<void>('LOGOUT');

  // NOTIFICATIONS
  export const addNotification =
    actionCreator.createAsyncAction<CreateNotificationData>('ADD_NOTIFICATION');

  export const changeSubscriptionStatus =
    actionCreator.createAsyncAction<boolean>('CHANGE_SUBSCRIPTION_STATUS');

  export const clearCurrent =
    actionCreator.createAsyncAction<string>('CLEAR_CURRENT');

  export const clearAll = actionCreator.createAsyncAction<void>('CLEAR_ALL');
}
