import { BrowseFilterType } from './browse-filter.type';

export const SOF_FILTERS: BrowseFilterType[] = [
  {
    id: 'PSLocationCode',
    name: 'PS Location Code',
    valueKey: 'PSLocationCodes',
  },
  {
    id: 'siteName',
    name: 'Site Name',
    valueKey: 'siteNames',
  },
  {
    id: 'fuzeID',
    name: 'Fuze ID',
    valueKey: 'fuzeIDs',
  },
];
