import {
  DropDownSelectorValues,
  Nullable,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';
import { createSelector } from 'reselect';
import { getEnumKeyByEnumValue } from '@core/utils/methods';
import { OptionProps } from '@models/interfaces';
import { DDVSelectorKey } from '@models/types';
import { AppState } from '@store/reducers';
import { DropdownValuesState } from '@store/reducers/admin/dropdown-values.reducer';

const DDVState = (state: AppState): DropdownValuesState =>
  state.admin.dropdownValues;

export namespace DDVSelectors {
  // TODO: rework typing as getDDVValuesAsOptionsByTypes (for Max)
  export const getDDVValuesAsOptionsByType = (type: DropDownSelectorValues) =>
    createSelector([DDVState], (state: DropdownValuesState) => {
      const key = getEnumKeyByEnumValue(
        DropDownSelectorValues,
        type,
      ) as DDVSelectorKey;

      return state.dropdownsOptions[key];
    });

  export const getProjectTypes = createSelector(
    [DDVState],
    ({ projectTypes }: DropdownValuesState): OptionProps[] =>
      projectTypes.map(({ id, value }) => ({
        viewValue: value,
        value: id,
      })),
  );

  export const getDDVValuesAsOptionsByTypes = <
    TKey extends keyof typeof DropDownSelectorValues,
  >(
    types: TKey[],
  ) =>
    createSelector([DDVState], (state: DropdownValuesState) => {
      if (types.length <= 1) {
        throw new Error('Please use more one type!');
      }

      return types.reduce(
        (
          acc: Record<`${TKey}DDV`, OptionProps[]>,
          type: keyof typeof DropDownSelectorValues,
        ) => ({
          ...acc,
          [`${type}DDV`]: state.dropdownsOptions[type],
        }),
        {} as Record<`${TKey}DDV`, OptionProps[]>,
      );
    });

  export const getCurrentDDVValueById = <T = string>(
    valueKey: DropDownSelectorValues,
    ddvId: Nullable<string>,
  ) =>
    createSelector([DDVState], (state: DropdownValuesState): Undefinable<T> => {
      const key = getEnumKeyByEnumValue(
        DropDownSelectorValues,
        valueKey,
      ) as DDVSelectorKey;

      return ddvId
        ? (state.dropdownValues[key].find(v => v.id === ddvId) as T)
        : undefined;
    });

  export const getDDVOptions = createSelector(
    [DDVState],
    ({ dropdownsOptions }: DropdownValuesState) => dropdownsOptions,
  );

  export const getDDValues = createSelector(
    [DDVState],
    ({ dropdownValues }: DropdownValuesState) => dropdownValues,
  );

  export const getDropdownValuesOptionsAndDropdownValues = createSelector(
    [DDVState],
    ({ dropdownValuesOptions, dropdownValues }: DropdownValuesState) => ({
      dropdownValuesOptions,
      dropdownValues,
    }),
  );
}
