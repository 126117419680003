import React, {
  FC,
  Fragment,
  PropsWithChildren,
  ReactElement,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Sticky, StickyContainer } from 'react-sticky';
import { DatabaseOutlined, MoreOutlined } from '@ant-design/icons';
import {
  DIRECT_MESSAGE_AREA_PATTERNS,
  DropDownSelectorValues,
  ProjectStatus,
} from '@symfa-inc/providence-verizon-types';
import { Button, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import { ActionRes, OptionProps, SiteOptions } from '@models/interfaces';
import { ProjectFuzeIDsActions } from '@store/actions';
import {
  CommonSelectors,
  DDVSelectors,
  ProjectSelectors,
} from '@store/selectors';
import { HeaderBarProps } from './models/interfaces';
import { InfoBox, Item } from './components';
import { DirectMessageManager } from './modals';

import './styles.scss';

export const HeaderBar: FC<HeaderBarProps> = ({
  className,
  fuzeID = false,
  site,
  siteOptions = [
    {
      key: 'siteName',
      label: 'Site Name',
    },
    {
      key: 'MDGLocationCode',
      label: 'MDG Location Code',
    },
  ],
  showTowerOwner = true,
  withoutTabPanel = false,
}: PropsWithChildren<HeaderBarProps>) => {
  const dispatch = useDispatch();

  const { currentProjectFuzeIDs, projectFuzeIDsTableVisible } = useSelector(
    ProjectSelectors.getProjectFuzeIDs,
  );
  const holdOptions = useSelector(ProjectSelectors.getHoldOptions);
  const providenceAgent = useSelector(ProjectSelectors.getProvidenceAgent);
  const towerOwners = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByType(DropDownSelectorValues.TowerOwner),
  );
  const riskCategories = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByType(
      DropDownSelectorValues.RiskCategory,
    ),
  );
  const routePath = useSelector(CommonSelectors.getRoutePath);

  const [visibleDMManager, setVisibleDMManager] = useState<boolean>(false);

  const menu = (
    <Menu>
      {DIRECT_MESSAGE_AREA_PATTERNS.has(routePath) && (
        <Menu.Item onClick={(): void => setVisibleDMManager(true)}>
          Direct Messages
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <StickyContainer>
      <Sticky>
        {(): ReactElement => (
          <div className={classNames('prov-header-bar', className)}>
            <div className="prov-header-bar__left">
              {!fuzeID &&
                siteOptions.map(
                  ({ label, key }: SiteOptions) =>
                    site && <Item key={key} label={label} value={site[key]} />,
                )}
              {fuzeID && (
                <>
                  <Item
                    key="siteName"
                    label="Site Name"
                    value={site?.siteName}
                  />
                  <Item
                    key="projectFuzeID"
                    label="Fuze ID"
                    element={
                      <span
                        className={classNames('item__element project-fuze-id', {
                          'project-fuze-id--active':
                            !projectFuzeIDsTableVisible,
                        })}
                        onClick={(): ActionRes<void> =>
                          dispatch(
                            ProjectFuzeIDsActions.toggleProjectFuzeIDsTableAction.done(),
                          )
                        }
                      >
                        {projectFuzeIDsTableVisible
                          ? 'X'
                          : currentProjectFuzeIDs?.length}
                      </span>
                    }
                  />
                  <Item
                    key="MDGLocationCode"
                    label="MDG Location Code"
                    value={site?.MDGLocationCode}
                  />
                </>
              )}
              {showTowerOwner && (
                <Item
                  key="towerOwner"
                  label="Tower Owner"
                  value={
                    towerOwners.find(
                      ({ value }: OptionProps) =>
                        (site?.towerOwner as unknown as string) === value,
                    )?.viewValue
                  }
                />
              )}
              {fuzeID && (
                <Item
                  key="providenceAgent"
                  label="Providence Agent"
                  value={providenceAgent}
                />
              )}
            </div>
            <div
              className={classNames('prov-header-bar__right', {
                margin: withoutTabPanel,
              })}
            >
              {site?.isSiteOn === false ? (
                <InfoBox
                  status="inactive"
                  title="Off"
                  value={site?.disablingNote}
                />
              ) : (
                site?.isHighRisk && (
                  <InfoBox
                    status="default"
                    title="High Risk Site"
                    value={
                      riskCategories?.find(
                        (riskCategory: OptionProps) =>
                          riskCategory.value ===
                          (site?.riskCategory as unknown as string),
                      )?.viewValue
                    }
                  />
                )
              )}
              {holdOptions?.projectStatus === ProjectStatus.Hold && (
                <InfoBox
                  status="hold"
                  title="Hold"
                  value={holdOptions?.holdReason}
                />
              )}
              <Dropdown
                overlay={menu}
                placement="bottomCenter"
                className="action"
                openClassName="active"
                overlayClassName="prov-header-bar__right__dropdown-menu"
              >
                <Button type="primary">
                  <DatabaseOutlined />
                  <MoreOutlined />
                </Button>
              </Dropdown>
              {DIRECT_MESSAGE_AREA_PATTERNS.has(routePath) && (
                <DirectMessageManager
                  visible={visibleDMManager}
                  areaKey={DIRECT_MESSAGE_AREA_PATTERNS.get(routePath)!}
                  onClose={(): void => setVisibleDMManager(false)}
                />
              )}
            </div>
          </div>
        )}
      </Sticky>
    </StickyContainer>
  );
};
