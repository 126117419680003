import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from '@symfa-inc/providence-verizon-types';
import { Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import classNames from 'classnames';
import { HttpService } from '@core/services';
import { FinancialHttpService } from '@core/services/http';
import {
  arraySort,
  isValidForm,
  momentizeObjectDates,
} from '@core/utils/methods';
import {
  DOLLAR_PREFIX,
  INPUT_POSITIVE_NUMBER_VALIDATOR,
} from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  AddAndEditModalProps,
  Driver,
  FinancialServicesData,
} from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import {
  Autocomplete,
  DatePicker,
  Input,
  InputNumber,
  TextArea,
} from '@shared/modules';
import {
  DriverManagementSelectors,
  FinancialSelectors,
  UserSelectors,
} from '@store/selectors';

import './styles.scss';

const { useForm } = Form;

export const AddAndEditService: FC<
  AddAndEditModalProps<FinancialServicesData>
> = ({
  className,
  visible,
  formValue,
  toggleModal,
  modalType,
  entity,
  projectId,
  permissions,
  ...props
}: PropsWithChildren<AddAndEditModalProps<FinancialServicesData>>) => {
  const [form] = useForm();

  const financialService = useMemo(
    () => HttpService.getHttpRequests(FinancialHttpService),
    [],
  );

  const driversList = useSelector(DriverManagementSelectors.getDriversList);
  const disabled = useSelector(FinancialSelectors.isFetching);
  const userId = useSelector(UserSelectors.getUserId);
  const roles = useSelector(UserSelectors.getUserRoles);

  const isAccounting = roles.includes(UserRole.Accounting);
  const isAdmin = roles.includes(UserRole.Admin);

  const isReadonly =
    modalType === ModalMainTypes.Edit &&
    isAccounting &&
    !isAdmin &&
    formValue.creator?.id !== userId;

  const drivers = arraySort(
    driversList.map(({ id, driverName, driverID }: Driver) => ({
      value: id,
      viewValue: `${driverID} - ${driverName}`,
    })),
    'ASC',
    'viewValue',
  );

  const add = async (): Promise<void> => {
    if (await isValidForm(form)) {
      const formData = form.getFieldsValue();
      const driver = driversList.find((d: Driver) => d.id === formData.driver);

      await financialService.addFinancialService(
        { ...formData, driver } as FinancialServicesData,
        projectId!,
        entity,
        userId,
      );
      toggleModal(modalType);
    }
  };

  const edit = async (): Promise<void> => {
    if (await isValidForm(form)) {
      await financialService.updateFinancialService(
        {
          ...(entity === 'accounting'
            ? formValue
            : { id: formValue?.id, driver: formValue?.driver }),
          ...momentizeObjectDates(form.getFieldsValue(), ['issuedDate'], true),
        } as FinancialServicesData,
        projectId!,
        entity,
      );
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    const {
      driver: { driverName, driverNote },
      ...driver
    } = formValue;

    if (visible) {
      form.setFieldsValue(
        momentizeObjectDates({ driverName, driverNote, ...driver }, [
          'issuedDate',
        ]),
      );
    }
  }, [visible, formValue, form]);

  return (
    <AddOrEditModal
      title={modalType === ModalMainTypes.Add ? 'Add Services' : 'Edit Cost'}
      type={modalType}
      visible={visible}
      className={classNames(className, 'financial-service-modal')}
      onOk={modalType === ModalMainTypes.Add ? add : edit}
      okText={modalType === ModalMainTypes.Add ? 'Add' : 'Save'}
      onCancel={(): void => {
        toggleModal(modalType);
        form.resetFields();
      }}
      formProps={{
        labelCol: { span: 7 },
        form,
      }}
      okButtonProps={{ disabled }}
      maskClosable={!disabled}
      {...props}
    >
      <div>
        {modalType === ModalMainTypes.Edit ? (
          <>
            {permissions && entity === 'accounting' && (
              <DatePicker id="issuedDate" label="Date Issued" />
            )}
            <Input id="driverName" label="Driver Name" isEditing={false} />
            {permissions && entity === 'accounting' && (
              <InputNumber
                id="PONumber"
                label="PO Number"
                formItemProps={{
                  rules: [INPUT_POSITIVE_NUMBER_VALIDATOR],
                }}
              />
            )}
          </>
        ) : null}
        {modalType === ModalMainTypes.Add ? (
          <Autocomplete
            id="driver"
            label="Driver"
            options={drivers}
            formItemProps={{
              rules: [{ required: true, message: 'Driver ID is required!' }],
            }}
            elementProps={{
              onChange: (value: SelectValue): void =>
                form.setFieldsValue({
                  driverNote: driversList.find(
                    (driver: Driver) => driver.id === value,
                  )?.driverNote,
                }),
            }}
          />
        ) : null}
        <TextArea
          id="driverNote"
          label="Driver Notes"
          formItemProps={{
            className: 'aligned-form-item',
          }}
          isEditing={false}
        />
        <InputNumber
          id="cost"
          label="Cost"
          elementProps={{ formatter: DOLLAR_PREFIX }}
          formItemProps={{
            rules: [
              INPUT_POSITIVE_NUMBER_VALIDATOR,
              { required: true, message: 'Cost is required!' },
            ],
          }}
          isEditing={!isReadonly}
        />
        <TextArea
          id="requestNote"
          label="Request Notes"
          formItemProps={{
            className: 'aligned-form-item',
          }}
        />
      </div>
    </AddOrEditModal>
  );
};
