import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  FiltersSetData,
  PaginatedResponse,
  PMBrowseFilterOptionsResponse,
  ProjectBrowseResponse,
  ProjectDetailsResponse,
  ProjectManagementDetailsResponse,
  ProjectManagementRequestFilter,
} from '@models/interfaces';
import { ValidationParams } from '@models/types';
import { BreadcrumbsActions, ProjectManagementActions } from '@store/actions';
import { ProjectManagementEffects, ProjectsEffects } from '@store/effects';
import { CommonHttpService } from './common-http.service';

export class ProjectManagementHttpService extends CommonHttpService {
  @StoredRequest(ProjectManagementActions.getPMBrowseDataAction)
  getProjectManagementBrowse(
    params: ProjectManagementRequestFilter,
  ): Promise<PaginatedResponse<ProjectBrowseResponse>> {
    return this.http.callApi.get(`${this.apiUrl}/project-management`, null, {
      params,
    });
  }

  @StoredRequest(ProjectManagementActions.getPMBrowseOptions)
  getPMBrowseFiltersOptions(): Promise<PMBrowseFilterOptionsResponse> {
    return this.http.callApi.get(
      `${this.apiUrl}/project-management/filter/options`,
    );
  }

  @StoredRequest(
    ProjectManagementActions.getPMDetailsDataAction,
    BreadcrumbsActions.projectManagement,
  )
  async getProjectManagementDetails(
    projectBundleID: string,
  ): Promise<
    Omit<ProjectManagementDetailsResponse, 'categories' | 'paceTasks'>
  > {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/project-management/${projectBundleID}`,
    );

    ProjectsEffects.setHoldOptions({
      holdReason: response.holdReason,
      projectStatus: response.projectStatus,
    } as ProjectDetailsResponse);

    return ProjectManagementEffects.setFinancial(
      ProjectManagementEffects.setOOMInformation(
        ProjectManagementEffects.setCurrentProvidenceAgent(
          ProjectManagementEffects.setPaceTasks(
            ProjectManagementEffects.setPaceTaskFields(
              ProjectManagementEffects.setProjectFuzeIDs(response),
            ),
          ),
        ),
      ),
    );
  }

  @StoredRequest(ProjectManagementActions.getPMBrowseFiltersData)
  getPMBrowseFiltersSets(): Promise<FiltersSetData[]> {
    return this.http.callApi.get(`${this.apiUrl}/project-management/filter`);
  }

  @StoredRequest(ProjectManagementActions.createPMBrowseFiltersSetAction)
  async createPMBrowseFiltersSet(
    body: FiltersSetData,
  ): Promise<FiltersSetData> {
    const id = await this.http.callApi.post(
      `${this.apiUrl}/project-management/filter`,
      body,
    );

    return {
      id,
      ...body,
    };
  }

  @StoredRequest(ProjectManagementActions.updatePMBrowseFiltersSetAction)
  async updatePMBrowseFiltersSet({
    id,
    ...body
  }: FiltersSetData): Promise<FiltersSetData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/project-management/filter/${id}`,
      body,
    );

    return { id, ...body };
  }

  @StoredRequest(ProjectManagementActions.deletePMBrowseFiltersSetAction)
  async deletePMBrowseFiltersSet(id: string): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/project-management/filter/${id}`,
    );

    return id;
  }

  getFilterNameValidation(
    { id }: ValidationParams,
    name: string,
  ): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/project-management/filter/validate-name`,
      null,
      {
        params: { id, name },
      },
    );
  }
}
