import nextId from 'react-id-generator';
import {
  EquipmentModal,
  LeasingDetails,
  ScopingEquipmentTableItem,
  ScopingInfo,
  SectorEquipmentDataResp,
  SectorEquipmentResp,
  SectorEquipmentTypeResp,
} from '@models/interfaces';
import { arraySort } from '../../../core/utils/methods/array-sort';

export const sortEquipments = (data: EquipmentModal[]): EquipmentModal[] =>
  arraySort<EquipmentModal>(data, 'ASC', 'equipmentTypeName', 'modelNumber');

export const getProcessedEquipments = (
  equipmentTypeNames: Set<string>,
  equipments:
    | ScopingEquipmentTableItem[]
    | LeasingDetails['leasing']['leasingEquipments']
    | LeasingDetails['scoping']['otherEquipments'],
  generatedIdName?: string,
): EquipmentModal[] =>
  sortEquipments(
    (equipments || []).map(
      (
        equipment:
          | ScopingEquipmentTableItem
          | LeasingDetails['leasing']['leasingEquipments'][number]
          | LeasingDetails['scoping']['otherEquipments'][number],
      ): EquipmentModal => {
        equipmentTypeNames.add(equipment.equipment.equipmentType.name);

        return {
          RAD: 'RAD' in equipment && equipment.RAD ? equipment.RAD : undefined,
          quantity: equipment.quantity,
          equipment: equipment.equipment.id,
          modelNumber: equipment.equipment.modelNumber,
          equipmentType: equipment.equipment.equipmentType.id,
          equipmentTypeName: equipment.equipment.equipmentType.name,
          ...('isLeaseRight' in equipment && equipment.isLeaseRight != null
            ? { isLeaseRight: equipment.isLeaseRight }
            : {}),
          ...(generatedIdName ? { generatedId: nextId(generatedIdName) } : {}),
        };
      },
    ),
  );

export const equipmentDuplicatesCombiner = (
  data: EquipmentModal[],
): EquipmentModal[] =>
  sortEquipments(
    Object.values(
      data.reduce(
        (acc: Record<string, EquipmentModal>, curItem: EquipmentModal) => {
          const key = `${curItem.equipmentType}${curItem.equipment}`;

          return {
            ...acc,
            [key]: {
              ...curItem,
              quantity: acc[key]
                ? acc[key].quantity + curItem.quantity
                : curItem.quantity,
            },
          };
        },
        {},
      ),
    ),
  );

export const getEquipmentTotalsFromScoping = (
  equipmentTypeNames: Set<string>,
  scopingInfo: ScopingInfo | LeasingDetails['scoping'],
  generatedIdName?: string,
): EquipmentModal[] => {
  const scopingData = scopingInfo ?? {};
  const { otherEquipments, scopingSectors } = scopingData;

  const processedOtherEquipments = getProcessedEquipments(
    equipmentTypeNames,
    otherEquipments,
    generatedIdName,
  );

  const allSectorsEquipments = (scopingSectors || []).flatMap(
    (
      sector:
        | SectorEquipmentDataResp
        | LeasingDetails['scoping']['scopingSectors'][number],
    ) =>
      // https://github.com/microsoft/TypeScript/issues/44063
      (sector.sectorEquipmentTypes as any[]).reduce(
        (
          acc: EquipmentModal[],
          curItem:
            | SectorEquipmentTypeResp
            | LeasingDetails['scoping']['scopingSectors'][number]['sectorEquipmentTypes'][number],
        ) => {
          equipmentTypeNames.add(curItem.equipmentType.name);

          return [
            ...acc,
            ...curItem.equipments.map(
              (
                equipment:
                  | SectorEquipmentResp
                  | LeasingDetails['scoping']['scopingSectors'][number]['sectorEquipmentTypes'][number]['equipments'][number],
              ) => ({
                equipmentType: curItem.equipmentType.id,
                equipmentTypeName: curItem.equipmentType.name,
                equipment: equipment.equipment?.id || '',
                modelNumber: equipment.equipment?.modelNumber || '',
                quantity: equipment.quantity || 1,
                ...(generatedIdName
                  ? { generatedId: nextId(generatedIdName) }
                  : {}),
              }),
            ),
          ];
        },
        [],
      ),
  );

  return equipmentDuplicatesCombiner([
    ...processedOtherEquipments,
    ...allSectorsEquipments,
  ]);
};
