import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { SelectValue } from 'antd/es/select';
import {
  AccountingBrowseFilter,
  ConfigureFilterProps,
  RangePickerValues,
} from '@models/interfaces';
import { Autocomplete, RangePicker } from '@shared/modules';
import { SOFToolSelectors } from '@store/selectors';

export const Filters: FC<ConfigureFilterProps<AccountingBrowseFilter>> = ({
  value,
  onChanges,
}: ConfigureFilterProps<AccountingBrowseFilter>) => {
  const filtersOptions = useSelector(SOFToolSelectors.browseFilters);

  return (
    <>
      <Autocomplete
        id="PSLocationCode"
        key="PSLocationCode"
        label="PS Location Code"
        options={filtersOptions?.PSLocationCodes || []}
        elementProps={{
          allowClear: true,
          value: value.PSLocationCode,
          onChange: (e: SelectValue): void =>
            onChanges({ PSLocationCode: e as string }),
        }}
      />
      <Autocomplete
        id="fuzeID"
        key="fuzeID"
        label="Fuze ID"
        options={filtersOptions?.fuzeIDs || []}
        elementProps={{
          allowClear: true,
          value: value.fuzeID,
          onChange: (e: SelectValue): void =>
            onChanges({ fuzeID: e as string }),
        }}
      />
      <RangePicker
        id="RE020ActualDate"
        key="RE020ActualDate"
        label="RE020 Actual Date"
        formItemProps={{ className: 'aligned-form-item' }}
        value={value.RE020ActualDate}
        onChange={(date: Undefinable<RangePickerValues>): void =>
          onChanges({ RE020ActualDate: date })
        }
      />
    </>
  );
};
