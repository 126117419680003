import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { DropdownValuesState } from '../reducers/admin/dropdown-values.reducer';
import { ProjectState } from '../reducers/project.reducer';
import { ProjectFuzeIDsState } from '../reducers/project-fuze-ids.reducer';

const projectFuzeIDsState = (state: AppState): ProjectFuzeIDsState =>
  state.projectFuzeIDs;
const projectState = (state: AppState): ProjectState => state.project;
const DDVState = (state: AppState): DropdownValuesState =>
  state.admin.dropdownValues;

export namespace ProjectFuzeIDsSelectors {
  export const getProjectFuzeIDsTableVisible = createSelector(
    [projectFuzeIDsState],
    ({ projectFuzeIDsTableVisible }: ProjectFuzeIDsState) =>
      projectFuzeIDsTableVisible,
  );

  export const getProjectFuzeIDs = createSelector(
    [projectFuzeIDsState],
    ({ currentProjectFuzeIDs }: ProjectFuzeIDsState) => currentProjectFuzeIDs,
  );

  export const getProjectFuzeIDInfo = createSelector(
    [projectFuzeIDsState],
    ({
      projectFuzeIDsTableVisible,
      currentProjectFuzeIDs,
      isCategoriesTableEditing,
    }: ProjectFuzeIDsState) => ({
      projectFuzeIDsTableVisible,
      currentProjectFuzeIDs,
      isCategoriesTableEditing,
    }),
  );

  export const getProjectFuzeIDModalInfo = createSelector(
    [DDVState, projectState],
    (
      { dropdownsOptions, dropdownValues }: DropdownValuesState,
      { currentProject }: ProjectState,
    ) => ({
      dropdownsOptions,
      dropdownValues,
      currentProject,
    }),
  );
}
