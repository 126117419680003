import { SubPage, Tab } from '@symfa-inc/providence-verizon-types';
import { TabAccessGuard } from '@core/guards/tab-access.guard';
import { TabProps } from '@models/classes/models';
import { Financial } from '@shared/components/financial';
import { PermittingSelectors } from '@store/selectors/permitting.selectors';
import {
  PermittingContacts,
  PermittingJurisdictionAudit,
  PermittingSummary,
  PermittingWorkflow,
} from '../details/tabs';

export const PERMITTING_TABS_PROPS: TabProps[] = [
  {
    path: '/summary',
    component: PermittingSummary,
    name: 'Summary',
    guards: [
      new TabAccessGuard('permitting', SubPage.PermittingBrowse, Tab.Summary),
    ],
  },
  {
    path: '/workflow',
    component: PermittingWorkflow,
    name: 'Workflow',
    guards: [
      new TabAccessGuard('permitting', SubPage.PermittingBrowse, Tab.Workflow),
    ],
  },
  {
    path: '/jurisdiction-audit',
    component: PermittingJurisdictionAudit,
    name: 'Jurisdiction Audit',
    guards: [
      new TabAccessGuard(
        'permitting',
        SubPage.PermittingBrowse,
        Tab.JurisdictionAudit,
      ),
    ],
  },
  {
    path: '/contacts',
    component: PermittingContacts,
    name: 'Contacts',
    guards: [
      new TabAccessGuard('permitting', SubPage.PermittingBrowse, Tab.Contacts),
    ],
  },
  {
    path: '/financial',
    component: Financial,
    name: 'Financial',
    componentProps: {
      feeAccessPath: 'permitting',
      siteDataRootPath: ['permitting', 'detailsData', 'site'],
      projectTypeSelector: PermittingSelectors.getProjectType,
      projectIdSelector: PermittingSelectors.getProjectId,
    },
    guards: [
      new TabAccessGuard('permitting', SubPage.PermittingBrowse, Tab.Financial),
    ],
  },
];
