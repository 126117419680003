import React, {
  forwardRef,
  PropsWithChildren,
  Ref,
  useImperativeHandle,
  useState,
} from 'react';
import classNames from 'classnames';
import { WHITESPACE_INPUT_LIMIT_RULES, YES_NO_GROUP } from '@models/constants';
import {
  Autocomplete,
  Input,
  InputNumber,
  RadioGroup,
  SwitchBox,
} from '@shared/modules';
import { SiteInformationModControlsProps } from '../../models/interfaces';
import { ModControlsRefType } from '../../models/types';

export const ModControls = forwardRef(
  (
    {
      isEditing,
      dropdownsOptions,
      permissions: {
        open48VBreakPosition,
        existingRectifier,
        iceBridgeLength,
        isFiberDCTrunkInInnerDuct,
        isFiberRunWithDCTrunkInSameDuct,
        powerPlantType,
      },
    }: PropsWithChildren<SiteInformationModControlsProps>,
    forwardedRef: Ref<ModControlsRefType>,
  ) => {
    const [unknownSwitchState, setUnknownSwitchState] =
      useState<boolean>(false);

    useImperativeHandle(forwardedRef, () => ({
      // getter and setter unknownSwitchState
      unknownSwitchState,
      changeUnknownSwitchState(value: boolean): void {
        setUnknownSwitchState(value);
      },
      //
      setUnknownSwitch(value: boolean): void {
        setUnknownSwitchState(value);
      },
    }));

    return (
      <>
        <div
          className={classNames('power-plant__wrapper', {
            'power-plant__wrapper-active': isEditing,
          })}
        >
          {unknownSwitchState ? (
            <Input
              id="unknownPowerPlantType"
              label="Power Plant Type"
              formItemProps={{
                className: 'power-plant-type',
                rules: WHITESPACE_INPUT_LIMIT_RULES,
              }}
              isEditing={isEditing && !!powerPlantType}
            />
          ) : (
            <Autocomplete
              id="powerPlantType"
              label="Power Plant Type"
              options={dropdownsOptions?.PowerPlantTypes}
              formItemProps={{ className: 'power-plant-type' }}
              isEditing={isEditing && !!powerPlantType}
            />
          )}
          {isEditing && (
            <SwitchBox
              title="Unknown"
              checked={unknownSwitchState}
              onChange={(): void =>
                setUnknownSwitchState((prevValue: boolean) => !prevValue)
              }
              disabled={isEditing && !powerPlantType}
            />
          )}
        </div>
        <InputNumber
          id="open48VBreakerPosition"
          label="Open -48V Breaker Positions"
          isEditing={isEditing && !!open48VBreakPosition}
        />
        <InputNumber
          id="existingRectifier"
          label="Existing Rectifiers"
          isEditing={isEditing && !!existingRectifier}
        />
        <InputNumber
          id="iceBridgeLength"
          label="Ice Bridge Length"
          isEditing={isEditing && !!iceBridgeLength}
        />
        <RadioGroup
          id="isFiberDCTrunkInInnerDuct"
          label="Fiber & DC Trunk in Inner Duct"
          buttons={YES_NO_GROUP}
          isEditing={isEditing && !!isFiberDCTrunkInInnerDuct}
        />
        <RadioGroup
          id="isFiberRunWithDCTrunkInSameDuct"
          label="Is Fiber run with DC Trunk in Same Duct"
          buttons={YES_NO_GROUP}
          isEditing={isEditing && !!isFiberRunWithDCTrunkInSameDuct}
        />
      </>
    );
  },
);
