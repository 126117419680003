import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import {
  IRule,
  LinkKey,
  NotifyType,
  RuleArea,
  RuleFieldValueType,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';
import { Form, Tooltip } from 'antd';
import { SelectValue } from 'antd/lib/select';
import infoIcon from 'assets/icons/info.svg';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';
import {
  arraySort,
  getEnumKeyByEnumValue,
  isValidForm,
  stringEnumToSelectableObject,
} from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import {
  NotifyTypeControls,
  ProjectTypeControls,
} from '@notifications/components';
import { NotifyTypeControlsRefType } from '@notifications/models';
import { AddOrEditModal } from '@shared/components';
import { Autocomplete, Select, TextArea } from '@shared/modules';
import { RuleModalProps } from '../models';
import {
  ChaneAreaActionPayload,
  ChaneTypeActionPayload,
  INITIAL_RULE_MODAL_STATE,
  RuleModalActions,
  ruleModalReducer,
} from './reducer';

import './styles.scss';

export const RuleModal: FC<RuleModalProps> = ({
  itemValue,
  visible,
  modalType,
  toggleModal,
  userList,
  towerOwners,
}: PropsWithChildren<RuleModalProps>) => {
  const notificationsService = HttpService.getHttpRequests(
    NotificationsHttpService,
  );

  const [form] = Form.useForm();

  const ref = useRef<NotifyTypeControlsRefType>(null);

  const areaOptions = stringEnumToSelectableObject(RuleArea);
  const fieldTypeOptions = stringEnumToSelectableObject(RuleFieldValueType);

  const [ruleModalState, ruleModalDispatch] = useReducer(
    ruleModalReducer,
    INITIAL_RULE_MODAL_STATE,
  );

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...(itemValue || {}),
      });

      if (itemValue) {
        ref.current?.changeNotifyType(
          getEnumKeyByEnumValue(NotifyType, itemValue.notifyType),
        );
        ref.current?.changeRoles(itemValue.roles || []);
      }
    }
  }, [visible, itemValue, form]);

  const onClose = (): void => {
    toggleModal(modalType);

    form.resetFields();

    ruleModalDispatch({ type: RuleModalActions.Reset });

    ref.current?.changeNotifyType(null);
    ref.current?.changeRoles(null);
  };

  const add = async (): Promise<void> => {
    if (await isValidForm(form)) {
      const {
        triggerFieldArea,
        triggerFieldType,
        completingFieldArea,
        completingFieldType,
        ...otherFields
      } = form.getFieldsValue();

      await notificationsService.createRule(otherFields as Omit<IRule, '_id'>);

      onClose();
    }
  };

  const edit = async (): Promise<void> => {
    if (await isValidForm(form)) {
      const {
        triggerFieldArea,
        triggerFieldType,
        completingFieldArea,
        completingFieldType,
        ...otherFields
      } = form.getFieldsValue();

      await notificationsService.updateRule(
        itemValue!._id,
        otherFields as Omit<IRule, '_id'>,
      );

      onClose();
    }
  };

  const onOk = async (): Promise<void> => {
    await (modalType === ModalMainTypes.Add ? add() : edit());
  };

  const validateField = (): Promise<void> => {
    const { triggerFieldKey, completingFieldKey } = form.getFieldsValue();

    return triggerFieldKey === completingFieldKey
      ? Promise.reject(
          new Error('Trigger/Completing Field are must be different'),
        )
      : Promise.resolve();
  };

  return (
    <AddOrEditModal
      onOk={onOk}
      onCancel={onClose}
      visible={visible}
      type={modalType}
      title="Rule"
      okText="Save"
      cancelText="Cancel"
      formProps={{
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
        form,
      }}
    >
      <Tooltip title="Trigger/Completing Field Area and Trigger/Completing Field Type are used for filtering Trigger/Completing Field only!">
        <img className="rule-modal-tooltip-icon" src={infoIcon} alt="info" />
      </Tooltip>
      {/* Trigger */}
      <Select
        id="triggerFieldArea"
        label="Trigger Field Area"
        options={areaOptions}
        elementProps={{
          allowClear: true,
          onChange: (value: Undefinable<SelectValue>): void => {
            ruleModalDispatch({
              type: RuleModalActions.ChangeTriggerFieldArea,
              payload: value as ChaneAreaActionPayload,
            });
          },
        }}
      />
      <Select
        id="triggerFieldType"
        label="Trigger Field Type"
        options={fieldTypeOptions}
        elementProps={{
          allowClear: true,
          onChange: (value: Undefinable<SelectValue>): void => {
            ruleModalDispatch({
              type: RuleModalActions.ChangeTriggerFieldType,
              payload: value as ChaneTypeActionPayload,
            });
          },
        }}
      />
      <Autocomplete
        id="triggerFieldKey"
        label="Trigger Field"
        formItemProps={{
          dependencies: ['completingFieldKey'],
          rules: [
            { required: true, message: 'Trigger Field is required!' },
            { validator: validateField },
          ],
        }}
        options={ruleModalState.triggerFieldOptions}
      />
      {/* Completing */}
      <Select
        id="completingFieldArea"
        label="Completing Field Area"
        options={areaOptions}
        elementProps={{
          allowClear: true,
          onChange: (value: Undefinable<SelectValue>): void => {
            ruleModalDispatch({
              type: RuleModalActions.ChangeCompletingFieldArea,
              payload: value as ChaneAreaActionPayload,
            });
          },
        }}
      />
      <Select
        id="completingFieldType"
        label="Completing Field Type"
        options={fieldTypeOptions}
        elementProps={{
          allowClear: true,
          onChange: (value: Undefinable<SelectValue>): void => {
            ruleModalDispatch({
              type: RuleModalActions.ChangeCompletingFieldType,
              payload: value as ChaneTypeActionPayload,
            });
          },
        }}
      />
      <Autocomplete
        id="completingFieldKey"
        label="Completing Field"
        formItemProps={{
          dependencies: ['triggerFieldKey'],
          rules: [
            { required: true, message: 'Completing Field is required!' },
            { validator: validateField },
          ],
        }}
        options={ruleModalState.completingFieldOptions}
      />
      <ProjectTypeControls isEditMode={itemValue != null} />
      <NotifyTypeControls
        ref={ref}
        form={form}
        userList={userList}
        towerOwners={towerOwners}
      />
      <TextArea
        id="message"
        label="Message"
        formItemProps={{
          rules: [{ required: true, message: 'Message is required!' }],
        }}
      />
      <Autocomplete
        id="redirectLinkKey"
        label="Redirect page"
        formItemProps={{
          rules: [{ required: true, message: 'Redirect page is required!' }],
        }}
        options={arraySort(
          stringEnumToSelectableObject(LinkKey),
          'ASC',
          'viewValue',
        )}
      />
    </AddOrEditModal>
  );
};
