import { Nullable } from '@symfa-inc/providence-verizon-types';
import {
  AccountingNotificationData,
  DashboardItem,
  DashboardProjectData,
} from '@models/interfaces';

export const accountingNotificationSearcher = (
  data: DashboardItem<AccountingNotificationData>[],
  searchValue: string,
): DashboardItem<AccountingNotificationData>[] => {
  const regExp = new RegExp(searchValue, 'gi');

  return !searchValue
    ? data
    : data.filter(
        ({
          notificationData: { type },
          projectData: {
            projectBundleID,
            projectType,
            siteName,
            PSProjectNumbers,
            fuzeIDs,
          },
        }: DashboardItem<AccountingNotificationData>) =>
          [
            type,
            projectBundleID.toString(),
            siteName,
            projectType,
            ...PSProjectNumbers.map(
              ({ value }: DashboardProjectData['PSProjectNumbers'][number]) =>
                value,
            ),
            ...fuzeIDs.map(
              ({ value }: DashboardProjectData['fuzeIDs'][number]) => value,
            ),
          ].some((item: Nullable<string>) => item && regExp.test(item)),
      );
};
