import React, { ReactNode } from 'react';
import { UserRole } from '@symfa-inc/providence-verizon-types';
import { ColumnsType } from 'antd/lib/table';
import {
  mapFromBackendToUserRole,
  readOnlyDatePickerRenderer,
} from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { UserManagementData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { ProjectStatus } from '@shared/modules';

export const getUserManagementColumns = (
  editFunc: ColumnsFunc<UserManagementData>,
  deleteFunc: ColumnsFunc<UserManagementData>,
): ColumnsType => [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: true,
  },
  {
    title: 'Role',
    key: 'roles',
    render: ({
      roles,
      responsibleForProjectType,
    }: UserManagementData): ReactNode => (
      <div className="project-fuze-id-cell">
        {roles.map((role: UserRole) => {
          const viewUserRole = mapFromBackendToUserRole(role);
          const hasAdminOrEMRole = [
            UserRole.Admin,
            UserRole.EngineeringManager,
          ].includes(role);
          const value = hasAdminOrEMRole
            ? `${viewUserRole} - ${
                responsibleForProjectType?.value ?? 'unknown'
              }`
            : viewUserRole;

          return <span key={role}>{value}</span>;
        })}
      </div>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    width: 310,
  },
  {
    title: 'Status',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (isActive: boolean): ReactNode => (
      <ProjectStatus status={isActive ? 'Active' : 'Inactive'} />
    ),
  },
  {
    title: 'Last Logged In',
    dataIndex: 'lastLoginDate',
    key: 'lastLoginDate',
    sorter: true,
    render: readOnlyDatePickerRenderer,
  },
  actionButtons({ editFunc, deleteFunc }),
];
