import { actionCreatorFactory } from '../../core/utils/factories';
import {
  FiltersSetData,
  PaceTask,
  PaginatedResponse,
  PMBrowseFilterOptionsResponse,
  ProjectBrowseResponse,
  ProjectManagementDetails,
} from '../../models/interfaces';

const actionCreator = actionCreatorFactory('PROJECT_MANAGEMENT');

export namespace ProjectManagementActions {
  export const getPMBrowseDataAction =
    actionCreator.createAsyncAction<PaginatedResponse<ProjectBrowseResponse>>(
      'GET_BROWSE_DATA',
    );
  export const getPMDetailsDataAction =
    actionCreator.createAsyncAction<ProjectManagementDetails>(
      'GET_DETAILS_DATA',
    );
  export const getPMBrowseFiltersData = actionCreator.createAsyncAction<
    FiltersSetData[]
  >('GET_BROWSE_FILTER_DATA');
  export const getPMBrowseOptions =
    actionCreator.createAsyncAction<PMBrowseFilterOptionsResponse>(
      'GET_PM_BROWSE_OPTIONS',
    );
  export const createPMBrowseFiltersSetAction =
    actionCreator.createAsyncAction<FiltersSetData>('CREATE_FILTERS_SET');
  export const updatePMBrowseFiltersSetAction =
    actionCreator.createAsyncAction<FiltersSetData>('UPDATE_FILTERS_SET');
  export const deletePMBrowseFiltersSetAction =
    actionCreator.createAsyncAction<string>('DELETE_FILTERS_SET');
  export const getPMScreenPaceTasks = actionCreator.createAsyncAction<
    PaceTask[]
  >('GET_PM_SCREEN_PACE_TASKS');
}
