import { createReducer, on } from '../../core/utils/store';
import {
  PayloadAndState,
  ProjectPaceTask,
  ProjectPaceTaskData,
  ReducerConfigPaceTaskData,
} from '../../models/interfaces';
import { ProjectActions } from '../actions/project.actions';

export interface PaceTasksState {
  currentPaceTasks: ProjectPaceTask[];
  paceTaskFields: ProjectPaceTask[];
  arePaceTasksChanged: boolean;
  isFetching: boolean;
  errors: boolean;
}

const initialState: PaceTasksState = {
  currentPaceTasks: [],
  paceTaskFields: [],
  arePaceTasksChanged: false,
  isFetching: false,
  errors: false,
};

const paceTaskMapper = (
  payload: ReducerConfigPaceTaskData[],
  paceTasks: ProjectPaceTask[],
): ProjectPaceTask[] =>
  paceTasks.map((projectPaceTask: ProjectPaceTask) => {
    const editableTask = payload.find(
      ({ id: PACETaskId }: ReducerConfigPaceTaskData) =>
        PACETaskId === projectPaceTask.id,
    );

    return editableTask
      ? {
          ...projectPaceTask,
          projectPaceTaskData: {
            ...projectPaceTask.projectPaceTaskData,
            [editableTask.dateType]: editableTask.date,
          },
        }
      : projectPaceTask;
  });

export const reducer = createReducer(
  initialState,
  on(
    ProjectActions.updateProjectPaceTasksAction,
    ({
      payload: currentPaceTasks,
    }: PayloadAndState<ProjectPaceTask[], PaceTasksState>) => ({
      currentPaceTasks,
    }),
  ),
  on(
    ProjectActions.updateProjectPaceTaskFields,
    ({
      payload: paceTaskFields,
    }: PayloadAndState<ProjectPaceTask[], PaceTasksState>) => ({
      paceTaskFields,
    }),
  ),
  on(
    ProjectActions.setArePACETasksChanged,
    ({ payload }: PayloadAndState<boolean, PaceTasksState>) => ({
      arePaceTasksChanged: payload,
    }),
  ),
  on(
    ProjectActions.updateProjectPaceTaskFieldsByConfig,
    ({
      payload,
      state: { paceTaskFields, currentPaceTasks },
    }: PayloadAndState<ReducerConfigPaceTaskData[], PaceTasksState>) => ({
      paceTaskFields: paceTaskMapper(payload, paceTaskFields),
      currentPaceTasks: paceTaskMapper(payload, currentPaceTasks),
    }),
  ),
  on(
    ProjectActions.updateProjectPaceTaskFieldsFromSummaryPageTable,
    ({
      payload,
      state: { paceTaskFields },
    }: PayloadAndState<ProjectPaceTaskData[], PaceTasksState>) => ({
      paceTaskFields: paceTaskFields.map((paceTaskField: ProjectPaceTask) => {
        const editableTask = payload.find(
          ({ paceTask }: ProjectPaceTaskData) => paceTask === paceTaskField.id,
        );

        delete editableTask?.paceTask;

        return editableTask
          ? {
              ...paceTaskField,
              projectPaceTaskData: {
                ...paceTaskField.projectPaceTaskData,
                ...editableTask,
              },
            }
          : paceTaskField;
      }),
    }),
  ),
  on(
    ProjectActions.updateProjectPaceTaskFromOOMTableAction,
    ({
      payload: { id, actualDate },
      state: { currentPaceTasks },
    }: PayloadAndState<
      Pick<ProjectPaceTask, 'id'> & {
        actualDate: ProjectPaceTaskData['actualDate'];
      },
      PaceTasksState
    >) => ({
      currentPaceTasks: currentPaceTasks.map((v: ProjectPaceTask) =>
        v.id !== id
          ? v
          : {
              ...v,
              projectPaceTaskData: { ...v.projectPaceTaskData, actualDate },
            },
      ),
    }),
  ),
);
