import { EnvironmentType } from '../../models/enums';
import { ConnectionConfig, ProcessEnvMap } from '../../models/interfaces';

export class Config {
  private static instance: Config;
  private readonly env: ProcessEnvMap;

  static get(): Config {
    this.instance = this.instance ?? new Config();

    return this.instance;
  }

  private constructor() {
    this.env = process.env as ProcessEnvMap;
  }

  // # Env
  get mode(): EnvironmentType {
    return (
      (this.env.REACT_APP_NODE_ENV as EnvironmentType) ?? EnvironmentType.DEV
    );
  }

  get isDevelopment(): boolean {
    return this.mode === EnvironmentType.DEV;
  }

  get isProduction(): boolean {
    return this.mode === EnvironmentType.PROD;
  }

  // # Connection
  get connectionConfig(): ConnectionConfig {
    return {
      apiUrl: `${this.env.REACT_APP_API_URL}/api`,
      staticApiUrl: this.env.REACT_APP_API_URL,
      wsUrl: this.env.REACT_APP_SOCKET_URL,
      qoppa: this.env.REACT_APP_QOPPA_PAS,
      requestTimeout: +this.env.REACT_APP_REQUEST_TIMEOUT,
    };
  }

  get socketUrl(): string {
    return this.env.REACT_APP_SOCKET_URL;
  }

  // # Zoho
  // get zohoSrc(): string {
  //   return this.env.REACT_APP_ZOHO_SCRIPT_SRC;
  // }

  // # Bing
  get BingMapsKey(): string {
    return this.env.REACT_APP_BING_MAPS_KEY;
  }
}
