import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { UserRole } from '@symfa-inc/providence-verizon-types';
import { Details } from '@shared/modules';
import { UserSelectors } from '@store/selectors';
import { Dashboard, EmptyPage } from './content';
import { isNotOnlyReadOnlyUser } from './helpers';

import './styles.scss';

export const Home: FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const history = useHistory();

  const roles = useSelector(UserSelectors.getUserRoles);
  let content = <EmptyPage />;

  if (isNotOnlyReadOnlyUser(roles)) {
    content = roles?.includes(UserRole.Admin) ? (
      <Details
        {...props}
        headerBar={false}
        destroyInactiveTabPane
        animated={false}
      />
    ) : (
      <Dashboard type="assigned" history={history} />
    );
  }

  return content;
};
