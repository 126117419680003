import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Input, PrimaryButton, ShowMoreInput } from '@shared/modules';

const { useForm } = Form;

export const ProjectInformation: FC<InformationProps> = ({
  className,
  data: { projectNote, ...data },
  expectedId,
}: PropsWithChildren<InformationProps>) => {
  const [form] = useForm();

  useEffect(() => {
    if (data) {
      const {
        projectBundleID,
        projectType,
        projectSubtype,
        projectStatus,
        progressStatus,
        civilVendor,
        AEVendor,
        AEMountVendor,
      } = data;

      form.setFieldsValue({
        projectBundleID,
        projectType: projectType?.value,
        projectStatus: projectStatus?.value,
        progressStatus: progressStatus?.value,
        civilVendor: civilVendor?.value,
        AEVendor: AEVendor?.value,
        AEMountVendor: AEMountVendor?.value,
        projectSubtype: projectSubtype?.value,
      });
    }
  }, [data]);

  return (
    <Panel header="Project Information" className={className}>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} form={form}>
        <Row>
          <Col span="12">
            <Input
              id="projectBundleID"
              label="Project Bundle ID"
              isEditing={false}
            />
            <Input id="projectType" label="Project Type" isEditing={false} />
            <Input
              id="projectStatus"
              label="Project Status"
              isEditing={false}
            />
            <Input id="civilVendor" label="Civil Vendor" isEditing={false} />
          </Col>
          <Col span="12">
            <Input id="AEVendor" label="A/E CD Vendor" isEditing={false} />
            <Input
              id="AEMountVendor"
              label="A/E Mount Vendor"
              isEditing={false}
            />
            <ShowMoreInput
              id="projectNote"
              label="Project Notes"
              data={projectNote}
              form={form}
              isEditing={false}
            />
          </Col>
        </Row>
        <Row className="action-buttons">
          <PrimaryButton
            title="View details"
            target="_blank"
            href={`/project/${expectedId}/details`}
          />
        </Row>
      </Form>
    </Panel>
  );
};
