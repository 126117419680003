import { actionCreatorFactory } from '@core/utils/factories';
import {
  PaginatedResponse,
  ProcessEnvMap as KeyString,
  SiteAuditBrowseResponse,
  SiteAuditCablesData,
  SiteAuditDetailsResponse,
  SiteAuditRaycapsData,
} from '@models/interfaces';

const actionCreator = actionCreatorFactory('SITE_AUDIT');

export namespace SiteAuditActions {
  export const getSiteAuditBrowseDataAction = actionCreator.createAsyncAction<
    PaginatedResponse<SiteAuditBrowseResponse>
  >('GET_SITE_AUDIT_BROWSE');
  export const updateSiteAuditBrowseDataAction =
    actionCreator.createAsyncAction<PaginatedResponse<SiteAuditBrowseResponse>>(
      'UPDATE_SITE_AUDIT_BROWSE',
    );
  export const getSiteAuditDetailsAction =
    actionCreator.createAsyncAction<SiteAuditDetailsResponse>(
      'GET_SITE_AUDIT_DETAILS',
    );
  export const updateSiteAuditDetailsAction =
    actionCreator.createAsyncAction<SiteAuditDetailsResponse>(
      'UPDATE_SITE_AUDIT_DETAILS',
    );
  export const setCablesAction = actionCreator.createAsyncAction<
    SiteAuditCablesData[]
  >('CLEAR_SITE_AUDIT_CABLES');
  export const addCableItemAction =
    actionCreator.createAsyncAction<SiteAuditCablesData>(
      'ADD_SITE_AUDIT_CABLE_ITEM',
    );
  export const updateCableItemAction =
    actionCreator.createAsyncAction<SiteAuditCablesData>(
      'UPDATE_SITE_AUDIT_CABLE_ITEM',
    );
  export const deleteCableItemAction =
    actionCreator.createAsyncAction<KeyString>('DELETE_SITE_AUDIT_CABLE_ITEM');
  export const setRaycapsAction = actionCreator.createAsyncAction<
    SiteAuditRaycapsData[]
  >('CLEAR_SITE_AUDIT_RAYCAPS');
  export const addRaycapItemAction =
    actionCreator.createAsyncAction<SiteAuditRaycapsData>(
      'ADD_SITE_AUDIT_RAYCAP_ITEM',
    );
  export const updateRaycapItemAction =
    actionCreator.createAsyncAction<SiteAuditRaycapsData>(
      'UPDATE_SITE_AUDIT_RAYCAP_ITEM',
    );
  export const deleteRaycapItemAction =
    actionCreator.createAsyncAction<KeyString>('DELETE_SITE_AUDIT_RAYCAP_ITEM');
}
