import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { SelectValue } from 'antd/lib/select';
import { useFindCountyAndJurisdictions } from '@core/utils/hooks';
import { arrsToSelectableObject } from '@core/utils/methods';
import {
  AdminDropdownValuesData,
  ConfigureFilterProps,
  EngineeringBrowseFilter,
  OptionProps,
  StateWithCounties,
} from '@models/interfaces';
import { Autocomplete } from '@shared/modules';
import { SharedSelectors } from '@store/selectors';

export const Filters: FC<ConfigureFilterProps<EngineeringBrowseFilter>> = ({
  value,
  formProps,
  onChanges,
}: PropsWithChildren<ConfigureFilterProps<EngineeringBrowseFilter>>) => {
  const { dropdownValues, states } = useSelector(
    SharedSelectors.getDropdownValuesAndStates,
  );

  const [stateId, setStateId] = useState<Undefinable<string>>();
  const [countyOptions, setCountyOptions] = useState<OptionProps[]>([]);
  const [jurisdictionOptions, setJurisdictionOptions] = useState<OptionProps[]>(
    [],
  );

  useFindCountyAndJurisdictions({
    stateId,
    setCountyOptions,
    setJurisdictionOptions,
    effectDepArray: [stateId, states],
  });

  useEffect(() => {
    if (value.stateId !== stateId) {
      setStateId(value.stateId);
    }
  }, [value]);

  return (
    <div className="prov-project-browse-filters">
      <Autocomplete
        id="projectTypeId"
        key="projectTypeId"
        label="Project Type"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProjectType,
          'id',
          'value',
        )}
        elementProps={{
          value: value.projectTypeId,
          onChange: (projectTypeId: SelectValue): void =>
            onChanges({ projectTypeId: projectTypeId as Undefinable<string> }),
          allowClear: true,
        }}
      />
      <Autocomplete
        id="progressStatusId"
        key="progressStatusId"
        label="Progress Status"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProgressStatus,
          'id',
          'value',
        )}
        elementProps={{
          value: value.progressStatusId,
          onChange: (progressStatusId: SelectValue): void =>
            onChanges({
              progressStatusId: progressStatusId as Undefinable<string>,
            }),
          allowClear: true,
        }}
      />
      <Autocomplete
        id="towerOwnerId"
        key="towerOwnerId"
        label="Tower Owner"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.TowerOwner,
          'id',
          'value',
        )}
        elementProps={{
          value: value.towerOwnerId,
          onChange: (towerOwnerId: SelectValue): void =>
            onChanges({
              towerOwnerId: towerOwnerId as Undefinable<string>,
            }),
          allowClear: true,
        }}
      />
      <Autocomplete
        id="stateId"
        key="stateId"
        label="State"
        options={arrsToSelectableObject<StateWithCounties>(
          states,
          'id',
          'name',
        )}
        elementProps={{
          allowClear: true,
          value: value.stateId,
          onChange: (e: SelectValue): void => {
            formProps?.form?.resetFields(['countyId', 'jurisdictionId']);
            onChanges({
              countyId: undefined,
              jurisdictionId: undefined,
              stateId: e as string,
            });
            setStateId(e as string);
          },
        }}
      />
      <Autocomplete
        id="countyId"
        key="countyId"
        label="County"
        title="You should first select a state"
        tooltip={!stateId}
        options={countyOptions}
        elementProps={{
          disabled: !stateId,
          value: value.countyId,
          onChange: (e: SelectValue): void => {
            onChanges({ countyId: e as string });
          },
          allowClear: true,
        }}
      />
      <Autocomplete
        id="jurisdictionId"
        key="jurisdictionId"
        label="Jurisdiction"
        title="You should first select a state"
        tooltip={!stateId}
        options={jurisdictionOptions}
        elementProps={{
          disabled: !stateId,
          allowClear: true,
          value: value.jurisdictionId,
          onChange: (e: SelectValue): void =>
            onChanges({ jurisdictionId: e as string }),
        }}
      />
    </div>
  );
};
