import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Location } from 'history';
import { LocalStorageService, SocketIOService } from '@core/services';
import { showIfAuthorized } from '@core/utils/HOC';
import { fetchItems } from '@core/utils/methods';
import { RouteGroup } from '@models/classes';
import { MenuPlacement, StorageItem } from '@models/enums';
import { User } from '@models/interfaces';
import { MenuGroup, MenuItem } from '@models/types';
import { UserActions } from '@store/actions';
import { UserSelectors } from '@store/selectors';
import { ChangePassword } from './modals';

import './styles.scss';

const { SubMenu } = Menu;

const HeaderMenuComponent: FC = () => {
  const activeUser = useSelector(UserSelectors.activeUser);

  const [menuItems, setMenu] = useState<MenuGroup>([]);

  useEffect(() => {
    fetchItems(MenuPlacement.Header).then((menu: MenuGroup) => setMenu(menu));
  }, []);

  const [isChangePasswordModalOpen, setChangePasswordModal] = useState(false);

  const toggleChangePasswordModal = (): void =>
    setChangePasswordModal(!isChangePasswordModalOpen);

  const localStorageService: LocalStorageService = new LocalStorageService();

  const history = useHistory();

  const dispatch = useDispatch();

  const logout = useCallback(() => {
    history.push('/login');
  }, []);

  useEffect(() => {
    const unlisten = history.listen((location: Location) => {
      if (
        location.pathname.includes('/login') &&
        localStorageService.getItem(StorageItem.ACCESS_TOKEN)
      ) {
        unlisten();

        SocketIOService.get.destroy();
        localStorageService.clear();
        // ZohoService.get.remove();
        dispatch(UserActions.logout.done());

        dispatch(UserActions.getActiveUserAction.done({} as User));
      }
    });
  }, []);

  useEffect(() => {
    setChangePasswordModal(!!activeUser?.isTempPassword);
  }, [activeUser]);

  return (
    <Menu mode="horizontal" className="prov-header-menu">
      <SubMenu
        title={
          <span>
            {activeUser?.firstName} {activeUser?.lastName}
            <DownOutlined className="prov-header-menu__icon" />
          </span>
        }
      >
        <Menu.Item key="changePassword" onClick={toggleChangePasswordModal}>
          Change password
        </Menu.Item>
        {menuItems.map(([item]: MenuItem[]) => {
          const menuItem = item as RouteGroup;

          return (
            <Menu.Item
              className="header-menu-item"
              key={menuItem.path + menuItem.name}
            >
              <Link to={menuItem.path}>{menuItem.name}</Link>
            </Menu.Item>
          );
        })}
        <Menu.Item key="logout" onClick={logout}>
          Sign out
        </Menu.Item>
      </SubMenu>
      <ChangePassword
        visible={isChangePasswordModalOpen}
        onCancel={toggleChangePasswordModal}
        className="prov-change-password-modal"
      />
    </Menu>
  );
};

export const HeaderMenu: FC = showIfAuthorized(HeaderMenuComponent);
