import React, { ReactNode } from 'react';
import {
  ALL_RULE_FIELDS_OPTIONS,
  IRule,
  ISelectorOption,
  Undefinable,
} from '@symfa-inc/providence-verizon-types';
import { actionButtons } from '@models/constants';
import { OptionProps } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import {
  getCurrentNotifyType,
  getValueForUsersOrRolesColumn,
} from '@notifications/helpers';
import { VirtualTableColumn } from '@shared/components';

const renderFieldValueByFieldKey = (fieldKey: string): string =>
  ALL_RULE_FIELDS_OPTIONS.find(
    (option: ISelectorOption) => option.value === fieldKey,
  )?.viewValue || 'Unknown';

const renderProjectParams = (
  options: OptionProps[],
  id: string,
): Undefinable<OptionProps['viewValue']> =>
  options.find((option: OptionProps) => option.value === id)?.viewValue;

export const getRulesTableColumns = (
  usersList: OptionProps[],
  towerOwners: OptionProps[],
  projectTypeDDV: OptionProps[],
  projectTypes: OptionProps[],
  projectSubTypes: OptionProps[],
  editFunc: ColumnsFunc<IRule>,
  deleteFunc: ColumnsFunc<IRule>,
): Array<VirtualTableColumn<IRule>> => [
  {
    title: 'Trigger Field',
    key: 'triggerFieldKey',
    dataIndex: 'triggerFieldKey',
    width: 200,
    render: renderFieldValueByFieldKey,
  },
  {
    title: 'Completing Field',
    key: 'completingFieldKey',
    dataIndex: 'completingFieldKey',
    width: 200,
    render: renderFieldValueByFieldKey,
  },
  {
    title: 'Project Type',
    key: 'projectType',
    dataIndex: 'projectTypeId',
    width: 200,
    render: (value: string) => renderProjectParams(projectTypeDDV, value),
  },
  {
    title: 'Project Subtype',
    key: 'projectSubType',
    dataIndex: 'projectSubTypeId',
    width: 200,
    render: (value: string) => renderProjectParams(projectSubTypes, value),
  },
  {
    title: 'Notify Type',
    key: 'notifyType',
    width: 150,
    dataIndex: 'notifyType',
    render: getCurrentNotifyType,
  },
  {
    title: 'Users/Roles',
    key: 'usersOrRoles',
    width: 250,
    render: (rule: IRule): ReactNode => (
      <span className="cell cell--pre-wrap">
        {getValueForUsersOrRolesColumn(
          {
            ...rule,
            responsibleForProjectType: renderProjectParams(
              projectTypes,
              rule.responsibleForProjectType ?? '',
            ) as string,
          },
          usersList,
          towerOwners,
        )}
      </span>
    ),
  },
  {
    title: 'Message',
    key: 'message',
    dataIndex: 'message',
    width: 150,
  },
  {
    title: 'Redirect link',
    key: 'redirectLink',
    dataIndex: 'redirectLinkKey',
    width: 200,
  },
  actionButtons({ editFunc, deleteFunc }) as VirtualTableColumn,
];
