import {
  CreateNotificationData,
  RemoveNotificationData,
  WS_EVENT_PATTERNS,
} from '@symfa-inc/providence-verizon-types';
import { io, Socket } from 'socket.io-client';
import { StorageItem } from '@models/enums';
import { NotificationsLoader } from '@shared/components';
import { UserActions } from '@store/actions';
import store from '../../store';
import { Config } from '../config/config';

// TODO: in new version need create Context
export class SocketIOService {
  private _io!: Socket;

  private static _instance: SocketIOService;

  static get get(): SocketIOService {
    if (!this._instance) {
      this._instance = new SocketIOService();
    }

    return this._instance;
  }

  private constructor() {}

  init(): void {
    this._io = io(`${Config.get().socketUrl}/notifications`, {
      reconnectionDelay: 5000,
      reconnectionAttempts: 5,
      auth: {
        token: localStorage.getItem(StorageItem.ACCESS_TOKEN),
      },
    });

    this._io.emit(WS_EVENT_PATTERNS.subscribe);

    this._startCommonListeners();
    this._startNotificationListeners();
  }

  destroy(): void {
    this._io.removeAllListeners().close();
  }

  private _startCommonListeners(): void {
    const { io: ioManager } = this._io;
    const isDev = Config.get().isDevelopment;

    ioManager.on('reconnect', attempt => {
      if (isDev) {
        console.info(`Reconnected on attempt: ${attempt}`);

        console.info('Sending handshake to server ...');
      }

      this._io.emit(WS_EVENT_PATTERNS.subscribe);
    });

    if (isDev) {
      ioManager.on('reconnect_attempt', attempt => {
        console.info(`Reconnection Attempt: ${attempt}`);
      });

      ioManager.on('reconnect_error', error => {
        console.info(`Reconnection error: ${error}`);
      });

      ioManager.on('reconnect_failed', () => {
        console.info('Reconnection failure.');

        alert(
          'We are unable to connect you to the chat service.  Please make sure your internet connection is stable or try again later.',
        );
      });
    }

    this._io.on(WS_EVENT_PATTERNS.error, errorMassage => {
      if (errorMassage.includes('expired')) {
        return window.location.replace('/login');
      }

      NotificationsLoader.notificationError({
        message: 'Gateway error',
        description: errorMassage,
      });
    });
  }

  private _startNotificationListeners(): void {
    this._io.on(
      WS_EVENT_PATTERNS.createNotification,
      (notification: CreateNotificationData) => {
        const { title, body, type, clickRedirectUrl } = notification;

        NotificationsLoader.openNotificationWithArgs(type, {
          message: title,
          description: body,
          onClose: () =>
            store.dispatch(UserActions.addNotification.done(notification)),
          onClick: () =>
            clickRedirectUrl ? window.location.replace(clickRedirectUrl) : null,
        });
      },
    );

    this._io.on(
      WS_EVENT_PATTERNS.removeNotification,
      (processResolvedData: RemoveNotificationData) => {
        const { title, type, body, _id } = processResolvedData;

        NotificationsLoader.openNotificationWithArgs(type, {
          message: title,
          description: body,
          onClose: () => {
            store.dispatch(UserActions.clearCurrent.done(_id));
          },
        });
      },
    );
  }
}
