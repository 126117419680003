import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Input } from '@shared/modules';

const { useForm } = Form;

export const ProjectInformation: FC<InformationProps> = ({
  className,
  data,
}: PropsWithChildren<InformationProps>) => {
  const [form] = useForm();

  useEffect(() => {
    const {
      RESpecialist,
      civilVendor,
      progressStatus,
      projectBundleID,
      projectStatus,
      projectType,
      scoping,
      structuralNotes,
      structuralResult,
    } = data;

    form.setFieldsValue({
      AEVendor: scoping?.AEVendor?.value,
      RESpecialist: RESpecialist?.value,
      civilVendor: civilVendor?.value,
      progressStatus: progressStatus?.value,
      projectBundleID,
      projectStatus: projectStatus?.value,
      projectType: projectType?.value,
      structuralNotes,
      structuralResult,
    });
  }, []);

  useEffect(() => {
    const {
      RESpecialist,
      civilVendor,
      progressStatus,
      projectBundleID,
      projectStatus,
      projectType,
      scoping,
      structuralNotes,
      structuralResult,
    } = data;

    form.setFieldsValue({
      AEVendor: scoping?.AEVendor?.value,
      RESpecialist: RESpecialist?.value,
      civilVendor: civilVendor?.value,
      progressStatus: progressStatus?.value,
      projectBundleID,
      projectStatus: projectStatus?.value,
      projectType: projectType?.value,
      structuralNotes,
      structuralResult,
    });
  }, []);

  return (
    <Panel header="Project Information" className={className}>
      <Form labelCol={{ span: 14 }} wrapperCol={{ span: 10 }} form={form}>
        <Row>
          <Col span="12">
            <Input
              id="projectBundleID"
              label="Project Bundle ID"
              isEditing={false}
            />
            <Input id="projectType" label="Project Type" isEditing={false} />
            <Input
              id="projectStatus"
              label="Project Status"
              isEditing={false}
            />
            <Input
              id="progressStatus"
              label="Progress Status"
              isEditing={false}
            />
            <Input id="RESpecialist" label="RE Specialist" isEditing={false} />
          </Col>
          <Col span="12">
            <Input id="civilVendor" label="Civil Vendor" isEditing={false} />
            <Input id="AEVendor" label="AE Vendor" isEditing={false} />
            <Input
              id="structuralResult"
              label="Structural Result"
              isEditing={false}
            />
            <Input
              id="structuralNotes"
              label="Structural Notes"
              isEditing={false}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
