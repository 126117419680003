import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Input, PrimaryButton, Select, ShowMoreInput } from '@shared/modules';
import { DDVSelectors, LeasingSelectors } from '@store/selectors';

type DataType = ReturnType<
  typeof LeasingSelectors.getLeasingDetailsSummaryData
>['projectInfo'];

export const ProjectInformation: FC<InformationProps<DataType>> = ({
  className,
  data: initialData,
}: PropsWithChildren<InformationProps<DataType>>) => {
  const [form] = Form.useForm();
  const {
    ProjectTypeDDV,
    ProjectStatusDDV,
    ProgressStatusDDV,
    CivilVendorDDV,
    ProjectSubtypeDDV,
  } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes([
      'ProjectType',
      'ProjectStatus',
      'ProgressStatus',
      'CivilVendor',
      'ProjectSubtype',
    ]),
  );

  const { projectNote, ...data } = initialData!;

  useEffect(() => {
    form.setFieldsValue(data!);
  }, [form, data]);

  return (
    <Panel header="Project Information" className={className}>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} form={form}>
        <Row>
          <Col span="12">
            <Input
              id="projectBundleID"
              label="Project Bundle ID"
              isEditing={false}
            />
            <Select
              id="projectTypeId"
              label="Project Type"
              options={ProjectTypeDDV}
              isEditing={false}
            />
            <Select
              id="projectStatusId"
              label="Project Status"
              options={ProjectStatusDDV}
              isEditing={false}
            />
            <Select
              id="progressStatusId"
              label="Progress Status"
              options={ProgressStatusDDV}
              isEditing={false}
            />
          </Col>
          <Col span="12">
            <Select
              id="civilVendorId"
              label="Civil Vendor"
              options={CivilVendorDDV}
              isEditing={false}
            />
            <Select
              id="projectSubtypeId"
              label="Project Subtype"
              options={ProjectSubtypeDDV}
              isEditing={false}
            />
            <ShowMoreInput
              id="projectNote"
              label="Project Notes"
              isEditing={false}
              data={projectNote}
              form={form}
            />
          </Col>
        </Row>
        <Row className="action-buttons">
          <PrimaryButton
            title="View details"
            target="_blank"
            href={`/project/${data!.projectBundleID}/details`}
          />
        </Row>
      </Form>
    </Panel>
  );
};
