import React, { ReactNode } from 'react';
import {
  IRequiresAttention,
  ISelectorOption,
  NotificationType,
} from '@symfa-inc/providence-verizon-types';
import { ColumnsType } from 'antd/es/table';
import { tableSorter } from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { DashboardItem } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { ReadOnlyDate } from '@shared/modules';
import { assigneesRenderer } from '../../../helpers';
import { PROJECT_DATA_COLUMNS } from '../../../models';

export const REQUIRES_ATTENTION_COLUMNS = (
  adminsList: ISelectorOption[],
  editFunc: ColumnsFunc<DashboardItem<IRequiresAttention>>,
): ColumnsType => [
  {
    dataIndex: 'notificationData',
    key: 'notificationType',
    width: 45,
    sorter: tableSorter(['notificationData', 'notificationType']),
    defaultSortOrder: 'descend',
    className: 'empty-header',
    render: ({ notificationType }: IRequiresAttention): ReactNode => (
      <i
        className={`circle circle_${
          notificationType === NotificationType.Info ? 'gray' : 'high'
        }`}
      />
    ),
  },
  {
    title: 'Date',
    dataIndex: 'notificationData',
    key: 'date',
    sorter: tableSorter(['notificationData', 'date'], true),
    render: ({ date }: IRequiresAttention): ReactNode => (
      <ReadOnlyDate date={date} utc />
    ),
  },
  ...PROJECT_DATA_COLUMNS,
  {
    title: 'Action Required',
    dataIndex: 'notificationData',
    key: 'message',
    sorter: tableSorter(['notificationData', 'message']),
    render: ({ message }: IRequiresAttention): ReactNode => message,
  },
  {
    title: 'Action Field',
    dataIndex: 'notificationData',
    key: 'actionField',
    sorter: tableSorter(['notificationData', 'actionField']),
    render: ({ actionField }: IRequiresAttention): ReactNode => actionField,
  },
  {
    title: 'Due Date',
    dataIndex: 'notificationData',
    key: 'dueDate',
    sorter: tableSorter(['notificationData', 'dueDate'], true),
    render: ({ dueDate }: IRequiresAttention): ReactNode => (
      <ReadOnlyDate date={dueDate} />
    ),
  },
  {
    title: 'Past Due Date',
    dataIndex: 'notificationData',
    sorter: tableSorter(['notificationData', 'pastDueDate']),
    render: ({ pastDueDate }: IRequiresAttention): ReactNode =>
      `${pastDueDate} day(s)`,
  },
  {
    title: 'Task Note',
    dataIndex: 'notificationData',
    key: 'taskNote',
    sorter: tableSorter(['notificationData', 'taskNote']),
    render: ({ taskNote }: IRequiresAttention): ReactNode => taskNote,
  },
  {
    title: 'Assignee',
    dataIndex: 'notificationData',
    key: 'assignee',
    render: assigneesRenderer(adminsList),
  },
  actionButtons({ editFunc, width: 86 }),
];
