import React, { useState } from 'react';
import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { ImportError, ImportFormProps } from '@models/interfaces';
import { ErrorArea } from './error-area';
import { ImportForm } from './import-form';

import './styles.scss';

export const Import = <T extends void | Record<keyof T, unknown>>({
  template,
  sendFile,
}: Omit<ImportFormProps<T>, 'onError'>) => {
  const [errors, setErrors] = useState<Undefinable<ImportError[]>>(undefined);

  return (
    <div className="prov-import-form">
      <ImportForm template={template} onError={setErrors} sendFile={sendFile} />
      <ErrorArea errors={errors} />
    </div>
  );
};
