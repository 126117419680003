import React, { ReactNode } from 'react';
import { DropDownSelectorValues } from '@symfa-inc/providence-verizon-types';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import {
  getDDVById,
  getStateById,
  phoneElementRender,
} from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { JurisdictionDetailsContacts, OptionProps } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import store from '@store/index';

export const getPermittingContactsColumns = (
  editFunc: ColumnsFunc<JurisdictionDetailsContacts>,
  deleteFunc: ColumnsFunc<JurisdictionDetailsContacts>,
  isEditing?: boolean,
): ColumnsType => {
  const {
    admin: {
      dropdownValues: { dropdownsOptions },
    },
  } = store.getState();

  return [
    {
      title: 'Contact Type',
      dataIndex: 'contactType',
      key: 'contactType',
      width: 125,
      render: (id: string): ReactNode =>
        getDDVById(DropDownSelectorValues.ContactType, id).value,
    },
    {
      title: 'Project Type',
      dataIndex: 'projectType',
      key: 'projectType',
      width: 125,
      render: (projectType: string): ReactNode =>
        dropdownsOptions?.ProjectType.find(
          (option: OptionProps) => option.value === projectType,
        )?.viewValue,
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      key: 'lastValidatedDate',
      width: 165,
    },
    {
      title: 'Contact Address',
      dataIndex: 'contactAddress',
      key: 'address',
      width: 165,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 165,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 80,
      render: (id: string): ReactNode => getStateById(id)?.name,
    },
    {
      title: 'Zip',
      dataIndex: 'zip',
      key: 'zip',
      width: 120,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 165,
      render: (phone: string): ReactNode => phoneElementRender(phone),
    },
    {
      title: 'Fax',
      dataIndex: 'fax',
      key: 'fax',
      width: 165,
      render: (phone: string): ReactNode => phoneElementRender(phone),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 165,
    },
    {
      title: 'Contact Notes',
      dataIndex: 'contactNote',
      key: 'contactNote',
      width: 180,
      render: (value: string): ReactNode => (
        <span className="ellipsis-3">{value}</span>
      ),
    },
    {
      ...actionButtons({ editFunc, deleteFunc, isEditing }),
      fixed: 'right',
    } as ColumnType<unknown>,
  ];
};
