import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Details } from '@shared/modules/details';
import { ScopingSelectors } from '@store/selectors';

export const ScopingDetails: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const scopingSiteInfo = useSelector(ScopingSelectors.getScopingSiteInfo);

  return <Details {...props} fuzeID site={scopingSiteInfo} />;
};
