import { useEffect, useState } from 'react';
import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { SelectValue } from 'antd/lib/select';
import { ICoords } from '../../../models';
import { createCircle } from './create-circle';
import { removeLastEntity } from './remove-last-entity';

export const useCreateRadius = ({
  map,
  radius,
  coords,
}: {
  map: Microsoft.Maps.Map | undefined;
  radius: Undefinable<SelectValue>;
  coords: Undefinable<ICoords>;
}) => {
  const [entitiesLength, setEntitiesLength] = useState<number>();

  useEffect(() => {
    if (map) {
      setEntitiesLength(map?.entities.getLength());
    }
  }, [map]);

  useEffect(() => {
    if (radius && coords) {
      const loc = new Microsoft.Maps.Location(
        coords.latitude,
        coords.longitude,
      );

      Microsoft.Maps.loadModule('Microsoft.Maps.SpatialMath', () => {
        removeLastEntity(map, entitiesLength);
        map?.entities.push(createCircle(loc, radius, 'rgba(0,0,150,0.1)'));
      });
    }

    removeLastEntity(map, entitiesLength, !radius);
  }, [radius]);
};
