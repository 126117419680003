import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { createReducer, on } from '../../core/utils/store';
import {
  AccountingDetails,
  JurisdictionDetails,
  PayloadAndState,
  ProjectDetailsResponse,
  ProjectManagementDetails,
  SiteBrowse,
  SOFToolDetails,
} from '../../models/interfaces';
import { ProjectDetailsWithoutFuzeIDs } from '../../models/types';
import { BreadcrumbsActions } from '../actions/breadcrumbs.actions';

export interface BreadcrumbsState {
  breadcrumbs: Record<string, Undefinable<string | number>>;
}

const initialState: BreadcrumbsState = {
  breadcrumbs: {},
};

export const reducer = createReducer(
  initialState,
  on(
    BreadcrumbsActions.site,
    ({
      payload: { siteName },
    }: PayloadAndState<SiteBrowse, BreadcrumbsState>) => ({
      breadcrumbs: {
        siteName,
      },
    }),
  ),
  on(
    BreadcrumbsActions.project,
    ({
      payload: {
        site: { siteName },
        projectBundleID,
      },
    }: PayloadAndState<ProjectDetailsWithoutFuzeIDs, BreadcrumbsState>) => ({
      breadcrumbs: {
        siteName,
        projectBundleID,
      },
    }),
  ),
  on(
    BreadcrumbsActions.Engineering.engineering,
    ({
      payload: {
        site: { siteName },
        projectBundleID,
      },
    }: PayloadAndState<
      Omit<ProjectDetailsWithoutFuzeIDs, 'paceTasks'>,
      BreadcrumbsState
    >) => ({
      breadcrumbs: {
        siteName,
        projectBundleID,
      },
    }),
  ),
  on(
    BreadcrumbsActions.Engineering.SOFTool,
    ({
      payload: {
        generalInfo: {
          site: { siteName },
          projectBundleID,
        },
      },
    }: PayloadAndState<SOFToolDetails, BreadcrumbsState>) => ({
      breadcrumbs: {
        siteName,
        projectBundleID,
      },
    }),
  ),
  on(
    BreadcrumbsActions.Engineering.scoping,
    ({
      payload: {
        projectBundleID,
        site: { siteName },
      },
    }: PayloadAndState<
      Omit<ProjectDetailsWithoutFuzeIDs, 'RAD'>,
      BreadcrumbsState
    >) => ({
      breadcrumbs: {
        siteName,
        projectBundleID,
      },
    }),
  ),
  on(
    BreadcrumbsActions.Engineering.siteAudit,
    ({
      payload: {
        projectBundleID,
        site: { siteName },
      },
    }: PayloadAndState<ProjectDetailsWithoutFuzeIDs, BreadcrumbsState>) => ({
      breadcrumbs: {
        siteName,
        projectBundleID,
      },
    }),
  ),
  on(
    BreadcrumbsActions.leasing,
    ({
      payload: {
        projectBundleID,
        site: { siteName },
      },
    }: PayloadAndState<
      Omit<ProjectDetailsWithoutFuzeIDs, 'paceTasks'>,
      BreadcrumbsState
    >) => ({
      breadcrumbs: {
        siteName,
        projectBundleID,
      },
    }),
  ),
  on(
    BreadcrumbsActions.Permitting.permitting,
    ({
      payload: {
        projectBundleID,
        site: { siteName },
      },
    }: PayloadAndState<ProjectDetailsResponse, BreadcrumbsState>) => ({
      breadcrumbs: {
        siteName,
        projectBundleID,
      },
    }),
  ),
  on(
    BreadcrumbsActions.Permitting.jurisdiction,
    ({
      payload: { jurisdictionName },
    }: PayloadAndState<JurisdictionDetails, BreadcrumbsState>) => ({
      breadcrumbs: { jurisdictionName },
    }),
  ),
  on(
    BreadcrumbsActions.projectManagement,
    ({
      payload: {
        projectBundleID,
        site: { siteName },
      },
    }: PayloadAndState<
      Omit<ProjectManagementDetails, 'fuzeIDs' | 'paceTasks'>,
      BreadcrumbsState
    >) => ({
      breadcrumbs: { projectBundleID, siteName },
    }),
  ),
  on(
    BreadcrumbsActions.accounting,
    ({
      payload: {
        projectData: {
          projectBundleID,
          site: { siteName },
        },
      },
    }: PayloadAndState<AccountingDetails, BreadcrumbsState>) => ({
      breadcrumbs: { projectBundleID, siteName },
    }),
  ),
);
