import {
  IRule,
  ISchedule,
  NotifyType,
  UserRole,
  UserRoleView,
} from '@symfa-inc/providence-verizon-types';
import { mapFromBackendToUserRole } from '@core/utils/methods';
import { OptionProps } from '@models/interfaces';
import { getCurrentTowerOwners } from './get-current-tower-owners';

export const getValueForUsersOrRolesColumn = (
  item: IRule | ISchedule,
  usersList: OptionProps[],
  towerOwners: OptionProps[],
): string => {
  switch (item.notifyType) {
    case NotifyType.User:
      return (item.userIds || [])
        .map(
          (userId: string) =>
            usersList.find(({ value }: OptionProps) => value === userId)
              ?.viewValue,
        )
        .join(',\n');
    case NotifyType.Role:
      return (item.roles || [])
        .map((role: UserRole) => {
          const userRoleView = mapFromBackendToUserRole(role);
          let result: string = userRoleView;

          const hasAdminOrEMRole = [
            UserRoleView.Admin,
            UserRoleView.EngineeringManager,
          ].includes(userRoleView);

          if (hasAdminOrEMRole && item.responsibleForProjectType != null) {
            result = `${userRoleView} - ${item.responsibleForProjectType}`;
          }

          if (
            userRoleView === UserRoleView.LeasingAgent &&
            item.towerOwners &&
            item.towerOwners.length
          ) {
            result = `${userRoleView} (${
              getCurrentTowerOwners(towerOwners, item.towerOwners) || 'Unknown'
            })`;
          }

          return result;
        })
        .join(',\n');
    default:
      throw new Error('Incorrect value');
  }
};
