import { distructAndDispatch } from '../../core/utils/methods/destruct-and-dispatch';
import { transformFields } from '../../core/utils/methods/transform-fields';
import { PROJECT_FUZE_ID_TRANSFORM_FIELDS } from '../../models/constants/transform-fields';
import { ProjectDetailsResponse, ProjectFuzeID } from '../../models/interfaces';
import { ProjectFuzeIDsActions } from '../actions/project-fuze-ids.actions';

export namespace ProjectFuzeIDsEffects {
  export const setProjectFuzeIDs = distructAndDispatch<
    ProjectDetailsResponse,
    ProjectFuzeID[]
  >(
    'categories',
    ProjectFuzeIDsActions.setProjectFuzeIDsAction,
    (fuzeIDs: ProjectFuzeID[]) =>
      fuzeIDs?.map((fuzeID: ProjectFuzeID) =>
        transformFields<ProjectFuzeID>(
          fuzeID,
          PROJECT_FUZE_ID_TRANSFORM_FIELDS,
        ),
      ),
  );
}
