import { MAIN_PACE_TASK_IDs } from '@symfa-inc/providence-verizon-types';
import { PaceTasksToObjectMapper } from './pace-task-to-object-mapper.interface';

export const ZONING_SECTION_MAPPER_OPTIONS: PaceTasksToObjectMapper.Options[] =
  [
    {
      id: MAIN_PACE_TASK_IDs.zoningSubmitted,
      fieldKey: 'zoningSubmitted',
    },
    {
      id: MAIN_PACE_TASK_IDs.zoningAppDeemedComplete,
      fieldKey: 'zoningAppDeemedComplete',
    },
    {
      id: MAIN_PACE_TASK_IDs.zoningApproved,
      fieldKey: 'zoningApproved',
    },
    {
      id: MAIN_PACE_TASK_IDs.BPSubmitted,
      fieldKey: 'BPSubmitted',
    },
    {
      id: MAIN_PACE_TASK_IDs.BPReceived,
      fieldKey: 'BPReceived',
    },
  ];
