import { ISelectorOption } from '@symfa-inc/providence-verizon-types';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { ApiMethods } from '@models/enums';
import { AdminDropdownValuesData } from '@models/interfaces';
import {
  AddDropdownValueResponse,
  FileType,
  UpdateDropdownValueResponse,
} from '@models/types';
import { DropdownValuesActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class DropdownHttpService extends CommonHttpService {
  @StoredRequest(DropdownValuesActions.getAdminDropdownValuesDataAction)
  getDropdownValues(): Promise<AdminDropdownValuesData[]> {
    return this.http.callApi.get(`${this.apiUrl}/admin/drop-down-values`);
  }

  @StoredRequest(DropdownValuesActions.getAdminDropdownValuesProjectTypesAction)
  getProjectTypes(): Promise<Omit<AdminDropdownValuesData, 'selectorKey'>[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/drop-down-values/project-types`,
    );
  }

  @StoredRequest(DropdownValuesActions.getAdminDropdownValuesOptionsAction)
  getDropdownValuesOptions(): Promise<ISelectorOption<string>[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/drop-down-values/options`,
    );
  }

  @StoredRequest(DropdownValuesActions.addAdminDropdownValuesDataAction)
  addDropdownValue(
    key: string,
    values: string[],
  ): Promise<AddDropdownValueResponse> {
    return this.http.callApi.post(
      `${this.apiUrl}/admin/drop-down-values`,
      { values },
      {
        params: { key },
      },
    );
  }

  @StoredRequest(DropdownValuesActions.getAdminDropdownValuesDataAction)
  importDropDownValues(file: FileType): Promise<AdminDropdownValuesData[]> {
    return this.uploadFile(
      ApiMethods.Post,
      `${this.apiUrl}/admin/drop-down-values/import`,
      file,
    );
  }

  @StoredRequest(DropdownValuesActions.updateAdminDropdownValuesDataAction)
  updateDropdownValue(
    dropDownValueId: string,
    body: Omit<AdminDropdownValuesData, 'id'>,
  ): Promise<UpdateDropdownValueResponse> {
    return this.http.callApi.patch(
      `${this.apiUrl}/admin/drop-down-values`,
      body,
      {
        params: { dropDownValueId },
      },
    );
  }

  getDropdownValueValidation(
    id: string,
    key: string,
    value: string,
  ): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/drop-down-values/validation`,
      null,
      {
        params: { key, id, value: value.trim() },
      },
    );
  }

  @StoredRequest(DropdownValuesActions.deleteAdminDropdownValuesDataAction)
  async deleteDropdownValue(
    dropDownValueId: string,
    key: string,
  ): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/drop-down-values`,
      null,
      {
        params: { dropDownValueId, key },
      },
    );

    return dropDownValueId;
  }
}
