import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { ToTopOutlined } from '@ant-design/icons/lib';
import { Undefinable, UserRole } from '@symfa-inc/providence-verizon-types';
import { HttpService } from '@core/services';
import { FinancialHttpService } from '@core/services/http';
import { toggleModal } from '@core/utils/methods';
import { ALLOW_OOM_INFORMATION_PACE_TASK_OPTIONS } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  AdminDropdownValuesData,
  FinancialOOMInformation,
  FinancialServicesData,
  FinancialSiteFeesData,
} from '@models/interfaces';
import { AppState } from '@store/reducers';
import {
  DDVSelectors,
  FinancialSelectors,
  UserSelectors,
} from '@store/selectors';
import {
  CustomTable,
  DeleteModal,
  Panel,
  VirtualTable,
} from '../../components';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';
import {
  AddAndEditOOM,
  AddAndEditService,
  AddAndEditSiteFee,
  ExportModal,
  FinancialDrawer,
} from './components';
import {
  FINANCIAL_ENTITIES,
  FINANCIAL_OOM_INFORMATION_DEFAULT_VALUE,
  FINANCIAL_SERVICES_DEFAULT_VALUE,
  FINANCIAL_SITE_FEES_DEFAULT_VALUE,
  FinancialProps,
  financialServicesColumns,
  getFinancialServicesRequired,
  getFinancialSiteFees,
  getSiteFeesColumns,
  OOMInformationColumns,
} from './models';

import './styles.scss';

export const Financial = <TState,>({
  feeAccessPath,
  siteDataRootPath,
  projectTypeSelector,
  projectIdSelector,
}: PropsWithChildren<FinancialProps<TState>>) => {
  const financialService = useMemo(
    () => HttpService.getHttpRequests(FinancialHttpService),
    [],
  );

  const feeAccess: boolean = useSelector(
    UserSelectors.getFeeAccess(feeAccessPath),
  );

  const roles = useSelector(UserSelectors.getUserRoles);
  const userId = useSelector(UserSelectors.getUserId);
  const dropdownValues = useSelector(DDVSelectors.getDDValues);
  const financialData = useSelector(FinancialSelectors.getFinancialData);
  const OOMInformation = useSelector(FinancialSelectors.getOOMInformationData);

  const siteData = useSelector((state: AppState) => {
    let stateRoot = state as any;

    siteDataRootPath?.forEach((p: string) => {
      stateRoot = stateRoot[p];
    });

    return stateRoot;
  });

  const projectType = useSelector(projectTypeSelector);
  const projectId = useSelector(projectIdSelector);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );

  const [deleteSiteFeeModalVisible, setDeleteSiteFeeModalVisible] =
    useState<boolean>(false);
  const [deleteServiceModalVisible, setDeleteServiceModalVisible] =
    useState<boolean>(false);
  const [deleteOOMModalVisible, setDeleteOOMModalVisible] =
    useState<boolean>(false);
  const [exportModalVisible, setExportModalVisible] = useState<boolean>(false);
  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [isAddEditServiceModalVisible, setAddEditServiceModalVisible] =
    useState<boolean>(false);
  const [isAddEditOOMModalVisible, setAddEditOOMModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [isDrawerOpen, setDrawerState] = useState<boolean>(false);
  const [selectedSiteFee, setSelectedSiteFee] = useState<FinancialSiteFeesData>(
    FINANCIAL_SITE_FEES_DEFAULT_VALUE,
  );
  const [selectedService, setSelectedService] = useState<FinancialServicesData>(
    FINANCIAL_SERVICES_DEFAULT_VALUE,
  );
  const [selectedOOM, setSelectedOOM] = useState<FinancialOOMInformation>(
    FINANCIAL_OOM_INFORMATION_DEFAULT_VALUE,
  );

  const accountingRecord: boolean | null = !(
    roles.includes(UserRole.Accounting) || roles.includes(UserRole.Admin)
  )
    ? null
    : roles.includes(UserRole.Accounting) || !roles.includes(UserRole.Admin);

  const entity = FINANCIAL_ENTITIES[feeAccessPath];

  const toggleAddAndEditModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);

    if (isAddEditModalVisible && selectedSiteFee) {
      setSelectedSiteFee(FINANCIAL_SITE_FEES_DEFAULT_VALUE);
    }
  };

  const toggleAddAndEditServiceModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditServiceModalVisible(!isAddEditServiceModalVisible);

    if (isAddEditServiceModalVisible && selectedService) {
      setSelectedService(FINANCIAL_SERVICES_DEFAULT_VALUE);
    }
  };

  const toggleAddAndEditOOMModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditOOMModalVisible(!isAddEditOOMModalVisible);

    if (isAddEditOOMModalVisible && selectedOOM) {
      setSelectedOOM(FINANCIAL_OOM_INFORMATION_DEFAULT_VALUE);
    }
  };

  const selectSiteFee = (
    siteFee: FinancialSiteFeesData,
    setter: Dispatch<SetStateAction<boolean>>,
  ): void => {
    setSelectedSiteFee(siteFee);

    setter((prevState: boolean): boolean => !prevState);
  };

  const selectService = (
    service: FinancialServicesData,
    setter: Dispatch<SetStateAction<boolean>>,
  ): void => {
    setSelectedService(service);

    setter((prevState: boolean): boolean => !prevState);
  };

  const selectOOM = (
    OOM: FinancialOOMInformation,
    setter: Dispatch<SetStateAction<boolean>>,
  ): void => {
    setSelectedOOM(OOM);

    setter((prevState: boolean): boolean => !prevState);
  };

  const onDeleteSiteFee = async (): Promise<void> => {
    await financialService.deleteFinancialSiteFee(
      selectedSiteFee.id!,
      projectId,
      entity,
    );

    setDeleteSiteFeeModalVisible(false);
    setSelectedSiteFee(FINANCIAL_SITE_FEES_DEFAULT_VALUE);
  };

  const onDeleteService = async (): Promise<void> => {
    await financialService.deleteFinancialService(
      selectedService.id!,
      projectId,
      entity,
    );

    setDeleteServiceModalVisible(false);
    setSelectedService(FINANCIAL_SERVICES_DEFAULT_VALUE);
  };

  const onDeleteOOM = async (): Promise<void> => {
    await financialService.deleteFinancialOOM(
      selectedOOM.id!,
      projectId,
      entity,
    );

    setDeleteOOMModalVisible(false);
    setSelectedOOM(FINANCIAL_OOM_INFORMATION_DEFAULT_VALUE);
  };

  const editSiteFee = (siteFee: FinancialSiteFeesData): void =>
    selectSiteFee(siteFee, () => toggleAddAndEditModal(ModalMainTypes.Edit));

  const deleteSiteFee = (siteFee: FinancialSiteFeesData): void =>
    selectSiteFee(siteFee, setDeleteSiteFeeModalVisible);

  const editService = (service: FinancialServicesData): void =>
    selectService(service, () =>
      toggleAddAndEditServiceModal(ModalMainTypes.Edit),
    );

  const deleteService = (service: FinancialServicesData): void =>
    selectService(service, setDeleteServiceModalVisible);

  const editOOM = (OOM: FinancialOOMInformation): void =>
    selectOOM(OOM, () => toggleAddAndEditOOMModal(ModalMainTypes.Edit));

  const deleteOOM = (OOM: FinancialOOMInformation): void =>
    selectOOM(OOM, setDeleteOOMModalVisible);

  const toggleDrawer = (): void => setDrawerState(!isDrawerOpen);

  const getSiteFeeTypeById = (id: string): Undefinable<string> =>
    dropdownValues.SiteFeeType.find(
      (dropDownSiteFeeType: AdminDropdownValuesData) =>
        dropDownSiteFeeType.id === id,
    )?.value;

  const getAdminAccess = (): boolean => roles.includes(UserRole.Admin);
  const getAccountingAccess = (): boolean =>
    roles.includes(UserRole.Accounting) && projectTypePermission;

  const isOnlyAccounting =
    roles.includes(UserRole.Accounting) && !roles.includes(UserRole.Admin);

  const getDeleteFuncAccess = (
    item: FinancialSiteFeesData | FinancialServicesData,
  ): boolean =>
    feeAccessPath !== 'projects' &&
    (item.creator?.id === userId || getAdminAccess()) &&
    projectTypePermission;

  const includeOOMInformation =
    feeAccessPath === 'projectManagement' || feeAccessPath === 'accounting';

  useEffect(() => {
    if (!exportModalVisible) {
      setSelectedSiteFee(FINANCIAL_SITE_FEES_DEFAULT_VALUE);
    }
  }, [exportModalVisible]);

  return (
    <div className="prov-financial-tab">
      <Panel header="Services Required" className="prov-financial-tab__panel">
        <div className="services-actions">
          {feeAccess && !isOnlyAccounting && (
            <PrimaryButton
              title="Add"
              icon="+"
              className="add-btn"
              onClick={(): void =>
                toggleAddAndEditServiceModal(ModalMainTypes.Add)
              }
              disabled={!projectTypePermission}
            />
          )}
        </div>
        <CustomTable
          dataSource={getFinancialServicesRequired(financialData)}
          columns={financialServicesColumns({
            ...(feeAccess &&
              (projectTypePermission || getAccountingAccess()) && {
                editFunc: editService,
              }),
            deleteFunc: deleteService,
            feeAccessPath: feeAccessPath === 'accounting',
            accountingRecord,
            getDeleteFuncAccess,
          })}
          hoverable={false}
        />
      </Panel>
      {includeOOMInformation && (
        <Panel header="OOM Information" className="prov-financial-tab__panel">
          <div className="services-actions">
            {roles.includes(UserRole.Admin) && (
              <PrimaryButton
                title="Add"
                icon="+"
                className="add-btn"
                onClick={(): void =>
                  toggleAddAndEditOOMModal(ModalMainTypes.Add)
                }
                disabled={
                  !projectTypePermission ||
                  ALLOW_OOM_INFORMATION_PACE_TASK_OPTIONS.length ===
                    OOMInformation.length
                }
              />
            )}
          </div>
          <CustomTable
            dataSource={OOMInformation}
            columns={OOMInformationColumns(roles, editOOM, deleteOOM)}
            hoverable={false}
          />
        </Panel>
      )}
      <Panel header="Site Fees" className="prov-financial-tab__panel">
        <FinancialDrawer
          onClose={toggleDrawer}
          visible={isDrawerOpen}
          data={{
            financialData: getFinancialSiteFees(financialData) || [],
            siteData,
          }}
          getSiteFeeTypeById={getSiteFeeTypeById}
        />
        <div className="prov-financial-tab__panel-btn-box">
          {getAdminAccess() && (
            <PrimaryButton
              title="Export All"
              icon={<ToTopOutlined />}
              className="export-btn"
              onClick={toggleDrawer}
            />
          )}
          {feeAccess && !isOnlyAccounting && (
            <PrimaryButton
              title="Add"
              icon="+"
              className="add-btn"
              onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
              disabled={!projectTypePermission}
            />
          )}
        </div>
        <VirtualTable
          dataSource={getFinancialSiteFees(financialData)}
          columns={getSiteFeesColumns({
            ...(getAdminAccess() && {
              exportFunc: (siteFee: FinancialSiteFeesData): void =>
                selectSiteFee(siteFee, () =>
                  setExportModalVisible(!exportModalVisible),
                ),
            }),
            ...(feeAccess &&
              (projectTypePermission || getAccountingAccess()) && {
                editFunc: editSiteFee,
              }),
            deleteFunc: deleteSiteFee,
            getDeleteFuncAccess,
            feeAccessPath: feeAccessPath === 'accounting',
            accountingRecord,
          })}
          maxViewRow={5}
          rowHeight={72}
        />
        <ExportModal
          data={{ siteData, feeData: selectedSiteFee }}
          visible={exportModalVisible}
          onCancel={(): void => setExportModalVisible(!exportModalVisible)}
          onOk={toggleModal(setExportModalVisible)}
          getSiteFeeTypeById={getSiteFeeTypeById}
        />
      </Panel>
      <AddAndEditSiteFee
        permissions={accountingRecord}
        formValue={selectedSiteFee}
        visible={isAddEditModalVisible}
        toggleModal={toggleAddAndEditModal}
        className="prov-financial-tab__modal"
        modalType={currentModalType}
        entity={entity}
        projectId={projectId}
      />
      <AddAndEditService
        permissions={accountingRecord}
        formValue={selectedService}
        visible={isAddEditServiceModalVisible}
        toggleModal={toggleAddAndEditServiceModal}
        className="prov-financial-tab__modal"
        modalType={currentModalType}
        entity={entity}
        projectId={projectId}
      />
      <AddAndEditOOM
        formValue={selectedOOM}
        visible={isAddEditOOMModalVisible}
        toggleModal={toggleAddAndEditOOMModal}
        className="prov-financial-tab__modal"
        modalType={currentModalType}
        projectId={projectId}
        entity={entity}
      />
      <DeleteModal
        onOk={onDeleteSiteFee}
        onCancel={toggleModal(setDeleteSiteFeeModalVisible)}
        visible={deleteSiteFeeModalVisible}
      />
      <DeleteModal
        onOk={onDeleteService}
        onCancel={toggleModal(setDeleteServiceModalVisible)}
        visible={deleteServiceModalVisible}
      />
      <DeleteModal
        onOk={onDeleteOOM}
        onCancel={toggleModal(setDeleteOOMModalVisible)}
        visible={deleteOOMModalVisible}
      />
    </div>
  );
};
