import React, { FC, PropsWithChildren } from 'react';
import { DropDownSelectorValues } from '@symfa-inc/providence-verizon-types';
import { getDDVByIdOrUndefined } from '@core/utils/methods';
import {
  TableContainer as ContentContainer,
  ViewElement,
} from '@shared/components';
import { PrimaryButton, ProgressStatus, ProjectStatus } from '@shared/modules';
import { ProjectInfoProps } from '../../models';

export const ProjectInformation: FC<ProjectInfoProps> = ({
  projectData,
  scopingData,
}: PropsWithChildren<ProjectInfoProps>) => (
  <ContentContainer header="Project Information">
    <div className="information">
      <ViewElement
        label="Project Bundle ID"
        value={projectData.projectBundleID}
      />
      <ViewElement
        label="Civil Vendor"
        value={
          getDDVByIdOrUndefined(
            DropDownSelectorValues.CivilVendor,
            projectData.civilVendor,
          )?.value
        }
      />
      <ViewElement
        label="Project Type"
        value={
          getDDVByIdOrUndefined(
            DropDownSelectorValues.ProjectType,
            projectData.projectType.id,
          )?.value
        }
      />
      <ViewElement
        additionClassName="right"
        label="A/E CD Vendor"
        value={
          getDDVByIdOrUndefined(
            DropDownSelectorValues.AEVendor,
            scopingData.AEVendor,
          )?.value
        }
      />
      <ViewElement
        label="Project Status"
        element={
          <ProjectStatus
            status={
              getDDVByIdOrUndefined(
                DropDownSelectorValues.ProjectStatus,
                projectData.projectStatus,
              )?.value
            }
          />
        }
      />
      <ViewElement
        label="A/E Mount Vendor"
        value={
          getDDVByIdOrUndefined(
            DropDownSelectorValues.AEMountVendor,
            scopingData.AEMountVendor,
          )?.value
        }
      />
      <ViewElement
        label="Progress Status"
        element={
          <ProgressStatus
            status={
              getDDVByIdOrUndefined(
                DropDownSelectorValues.ProgressStatus,
                projectData.progressStatus,
              )?.value
            }
          />
        }
      />
      <ViewElement label="Project Notes" value={projectData?.projectNote} />
    </div>
    <PrimaryButton
      className="action-button"
      title="View Details"
      target="_blank"
      href={`/project/${projectData.projectBundleID}/details`}
    />
  </ContentContainer>
);
