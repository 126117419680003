import { ISelectorOption } from '@symfa-inc/providence-verizon-types';
import { momentizeObjectDates } from '@core/utils/methods';
import { createReducer, on } from '@core/utils/store';
import { PACE_TASK_BROWSE_FIELDS_FOR_MOMENTIZE } from '@models/constants';
import {
  IProjectPACETask,
  IUpdateProjectPACETask,
  PayloadAndState,
} from '@models/interfaces';
import { PACETasksBrowse } from '../../actions';

export interface PaceTasksBrowseState {
  projectBundleIDsOptions: {
    [key: string]: ISelectorOption<string | number, string | number>[];
  };
  projectPACETasks: IProjectPACETask[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: PaceTasksBrowseState = {
  errors: false,
  isFetching: false,
  projectBundleIDsOptions: {
    projectBundleIDs: [],
  },
  projectPACETasks: [],
};

export const reducer = createReducer(
  initialState,
  on(
    PACETasksBrowse.getProjectBundleIDsOptions,
    ({
      payload,
    }: PayloadAndState<
      { [key: string]: ISelectorOption<string | number, string | number>[] },
      PaceTasksBrowseState
    >) => {
      const { projectBundleIDs } = payload;

      return {
        projectBundleIDsOptions: {
          projectBundleIDs,
        },
      };
    },
  ),
  on(
    PACETasksBrowse.getProjectPACETasks,
    ({
      payload,
    }: PayloadAndState<IProjectPACETask[], PaceTasksBrowseState>) => ({
      projectPACETasks: payload.map((value: IProjectPACETask) => ({
        ...value,
        projectPaceTaskData: momentizeObjectDates(
          value.projectPaceTaskData,
          PACE_TASK_BROWSE_FIELDS_FOR_MOMENTIZE,
        ),
      })),
    }),
  ),
  on(
    PACETasksBrowse.updateProjectPACETasks,
    ({
      payload,
      state,
    }: PayloadAndState<IUpdateProjectPACETask[], PaceTasksBrowseState>) => {
      const projectPACETasks = payload.reduce(
        (
          acc: Record<string, Omit<IUpdateProjectPACETask, 'paceTaskId'>>,
          currentValue: IUpdateProjectPACETask,
        ) => {
          const { paceTaskId, ...otherValues } = currentValue;

          acc[paceTaskId] = otherValues;

          return acc;
        },
        {} as Record<string, Omit<IUpdateProjectPACETask, 'paceTaskId'>>,
      );

      return {
        projectPACETasks: state.projectPACETasks.map(
          (projectPACETask: IProjectPACETask) => ({
            ...projectPACETask,
            projectPaceTaskData: {
              ...projectPACETask.projectPaceTaskData,
              ...(projectPACETasks[projectPACETask.id]
                ? momentizeObjectDates(
                    projectPACETasks[projectPACETask.id],
                    PACE_TASK_BROWSE_FIELDS_FOR_MOMENTIZE,
                  )
                : {}),
            },
          }),
        ),
      };
    },
  ),
);
