import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import {
  DropDownSelectorValues,
  ProjectStatus as ProjectStatusEnum,
  SubPage,
  Tab,
} from '@symfa-inc/providence-verizon-types';
import { canUserEditByProjectStatusAndRoles } from '@core/utils/methods';
import { TabComponentProps } from '@models/interfaces';
import { UserPermissionKeys } from '@models/types';
import {
  DDVSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';
import { EditablePage } from '../../components/editable-page';
import { LeaseAudit } from './components';

export const LeaseAuditTab: FC<
  TabComponentProps & { area: UserPermissionKeys; subPage: SubPage }
> = ({
  isActive,
  projectTypeSelector,
  area,
  subPage,
}: PropsWithChildren<
  TabComponentProps & { area: UserPermissionKeys; subPage: SubPage }
>) => {
  const { browse } = useSelector(UserSelectors.getLeasingPermissions);
  const projectType = useSelector(projectTypeSelector);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit(area, subPage, Tab.LeaseAudit),
  );

  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);
  const projectStatus = useSelector(
    DDVSelectors.getCurrentDDVValueById<ProjectStatusEnum>(
      DropDownSelectorValues.ProjectStatus,
      projectStatusId,
    ),
  );

  return (
    <EditablePage
      isActive={isActive}
      editableComponent={LeaseAudit}
      permissions={browse}
      area={area}
      canUserEdit={
        canEdit &&
        projectTypePermission &&
        canUserEditByProjectStatusAndRoles(projectStatus, userRoles)
      }
    />
  );
};
