import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { isValidForm, normalize } from '@core/utils/methods';
import { INPUT_POSITIVE_NUMBER_VALIDATOR } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { JurisdictionDetailsContacts } from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import {
  Autocomplete,
  Input,
  InputNumber,
  PhoneNumber,
  ShowMoreInput,
} from '@shared/modules';
import {
  CountySelectors,
  DDVSelectors,
  PermittingSelectors,
} from '@store/selectors';
import { PERMITTING_CONTACTS_DEFAULT_VALUE } from './models/constants';
import { PermittingContactModalProps } from './models/interfaces';

const { useForm } = Form;

export const PermittingContactModal: FC<PermittingContactModalProps> = ({
  modalType,
  visible,
  formValue,
  toggleModal,
}: PropsWithChildren<PermittingContactModalProps>) => {
  const [form] = useForm();

  const { id: jurisdictionId } = useSelector(
    PermittingSelectors.getJurisdictionDetailsData(['id']),
  );

  const permittingService = useMemo(
    () => HttpService.getHttpRequests(PermittingHttpService),
    [],
  );

  const { ProjectTypeDDV, ContactTypeDDV } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes(['ProjectType', 'ContactType']),
  );
  const states = useSelector(CountySelectors.getStateLikeSelectorOptions);

  const add = async (): Promise<void> => {
    if (await isValidForm(form)) {
      try {
        const jurisdictionDetailsContacts: Partial<JurisdictionDetailsContacts> =
          {};

        Object.entries<string>(form.getFieldsValue()).forEach(
          ([key, value]: [string, string]) => {
            if (value) {
              jurisdictionDetailsContacts[
                key as keyof JurisdictionDetailsContacts
              ] = value;
            }
          },
        );

        if (jurisdictionId) {
          await permittingService.addJurisdictionContact(
            jurisdictionId,
            jurisdictionDetailsContacts,
          );
        }

        toggleModal(modalType);

        form.resetFields();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const edit = async (): Promise<void> => {
    if (await isValidForm(form)) {
      try {
        await permittingService.updateJurisdictionContact(
          formValue.id,
          form.getFieldsValue(),
        );

        toggleModal(modalType);

        form.resetFields();
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (visible) {
      const initValues =
        modalType === ModalMainTypes.Add
          ? PERMITTING_CONTACTS_DEFAULT_VALUE
          : formValue;

      form.setFieldsValue(initValues);
    }
  }, [visible]);

  return (
    <AddOrEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add()
          : (): Promise<void> => edit()
      }
      onCancel={(): void => {
        toggleModal(modalType);
        form.resetFields();
      }}
      visible={visible}
      type={modalType}
      className="prov-contacts__modal"
      okText={`${modalType === ModalMainTypes.Add ? 'Add' : 'Edit'} Contact`}
      title="Contact"
      formProps={{
        labelCol: { span: 7 },
        wrapperCol: { span: 15 },
        form,
      }}
    >
      <Autocomplete
        id="contactType"
        label="Contact Type"
        options={ContactTypeDDV}
        formItemProps={{
          rules: [
            {
              required: true,
              message: 'Contact Type is required!',
            },
          ],
        }}
      />
      <Autocomplete
        id="projectType"
        label="Project Type"
        options={ProjectTypeDDV}
        formItemProps={{
          rules: [
            {
              required: true,
              message: 'Project Type is required!',
            },
          ],
        }}
      />
      <Input
        id="contactName"
        label="Contact Name"
        formItemProps={{
          rules: [
            {
              required: true,
              message: 'Contact Name is required!',
            },
          ],
        }}
      />
      <Input id="contactAddress" label="Contact Address" />
      <Input id="city" label="City" />
      <Autocomplete id="state" label="State" options={states} />
      <InputNumber
        id="zip"
        label="Zip"
        formItemProps={{
          normalize,
          rules: [INPUT_POSITIVE_NUMBER_VALIDATOR],
        }}
      />
      <PhoneNumber id="phone" label="Phone" />
      <PhoneNumber id="fax" label="Fax" />
      <Input
        id="email"
        label="Email"
        formItemProps={{
          rules: [{ type: 'email', message: 'Email must be an email!' }],
        }}
      />
      <ShowMoreInput id="contactNote" label="Contact Notes" form={form} />
    </AddOrEditModal>
  );
};
