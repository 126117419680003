import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  AccountingHttpService,
  DriverManagementHttpService,
  SiteHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';

export class AccountingDetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await HttpService.getHttpRequests(AccountingHttpService).getAccounting(
      projectBundleID,
    );
    await HttpService.getHttpRequests(
      SiteHttpService,
    ).getPSLocationCodeOptions();
    await HttpService.getHttpRequests(
      DriverManagementHttpService,
    ).getDriversListByWorkflow();
  }
}
