import { DirectMessageAreaKey } from '@symfa-inc/providence-verizon-types';
import { createSelector } from 'reselect';
import { EngineeringBrowseState } from '@store/reducers/engineering/browse.reducer';
import { EngineeringScopingState } from '@store/reducers/engineering/scoping.reducer';
import { EngineeringSiteAuditState } from '@store/reducers/engineering/site-audit.reducer';
import { LeasingState } from '@store/reducers/leasing.reducer';
import { PermittingState } from '@store/reducers/permitting.reducer';
import { ProjectState } from '@store/reducers/project.reducer';
import { ProjectManagementState } from '@store/reducers/project-management.reducer';
import { AppState } from '../reducers';
import { DirectMessageState } from '../reducers/direct-message.reducer';

const directMessageState = (state: AppState): DirectMessageState =>
  state.directMessage;

const engineeringScopingState = (state: AppState): EngineeringScopingState =>
  state.engineering.scoping;

const engineeringBrowseState = (state: AppState): EngineeringBrowseState =>
  state.engineering.browse;

const engineeringSiteAuditState = (
  state: AppState,
): EngineeringSiteAuditState => state.engineering.siteAudit;

const leasingState = (state: AppState): LeasingState => state.leasing;
const permittingState = (state: AppState): PermittingState => state.permitting;
const projectState = (state: AppState): ProjectState => state.project;
const pmState = (state: AppState): ProjectManagementState =>
  state.projectManagement;

export namespace DirectMessageSelectors {
  export const getUserList = createSelector(
    [directMessageState],
    (state: DirectMessageState) => state.userList,
  );

  export const getDirectMessages = createSelector(
    [directMessageState],
    (state: DirectMessageState) => state.directMessages,
  );

  export const getProjectId = (areaKey: DirectMessageAreaKey | null) => {
    switch (areaKey) {
      case DirectMessageAreaKey.EngineeringBrowseFinancial:
      case DirectMessageAreaKey.EngineeringBrowseSiteAudit:
      case DirectMessageAreaKey.EngineeringBrowseSOF:
      case DirectMessageAreaKey.EngineeringBrowseSummary:
      case DirectMessageAreaKey.EngineeringBrowseWorkflow:
        return createSelector(
          [engineeringBrowseState],
          (state: EngineeringBrowseState) => state.engineeringProjectData.id,
        );
      case DirectMessageAreaKey.EngineeringScopingAssociatedProjects:
      case DirectMessageAreaKey.EngineeringScopingLeaseAudit:
      case DirectMessageAreaKey.EngineeringScopingEquipment:
      case DirectMessageAreaKey.EngineeringScopingSiteAudit:
      case DirectMessageAreaKey.EngineeringScopingLeaseEquipment:
      case DirectMessageAreaKey.EngineeringScopingSummary:
        return createSelector(
          [engineeringScopingState],
          (state: EngineeringScopingState) => state.currentProjectId,
        );
      case DirectMessageAreaKey.EngineeringSiteAudit:
        return createSelector(
          [engineeringSiteAuditState],
          (state: EngineeringSiteAuditState) => state.projectInfo.id,
        );
      case DirectMessageAreaKey.LeasingEquipment:
      case DirectMessageAreaKey.LeasingFinancial:
      case DirectMessageAreaKey.LeasingLeaseAudit:
      case DirectMessageAreaKey.LeasingSummary:
      case DirectMessageAreaKey.LeasingWorkflow:
        return createSelector(
          [leasingState],
          (state: LeasingState) => state.leasingDetailsData.id,
        );
      case DirectMessageAreaKey.PermittingContacts:
      case DirectMessageAreaKey.PermittingFinancial:
      case DirectMessageAreaKey.PermittingJurisdictionAudit:
      case DirectMessageAreaKey.PermittingSummary:
      case DirectMessageAreaKey.PermittingWorkflow:
        return createSelector(
          [permittingState],
          (state: PermittingState) => state.detailsData.id,
        );
      case DirectMessageAreaKey.ProjectFinancial:
      case DirectMessageAreaKey.ProjectSummary:
        return createSelector(
          [projectState],
          (state: ProjectState) => state.currentProject.id,
        );
      case DirectMessageAreaKey.ProjectManagementFinancial:
      case DirectMessageAreaKey.ProjectManagementSummary:
        return createSelector(
          [pmState],
          (state: ProjectManagementState) => state.currentProjectManagement.id,
        );
      case null:
        return createSelector([], () => null);
      default:
        throw new Error('Unknown DirectMessageAreaKey');
    }
  };

  export const getReceivedDirectMessagesForArea = createSelector(
    [directMessageState],
    (state: DirectMessageState) => state.receivedDirectMessages,
  );

  export const isFetching = createSelector(
    [directMessageState],
    (state: DirectMessageState) => state.isFetching,
  );
}
