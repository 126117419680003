import { arrsToSelectableObject } from '@core/utils/methods';
import { OptionProps } from '@models/interfaces';
import { getProjectFieldsList, getSiteFieldsList } from './fields-lists';

export const getSetFieldsOptions = (): OptionProps[] =>
  arrsToSelectableObject(
    [...getSiteFieldsList(), ...getProjectFieldsList()],
    'id',
    'label',
  );
