import React from 'react';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import PushpinImg from 'assets/icons/pushpin.png';
import { Icon } from 'leaflet';
import { Candidate } from '@models/interfaces';

const icon = new Icon({
  iconUrl: PushpinImg,
  iconSize: [16, 16],
  iconAnchor: [8, 8],
});

export const getMapMarkers = (candidates: Candidate[]) =>
  candidates.map(({ longitude, latitude, PSLocationCode, siteName }) => (
    <Marker position={[latitude, longitude]} key={PSLocationCode} icon={icon}>
      <Tooltip direction="bottom" opacity={1} permanent>
        {PSLocationCode}
      </Tooltip>
      <Popup>
        <div>
          <div className="leaflet-popup-title">{PSLocationCode}</div>
          <div className="leaflet-popup-body">
            {siteName ? <span>Site Name: {siteName}</span> : null}
          </div>
        </div>
      </Popup>
    </Marker>
  ));
