import { PermittingBrowseFilter } from '@models/interfaces';

export const PERMITTING_DEFAULT_BROWSE_CONFIGURE_FILTER: PermittingBrowseFilter =
  {
    state: undefined,
    jurisdiction: undefined,
    county: undefined,
    assignedDate: undefined,
    projectTypeId: undefined,
    towerOwner: undefined,
    zoningSubmittedDate: undefined,
    zoningSubmittedForecastedDate: undefined,
  };
