import { distructAndDispatch } from '../../core/utils/methods/destruct-and-dispatch';
import {
  ConfigProjectPaceTaskData,
  ProjectDetailsResponse,
  ProjectPaceTask,
  RadData,
  ReducerConfigPaceTaskData,
  User,
} from '../../models/interfaces';
import { ProjectActions } from '../actions/project.actions';
// eslint-disable-next-line no-restricted-imports
import store from '../index';

export namespace ProjectsEffects {
  export const setPaceTasks = distructAndDispatch<
    ProjectDetailsResponse,
    ProjectPaceTask[]
  >('paceTasks', ProjectActions.updateProjectPaceTasksAction);

  export const setPaceTaskFields = distructAndDispatch<
    ProjectDetailsResponse,
    ProjectPaceTask[]
  >('paceTaskFields', ProjectActions.updateProjectPaceTaskFields);

  export const updatePaceTaskFieldsByConfig = <
    T extends Partial<Record<keyof T, unknown>>,
  >(
    obj: T,
    tasks: ConfigProjectPaceTaskData<T>[],
  ): T => {
    const configTasks = tasks.map(
      ({
        field,
        ...task
      }: ConfigProjectPaceTaskData<T>): ReducerConfigPaceTaskData => ({
        ...task,
        date: obj[field] as ReducerConfigPaceTaskData['date'],
      }),
    );

    store.dispatch(
      ProjectActions.updateProjectPaceTaskFieldsByConfig.done(configTasks),
    );

    return obj;
  };

  export const setRAD = distructAndDispatch<ProjectDetailsResponse, RadData[]>(
    'RAD',
    ProjectActions.setRADAction,
  );

  export const setCurrentProvidenceAgent = distructAndDispatch<
    ProjectDetailsResponse,
    User
  >('providenceAgent', ProjectActions.setCurrentProvidenceAgent);

  export const setHoldOptions = (project: ProjectDetailsResponse) => {
    store.dispatch(ProjectActions.setHoldOptions.done(project));

    return project;
  };
}
