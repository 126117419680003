import { SiteOptions } from '@models/interfaces';

export const DETAILS_HEADER_OPTIONS: SiteOptions[] = [
  {
    key: 'siteName',
    label: 'Site Name',
  },
  {
    key: 'MDGLocationCode',
    label: 'MDG Location Code',
  },
];
