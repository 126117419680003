import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SiteInformation } from '@client/pages/site/components';
import { SiteSelectors, UserSelectors } from '@store/selectors';

import './styles.scss';

export const SiteCreate: FC = () => {
  const history = useHistory();
  const permissions = useSelector(UserSelectors.getSitePermissions('create'));
  const createdSiteUniqueFields = useSelector(
    SiteSelectors.getCreatedSiteUniqueFields,
  );

  return (
    <div className="prov-site-create">
      <SiteInformation
        permissions={{ siteInformationFields: permissions }}
        isEditing
        isNew
        data={createdSiteUniqueFields}
        history={history}
      />
    </div>
  );
};
